import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import DeleteRole from "../components/role/deleteRole";
import { Link } from "react-router-dom";
import { baseURL } from "../config/config";
import DataTable from "react-data-table-component";
import Loader from "../components/loader";
import DisplayError from "../components/ErrorComponants/DisplayError";
import EmptyData from "../components/emptyData";
import { UserContext } from "../context/UserContext";
import { PERMISSIONS, hasPermission } from "../components/checkPermissions";
import NotAuthorized from "../components/NotAuthorized";
import CreateRole from "./CreateRole";
import EditRole from "./EditRole";

const Role = () => {
  const [addRoleModal, setaddRoleModal] = React.useState();
  const [data, setData] = React.useState();
  const [roles, setRoles] = React.useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const { user } = useContext(UserContext);

  const hasUserEditPermission = hasPermission(user, PERMISSIONS.roles, "edit");
  const hasRolesCreatePermission = hasPermission(
    user,
    PERMISSIONS.roles,
    "create"
  );
  const hasRolesConsultPermission = hasPermission(
    user,
    PERMISSIONS.roles,
    "consult"
  );

  const hasRolesDeletePermission = hasPermission(
    user,
    PERMISSIONS.roles,
    "delete"
  );

  async function getAllRoles() {
    axios
      .get(`${baseURL}/roles`)
      .then((response) => {
        if (response.data.error) return;
        setRoles(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (hasRolesConsultPermission) {
      setData(<Loader />);
      getAllRoles();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasRolesConsultPermission]);

  useEffect(() => {
    if (!hasRolesConsultPermission) {
      return;
    }
    const filteredData = applyFilters(roles, [
      (data) => filterRoleByName(data, nameFilter),
    ]);
    if (nameFilter) {
      if (filteredData.length > 0) {
        setData(
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
          />
        );
      } else {
        setData(<EmptyData />);
      }
    } else {
      setData(
        <DataTable
          columns={columns}
          data={roles}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
        />
      );
    }
  }, [hasRolesConsultPermission, roles, nameFilter]);

  function applyFilters(data, filters) {
    let filteredData = data;

    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (nameFilter !== "") {
      sessionStorage.setItem("nameFilterRole", nameFilter);
    }
    return filteredData;
  }
  function handleRestoreFilter() {
    const nameFilterRole = sessionStorage.getItem("nameFilterRole");
    if (nameFilterRole !== null) {
      setNameFilter(nameFilterRole);
    }
    setTimeout(() => {
      sessionStorage.removeItem("nameFilterRole");
    }, 100);
  }

  function filterRoleByName(data, searchText) {
    return data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function handleResetFilter() {
    setNameFilter("");
  }
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "12%",
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => row.name,
      width: "60%",
      sortable: true,
    },
    {
      name: "Actions",
      width: "28%",
      sortable: true,
      cell: (row) => (
        <div className="row float-end">
          {hasUserEditPermission && (
            <div className="col-3 ">
              <button
                type="button"
                className=" btn text-primary me-2"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight2"
                aria-controls="offcanvasRight2"
                title="Modifier role"
                onClick={() => handleUpdateButtonClick(row.id)}
              >
                <img src={`../assets/img/edit-01.svg`} />
              </button>
            </div>
          )}
          {hasRolesDeletePermission && (
            <div className="col-3 ">
              <button
                type="button"
                className=" btn text-danger me-2 btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Supprimer role"
                onClick={() =>
                  setaddRoleModal(
                    <DeleteRole
                      setAddRoleModalFromChild={setaddRoleModal}
                      roleId={row.id}
                      getAllRoles={getAllRoles}
                    />
                  )
                }
              >
                <img src={`../assets/img/trash-01.svg`} />
              </button>
            </div>
          )}
        </div>
      ),
    },
  ];

  const [selectedForUpdate, setSelectedForUpdate] = useState(null);

  function handleUpdateButtonClick(roleId) {
    setSelectedForUpdate(roleId);
  }

  return (
    <>
      <div >
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <div className="d-flex align-items-center">
                      <i
                        className="ri-arrow-left-line"
                        style={{ color: "#0959AD", marginRight: "5px" }}
                      ></i>
                      <span style={{ color: "#0959AD" }}>Accueil</span>
                    </div>
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4 text-end">
            {hasRolesCreatePermission && (
              <button
                className="btn btn-primary  mb-2 ms-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                + Ajouter un Rôle
              </button>
            )}
          </div>
        </div>

        <div className="div_tabRoles">
          <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 ">
              <h4 className="fw-bold m-4">Liste des rôles</h4>
            </div>
            <div className="col-lg-3 col-sm-5 col-11 ms-2 ms-md-0">
              <div className="groupFiltre">
                <svg
                  className="iconFiltre"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  placeholder="Taper un mot"
                  className="form-control inputFiltre my-3"
                  type="search"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
              </div>
            </div>

            <div className="col-lg-5 col-sm-12 mt-3 d-flex">
              <div className="col-lg-5 col-md-3 col-sm-12 ms-2">
                <button
                  type="button"
                  className="btn btn-reset "
                  onClick={handleResetFilter}
                >
                  <img src={`../assets/img/repeat-04.svg`} />
                  &nbsp;Réinitialiser
                </button>
              </div>
              <div className="col-lg-6 col-md-4 col-sm-12 ms-2">
                <button
                  type="button"
                  className="btn but-restore px-2"
                  onClick={handleRestoreFilter}
                >
                  <i className="ri-arrow-left-line"></i> Dernière recherche
                </button>
              </div>
            </div>
          </div>
          <section className="section">
            <div className="row">
              <div className="pt-3">{data}</div>
            </div>
            {addRoleModal}
          </section>
        </div>
      </div>
      {/* Modal for create role */}
      <CreateRole getAllRoles={getAllRoles} />
      {/* Modal for update role */}
      <EditRole
        getAllRoles={getAllRoles}
        selectedForUpdate={selectedForUpdate}
      />
    </>
  );
};

export default Role;
