import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import Loader from "../loader";
import DisplayError from "../ErrorComponants/DisplayError";

function EditRelaunchMission({
  getRelaunchMissionByMissionId,
  relaunchMissionId,
}) {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [time, setTime] = useState("");
  const today = new Date().toISOString().substr(0, 10);
  const [isLoading, setIsLoading] = useState(false);

  const initFormErrors = {
    title: [""],
    date: [""],
    description: [""],
    time: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);

  async function getData() {
    setTitle("");

    await axios
      .get(`${baseURL}/relaunchMission/${relaunchMissionId}`)
      .then((resp) => {
        // if (resp.data.error === true) return;
        setTitle(resp.data.data?.title ?? "");
        setDate(resp.data.data?.date ?? "");
        setDescription(resp.data.data?.description ?? "");
        setTime(resp.data.data?.time ?? "");
      })
      .catch(() => {
        DisplayError();
      });
  }
  useEffect(() => {
    setTitle();
    setDate();
    setDescription();
    setTime();
    if (
      relaunchMissionId === null ||
      relaunchMissionId === undefined ||
      relaunchMissionId === ""
    ) {
      return;
    }
    getData();
  }, [relaunchMissionId]);

  async function sendData() {
    setIsLoading(true);
    await axios
      .put(`${baseURL}/relaunchMission/${relaunchMissionId}`, {
        title: title,
        date: date,
        description: description,
        time: time,
      })
      .then((resp) => {
        setIsLoading(false);

        setFormErrors(initFormErrors);
        if (resp?.data?.error) return;
        swal(resp?.data?.message, {
          icon: "success",
          timer: 2500,
        });
        getRelaunchMissionByMissionId();
      })
      .catch((error) => {
        setIsLoading(false);
        setFormErrors(error.response.data);
      });
  }
  return (
    <div>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-1"
        id="relaunchMisionUpdate_offCanvas"
        aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header ">
          <h4 id="offcanvasRightLabel" className="fw-bold">
            Modifier un rappel
          </h4>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
        </div>
        <div className="offcanvas-body offcanvas-body-scrollable">
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Titre: *</label>
            <input
              type="text"
              defaultValue={title}
              className="form-control offcanvas-input"
              placeholder="Titre"
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.title}
              </span>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Date: *</label>
            <input
              type="date"
              defaultValue={date}
              className="form-control offcanvas-input"
              onChange={(e) => setDate(e.target.value)}
              required
              id="date"
              placeholder="Date"
              min={today}
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.date}
              </span>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Temps: *</label>
            <input
              type="time"
              defaultValue={time}
              className="form-control offcanvas-input"
              onChange={(e) => {
                setTime(e.target.value);
              }}
              required
              id="time"
              placeholder="time"
              min={today}
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.time}
              </span>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Description: *</label>
            <input
              className="form-control offcanvas-input"
              type="text"
              id="description"
              defaultValue={description}
              placeholder="Description"
              rows="4"
              onChange={(e) => setDescription(e.target.value)}
              required
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.description}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2 pb-1">
            <button
              type="button"
              className="btn btn-primary"
              onClick={sendData}
              disabled={isLoading}>
              {isLoading ? <Loader /> : "Ajouter"}
            </button>
          </div>
          <div className="row px-2 pt-1 pb-2">
            <button
              id="cancelButton"
              type="button"
              className="btn but-restore"
              data-bs-dismiss="offcanvas"
              aria-label="Close">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRelaunchMission;
