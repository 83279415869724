import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { CandidatContext } from "../../../context/candidatContext";
import { baseURL } from "../../../config/config";
const initFormErrors = {
  status: [""],
  city: [""],
  address: [""],
  cp: [""],
};

const PageTwoUpdate = ({ nextpage, previuosPage }) => {
  const msg = "Ce champ est obligatoire";
  const [countries, setCountries] = React.useState([]);
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [city, setCity] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(
    newCandidat?.countryid
  );

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }
  const handlecountry = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountryId(selectedCountryId);
    setNewCandidat((prevState) => ({
      ...prevState,
      countryid: selectedCountryId,
    }));
  };

  function getCities(countryid) {
    if (!countryid) {
      return;
    }
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) {
        setCity(resp.data.data);
        return;
      }
      setCity(resp.data.data);
    });
  }
  useEffect(() => {
    getCities(selectedCountryId);
  }, [selectedCountryId]);

  useEffect(() => {
    getCountries();
  }, []);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat?.countryid !== "") {
      if (newCandidat?.city === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          city: msg,
        }));
      }
    }
    if (error === false) {
      nextpage("pagethree");
    }
  }
  return (
    <div className="mt-4">
      <div className="row px-2 py-2 ">
        <img src={`../assets/img/progressBar2.png`} />
        <label className="px-0 mb-2 text-500 mt-4">Etat Civil </label>
        <div className="input-group mb-2 px-0">
          <select
            id="inputEtatCivil"
            value={newCandidat?.status}
            className="form-select offcanvas-input"
            placeholder="Etat civil"
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                status: e.target.value,
              }));
            }}>
            <option disabled selected value="">
              Sélectionner votre état civil
            </option>
            <option value="single">Célibataire</option>
            <option value="divorced">Divorcé</option>
            <option value="in_relationship">En couple</option>
            <option value="married">Marié</option>
            <option value="pacs">Pacsé</option>
          </select>
        </div>
        <div className="input-error">
          <span className="text-danger text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.status}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Adresse</label>
        <input
          type="text"
          className="form-control offcanvas-input"
          placeholder="Adresse"
          value={newCandidat?.address}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              address: e.target.value,
            }));
          }}
          required
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.address}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Pays</label>
        <div className="input-group mb-2 px-0">
          <select
            value={newCandidat.countryid}
            className="form-select offcanvas-input"
            onChange={(e) => handlecountry(e)}>
            <option disabled selected value="">
              Sélectionner une pays
            </option>
            {countries != null &&
              countries.map((type, index) => {
                return (
                  <option key={index} value={type.id}>
                    {type.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="input-error">
          <span className="text-danger text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.countryid}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Ville</label>
        <div className="input-group mb-2 px-0">
          <select
            value={newCandidat?.city}
            onChange={(e) => {
              const selectedCityId = e.target.value;
              const selectedCity = city.find(
                (cityIteration) => cityIteration.id === selectedCityId
              );
              setNewCandidat((prevState) => ({
                ...prevState,
                city: selectedCityId,
                cityName: selectedCity ? selectedCity.name : "",
              }));
            }}
            className="form-select offcanvas-input">
            <option disabled selected value="">
              Sélectionner une ville
            </option>
            {city != null &&
              city.map((cityIteration, index) => {
                return (
                  <option key={index} value={cityIteration?.id}>
                    {cityIteration?.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="input-error">
          <span className="text-danger text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.city}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Adresse</label>
        <input
          className="form-control offcanvas-input"
          placeholder="4000"
          min="0"
          type="number"
          value={newCandidat?.cp}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              cp: e.target.value,
            }));
          }}
          required
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.cp}
          </span>
        </div>
      </div>
      <div className="row px-2 pt-2 pb-1">
        <button type="button" className="btn btn-add" onClick={validate}>
          Continuer
        </button>
      </div>
      <div className="row px-2 pt-1 pb-2">
        <button
          type="button"
          className="btn but-restore"
          onClick={() => previuosPage("pageone")}>
          Retour
        </button>
      </div>
    </div>
  );
};

export default PageTwoUpdate;
