import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {BsPencilSquare } from "react-icons/bs";
import DataTable from "react-data-table-component";
import { baseURL } from "../../../config/config";
import Createcategory from "../../../components/invoiceCategory/Createcategory";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Updatecategory } from "../../../components/invoiceCategory/Updatecategory";
import DisplayError from "../../../components/ErrorComponants/DisplayError";
const Getcategory = () => {
  const [data, setdata] = useState([]);
  const [displayModal, setDisplayModal] = useState();

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "15%",
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => <div>{row.Name}</div>,
      width: "60%",
      sortable: true,
    },

    {
      name: "Actions",
      width: "25%",
      cell: (row) => (
        <div className="row col-12">
          <button
            type="button"
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisable(row.id, row.isEnabled);
            }}
          >
            {row.isEnabled === "yes" ? (
              <i
                className="text-success bi bi-toggle2-on"
                data-toggle="tooltip"
                data-placement="top"
                title="Désactiver categorie"
              ></i>
            ) : (
              <i
                className="text-danger bi bi-toggle2-off"
                data-toggle="tooltip"
                data-placement="top"
                title="Activer categorie"
              ></i>
            )}
          </button>
          <button
            className="col-3 btn text-primary  btn"
            onClick={() =>
              setDisplayModal(
                <Updatecategory
                  getdata={getdata}
                  setDisplayModal={setDisplayModal}
                  Id={row.id}
                />
              )
            }
          >
            <BsPencilSquare className="text-primary"></BsPencilSquare>
          </button>

          <div className="hstack gap-3"></div>
        </div>
      ),
    },
  ];

  function getdata() {
    axios
      .get(`${baseURL}/category/getAllCategory`)

      .then((res) => {
        setdata(res.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    getdata();
  }, []);

  async function enableDisable(Id, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette catégorie?"
        : "Voulez vous Activer cette catégorie?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmed) => {
        if (confirmed) {
          axios.get(`${baseURL}/category/updateStatus/${Id}`).then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getdata();
            }
          });
        }
      })
      .catch((error) => {
        DisplayError();
      });
  }
  return (
    <>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Liste des catégories</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2">
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>Accueil
                  </Link>
                </li>
                <li className="breadcrumb-item active">Liste des catégories</li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-4 text-end">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end text-sm"
              onClick={() =>
                setDisplayModal(
                  <Createcategory
                    getdata={getdata}
                    setaddCreateModal={setDisplayModal}
                  />
                )
              }>
              + Ajouter une catégorie
            </button>
          </div>
        </div>
      </div>

      <div className="card mt-3 ">
        <DataTable
          className="dataTableClass"
          columns={columns}
          data={data}
          pagination></DataTable>
      </div>
      {displayModal}
    </>
  );
};
export default Getcategory;
