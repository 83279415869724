import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import permissions from "../../components/permissions";
import Loader from "../../components/loader";
import DisplayError from "../ErrorComponants/DisplayError";
import EmptyData from "../emptyData";
import Pusher from "pusher-js";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AllNotificationsOffCanvas = ({ getAllUsers, roles, getRoles }) => {
  const { user } = useContext(UserContext);
  const [allNotifications, setAllNotifications] = useState([]);

  async function downloadFile(path, isRead, notificationId) {
    if (path) {
      fetch(baseURL + path, {
        method: "GET",
        headers: {
          "Content-Type": "file/txt",
        },
      })
        .then((response) => response.blob())
        .then(async (blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `resultat.txt`);
          link.click();
          if (isRead === "no") {
            await axios
              .get(`${baseURL}/notifications/markAsRead/${notificationId}`)
              .then((response) => {
                Toastify({
                  text: "Notification marquer comme lu",
                  destination: url,
                  duration: 5000,
                  close: true,
                  gravity: "bottom",
                  position: "right",
                  stopOnFocus: true,
                  style: {
                    background: "linear-gradient(to right, #0f8499 , #0f8499 )",
                  },
                }).showToast();
              })
              .catch((err) => {
                DisplayError();
              });
          }
        });
    }
  }
  async function getAllNotification() {
    await axios
      .get(`${baseURL}/notifications/allNotifications/${user.id}`)
      .then((response) => {
        if (response) {
          setAllNotifications(response.data.data);
        }
      })
      .catch((err) => {
        DisplayError();
      });
  }
  async function getLastNotification() {
    await axios
      .get(`${baseURL}/notifications/newNotifications/${user.id}`)
      .then((response) => {
        if (response) {
          setAllNotifications(response.data.data);
        }
      })
      .catch((err) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (user !== null) {
      getLastNotification();

      var pusher = new Pusher("b1c9304f5b95a9ae97e2", {
        cluster: "eu",
      });
      var channel = pusher.subscribe(`${user.id}`);
      channel.bind("fileManipulation", async function (data) {
        const message = data.message;
        getLastNotification();
        Toastify({
          text: message,
          duration: 5000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #0f8499 , #0f8499 )",
          },
        }).showToast();
      });
    }
  }, [user]);

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="allNotificationsOffCanvas"
      aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel" className="fw-bold">
          Notifications
        </h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div className="row px-2 py-2">
          {allNotifications.length > 0 ? (
            allNotifications.map((notif) => {
              return (
                <div className="px-2 mx-1 mb-4 card notification-item-card">
                  <div className="row">
                    <div className="d-flex justify-content-center align-items-center col-2">
                      {notif?.treatmentStatus !== "success" ? (
                        <svg
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect
                            x="3"
                            y="3"
                            width="32"
                            height="32"
                            rx="16"
                            fill="#FEE4E2"
                          />
                          <path
                            d="M20.3327 12.5136V15.2673C20.3327 15.6407 20.3327 15.8274 20.4053 15.97C20.4693 16.0954 20.5712 16.1974 20.6967 16.2613C20.8393 16.334 21.026 16.334 21.3993 16.334H24.153M24.3327 17.6595V22.4673C24.3327 23.5874 24.3327 24.1475 24.1147 24.5753C23.9229 24.9516 23.617 25.2576 23.2407 25.4493C22.8128 25.6673 22.2528 25.6673 21.1327 25.6673H16.866C15.7459 25.6673 15.1859 25.6673 14.758 25.4493C14.3817 25.2576 14.0757 24.9516 13.884 24.5753C13.666 24.1475 13.666 23.5874 13.666 22.4673V15.534C13.666 14.4139 13.666 13.8538 13.884 13.426C14.0757 13.0497 14.3817 12.7437 14.758 12.552C15.1859 12.334 15.7459 12.334 16.866 12.334H19.0072C19.4964 12.334 19.741 12.334 19.9711 12.3892C20.1752 12.4382 20.3703 12.519 20.5493 12.6287C20.7511 12.7524 20.924 12.9253 21.2699 13.2712L23.3954 15.3967C23.7413 15.7426 23.9143 15.9156 24.038 16.1174C24.1476 16.2964 24.2284 16.4914 24.2774 16.6955C24.3327 16.9257 24.3327 17.1703 24.3327 17.6595Z"
                            stroke="#D92D20"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <rect
                            x="3"
                            y="3"
                            width="32"
                            height="32"
                            rx="16"
                            stroke="#FEF3F2"
                            stroke-width="4"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect
                            x="3"
                            y="3"
                            width="32"
                            height="32"
                            rx="16"
                            fill="#BEDEFF"
                          />
                          <path
                            d="M20.3327 12.5136V15.2673C20.3327 15.6407 20.3327 15.8274 20.4053 15.97C20.4693 16.0954 20.5712 16.1974 20.6967 16.2613C20.8393 16.334 21.026 16.334 21.3993 16.334H24.153M24.3327 17.6595V22.4673C24.3327 23.5874 24.3327 24.1475 24.1147 24.5753C23.9229 24.9516 23.617 25.2576 23.2407 25.4493C22.8128 25.6673 22.2528 25.6673 21.1327 25.6673H16.866C15.7459 25.6673 15.1859 25.6673 14.758 25.4493C14.3817 25.2576 14.0757 24.9516 13.884 24.5753C13.666 24.1475 13.666 23.5874 13.666 22.4673V15.534C13.666 14.4139 13.666 13.8538 13.884 13.426C14.0757 13.0497 14.3817 12.7437 14.758 12.552C15.1859 12.334 15.7459 12.334 16.866 12.334H19.0072C19.4964 12.334 19.741 12.334 19.9711 12.3892C20.1752 12.4382 20.3703 12.519 20.5493 12.6287C20.7511 12.7524 20.924 12.9253 21.2699 13.2712L23.3954 15.3967C23.7413 15.7426 23.9143 15.9156 24.038 16.1174C24.1476 16.2964 24.2284 16.4914 24.2774 16.6955C24.3327 16.9257 24.3327 17.1703 24.3327 17.6595Z"
                            stroke="#0C6FD6"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <rect
                            x="3"
                            y="3"
                            width="32"
                            height="32"
                            rx="16"
                            stroke="#D4E9FF"
                            stroke-width="4"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="col-8">
                      {notif.type === "relaunchMission" ||
                      notif.type === "RdvNotification" ? (
                        <div>{notif?.title ?? ""}</div>
                      ) : (
                        <div>{notif?.file?.path?.split("/logs/")?.pop()}</div>
                      )}
                      <div>{notif.message}</div>
                      {notif.type === "RdvNotification" ? (
                        <Link
                          className="color-msit"
                          to={`/candidats/details/${notif?.extraInfo?.consultant?._id}`}>
                          {notif?.extraInfo?.consultant?.firstname +
                            " " +
                            notif?.extraInfo?.consultant?.lastname}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    {notif?.file?.path && (
                      <div className="col-2 px-2 d-flex align-items-center justify-content-center">
                        <button
                          className="btn btn-outline-primary border-0 d-flex align-items-center justify-content-center"
                          onClick={() => {
                            downloadFile(
                              notif?.file?.path,
                              notif.isRead,
                              notif._id
                            );
                          }}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                              stroke="#475467"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    {notif.type === "relaunchMission" ||
                    notif.type === "RdvNotification" ? (
                      <div className="col-2 px-2 d-flex align-items-center justify-content-center">
                        <Link
                          className="color-msit "
                          to={`/missions/details/${notif?.extraInfo?.mission}`}>
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="Interface / External_Link">
                              <path
                                id="Vector"
                                d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11"
                                stroke="#000000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                          </svg>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <EmptyData></EmptyData>
          )}
        </div>
        <div className="row px-2 pt-1 pb-2">
          <button
            onClick={getAllNotification}
            className="btn btn-outline-primary">
            charger tous
          </button>
        </div>
        <div className="row px-2 pt-1 pb-2">
          <button
            type="button"
            className="btn but-restore"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasUpdateConsultant"
            aria-controls="offcanvasUpdateConsultant">
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};
export default AllNotificationsOffCanvas;
