import React, { useContext, useRef, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import swal from "sweetalert";
import { CandidatContext } from "../../../context/candidatContext";
import { baseURL } from "../../../config/config";
import { PERMISSIONS, hasPermission } from "../../checkPermissions";
import { UserContext } from "../../../context/UserContext";
import DisplayError from "../../ErrorComponants/DisplayError";

const initFormErrors = {
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  cv: [""],
  skills: [""],
  experience: [""],
};
const animatedComponents = makeAnimated();

const PageThreeUpdate = ({ nextpage, previuosPage }) => {
  const { user } = useContext(UserContext);
  const hasCandidatTypesCreatePermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "create"
  );
  const msg = "Ce champ est obligatoire";
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTypeOfContract, setSelectedTypeOfContract] = useState(null);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState();
  const mapSelectedToOptions = (selectedValues) => {
    return languesOptions.filter((option) =>
      selectedValues.includes(option.value)
    );
  };
  const [selectedLangues, setSelectedLangues] = useState();
  const [typeName, setTypeName] = useState();
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consult"
  );
  const thirdOffcanvasRef = useRef();
  const ouvrirThirdOffcanvas = () => {
    const thirdOffcanvas = new window.bootstrap.Offcanvas(
      thirdOffcanvasRef.current
    );
    thirdOffcanvas.show();
  };
  const [compAddTypeVisible, setCompAddTypeVisible] = useState(false);
  async function getAllCompanies() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        const newTypes = response.data.data
          .sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((item) => ({
            value: item._id,
            label: item.name,
          }));
        setCompanies(newTypes);
      })
      .catch(() => {
        DisplayError();
      });
  }
  function getAllTypes() {
    axios.get(`${baseURL}/candidatTypes/types`).then((resp) => {
      if (resp.data.error) return;

      const newTypes = resp.data.data
        .sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((item) => ({
          value: JSON.stringify(item),
          label: item.name,
        }));
      setTypes(newTypes);
    });
  }
  useEffect(() => {
    if (newCandidat && newCandidat.posteType) {
      const selectedTypeValue = {
        id: newCandidat?.posteType,
        name: newCandidat?.posteTypeName,
      };

      setSelectedType({
        value: selectedTypeValue,
        label: selectedTypeValue.name,
      });
    }
    if (
      newCandidat &&
      newCandidat.typeOfContract &&
      newCandidat.typeOfContract !== "none"
    ) {
      typeOfContracts.map((types) => {
        if (types.value === newCandidat.typeOfContract) {
          const selectedTypeOfContract = {
            id: newCandidat?.typeOfContract,
            name: types.label,
          };

          setSelectedTypeOfContract({
            value: selectedTypeOfContract,
            label: types.label,
          });
        }
      });
    }
    if (newCandidat && newCandidat.langues) {
      setSelectedLangues(mapSelectedToOptions(newCandidat.langues));
    }
    if (newCandidat && newCandidat.company) {
      let selectedcompanyValue = {
        id: newCandidat?.company,
        name: newCandidat?.companyName,
      };

      setSelectedCompany({
        value: selectedcompanyValue,
        label: selectedcompanyValue.name,
      });
    }
  }, [newCandidat]);
  useEffect(() => {
    getAllTypes();
    getAllCompanies();
  }, []);
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat.type !== "supplier") {
      if (newCandidat?.experience === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          experience: msg,
        }));
      }
      if (newCandidat?.skills === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          skills: msg,
        }));
      }

      if (newCandidat.type !== "candidat") {
        if (newCandidat?.posteType === "") {
          error = true;
          setErrorMessage((prevState) => ({
            ...prevState,
            posteType: msg,
          }));
        }

        if (newCandidat.type === "employee") {
          if (newCandidat?.salary === "") {
            error = true;
            setErrorMessage((prevState) => ({
              ...prevState,
              salary: msg,
            }));
          }
          if (newCandidat?.startDate === "") {
            error = true;
            setErrorMessage((prevState) => ({
              ...prevState,
              startDate: msg,
            }));
          }
          if (newCandidat?.typeOfContract === "none") {
            error = true;
            setErrorMessage((prevState) => ({
              ...prevState,
              typeOfContract: msg,
            }));
          }
          if (newCandidat?.nirpp === "") {
            error = true;
            setErrorMessage((prevState) => ({
              ...prevState,
              nirpp: msg,
            }));
          }
        }
        if (
          newCandidat?.typeOfContract === "soustraitence" &&
          newCandidat?.company === ""
        ) {
          error = true;
          setErrorMessage((prevState) => ({
            ...prevState,
            company: msg,
          }));
        }
      }
    }
    if (newCandidat?.posteType === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        posteType: msg,
      }));
    }
    // if (newCandidat?.cv === "") {
    //   error = true;
    //   setErrorMessage((prevState) => ({
    //     ...prevState,
    //     cv: msg,
    //   }));
    // }
    if (newCandidat?.cv) {
      if (newCandidat?.cvname === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          cvname: msg,
        }));
      }
    }
    if (error === false) {
      nextpage("pagefour");
    }
  }
  const typeOfContracts = [
    { value: "cdd", label: "cdd" },
    { value: "cdi", label: "cdi" },
    { value: "embauche", label: "Embauche Sous Mission" },
    { value: "freelance", label: "Freelance" },
    { value: "portage_salarial", label: "Portage Salarial" },
    { value: "soustraitence", label: "Sous Traitance" },
    { value: "internship", label: "Stage" },
  ];
  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  function sendData() {
    axios
      .post(`${baseURL}/candidatTypes/create`, {
        name: typeName,
      })
      .then((resp) => {
        setErrorMessage("");
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        openComponentAddType();
        if (hasCandidatTypesConsultPermission) {
          getAllTypes();
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  }

  const openComponentAddType = () => {
    setCompAddTypeVisible(!compAddTypeVisible);
  };
  const [uploadedCV, setUploadedCV] = useState(false);
  const [name, setName] = React.useState("");
  const [emailCompany, setEmail] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [phoneCompany, setPhoneCompany] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [webSite, setWebSite] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [cities, setCities] = React.useState();
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [countries, setCountries] = React.useState();
  const [descriptionCompany, setDescriptionCompany] = React.useState("");
  const [siren, setSiren] = React.useState("");
  const [siret, setSiret] = React.useState("");
  const [tvaNumber, setTvaNumber] = React.useState("");
  const [rcs, setRcs] = React.useState("");

  const initFormErrorsCompany = {
    name: [""],
    email: [""],
    phone: [""],
    owner: [""],
    webSite: [""],
    address: [""],
    city: [""],
    logo: [""],
    country: [""],
    siren: [""],
    siret: [""],
    tva_number: [""],
    rcs: [""],
    selectCity: "Veuillez sélectionner une ville.",
  };
  const [formerrorsCompany, setFormErrorsCompany] = React.useState(
    initFormErrorsCompany
  );
  const [avatarUploaded, setAvatarUploaded] = React.useState();
  const handleImageChange = (e) => {
    setLogo(e.target.files[0]);
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setAvatarUploaded(event.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  function getCities() {
    if (!country) return;
    axios.get(`${baseURL}/apiData/cities/${country}`).then((resp) => {
      if (resp.data.error) return;
      setCities(resp.data.data);
    });
  }
  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }

  useEffect(() => {
    getCities();
  }, [country]);

  useEffect(() => {
    getCountries();
  }, []);
  function createCompany() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("logo", logo);
    formData.append("email", emailCompany);
    formData.append("owner", owner);
    formData.append("phone", phoneCompany);
    formData.append("website", webSite);
    formData.append("address", address);
    formData.append("description", descriptionCompany);
    formData.append("siren", siren);
    formData.append("siret", siret);
    formData.append("tva_number", tvaNumber);
    formData.append("rcs", rcs);
    if (country) {
      if (!city) {
        setFormErrorsCompany({ city: errorMessage.selectCity });
        return;
      }
      formData.append("city", city);
      formData.append("country", country);
    }
    axios
      .post(`${baseURL}/companies/create`, formData)
      .then((resp) => {
        setFormErrorsCompany(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById("CloseCreateCompany");
        offcanvasButton.click();
        if (getAllCompanies) {
          getAllCompanies();
        }
        setName("");
        setEmail("");
        setOwner("");
        setPhoneCompany("");
        setAddress("");
        setWebSite("");
        setCity("");
        setCountry("");
        setDescriptionCompany("");
        setSiret("");
        setSiren("");
        setAvatarUploaded("");
        setTvaNumber("");
        setRcs("");
        setLogo("");
      })
      .catch((error) => {
        setFormErrorsCompany(error.response.data);
      });
  }
  function handleUpload(e) {
    const file = e.target.files[0];
    setNewCandidat((prevState) => ({
      ...prevState,
      cvname: file.name,
    }));
  }
  return (
    <div className="mt-4">
      <div className="row px-2 py-2">
        <img src={`../assets/img/progressBar3.png`} />
        <label className="px-0 mb-2 text-500 mt-4">
          Nombre d’années d’expérience *
        </label>
        <input
          className="form-control offcanvas-input"
          placeholder="4"
          min="0"
          type="number"
          value={newCandidat?.experience}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              experience: e.target.value,
            }));
          }}
          required
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.experience}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">
          Compétences * , séparation par(;)
        </label>
        <input
          type="text"
          className="form-control offcanvas-input"
          placeholder="nodejs;reactjs"
          value={newCandidat?.skills}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              skills: e.target.value,
            }));
          }}
          required
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.experience}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">langues</label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={languesOptions}
          onChange={(selectedOptions) => {
            const selectedValues = selectedOptions.map(
              (option) => option.value
            );
            setSelectedLangues(mapSelectedToOptions(selectedValues));
            setNewCandidat((prevState) => ({
              ...prevState,
              langues: JSON.stringify(selectedValues),
            }));
          }}
          value={selectedLangues}
          className="px-0"
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.langues}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Dernier diplôme obtenu</label>
        <div className="input-group mb-2 px-0">
          <input
            type="text"
            className="form-control offcanvas-input"
            placeholder="licence MDW"
            value={newCandidat?.diplome}
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                diplome: e.target.value,
              }));
            }}
            required
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.diplome}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Année de diplôme</label>
        <div className="input-group mb-2 px-0">
          <input
            className="form-control offcanvas-input"
            value={newCandidat?.diplomeDate}
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                diplomeDate: e.target.value,
              }));
            }}
            type="date"
            id="datePicker"
            required
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.diplomeDate}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Type de consultant *</label>
        <div className="col-10 px-0">
          <Select
            options={types}
            isSearchable
            value={selectedType}
            onChange={(selectedOption) => {
              const selectedTypeValue = JSON.parse(selectedOption.value);
              setSelectedType(selectedOption);

              setNewCandidat((prevState) => ({
                ...prevState,
                posteType: selectedTypeValue.id,
                posteTypeName: selectedTypeValue.name,
              }));
            }}
            className="px-0"
          />
        </div>
        {hasCandidatTypesCreatePermission && (
          <div className="col-2">
            <button className="btn btn-add" onClick={openComponentAddType}>
              <i className="bi bi-plus-lg"></i>
            </button>
          </div>
        )}
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.posteType}
          </span>
        </div>
        {compAddTypeVisible && (
          <div className="cardAddROle mt-2">
            <div className="mt-3 ms-3">
              <h4 id="fs-title">Ajouter un type de consultant</h4>
            </div>
            <div className="offcanvas-body offcanvas-body-scrollable">
              <div>
                <div>
                  <label>Type de consultant</label>
                  <input
                    type="text"
                    className="form-control"
                    id="role_name"
                    placeholder="Ajouter un type"
                    onChange={(e) => setTypeName(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    {errorMessage && (
                      <span className="text-danger">{errorMessage.type}</span>
                    )}
                  </div>
                </div>
                <div>
                  <div className="row mt-2 d-flex justify-content-center ">
                    <button
                      type="button"
                      className="btn btn-primary col-11"
                      onClick={sendData}>
                      Ajouter
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary col-11  mt-2"
                      onClick={openComponentAddType}>
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">nirpp :</label>
        <div className="input-group mb-2 px-0">
          <input
            min="0"
            type="number"
            className="form-control offcanvas-input"
            placeholder="nirpp"
            id="nirpp"
            value={newCandidat?.nirpp}
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                nirpp: e.target.value,
              }));
            }}
            required
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.nirpp}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Société :</label>
        <div className="col-10 px-0">
          <Select
            id="companyId"
            options={companies}
            isSearchable
            defaultInputValue={selectedCompany}
            onChange={(selectedCompanyOption) => {
              const selectedCompanyValue = selectedCompanyOption.value;
              const selectedCompanyName = selectedCompanyOption.label;
              setNewCandidat((prevState) => ({
                ...prevState,
                company: selectedCompanyValue,
                companyName: selectedCompanyName,
              }));
            }}
            className="px-0"
          />
          <div className="input-error">
            <span className="text-danger">{errorMessage?.company}</span>
          </div>
        </div>
        <div className="col-2">
          {hasCandidatTypesCreatePermission && (
            <div className="col-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={ouvrirThirdOffcanvas}
                ata-bs-toggle="offcanvas"
                data-bs-target="#ajouterSociete"
                aria-controls="ajouterSociete">
                <i className="bi bi-plus-lg"></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Type de contrat :</label>
        <div className="col-12 px-0">
          <Select
            id="contractId"
            options={typeOfContracts}
            isSearchable
            value={selectedTypeOfContract}
            onChange={(selectedOption) => {
              const selectedCompanyValue = selectedOption.value;
              setSelectedTypeOfContract(selectedOption);
              setNewCandidat((prevState) => ({
                ...prevState,
                typeOfContract: selectedCompanyValue,
              }));
            }}
            className="px-0"
          />
          <div className="input-error">
            <span className="text-danger">{errorMessage?.typeOfContract}</span>
          </div>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">TJM :</label>
        <div className="input-group mb-2 px-0">
          <input
            min="0"
            type="number"
            className="form-control offcanvas-input"
            placeholder="TJM"
            id="salary"
            value={newCandidat?.salary}
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                salary: e.target.value,
              }));
            }}
            required
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.salary}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Date début de travail :</label>
        <div className="input-group mb-2 px-0">
          <input
            className="form-control offcanvas-input"
            value={newCandidat?.startDate}
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                startDate: e.target.value,
              }));
            }}
            type="date"
            id="datePicker"
            required
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.startDate}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Upload cv *</label>
        <div className=" mb-2 px-0">
          <div className="card upload-cv-card pt-3">
            <div className="card-body text-center  ">
              <label
                forhtml="fileInput"
                className="upload-area"
                id="uploadArea">
                <div className=" mb-2 uploadInput">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="upload-icon-circle"
                    width="40"
                    height="40"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                      stroke="#475467"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <p className="text-400">
                  <span className="color-blue-700 fw-600">
                    Cliquer por choisir un cv
                  </span>
                  ou glisser directement <br />
                  <span className="fs-12 lh-18">
                    (Ici mettez les formats que vous voulez)
                  </span>
                </p>
                <input
                  type="file"
                  id="fileInput"
                  name="cv"
                  className="d-none"
                  accept=".pdf, .docx"
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      cv: e.target.files[0],
                    }));
                    setUploadedCV(true);
                    handleUpload(e);
                  }}
                />
              </label>
              {uploadedCV && (
                <p className="color-blue-700 fw-600 text-success">
                  Fichier téléchargé
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.cv}
          </span>
        </div>
      </div>
      <div className={`row px-2 py-2 ${uploadedCV ? "" : "d-none"}`}>
        <label className="px-0 mb-2 text-500">Nom de CV *</label>
        <div className="input-group mb-2 px-0">
          <input
            type="text"
            className="form-control offcanvas-input"
            placeholder="Nom de CV "
            value={newCandidat?.cvname}
            onChange={(e) => {
              setNewCandidat((prevState) => ({
                ...prevState,
                cvname: e.target.value,
              }));
            }}
            required
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.cvname}
          </span>
        </div>
      </div>
      <div className="row px-2 pt-2 pb-1">
        <button type="button" className="btn btn-add" onClick={validate}>
          Continuer
        </button>
      </div>
      <div className="row px-2 pt-1 pb-2">
        <button
          type="button"
          className="btn but-restore"
          onClick={() => previuosPage("pagetwo")}>
          Retour
        </button>
      </div>
      {/* ofCanvas pour ajouter une societe */}
      <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-1"
        id="thirdOffcanvas"
        aria-labelledby="thirdOffcanvasLabel"
        ref={thirdOffcanvasRef}>
        <div className="offcanvas-header ">
          <h4 id="thirdOffcanvas fw-bold">Ajouter une societe</h4>
          <button
            id="CloseCreateCompany"
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="CloseCreateCompany"></button>
        </div>
        <div className="offcanvas-body offcanvas-body-scrollable">
          <div>
            <div className="mt-4">
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <div className="bg-white">
                    <label
                      htmlFor="fileInput"
                      className="d-flex justify-content-center">
                      <div className="mb-2">
                        {avatarUploaded ? (
                          <img
                            src={avatarUploaded}
                            alt="Uploaded"
                            className="imgUploaded "
                          />
                        ) : (
                          <img
                            src="../../assets/img/input-pictures.png"
                            alt="Placeholder"
                            className=" uploadInput"
                          />
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      name="profile_picture"
                      className="d-none"
                      accept="image/png, image/gif, image/jpeg"
                      required
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-2">
                <label className="px-0 mb-2 text-500">Nom: *</label>
                <input
                  type="text"
                  className="form-control offcanvas-input"
                  placeholder="Nom"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.name}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Responsable : </label>
                <input
                  type="text"
                  className="form-control offcanvas-input"
                  placeholder="Responsable "
                  onChange={(e) => setOwner(e.target.value)}
                  value={owner}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.owner}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Téléphone : </label>
                <div className="input-group px-0">
                  <phoneInput
                    inputClass="phoneInput"
                    className="phoneInput"
                    placeholder="Téléphone"
                    value={phoneCompany}
                    onChange={(value, country, event) =>
                      setPhoneCompany(
                        event.target.value ? event.target.value : value
                      )
                    }
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.phone}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Email : </label>
                <input
                  type="text"
                  className="form-control offcanvas-input"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={emailCompany}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.email}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Site Web : </label>
                <div className="input-group px-0">
                  <input
                    placeholder="Site Web"
                    type="text"
                    className="form-control"
                    onChange={(e) => setWebSite(e.target.value)}
                    value={webSite}
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany?.website}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Adresse : </label>
                  <div className="input-group mb-2">
                    <input
                      placeholder="Adresse"
                      className="form-control  offcanvas-input"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.address}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Description : </label>
                  <div className="input-group mb-2 ">
                    <input
                      type="text"
                      placeholder="Description"
                      className="form-control offcanvas-input"
                      onChange={(e) => setDescriptionCompany(e.target.value)}
                      value={descriptionCompany}
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.description}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Siren : </label>
                  <div className="input-group mb-2">
                    <input
                      placeholder="Siren"
                      className="form-control offcanvas-input"
                      value={siren}
                      onChange={(e) => setSiren(e.target.value)}
                      min="0"
                      type="number"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsCompany.siren}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Siret : </label>
                  <div className="input-group mb-2 ">
                    <input
                      placeholder="Siret"
                      className="form-control offcanvas-input"
                      value={siret}
                      onChange={(e) => setSiret(e.target.value)}
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">
                    Numéro TVA intracommunautaire :
                  </label>
                  <div className="input-group mb-2">
                    <input
                      placeholder="Numéro TVA "
                      className="form-control offcanvas-input"
                      value={tvaNumber}
                      onChange={(e) => setTvaNumber(e.target.value)}
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">RCS : </label>
                  <div className="input-group mb-2 ">
                    <input
                      placeholder="RCS"
                      className="form-control offcanvas-input"
                      value={rcs}
                      onChange={(e) => setRcs(e.target.value)}
                      type="number"
                      min="0"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Pays : </label>
                  <div className="input-group mb-2">
                    <select
                      id="inputCountry"
                      className="form-select  offcanvas-input"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}>
                      <option value="" disabled>
                        Selectionner une pays ...
                      </option>
                      {countries != null &&
                        countries.map((country, key) => (
                          <option key={key} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                    </select>
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrorsCompany.country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Ville : </label>
                  <div className="input-group mb-2">
                    <select
                      id="inputCountry"
                      className="form-select  offcanvas-input"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}>
                      <option value="" disabled>
                        Sectionner une ville ...
                      </option>
                      {cities != null &&
                        cities.map((item, key) => {
                          return (
                            <option key={key} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrorsCompany.city}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-2 pt-2 pb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={createCompany}>
                  Ajouter
                </button>
              </div>
              <div className="row px-2 pt-1 pb-2">
                <button
                  id="CloseCreateCompany"
                  type="button"
                  className="btn but-restore"
                  data-bs-dismiss="offcanvas"
                  aria-label="CloseCreateCompany">
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageThreeUpdate;
