import React, {useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/loader";

const UpdatePasswordUser = ({ selectedForUpdate }) => {
  const [passwordReset, setPasswordReset] = React.useState();
  const [confirmPasswordReset, setConfirmPasswordReset] = React.useState();
  const [isLoading,setIsLoading] = useState(false);

  const initFormErrorsReset = {
    password: [""],
    confirmPassword: [""],
  };
  const [formerrorsReset, setFormErrorsReset] = useState(initFormErrorsReset);

  function sendDataReset() {
    setIsLoading(true);
    const userId = selectedForUpdate;
    axios
      .post(`${baseURL}/users/changePassword/${userId}`, {
        password: passwordReset,
        confirm_password: confirmPasswordReset,
      })
      .then((resp) => {
        setFormErrorsReset(initFormErrorsReset);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById("cancelButton3");
        offcanvasButton.click();
        setPasswordReset("");
        setConfirmPasswordReset("");
      })
      .catch((error) => {
        setFormErrorsReset(error.response.data);
        setIsLoading(false);
      });
  }
  function resetFormErrorsReset() {
    setPasswordReset("");
    setConfirmPasswordReset("");
    setFormErrorsReset(initFormErrorsReset);
  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="changerMotdepasse"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel fw-bold">Changer le mot de passe </h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetFormErrorsReset}
        ></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
            <div className="mt-4">
              <div className="row px-2">
                <label className="px-0 mb-2 text-500">
                  Nouveau Mot de Passe :
                </label>
                <input
                  type="password"
                  className="form-control offcanvas-input"
                  placeholder="Nouveau mot de passe"
                  onChange={(e) => setPasswordReset(e.target.value)}
                  value={passwordReset}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">
                    {formerrorsReset.password}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">
                  Confirmer Mot de Passe :
                </label>
                <input
                  type="password"
                  className="form-control offcanvas-input"
                  placeholder="Confirmer mot de passe "
                  onChange={(e) => setConfirmPasswordReset(e.target.value)}
                  value={confirmPasswordReset}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrorsReset.confirm_password}
                  </span>
                </div>
              </div>
              <div className="row px-2 pt-2 pb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendDataReset}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Enregistrer"}                  
                </button>
              </div>
              <div className="row px-2 pt-1 pb-2">
                <button
                  id="cancelButton3"
                  type="button"
                  className="btn but-restore"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={resetFormErrorsReset}
                >
                  Annuler
                </button>
              </div>
            </div>
      </div>
    </div>
  );
};
export default UpdatePasswordUser;
