import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import Loader from "../../components/loader";

const UpdateCompany = ({ getAllCompany, selectedForUpdate }) => {
  const [type, setType] = useState("no");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [owner, setOwner] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const [webSite, setWebSite] = useState("");
  const [address, setAddress] = useState("");
  const [cities, setCities] = useState();
  const [city_id, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState();
  const [description, setDescription] = useState();
  const [siren, setSiren] = useState("");
  const [siret, setSiret] = useState("");
  const [tvaNumber, setTvaNumber] = useState("");
  const [rcs, setRcs] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    owner: [""],
    webSite: [""],
    address: [""],
    city_id: [""],
    logo: [""],
    country: [""],
    description: [""],
    siren: [""],
    siret: [""],
    tva_number: [""],
    rcs: [""],
    type: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [avatarUploadedUpdate, setAvatarUploadedUpdate] = useState(null);
  function handleImageChangeUpdate(e) {
    setLogo(e.target.files[0]);
    const selectedFileUpdate = e.target.files[0];
    if (selectedFileUpdate) {
      const readerUpdate = new FileReader();
      readerUpdate.onload = (event) => {
        setAvatarUploadedUpdate(event.target.result);
      };
      readerUpdate.readAsDataURL(selectedFileUpdate);
    }
  }
  function getCities() {
    if (country === undefined || country === "") return;
    axios.get(`${baseURL}/apiData/cities/${country}`).then((resp) => {
      if (resp.data.error) return;
      setCities(resp.data.data);
    });
  }
  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }

  function getCompany() {
    const companyId = selectedForUpdate;
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/companies/${companyId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const companyData = resp.data.data;
        setName(companyData.name);
        setEmail(companyData.email);
        setAddress(companyData.address);
        setPhone(companyData.phone);
        setWebSite(companyData.website);
        setCity(companyData.city_id);
        setCountry(companyData.country);
        setOwner(companyData.owner);
        setDescription(companyData.description);
        setSiren(companyData.siren);
        setSiret(companyData.siret);
        setTvaNumber(companyData.tva_number);
        setRcs(companyData.rcs);
        setLogo(companyData.logo);
        setType(companyData.isClient);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (selectedForUpdate !== null) {
      getCompany();
    }
  }, [selectedForUpdate]);
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getCities();
  }, [country]);

  function sendData() {
    setIsLoading(true);
    const companyId = selectedForUpdate;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("logo", logo);
    formData.append("email", email);
    formData.append("owner", owner);
    formData.append("phone", phone);
    formData.append("website", webSite);
    formData.append("address", address);
    formData.append("description", description);
    formData.append("siren", siren);
    formData.append("siret", siret);
    formData.append("tva_number", tvaNumber);
    formData.append("isClient", type);
    formData.append("rcs", rcs);
    // Check if a country is selected
    if (country) {
      if (!city_id) {
        setFormErrors({ city_id: "Veuillez sélectionner une ville." });
        setIsLoading(false);
        return;
      }
      formData.append("city", city_id);
      formData.append("country", country);
    }
    axios
      .put(`${baseURL}/companies/${companyId}`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const boutonOffcanvas = document.getElementById(
          "cancelButtonUpdateCompany"
        );
        boutonOffcanvas.click();
        getCompany();
        getAllCompany();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setIsLoading(false);
      });
  }
  function resetFormErrors() {
    setFormErrors(initFormErrors);
  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="modifierUtilisateur"
      aria-labelledby="modifierUtilisateur">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel fw-bold">Modifier Société</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetFormErrors}></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div>
          <div className="mt-4">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="bg-white">
                  <label
                    htmlFor="fileInputUpdateCompany"
                    className="d-flex justify-content-center">
                    <div className="mb-2">
                      {avatarUploadedUpdate ? (
                        <img
                          src={avatarUploadedUpdate}
                          alt="Uploaded"
                          className="imgUploaded "
                        />
                      ) : (
                        <img src={logo} className="imgUploaded " />
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileInputUpdateCompany"
                    className="d-none"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    required
                    onChange={(e) => handleImageChangeUpdate(e)}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2">
              <label className="px-0 text-500">Type : *</label>
              <div className="col-12 py-2 px-0">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input offcanva-checkbox"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    value="no"
                    checked={type === "no"}
                  />
                  <label className="text-500" htmlFor="inlineRadio1">
                    Esn
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input offcanva-checkbox"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="yes"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    checked={type === "yes"}
                  />
                  <label className="text-500" htmlFor="inlineRadio2">
                    Client
                  </label>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors?.type}
                  </span>
                </div>
              </div>
            </div>
            <div className="row px-2">
              <label className="px-0 mb-2 text-500">Nom: *</label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Nom"
                onChange={(e) => setName(e.target.value)}
                defaultValue={name}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.name}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Responsable : </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Responsable "
                onChange={(e) => setOwner(e.target.value)}
                value={owner}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.owner}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Téléphone : </label>
              <div className="input-group px-0">
                <PhoneInput
                  inputClass="phoneInput"
                  className="phoneInput"
                  placeholder="Téléphone"
                  value={phone}
                  onChange={(value, country, event) =>
                    setPhone(event.target.value ? event.target.value : value)
                  }
                />
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.phone}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Email : </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.email}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Site Web : </label>
              <div className="input-group px-0">
                <input
                  placeholder="Site Web"
                  type="text"
                  className="form-control"
                  onChange={(e) => setWebSite(e.target.value)}
                  value={webSite}
                />
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.website}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Adresse : </label>
                <div className="input-group mb-2">
                  <input
                    placeholder="Adresse"
                    className="form-control  offcanvas-input"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.address}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Description : </label>
                <div className="input-group mb-2 ">
                  <input
                    type="text"
                    placeholder="Description"
                    className="form-control offcanvas-input"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.description}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Siren : </label>
                <div className="input-group mb-2">
                  <input
                    placeholder="Siren"
                    className="form-control offcanvas-input"
                    value={siren}
                    onChange={(e) => setSiren(e.target.value)}
                    type="number"
                    min="0"
                    required
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.siren}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Siret : </label>
                <div className="input-group mb-2 ">
                  <input
                    placeholder="Siret"
                    className="form-control offcanvas-input"
                    value={siret}
                    onChange={(e) => setSiret(e.target.value)}
                    type="number"
                    min="0"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">
                  Numéro TVA intracommunautaire :
                </label>
                <div className="input-group mb-2">
                  <input
                    placeholder="Numéro TVA "
                    className="form-control offcanvas-input"
                    value={tvaNumber}
                    onChange={(e) => setTvaNumber(e.target.value)}
                    type="number"
                    min="0"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">RCS : </label>
                <div className="input-group mb-2 ">
                  <input
                    placeholder="RCS"
                    className="form-control offcanvas-input"
                    value={rcs}
                    onChange={(e) => setRcs(e.target.value)}
                    type="number"
                    min="0"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Pays : </label>
                <div className="input-group mb-2">
                  <select
                    id="inputCountry"
                    className="form-select  offcanvas-input"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}>
                    <option value="" disabled>
                      Selectionner une pays ...
                    </option>
                    {countries != null &&
                      countries.map((country, key) => (
                        <option key={key} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.country}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Ville : </label>
                <div className="input-group mb-2">
                  <select
                    id="inputCountry"
                    className="form-select  offcanvas-input"
                    onChange={(e) => setCity(e.target.value)}
                    value={city_id}>
                    <option value="" disabled>
                      Sectionner une ville ...
                    </option>
                    {cities != null &&
                      cities.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.city}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-2 pt-2 pb-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
                disabled={isLoading}>
                {isLoading ? <Loader /> : "Modifier"}
              </button>
            </div>
            <div className="row px-2 pt-1 pb-2">
              <button
                id="cancelButtonUpdateCompany"
                type="button"
                className="btn but-restore"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={resetFormErrors}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div>
    //   <div className="pagetitle">
    //     <div className="row">
    //       <div className="col-md-8">
    //         <h1>Modifier une société</h1>
    //       </div>
    //       <div className="col-md-4">
    //         <Link to="/company">
    //           <button
    //             type="button"
    //             className="btn btn-outline-secondary mb-2 float-end  me-2"
    //           >
    //             Retour
    //           </button>
    //         </Link>
    //       </div>
    //     </div>

    //     <nav>
    //       <ol className="breadcrumb">
    //         <li className="breadcrumb-item">
    //           <Link to={"/"}>
    //             <i className="bi bi-house-door"></i>
    //           </Link>
    //         </li>
    //         <li className="breadcrumb-item">
    //           <Link to={"/company"}>Société</Link>
    //         </li>
    //         <li className="breadcrumb-item active">Modifier la société</li>
    //       </ol>
    //     </nav>
    //   </div>
    //   <section className="section">
    //     <div className="row">
    //       <div className="col-lg-12"></div>
    //       <div className="card">
    //         <div className="card-body pt-3">
    //           <form className="row g-3">
    //             <div className="col-md-6">
    //               <label>Nom</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={name != null && name}
    //                 onChange={(e) => setName(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.name}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Responsable</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={owner != null && owner}
    //                 onChange={(e) => setOwner(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.owner}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Téléphone</label>
    //               <PhoneInput
    //                 inputClass="phoneInput"
    //                 className="phoneInput"
    //                 placeholder="Téléphone"
    //                 value={phone != null && phone}
    //                 onChange={(value, country, event) =>
    //                   setPhone(event.target.value ? event.target.value : value)
    //                 }
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.phone}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Email</label>
    //               <input
    //                 type="email"
    //                 className="form-control"
    //                 value={email != null && email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.email}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Site Web</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={webSite != null && webSite}
    //                 onChange={(e) => setWebSite(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.website}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Adresse</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={address != null && address}
    //                 onChange={(e) => setAddress(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.address}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Description</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={description != null && description}
    //                 onChange={(e) => setDescription(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">
    //                   {formerrors.description}
    //                 </span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Siren</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={siren != null && siren}
    //                 onChange={(e) => setSiren(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.siren}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Siret</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={siret != null && siret}
    //                 onChange={(e) => setSiret(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.siret}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Numéro TVA intracommunautaire</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={tvaNumber != null && tvaNumber}
    //                 onChange={(e) => setTvaNumber(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.tva_number}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>RCS</label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 value={rcs != null && rcs}
    //                 onChange={(e) => setRcs(e.target.value)}
    //               />
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.rcs}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Pays</label>
    //               <select
    //                 id="inputCountry"
    //                 className="form-select"
    //                 value={country !== null ? country : ""}
    //                 onChange={(e) => {
    //                   setCountry(e.target.value);
    //                   setCity("");
    //                 }}
    //               >
    //                 <option disabled value="">
    //                   Sectionner un Pays ...{" "}
    //                 </option>
    //                 {countries != null &&
    //                   countries.map((countryItem, key) => {
    //                     return (
    //                       <option
    //                         key={key}
    //                         value={countryItem.id}
    //                         selected={country === countryItem.id}
    //                       >
    //                         {countryItem.name}
    //                       </option>
    //                     );
    //                   })}
    //               </select>
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.country}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-6">
    //               <label>Ville</label>
    //               <select
    //                 id="inputState"
    //                 className="form-select"
    //                 value={city_id !== null ? city_id : ""}
    //                 onChange={(e) => setCity(e.target.value)}
    //               >
    //                 <option disabled value="">
    //                   Sectionner une ville ...
    //                 </option>
    //                 {cities != null &&
    //                   cities.map((item, key) => {
    //                     return (
    //                       <option
    //                         key={key}
    //                         value={item.id}
    //                         selected={city_id === item.id}
    //                       >
    //                         {item.name}
    //                       </option>
    //                     );
    //                   })}
    //               </select>
    //               <div className="input-error">
    //                 <span className="text-danger">{formerrors.city_id}</span>
    //               </div>
    //             </div>
    //             <div className="col-md-4">
    //               <label>Logo</label>
    //               <input
    //                 type="file"
    //                 accept="image/png, image/gif, image/jpeg"
    //                 className="form-control"
    //                 onChange={(e) => setLogo(e.target.files[0])}
    //               />
    //             </div>

    //             <div className="text-end">
    //               <Link to="/company">
    //                 <button
    //                   type="button"
    //                   className="btn btn-outline-secondary  me-2"
    //                 >
    //                   Annuler
    //                 </button>
    //               </Link>
    //               <button
    //                 type="button"
    //                 className="btn btn-primary"
    //                 onClick={sendData}
    //               >
    //                 Enregistrer
    //               </button>
    //             </div>
    //           </form>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </div>
  );
};
export default UpdateCompany;
