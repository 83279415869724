import { Link } from "react-router-dom";
import { baseURL } from "../../config/config";
import React, { useMemo, useState } from "react";
import CandidatsExtractingFormStep1 from "../candidatExtractingForm/CandidatsExtractingFormStep1";
import axios from "axios";
import { CandidatContext } from "../../context/candidatContext";
import CircularProgress from "@mui/material/CircularProgress";
import DisplayError from "../ErrorComponants/DisplayError";

function FileExtraction() {
  const [file, setFile] = useState();
  const [candidatId, setCandidatId] = useState();
  const [loading, setLoading] = useState(false);
  const [childComponent, setChildComponent] = useState();
  const [newCandidat, setNewCandidat] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    cityName: "",
    address: "",
    cp: "",
    phone: "",
    birthDay: "",
    birthPlace: "",
    diplomeDate: "",
    diplome: "",
    type: "",
    typename: "",
    langues: "",
    status: "",
    skills: "",
    experience: "",
    avatar: "",
    cv: "",
    cvname: "",
  });
  function handleCvUpload(file) {
    setLoading(!loading);
    const data = new FormData();
    data.append("cv", file);
    axios.post(`${baseURL}/cv/add`, data).then(async (resp) => {
      const cvID = resp.data.cvId;
      axios
        .get(`${baseURL}/cv/getcvextract/${cvID}`)
        .then(async (resp) => {
          setFile(resp.data);
          setLoading(false);
          setNewCandidat((prevState) => ({
            ...prevState,
            cv: cvID,
          }));
          setChildComponent(
            <CandidatsExtractingFormStep1
              setChildComponent={setChildComponent}
              candidatId={candidatId}
              setCandidatId={setCandidatId}
            />
          );
        })
        .catch((err) => {
          setLoading(false);
          DisplayError();
        });
    });
  }

  const value = useMemo(
    () => ({ newCandidat, setNewCandidat }),
    [newCandidat, setNewCandidat]
  );
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Extraction des donnees</h1>
          </div>
          <div className="col-md-4">
            <Link to="/candidats">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2">
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/candidats"}>Candidats</Link>
                </li>
                <li className="breadcrumb-item active">
                  Extraction des donnees
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body  pt-3">
                <div className="row">
                  <div className="col-12">
                    <h3>Inserer un CV</h3>
                    <div className="custom-file">
                      <input
                        type="file"
                        accept=".pdf, .docx"
                        onChange={(e) => {
                          handleCvUpload(e.target.files[0]);
                        }}
                        className="form-control"
                      />
                    </div>

                    <div className="d-flex justify-content-center mb-5">
                      {loading ? (
                        ""
                      ) : file ? (
                        <iframe
                          src={baseURL + file?.cv?.pathcv.substring(1)}
                          width="900px"
                          height="500px"
                          title="CV Document"
                        />
                      ) : null}
                    </div>
                  </div>
                  {loading ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <CircularProgress />
                      </div>
                    </>
                  ) : file ? (
                    <>
                      <div className="col-lg-8">
                        <div>
                          <CandidatContext.Provider value={value}>
                            {childComponent}
                          </CandidatContext.Provider>
                        </div>
                      </div>
                      <div class="col-lg-4 ">
                        <textarea
                          value={file?.extracted}
                          className="form-control"
                          rows="20"
                          cols="30"></textarea>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default FileExtraction;
