import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import Select from "react-select";
import DisplayError from "../ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";
import PhoneInput from "react-phone-input-2";
import Loader from "../loader";
function EditMission({
  companyData,
  getManagerByCompanyId,
  managers,
  selectedMissionForUpdate,
  getMissionByCompanyId,
  getMissions,
  refreshAllMission,
}) {
  const { user } = useContext(UserContext);
  const [title, setTitle] = useState("");
  const [langues, setlangues] = useState("");
  const [skills, setskills] = useState("");
  const [programminglanguage, setprogramminglanguage] = useState("");
  const [duration, setduration] = useState("");
  const [description, setDescription] = useState("");
  const [period, setPeriod] = useState("");
  const [startDate, setStartDate] = useState("");
  const [manager, setManager] = useState("");
  const [company, setcompany] = useState(companyData ? companyData : "");
  const [companies, setcompanies] = useState();
  const [client, setClient] = useState("");
  const [allmanagers, setallmanagers] = useState(managers ? managers : "");
  const [isLoading, setIsLoading] = useState(false);
  const [salary, setSalary] = useState();
  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    owner: [""],
    webSite: [""],
    address: [""],
    city: [""],
    logo: [""],
    country: [""],
    siren: [""],
    siret: [""],
    tva_number: [""],
    rcs: [""],
    client: [""],
    selectCity: "Veuillez sélectionner une ville.",
  };
  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const hasManagerCreatePermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "create"
  );
  async function getMissionById() {
    const missionId = selectedMissionForUpdate ?? null;
    if (missionId === undefined || missionId === null || missionId === "")
      return;
    await axios.get(`${baseURL}/missions/${missionId}`).then((resp) => {
      if (resp.data.error) return;
      const data = resp.data.data;
      setTitle(data?.title ?? "");
      setprogramminglanguage(data?.programminglanguage ?? "");
      setlangues(data?.langues ?? "");
      setStartDate(data?.startDate ?? "");
      setDescription(data?.description ?? "");
      setPeriod(data?.period ?? "");
      setClient(data?.client?._id ?? null);
      setcompany(data?.company ?? "");
      setduration(data?.duration ?? "");
      setskills(data?.skills ?? "");
      setSalary(data?.salary ?? "");
      setManager(data?.manager?._id ?? "");

      if (company?._id) {
        getallManagerByCompanyId(company?._id);
      }
    });
  }

  async function getallManagerByCompanyId(companyid) {
    if (companyid === undefined) return;
    await axios
      .get(`${baseURL}/managers/${companyid}`)
      .then((response) => {
        if (response.data.error) return;
        setallmanagers(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  async function getAllCompany() {
    axios.get(`${baseURL}/companies/`).then((response) => {
      if (response.data.error) return;
      setcompanies(
        response.data.data.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      );
    });
  }

  useEffect(() => {
    if (
      selectedMissionForUpdate === null ||
      selectedMissionForUpdate === undefined ||
      selectedMissionForUpdate === ""
    ) {
      return;
    }
    getMissionById();
    getAllCompany();
  }, [selectedMissionForUpdate]);
  useEffect(() => {
    if (company === null || company === undefined || company === "") {
      return;
    }
    let idCompany = company?._id ?? company;
    getallManagerByCompanyId(idCompany);
  }, [company]);
  async function updateMission() {
    setIsLoading(true);
    const missionId = selectedMissionForUpdate ?? null;
    if (missionId === undefined || missionId === null || missionId === "")
      return;
    let data = {
      title: title !== "" ? title : null,
      langues: langues !== "" ? langues : null,
      skills: skills !== "" ? skills : null,
      programminglanguage:
        programminglanguage !== "" ? programminglanguage : null,
      duration: duration !== "" ? duration : null,
      description: description !== "" ? description : null,
      period: period !== "" ? period : null,
      startDate: startDate !== "" ? startDate : null,
      company: company?._id ?? company,
      manager: manager !== "" ? manager : null,
      salary: salary ?? null,
      client: client && client !== "" ? client : null,
    };

    await axios
      .put(`${baseURL}/missions/${missionId}`, {
        data,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        if (refreshAllMission) {
          refreshAllMission();
        }
        if (getMissionByCompanyId) {
          getMissionByCompanyId();
        }
        if (getMissions) {
          getMissions();
        }
        const boutonOffcanvas = document.getElementById("cancelUpdateMission");
        boutonOffcanvas.click();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setIsLoading(false);
      });
  }
  function resetFormErrorsReset() {
    setFormErrors(initFormErrors);
  }
  const secondOffcanvasRef = useRef();
  const ouvrirSecondOffcanvas = () => {
    const secondOffcanvas = new window.bootstrap.Offcanvas(
      secondOffcanvasRef.current
    );
    secondOffcanvas.show();
  };

  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmails] = useState();
  const [designation, setDesignation] = useState();
  const initFormErrorsContact = {
    lname: [""],
    company_id: [""],
    email: [""],
    phone: [""],
    designation: [""],
  };
  const [formerrorsContact, setFormErrorsConatct] = useState(
    initFormErrorsContact
  );

  function createManager() {
    setIsLoading(true);
    axios
      .post(`${baseURL}/managers/create`, {
        lname: lastName,
        fname: firstName,
        phone: phone,
        email: email,
        company_id: company?._id ?? company,
        designation: designation,
      })
      .then((resp) => {
        setFormErrorsConatct(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        if (hasManagersConsultPermission && getManagerByCompanyId) {
          getManagerByCompanyId();
        }

        getallManagerByCompanyId(company?._id ?? company);

        const offcanvasButton = document.getElementById(
          "cancelButtonCreateManager"
        );
        offcanvasButton.click();
        setLastName("");
        setFirstName("");
        setPhone("");
        setEmails("");
        setDesignation("");
      })
      .catch((error) => {
        setFormErrorsConatct(error.response.data);
        setIsLoading(false);
      });
  }
  const thirdOffcanvasRef = useRef();
  const ouvrirThirdOffcanvas = () => {
    const thirdOffcanvas = new window.bootstrap.Offcanvas(
      thirdOffcanvasRef.current
    );
    thirdOffcanvas.show();
  };
  const [name, setName] = useState("");
  const [emailCompany, setEmail] = useState("");
  const [owner, setOwner] = useState("");
  const [phoneCompany, setPhoneCompany] = useState("");
  const [logo, setLogo] = useState("");
  const [webSite, setWebSite] = useState("");
  const [address, setAddress] = useState("");
  const [cities, setCities] = useState();
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState();
  const [descriptionCompany, setDescriptionCompany] = useState("");
  const [siren, setSiren] = useState("");
  const [siret, setSiret] = useState("");
  const [tvaNumber, setTvaNumber] = useState("");
  const [rcs, setRcs] = useState("");

  const initFormErrorsCompany = {
    name: [""],
    email: [""],
    phone: [""],
    owner: [""],
    webSite: [""],
    address: [""],
    city: [""],
    logo: [""],
    country: [""],
    siren: [""],
    siret: [""],
    tva_number: [""],
    rcs: [""],
    selectCity: "Veuillez sélectionner une ville.",
  };
  const [formerrorsCompany, setFormErrorsCompany] = useState(
    initFormErrorsCompany
  );
  const [avatarUploaded, setAvatarUploaded] = useState();
  const handleImageChange = (e) => {
    setLogo(e.target.files[0]);
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setAvatarUploaded(event.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  function getCities() {
    if (!country) return;
    axios.get(`${baseURL}/apiData/cities/${country}`).then((resp) => {
      if (resp.data.error) return;
      setCities(resp.data.data);
    });
  }
  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }

  useEffect(() => {
    if (country === null || country === undefined || country === "") {
      return;
    }
    getCities();
  }, [country]);

  useEffect(() => {
    getCountries();
  }, []);
  function createCompany() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("logo", logo);
    formData.append("email", emailCompany);
    formData.append("owner", owner);
    formData.append("phone", phoneCompany);
    formData.append("website", webSite);
    formData.append("address", address);
    formData.append("description", descriptionCompany);
    formData.append("siren", siren);
    formData.append("siret", siret);
    formData.append("tva_number", tvaNumber);
    formData.append("rcs", rcs);
    if (country) {
      if (!city) {
        setFormErrorsCompany({ city: formerrors?.selectCity });
        setIsLoading(false);
        return;
      }
      formData.append("city", city);
      formData.append("country", country);
    }
    axios
      .post(`${baseURL}/companies/create`, formData)
      .then((resp) => {
        setFormErrorsCompany(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById(
          "CloseCreateCompanybut"
        );
        offcanvasButton.click();
        if (getAllCompany) {
          getAllCompany();
        }
        setName("");
        setEmail("");
        setOwner("");
        setPhoneCompany("");
        setAddress("");
        setWebSite("");
        setCity("");
        setCountry("");
        setDescriptionCompany("");
        setSiret("");
        setSiren("");
        setAvatarUploaded("");
        setTvaNumber("");
        setRcs("");
        setLogo("");
      })
      .catch((error) => {
        setFormErrorsCompany(error.response.data);
        setIsLoading(false);
      });
  }
  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="modifierMission"
      aria-labelledby="modifierMission">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel fw-bold">Modifier mission</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetFormErrorsReset}></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div>
          <div className="mt-4">
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">Titre: * </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Titre"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.title}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">
                langage de programmation : *
                <span className="textSkills">separation par(,)</span>
              </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="langage de programmation"
                onChange={(e) => setprogramminglanguage(e.target.value)}
                defaultValue={programminglanguage}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.programminglanguage}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">
                Experience requise: *
              </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Experience requise"
                onChange={(e) => setduration(e.target.value)}
                value={duration}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.duration}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">Skills optionnel: </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Skills"
                onChange={(e) => setskills(e.target.value)}
                value={skills}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.skills}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">Langues: *</label>
              <div className="px-0">
                <Select
                  options={languesOptions}
                  isMulti
                  onChange={(e) => {
                    let langues = [];
                    e.forEach((lang, key) => {
                      langues.push(lang.value);
                    });
                    setlangues(JSON.stringify(langues));
                  }}
                  value={languesOptions.filter(
                    (option) => langues && langues.includes(option.value)
                  )}
                />
              </div>

              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.langues}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">Description: </label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Description"
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.description}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">TJM : *</label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="TJM"
                onChange={(e) => setSalary(e.target.value)}
                value={salary}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.programminglanguage}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">Periode du mission :</label>
              <input
                type="text"
                placeholder="Periode de travail "
                className="form-control"
                id="startDate"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.period}
                </span>
              </div>
            </div>
            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">
                Date début de mission : *
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                Value={startDate ? startDate : new Date()}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.period}
                </span>
              </div>
            </div>
            <div className="row ">
              {company ? (
                <div className="col-md-12 mt-2">
                  <label>la société:</label>
                  <div className="input-group mb-3">
                    <select
                      id="companyid"
                      value={company?._id ?? company}
                      onChange={(e) => {
                        setcompany(e.target.value);
                      }}
                      className="form-select">
                      <option disabled selected value="">
                        Sélectionner une société
                      </option>
                      {companies != null &&
                        companies.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {`${item.name} `}
                            </option>
                          );
                        })}
                    </select>
                    <span className="input-group-text p-0" id="basic-addon2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={ouvrirThirdOffcanvas}
                        ata-bs-toggle="offcanvas"
                        data-bs-target="#ajouterSociete"
                        aria-controls="ajouterSociete">
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </span>
                  </div>
                  <div className="input-error">
                    <span className="text-danger">{formerrors?.company}</span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-12 mt-2">
              <label>le Client final :</label>
              <div className="input-group mb-3">
                <select
                  id="clientId"
                  value={client}
                  onChange={(e) => {
                    setClient(e.target.value);
                  }}
                  className="form-select">
                  <option value="" disabled selected>
                    Sélectionner une société
                  </option>
                  {companies != null &&
                    companies
                      .filter((company) => company.isClient === "yes")
                      .sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                          return -1;
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {`${item.name}`}
                          </option>
                        );
                      })}
                </select>
                <span className="input-group-text p-0" id="basic-addon2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={ouvrirThirdOffcanvas}
                    ata-bs-toggle="offcanvas"
                    data-bs-target="#ajouterSociete"
                    aria-controls="ajouterSociete">
                    <i className="bi bi-plus-lg"></i>
                  </button>
                </span>
              </div>
              <div className="input-error">
                <span className="text-danger">{formerrors?.Client}</span>
              </div>
            </div>

            <div className="row px-2 py-3">
              <label className="px-0 mb-2 text-500">Contact : </label>
              <div className="input-selectRole p-0">
                <select
                  id="managerId"
                  onChange={(e) => {
                    setManager(e.target.value);
                  }}
                  value={manager}
                  className="form-select">
                  <option disabled selected value="">
                    Sélectionner un responsable
                  </option>
                  {company && allmanagers
                    ? allmanagers.map((item, index) => (
                        <option key={index} value={item._id}>
                          {` ${item.fname} ${item.lname}`}
                        </option>
                      ))
                    : managers &&
                      managers.map((item, index) => (
                        <option key={index} value={item._id}>
                          {` ${item.fname} ${item.lname}`}
                        </option>
                      ))}
                </select>
                {hasManagerCreatePermission && (
                  <span className="input-group-text p-0" id="basic-addon1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      ata-bs-toggle="offcanvas"
                      data-bs-target="#addManager"
                      aria-controls="addManager"
                      onClick={ouvrirSecondOffcanvas}>
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  </span>
                )}
              </div>
              {/* ofCanvas pour ajouter un contact */}
              <div
                className="offcanvas offcanvas-end offcanvas-body-border"
                tabIndex="-1"
                id="secondoffcanvas"
                aria-labelledby="secondOffcanvasLabel"
                ref={secondOffcanvasRef}>
                <div className="offcanvas-header ">
                  <h4 id="addManager fw-bold">Ajouter un contact</h4>
                  <button
                    id="cancelButtonCreateManager"
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="cancelButtonCreateManager"></button>
                </div>
                <div className="offcanvas-body offcanvas-body-scrollable">
                  <div>
                    <div className="mt-4">
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">Nom: *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lname"
                          placeholder="Nom"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrorsContact.lname}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">Prénom: *</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lname"
                          placeholder="Prénom"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrorsContact.fname}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">
                          Téléphone: *
                        </label>
                        <div className="input-group px-0">
                          <PhoneInput
                            inputClass="phoneInput"
                            className="phoneInput"
                            placeholder="Téléphone"
                            value={phone}
                            onChange={(value, country, event) =>
                              setPhone(
                                event.target.value ? event.target.value : value
                              )
                            }
                          />
                        </div>
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrorsContact.phone}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">Email: *</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                          onChange={(e) => setEmails(e.target.value)}
                          value={email}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrorsContact.email}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">
                          Désignation:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="designation"
                          placeholder="Désignation"
                          onChange={(e) => setDesignation(e.target.value)}
                          value={designation}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrorsContact.designation}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 pt-2 pb-1">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={createManager}
                          disabled={isLoading}>
                          {isLoading ? <Loader /> : "Ajouter"}
                        </button>
                      </div>
                      <div className="row px-2 pt-1 pb-2">
                        <button
                          id="cancelButtonCreateManager"
                          type="button"
                          className="btn but-restore"
                          data-bs-dismiss="offcanvas"
                          aria-label="cancelButtonCreateManager">
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ofCanvas pour ajouter une societe */}
              <div
                className="offcanvas offcanvas-end offcanvas-body-border"
                tabIndex="-1"
                id="thirdOffcanvas"
                aria-labelledby="thirdOffcanvasLabel"
                ref={thirdOffcanvasRef}>
                <div className="offcanvas-header ">
                  <h4 id="thirdOffcanvas fw-bold">Ajouter une societe</h4>
                  <button
                    id="CloseCreateCompanybut"
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="CloseCreateCompany"></button>
                </div>
                <div className="offcanvas-body offcanvas-body-scrollable">
                  <div>
                    <div className="mt-4">
                      <div className="row d-flex justify-content-center">
                        <div className="col-6">
                          <div className="bg-white">
                            <label
                              htmlFor="fileInput"
                              className="d-flex justify-content-center">
                              <div className="mb-2">
                                {avatarUploaded ? (
                                  <img
                                    src={avatarUploaded}
                                    alt="Uploaded"
                                    className="imgUploaded "
                                  />
                                ) : (
                                  <img
                                    src="../../assets/img/input-pictures.png"
                                    alt="Placeholder"
                                    className=" uploadInput"
                                  />
                                )}
                              </div>
                            </label>
                            <input
                              type="file"
                              id="fileInput"
                              name="profile_picture"
                              className="d-none"
                              accept="image/png, image/gif, image/jpeg"
                              required
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row px-2">
                        <label className="px-0 mb-2 text-500">Nom: *</label>
                        <input
                          type="text"
                          className="form-control offcanvas-input"
                          placeholder="Nom"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.name}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">Contact : </label>
                        <input
                          type="text"
                          className="form-control offcanvas-input"
                          placeholder="Contact "
                          onChange={(e) => setOwner(e.target.value)}
                          value={owner}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.owner}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">
                          Téléphone :{" "}
                        </label>
                        <div className="input-group px-0">
                          <PhoneInput
                            inputClass="phoneInput"
                            className="phoneInput"
                            placeholder="Téléphone"
                            value={phoneCompany}
                            onChange={(value, country, event) =>
                              setPhoneCompany(
                                event.target.value ? event.target.value : value
                              )
                            }
                          />
                        </div>
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.phone}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">Email : </label>
                        <input
                          type="text"
                          className="form-control offcanvas-input"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={emailCompany}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.email}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <label className="px-0 mb-2 text-500">
                          Site Web :{" "}
                        </label>
                        <div className="input-group px-0">
                          <input
                            placeholder="Site Web"
                            type="text"
                            className="form-control"
                            onChange={(e) => setWebSite(e.target.value)}
                            value={webSite}
                          />
                        </div>
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany?.website}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">
                            Adresse :{" "}
                          </label>
                          <div className="input-group mb-2">
                            <input
                              placeholder="Adresse"
                              className="form-control  offcanvas-input"
                              onChange={(e) => setAddress(e.target.value)}
                              value={address}
                              type="text"
                              required
                            />
                          </div>
                        </div>
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.address}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">
                            Description :{" "}
                          </label>
                          <div className="input-group mb-2 ">
                            <input
                              type="text"
                              placeholder="Description"
                              className="form-control offcanvas-input"
                              onChange={(e) =>
                                setDescriptionCompany(e.target.value)
                              }
                              value={descriptionCompany}
                            />
                          </div>
                        </div>
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.description}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">Siren : </label>
                          <div className="input-group mb-2">
                            <input
                              placeholder="Siren"
                              className="form-control offcanvas-input"
                              value={siren}
                              onChange={(e) => setSiren(e.target.value)}
                              min="0"
                              type="number"
                              required
                            />
                          </div>
                        </div>
                        <div className="input-error">
                          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                            {formerrorsCompany.siren}
                          </span>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">Siret : </label>
                          <div className="input-group mb-2 ">
                            <input
                              placeholder="Siret"
                              className="form-control offcanvas-input"
                              value={siret}
                              onChange={(e) => setSiret(e.target.value)}
                              min="0"
                              type="number"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">
                            Numéro TVA intracommunautaire :
                          </label>
                          <div className="input-group mb-2">
                            <input
                              placeholder="Numéro TVA "
                              className="form-control offcanvas-input"
                              value={tvaNumber}
                              onChange={(e) => setTvaNumber(e.target.value)}
                              min="0"
                              type="number"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">RCS : </label>
                          <div className="input-group mb-2 ">
                            <input
                              placeholder="RCS"
                              className="form-control offcanvas-input"
                              value={rcs}
                              onChange={(e) => setRcs(e.target.value)}
                              min="0"
                              type="number"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">Pays : </label>
                          <div className="input-group mb-2">
                            <select
                              id="inputCountry"
                              className="form-select  offcanvas-input"
                              onChange={(e) => setCountry(e.target.value)}
                              value={country}>
                              <option value="" disabled>
                                Selectionner une pays ...
                              </option>
                              {countries != null &&
                                countries.map((country, key) => (
                                  <option key={key} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                            </select>
                            <div className="input-error">
                              <span className="text-danger">
                                {formerrorsCompany.country}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row px-2 py-2">
                        <div className="date-picker px-0">
                          <label className="px-0 mb-2 text-500">Ville : </label>
                          <div className="input-group mb-2">
                            <select
                              id="inputCountry"
                              className="form-select  offcanvas-input"
                              onChange={(e) => setCity(e.target.value)}
                              value={city}>
                              <option value="" disabled>
                                Sectionner une ville ...
                              </option>
                              {cities != null &&
                                cities.map((item, key) => {
                                  return (
                                    <option key={key} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                            <div className="input-error">
                              <span className="text-danger">
                                {formerrorsCompany.city}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row px-2 pt-2 pb-1">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={createCompany}
                          disabled={isLoading}>
                          {isLoading ? <Loader /> : "Ajouter"}
                        </button>
                      </div>
                      <div className="row px-2 pt-1 pb-2">
                        <button
                          id="CloseCreateCompanybut"
                          type="button"
                          className="btn but-restore"
                          data-bs-dismiss="offcanvas"
                          aria-label="CloseCreateCompany">
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-error">
                <span className="text-danger">{formerrors?.manager}</span>
              </div>
            </div>

            <div className="row px-2 pt-2 pb-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={updateMission}
                disabled={isLoading}>
                {isLoading ? <Loader /> : "Modifier"}
              </button>
            </div>
            <div className="row px-2 pt-1 pb-2">
              <button
                id="cancelUpdateMission"
                type="button"
                className="btn but-restore"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={resetFormErrorsReset}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMission;
