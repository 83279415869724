import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../config/config";
import { useParams } from "react-router";
import CandidatDetailsComponent from "../../components/candidat/CandidatDetailsComponent";
import ExperienceDetails from "../../components/experience/ExperienceDetails";
import CreateNote from "../../components/candidat/candidatNote";
import swal from "sweetalert";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import UpdateCandidat from "../../components/candidat/updateStepsCandidats/Updatecandidat";
import Switch from "@mui/material/Switch";
import DataTable from "react-data-table-component";
import AffectMissionModal from "../employee/affectMissionModal";
import EmptyData from "../../components/emptyData";
import EditMission from "../../components/mission/updateMission";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import ConsultantRdvList from "../../components/consultant/consultantsRdv";
import ImportCandidatCV from "../../components/candidat/importCandidatCV";

const CandidatDetails = () => {
  const { user } = useContext(UserContext);

  const hasCandidatsEditPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "edit"
  );
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consulter_enabled"
  );
  const hasCandidatsStatusPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "change_status"
  );
  const hasCandidatsNotePermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "create_note"
  );
  const hasMissionsEditPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "edit"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasMissionsEmployeesStatusPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "change_status"
  );
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionsEmployeeConsultPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "consult"
  );
  const hasMissionsEmployeeAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "create"
  );
  const hasMissionsStatusPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "change_status"
  );
  const hasCandidatsLockPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "lock"
  );
  const hasCandidatsNoteConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consulter_note"
  );
  const hasCvCreatePermission = hasPermission(user, PERMISSIONS.cv, "create");
  const hasExperienceCandidatPermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "consult"
  );
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const [candidat, setCandidat] = useState([]);
  const { candidatId } = useParams();
  const [childComponent, setChildComponent] = useState();
  const [activeDetails, setActiveDetails] = useState("active");
  const [activeExperience, setActiveExperience] = useState("");
  const [missionData, setMissionData] = useState([]);
  const [mission, setMission] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const history = useHistory();
  const [importConsultantCV, setimportConsultantCV] = useState();

  const noDataFound = "Aucune donnée trouvée";
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };

  async function getMission() {
    if (candidatId === null) return;
    axios
      .get(`${baseURL}/missionEmployees/missions/${candidatId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {
        DisplayError();
      });
  }

  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`${baseURL}/missionEmployees/toggleStatus/${candidatId}`, {
            missionId: missionId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getMission();
            }
          })
          .catch((err) => {
            DisplayError();
          });
      }
    });
  }
  async function getCandidatById() {
    if (candidatId === undefined || candidatId === null) return;
    axios
      .get(`${baseURL}/consultants/${candidatId}`)
      .then((response) => {
        if (response.data.error) return;
        setCandidat(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  // function handleUpdateMissionButtonClick(missionId) {
  //   setSelectedMissionForUpdate(missionId);
  // }
  // const [selectedMissionForUpdate, setSelectedMissionForUpdate] =
  useEffect(() => {
    if (candidatId === undefined || candidatId === null) return;
    getCandidatById();
    getMission();
  }, [candidatId]);
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable
          className="dataTableClass"
          columns={columnsMission}
          data={mission}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);
  const columnsMission = [
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <Link
            className="color-msit "
            to={`/missions/details/${row?.mission?._id}`}>
            {row?.mission?.title}
          </Link>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>
            {row.description ? (
              <>
                {row.mission?.description?.substring(0, 15)}
                {row.mission?.description?.length > 15 ? "..." : ""}
              </>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ),
      hide: "md",
      sortable: true,
      center: true,
    },
    {
      name: "Date de début ",
      selector: (row) => (
        <div>
          <div>{row.mission?.startDate}</div>
        </div>
      ),
      hide: "lg",
      sortable: true,
      center: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.mission?.period}</div>
        </div>
      ),
      hide: "lg",
      sortable: true,
      center: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div>
            {row?.mission?.manager != null && row?.mission?.manager?.fname}
            {row?.mission?.manager != null && row?.mission?.manager?.lname}
            {" / "}
            {row.mission?.company != null && row.mission?.company?.name}
          </div>
        </div>
      ),
      sortable: true,
      hide: "sm",
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasMissionsStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisableMission(
                          row?.mission?._id,
                          row?.mission?.isEnabled
                        );
                      }}
                      checked={row.mission?.isEnabled === "yes"}
                    />
                    Activer la mission
                  </label>
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                href={`#/missions/details/${row?.mission?._id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2"> Consulter la mission </span>
              </Dropdown.Item>
              {/* {hasMissionsEditPermission &&
                hasCompanyConsultPermission &&
                hasManagersConsultPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#modifierMission"
                    aria-controls="modifierMission"
                    // onClick={() =>
                    //   handleUpdateMissionButtonClick(row?.mission?._id)
                    // }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier la mission</span>
                  </Dropdown.Item>
                )} */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Date de début :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.mission?.startDate || noDataFound}
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Période :</div>
            {data?.mission?.period || noDataFound}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 599 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Contact :</div>
            {data.manager !== null
              ? data?.mission?.manager?.fname +
                " " +
                data?.mission?.manager?.lname +
                " / " +
                data?.mission?.company?.name
              : noDataFound}
          </div>
          <div className="col-md-12 my-1">
            <div className="ms-1 text-details">Description :</div>

            {data?.mission?.description ? (
              <Tooltip title={data?.mission?.description}>
                <div>
                  {data?.mission?.description?.substring(0, 20)}
                  {data?.mission?.description?.length > 20 ? "..." : ""}
                </div>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 959 && isMobile >= 600 ? (
        <div className="row my-1">
          <div className="col-md-12 my-1">
            <div className="ms-1 text-details">Description :</div>

            {data.mission?.description ? (
              <Tooltip title={data.mission?.description} arrow>
                <div>
                  {data?.mission?.description?.substring(0, 60)}
                  {data?.mission?.description?.length > 60 ? "..." : ""}
                </div>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );
  useEffect(() => {
    if (activeDetails === "active") {
      setChildComponent(<CandidatDetailsComponent candidat={candidat} />);
    } else if (activeExperience === "active") {
      <ExperienceDetails candidat_id={candidatId} />;
    }
  }, [candidat]);
  async function lockUnlock(candidatId, isLocked) {
    let message =
      isLocked === "yes"
        ? "voulez vous déverrouiller ce consultant?"
        : "Voulez vous verrouiller ce consultant?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/consultants/toggleLock/${candidatId}`)
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getCandidatById();
            }
          });
      }
    });
  }
  async function toggleToDelete(candidatId) {
    await axios
      .get(`${baseURL}/consultants/toggleToDelete/${candidatId}`)
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getCandidatById();
      })
      .catch(() => {
        DisplayError();
      });
  }
  async function enableDisable(candidatId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous désactiver ce candidat?"
        : "Voulez vous activer ce candidat?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/consultants/toggleStatus/${candidatId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getCandidatById();
            }
          });
      }
    });
  }

  let tel = `tel:+${candidat.phone}`;
  let mailto = `mailto:${candidat.email}`;

  return (
    <div>
      <div className="pagetitle row">
        <div className="col-lg-8">
          <h1>Détails du consultant</h1>
        </div>
        <div className="col-lg-4">
          <button
            type="button"
            className="btn btn-outline-secondary mb-2 float-end  me-2"
            onClick={history.goBack}>
            Retour
          </button>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/candidats">les cansultants</Link>
            </li>
            <li className="breadcrumb-item active">Details</li>
          </ol>
        </nav>
      </div>

      <section className="section candidat">
        <div className="card-candidat d-lg-flex ms-0">
          <div
            className="ps-0 me-3 candidat-card  d-flex align-items-center justify-content-center justify-content-lg-start
                  text-uppercase">
            <img
              className="w-100  rounded-start"
              alt=""
              src={
                candidat.avatar != null
                  ? baseURL + candidat.avatar?.path?.substring(1)
                  : undefined
              }
            />
          </div>
          <div className="px-4 d-flex flex-column col row justify-content-center align-items-lg-start align-items-center text-uppercase">
            <div className="row">
              <div className="col-lg-8">
                <h2>
                  {candidat?.lastname &&
                    `${candidat.lastname.substring(0, 10)}${
                      candidat.lastname.length > 10 ? "..." : ""
                    }`}{" "}
                  {candidat?.firstname &&
                    `${candidat.firstname.substring(0, 10)}${
                      candidat.firstname.length > 10 ? "..." : ""
                    }`}
                </h2>
                <h6 className="small  fw-bold">
                  {candidat?.posteType?.name &&
                    `${candidat.posteType?.name.substring(0, 15)}${
                      candidat.posteType?.name.length > 15 ? "..." : ""
                    }`}
                </h6>
              </div>
              <div className="col-lg-4 my-2 d-flex justify-content-center justify-content-lg-end align-items-center">
                <Dropdown className="w-auto">
                  <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none">
                      <path
                        d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                        stroke="#98A2B3"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                        stroke="#98A2B3"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                        stroke="#98A2B3"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="candidat-dropdown-menu">
                    {hasCandidatsStatusPermission && (
                      <Dropdown.Item
                        onClick={() => {
                          enableDisable(candidat?._id, candidat?.isEnabled);
                        }}
                        className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                        <label className="form-check form-check-single form-switch">
                          <input
                            className="form-check-input"
                            data-placement="top"
                            type="checkbox"
                            checked={candidat?.isEnabled === "yes"}
                          />
                          Activer le Profil
                        </label>
                      </Dropdown.Item>
                    )}
                    {hasCandidatsLockPermission && (
                      <Dropdown.Item
                        onClick={() => {
                          lockUnlock(candidat?._id, candidat?.isLocked);
                        }}
                        className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                        <label className="form-check form-check-single form-switch">
                          <input
                            className="form-check-input"
                            data-placement="top"
                            type="checkbox"
                            checked={candidat?.isLocked === "yes"}
                          />
                          verrouiller le Profil
                        </label>
                      </Dropdown.Item>
                    )}
                    {hasCandidatsStatusPermission && (
                      <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                        <label
                          onClick={() => {
                            toggleToDelete(candidat._id);
                          }}
                          className="form-check form-check-single form-switch">
                          <input
                            className="form-check-input"
                            data-placement="top"
                            type="checkbox"
                            checked={candidat?.toDelete === "yes"}
                          />
                          Supprimer le Profil
                        </label>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />

                    {hasCandidatsNotePermission &&
                      hasCandidatsNoteConsultPermission && (
                        <Dropdown.Item
                          className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                          onClick={() => {
                            setimportConsultantCV(
                              <ImportCandidatCV
                                hideModal={() => setimportConsultantCV("")}
                                consultantId={candidat?._id}
                              />
                            );
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <path
                              d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                              stroke="#344054"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="ms-2"> Ajouter un CV</span>
                        </Dropdown.Item>
                      )}

                    <Dropdown.Item
                      className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                      onClick={() => {
                        setimportConsultantCV(
                          <ImportCandidatCV
                            hideModal={() => setimportConsultantCV("")}
                            consultantId={candidat._id}
                          />
                        );
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none">
                        <path
                          d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="ms-2">Consulter les CVs</span>
                    </Dropdown.Item>
                    {hasCvCreatePermission &&
                      hasExperienceCandidatPermission && (
                        <Dropdown.Item
                          className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                          href={`#/candidats/createCv/${candidat?._id}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M13.3327 8.33398V4.53398C13.3327 3.41388 13.3327 2.85383 13.1147 2.426C12.9229 2.04968 12.617 1.74372 12.2407 1.55197C11.8128 1.33398 11.2528 1.33398 10.1327 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H7.99935M9.33268 7.33398H5.33268M6.66602 10.0007H5.33268M10.666 4.66732H5.33268M9.66602 12.6673L10.9993 14.0007L13.9993 11.0007"
                              stroke="#344054"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="ms-2"> Créer un cv</span>
                        </Dropdown.Item>
                      )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="mb-2">
              <div className="row">
                <div className="col-12 col-lg-8  mb-4  justify-content-center">
                  <div className="row d-xxl-flex d-grid">
                    <div className="col-lg-6 d-flex d-xl-grid justify-content-start align-items-center">
                      <div className="d-flex ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none">
                          <path
                            d="M9.16329 1.75466L14.182 5.0168C14.3593 5.13207 14.448 5.18971 14.5122 5.26659C14.5691 5.33464 14.6118 5.41335 14.6379 5.49812C14.6673 5.59388 14.6673 5.69964 14.6673 5.91114V10.7989C14.6673 11.9191 14.6673 12.4791 14.4493 12.9069C14.2576 13.2833 13.9516 13.5892 13.5753 13.781C13.1475 13.9989 12.5874 13.9989 11.4673 13.9989H4.53398C3.41388 13.9989 2.85383 13.9989 2.426 13.781C2.04968 13.5892 1.74372 13.2833 1.55197 12.9069C1.33398 12.4791 1.33398 11.9191 1.33398 10.7989V5.91114C1.33398 5.69964 1.33398 5.59388 1.36343 5.49812C1.3895 5.41335 1.43222 5.33464 1.48908 5.26659C1.55332 5.18971 1.64199 5.13207 1.81933 5.0168L6.83801 1.75466M9.16329 1.75466C8.74246 1.48112 8.53205 1.34435 8.30534 1.29114C8.10494 1.2441 7.89637 1.2441 7.69597 1.29114C7.46925 1.34435 7.25884 1.48112 6.83801 1.75466M9.16329 1.75466L13.2914 4.43794C13.75 4.73601 13.9793 4.88504 14.0587 5.07404C14.1281 5.2392 14.1281 5.42535 14.0587 5.59052C13.9793 5.77952 13.75 5.92855 13.2914 6.22662L9.16329 8.9099C8.74247 9.18343 8.53205 9.3202 8.30534 9.37342C8.10494 9.42046 7.89637 9.42046 7.69597 9.37342C7.46925 9.3202 7.25884 9.18343 6.83801 8.90989L2.70989 6.22662C2.25133 5.92855 2.02204 5.77952 1.94263 5.59052C1.87324 5.42535 1.87324 5.2392 1.94263 5.07404C2.02204 4.88504 2.25133 4.73601 2.70989 4.43794L6.83801 1.75466M14.334 12.6656L9.90544 8.66561M6.09587 8.66561L1.66732 12.6656"
                            stroke="black"
                            stroke-width="1.06667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div className="ms-1 text-details">Email :</div>
                      </div>
                      {candidat?.email ? (
                        <a
                          href={mailto}
                          className="color-msit "
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Envoyer mail">
                          `{candidat.email.substring(0, 15)}
                          {candidat.email.length > 15 ? "..." : ""}`
                        </a>
                      ) : (
                        <div>Aucune donnée trouvée</div>
                      )}
                    </div>
                    <div
                      className="col-lg-6 d-flex d-xl-grid justify-content-start align-items-center"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Appeler">
                      <div className="d-flex ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none">
                          <path
                            d="M10.0004 2.66667V6M10.0004 6L13.3337 6M10.0004 6L14.0004 2M6.81841 9.24205C6.01736 8.44099 5.38483 7.53523 4.92084 6.56882C4.88092 6.48569 4.86097 6.44413 4.84564 6.39154C4.79115 6.20463 4.83029 5.97513 4.94363 5.81684C4.97552 5.7723 5.01363 5.7342 5.08984 5.65799C5.32291 5.42492 5.43944 5.30838 5.51563 5.1912C5.80296 4.74927 5.80296 4.17955 5.51563 3.73762C5.43944 3.62044 5.32291 3.5039 5.08984 3.27083L4.95992 3.14092C4.60563 2.78662 4.42848 2.60947 4.23823 2.51324C3.85985 2.32186 3.41301 2.32186 3.03464 2.51324C2.84438 2.60947 2.66723 2.78662 2.31294 3.14092L2.20785 3.24601C1.85477 3.59909 1.67823 3.77563 1.54339 4.01565C1.39378 4.28199 1.28621 4.69565 1.28712 5.00113C1.28793 5.27643 1.34134 5.46458 1.44814 5.84087C2.02212 7.86314 3.1051 9.77138 4.69709 11.3634C6.28908 12.9554 8.19732 14.0383 10.2196 14.6123C10.5959 14.7191 10.784 14.7725 11.0593 14.7733C11.3648 14.7743 11.7785 14.6667 12.0448 14.5171C12.2848 14.3822 12.4614 14.2057 12.8145 13.8526L12.9195 13.7475C13.2738 13.3932 13.451 13.2161 13.5472 13.0258C13.7386 12.6474 13.7386 12.2006 13.5472 11.8222C13.451 11.632 13.2738 11.4548 12.9195 11.1005L12.7896 10.9706C12.5566 10.7376 12.44 10.621 12.3228 10.5448C11.8809 10.2575 11.3112 10.2575 10.8693 10.5448C10.7521 10.621 10.6355 10.7376 10.4025 10.9706C10.3263 11.0468 10.2882 11.0849 10.2436 11.1168C10.0853 11.2302 9.85582 11.2693 9.66892 11.2148C9.61633 11.1995 9.57476 11.1795 9.49164 11.1396C8.52523 10.6756 7.61946 10.0431 6.81841 9.24205Z"
                            stroke="black"
                            stroke-width="1.07"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <div className="ms-1 text-details">Téléphone :</div>
                      </div>
                      {candidat?.phone ? (
                        <a href={tel} className="color-msit text-nowrap">
                          {candidat.phone}
                        </a>
                      ) : (
                        "Aucune donnée trouvée"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 P-0 col-lg-4 d-xl-flex justify-content-center justify-content-md-end  align-items-center">
                  {hasCandidatTypesConsultPermission &&
                    hasCandidatsEditPermission && (
                      <button
                        className="mx-1 w-100 my-1 text-wrapping btn btn-primary "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Modifier candidat"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasUpdateConsultant"
                        aria-controls="offcanvasUpdateConsultant">
                        <span className="ms-2">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_577_25351)">
                              <path
                                d="M9.16699 3.33417H5.66699C4.26686 3.33417 3.5668 3.33417 3.03202 3.60666C2.56161 3.84634 2.17916 4.22879 1.93948 4.6992C1.66699 5.23398 1.66699 5.93404 1.66699 7.33417V14.3342C1.66699 15.7343 1.66699 16.4344 1.93948 16.9691C2.17916 17.4396 2.56161 17.822 3.03202 18.0617C3.5668 18.3342 4.26686 18.3342 5.66699 18.3342H12.667C14.0671 18.3342 14.7672 18.3342 15.302 18.0617C15.7724 17.822 16.1548 17.4396 16.3945 16.9691C16.667 16.4344 16.667 15.7343 16.667 14.3342V10.8342M6.66697 13.3342H8.06242C8.47007 13.3342 8.6739 13.3342 8.86571 13.2881C9.03577 13.2473 9.19835 13.18 9.34747 13.0886C9.51566 12.9855 9.65979 12.8414 9.94804 12.5531L17.917 4.58417C18.6073 3.89382 18.6073 2.77453 17.917 2.08417C17.2266 1.39382 16.1073 1.39382 15.417 2.08417L7.44802 10.0531C7.15977 10.3414 7.01564 10.4855 6.91257 10.6537C6.82119 10.8028 6.75385 10.9654 6.71302 11.1355C6.66697 11.3273 6.66697 11.5311 6.66697 11.9387V13.3342Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_577_25351">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Edit
                        </span>
                      </button>
                    )}

                  {hasCandidatsNotePermission && (
                    <button
                      className="my-1 w-100 text-wrapping btn btn-primary text-white mx-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#addNoteCandidat"
                      aria-controls="addNoteCandidat"
                      title="Créer note">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.1663 1.89063V5.33274C12.1663 5.79945 12.1663 6.03281 12.2572 6.21107C12.3371 6.36787 12.4645 6.49535 12.6213 6.57525C12.7996 6.66608 13.033 6.66608 13.4997 6.66608H16.9418M10.4997 14.9993V9.99935M7.99967 12.4993H12.9997M12.1663 1.66602H7.83301C6.43288 1.66602 5.73281 1.66602 5.19803 1.9385C4.72763 2.17818 4.34517 2.56063 4.10549 3.03104C3.83301 3.56582 3.83301 4.26588 3.83301 5.66602V14.3327C3.83301 15.7328 3.83301 16.4329 4.10549 16.9677C4.34517 17.4381 4.72763 17.8205 5.19803 18.0602C5.73281 18.3327 6.43288 18.3327 7.83301 18.3327H13.1663C14.5665 18.3327 15.2665 18.3327 15.8013 18.0602C16.2717 17.8205 16.6542 17.4381 16.8939 16.9677C17.1663 16.4329 17.1663 15.7328 17.1663 14.3327V6.66602L12.1663 1.66602Z"
                          stroke="white"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Ajouter note
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-candidat ">
          <div className="card-body pt-3">
            <div className="row ps-3">
              <ul className="nav nav-tabs nav-tabs-bordered col-10">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeDetails}`}
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                    onClick={() => {
                      setActiveDetails("active");
                      setChildComponent(
                        <CandidatDetailsComponent candidat={candidat} />
                      );
                    }}>
                    Détailles
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeExperience}`}
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                    onClick={() => {
                      setActiveDetails("");
                      setActiveExperience("active");
                      setChildComponent(
                        <ExperienceDetails candidat_id={candidatId} />
                      );
                    }}>
                    Expériences
                  </button>
                </li>
              </ul>
            </div>
            {childComponent}
          </div>
        </div>
      </section>
      <div className="card-candidat">
        <div className="card-header">
          <div className="pagetitle">
            <h1>Les Missions</h1>
            <div className="row">
              <div className="col-md-8"></div>
              {hasCvConsultPermission &&
                hasMissionsConsultPermission &&
                hasMissionsEmployeeAffectPermission && (
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="btn btn-primary btn-rounded float-end text-sm"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasAffectMission"
                      aria-controls="offcanvasAffectMission">
                      <i className="bi bi-plus-lg"></i> Ajouter une mission
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="card-body pt-3">
          <section className="section">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">{missionData}</div>
              </div>
              <AffectMissionModal employee={candidat} />
            </div>
          </section>
        </div>
      </div>
      {importConsultantCV}
      <ConsultantRdvList consultantId={candidatId} />
      <UpdateCandidat id={candidatId} />
      <CreateNote selectedForUpdate={candidat._id} destination={"candidates"} />
    </div>
  );
};

export default CandidatDetails;
