import React, { useMemo, useState } from "react";
import PageOne from "./pageOne";
import PageTwo from "./pageTwo";
import PageThree from "./pageThree";
import PageFourth from "./pageFourth";
import { CandidatContext } from "../../context/candidatContext";

export default function Addcandidat({
  setaddEmployeeModalFromChild,
  getAllCandidats,
}) {
  const [newCandidat, setNewCandidat] = useState({
    gender: "male",
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    cityname: "",
    address: "",
    cp: "",
    phone: "",
    birthday: "",
    birthPlace: "",
    diplomeDate: "",
    diplome: "",
    typename: "",
    langues: "",
    status: "",
    skills: "",
    experience: "",
    avatar: "",
    nirpp: "",
    typeOfContract: "none",
    salary: "",
    posteType: "",
    posteTypeName: "",
    startDate: "",
    cv: "",
    countryid: "",
    cvname: "",
    type: "",
    company: "",
  });

  const [page, setPage] = useState("pageone");

  const nextPage = (page) => {
    setPage(page);
  };
  const previuosPage = (page) => {
    setPage(page);
  };
  const value = useMemo(
    () => ({ newCandidat, setNewCandidat }),
    [newCandidat, setNewCandidat]
  );
  return (
    <>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border pb-3"
        tabIndex="-1"
        id="offcanvasCreate"
        aria-labelledby="offcanvasRightLabel"
        aria-modal="true"
        role="dialog">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            {page !== "pagefour"
              ? "Ajouter un consultant"
              : "Fiche de consultant"}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => setaddEmployeeModalFromChild("")}></button>
        </div>
        <div className="offcanvas-body">
          <CandidatContext.Provider value={value}>
            {
              {
                pageone: (
                  <PageOne
                    nextpage={nextPage}
                    previuosPage={previuosPage}
                    setaddCandidat={setaddEmployeeModalFromChild}
                  />
                ),
                pagetwo: (
                  <PageTwo nextpage={nextPage} previuosPage={previuosPage} />
                ),
                pagethree: (
                  <PageThree nextpage={nextPage} previuosPage={previuosPage} />
                ),
                pagefour: (
                  <PageFourth
                    previuosPage={previuosPage}
                    setaddCandidat={setaddEmployeeModalFromChild}
                    getAllCandidats={getAllCandidats}
                    setNewCandidat={setNewCandidat}
                  />
                ),
              }[page]
            }
          </CandidatContext.Provider>
        </div>
      </div>
    </>
  );
}
