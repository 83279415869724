import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function DeleteExperience({
  setAddExperienceModalFromChild,
  getExperienceByCandidatId,
  experienceId,
}) {
  function DeleteExperience() {
    axios.delete(`${baseURL}/experience/${experienceId}`).then((resp) => {
      if (resp.data.error) return;

      swal("Expérience est supprimé avec succés!", {
        icon: "success",
        timer: 2000,
      });
      getExperienceByCandidatId();
      setTimeout(() => {
        setAddExperienceModalFromChild("");
        getExperienceByCandidatId();
      }, 2400);
    });
  }

  React.useEffect(() => {
    swal({
      title: "êtes vous sûr de supprimer cette expérience?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer cette expérience!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        DeleteExperience();
      } else {
        setAddExperienceModalFromChild("");
      }
    });
  }, []);
  return <div></div>;
}

export default DeleteExperience;
