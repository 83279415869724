/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
import axios from "axios";
import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import Loader from "../../components/loader";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import EmptyData from "../../components/emptyData";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import NotAuthorized from "../../components/NotAuthorized";
import Dropdown from "react-bootstrap/Dropdown";
import CreateUser from "./createUser";
import UpdateUser from "./updateUser";
import UpdatePasswordUser from "./updatePasswordUser";
const Users = () => {
  const { user } = useContext(UserContext);

  const hasUserEditPermission = hasPermission(user, PERMISSIONS.users, "edit");
  const hasUsersCreatePermission = hasPermission(
    user,
    PERMISSIONS.users,
    "create"
  );
  const hasUsersConsultPermission = hasPermission(
    user,
    PERMISSIONS.users,
    "consult"
  );
  const hasUsersStatusPermission = hasPermission(
    user,
    PERMISSIONS.users,
    "change_status"
  );
  const hasUsersPasswordPermission = hasPermission(
    user,
    PERMISSIONS.users,
    "change_password"
  );
  const hasRolesConsultPermission = hasPermission(
    user,
    PERMISSIONS.roles,
    "consult"
  );

  const [users, setUsers] = React.useState([]);
  const [data, setData] = React.useState();
  const [roles, setRoles] = React.useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [adresseFilter, setAdresseFilter] = useState("");
  const columns = [
    {
      name: "Utilisateur",
      selector: (row) => (
        <div>
          <img
            src={row.avatar}
            className="rounded-circle me-2"
            width="35"
            height="35"
          />
          {row.name.length > 15 ? `${row.name.substring(0, 15)}...` : row.name}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Numéro de téléphone",
      center: true,
      cell: (row) => (
        <div className="phone text-nowrap">
          <a
            href={`tel:+${row.phone}`}
            className="color-msit "
            data-placement="top"
            title="Appeler">
            {row.phone}
          </a>
        </div>
      ),
      hide: "md",
    },
    {
      name: "Adresse",
      selector: (row) => row.address,
      center: true,
      sortable: true,
      hide: "lg",
    },
    {
      name: "NIRPP ",
      selector: (row) => row.nirpp,
      hide: "md",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role != null && row.role.name,
      hide: "sm",
      center: true,
      sortable: true,
    },
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle
              bsPrefix="but-dropdown border-0 px-1"
              style={{ background: "none" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasUsersStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisable(row.id, row.isEnabled);
                      }}
                      checked={row.isEnabled == "yes"}
                    />
                    Activer le Profil
                  </label>
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              {hasUserEditPermission && hasRolesConsultPermission && (
                <Dropdown.Item
                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#modifierUtilisateur"
                  aria-controls="modifierUtilisateur"
                  onClick={() => handleUpdateButtonClick(row.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <g clipPath="url(#clip0_192_15848)">
                      <path
                        d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_192_15848">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ms-2">Modifier</span>
                </Dropdown.Item>
              )}
              {hasUsersPasswordPermission && (
                <Dropdown.Item
                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#changerMotdepasse"
                  aria-controls="changerMotdepasse"
                  onClick={() => handleUpdateButtonClick(row.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M11.334 3.41667C12.7482 4.44703 13.6673 6.11605 13.6673 7.99974C13.6673 11.1294 11.1303 13.6664 8.00065 13.6664H7.66732M4.66732 12.5828C3.25309 11.5525 2.33398 9.88343 2.33398 7.99974C2.33398 4.87013 4.87104 2.33307 8.00065 2.33307H8.33398M8.66732 14.9331L7.33398 13.5997L8.66732 12.2664M7.33398 3.73307L8.66732 2.39974L7.33398 1.06641"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ms-2"> Changer mot de passe</span>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];
  const noDataFound = "Aucune donnée trouvée";
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      <div className="row my-1">
        {isMobile <= 1280 ? (
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Adresse :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.address}
            </div>
          </div>
        ) : (
          ""
        )}
        {isMobile <= 599 ? (
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Role :</div>
            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.role.name}
            </div>
          </div>
        ) : (
          ""
        )}
        {isMobile <= 959 ? (
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">NIRPP :</div>
            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.nirpp}
            </div>
          </div>
        ) : (
          ""
        )}
        {isMobile <= 959 ? (
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Numéro de téléphone :</div>
            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              <a
                href={`tel:+${data.phone}`}
                className="text-dark "
                data-placement="top"
                title="Appeler">
                {data.phone || noDataFound}
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <hr className="W-100"></hr>
    </pre>
  );

  async function getAllUsers() {
    axios
      .get(`${baseURL}/users`)
      .then((response) => {
        if (response.data.error) return;
        setUsers(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  function getRoles() {
    axios.get(`${baseURL}/roles`).then((resp) => {
      if (resp.data.error) return;
      setRoles(resp.data.data);
    });
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (hasRolesConsultPermission) {
      getRoles();
    }
  }, [hasRolesConsultPermission]);

  useEffect(() => {
    if (hasUsersConsultPermission) {
      setData(<Loader />);
      getAllUsers();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasUsersConsultPermission]);

  const [filterEmployee, setFilterEmployee] = useState("");
  useEffect(() => {
    if (!hasUsersConsultPermission) {
      return;
    }
    let filteredData = applyFilters(users, [
      (data) => filterUsersByName(data, nameFilter),
      (data) => filterUsersByEmail(data, emailFilter),
      (data) => filterUsersByAdresse(data, adresseFilter),
    ]);
    if (filterEmployee === "manager") {
      filteredData = filteredData.filter((user) => user.isEmployee === "no");
    } else if (filterEmployee === "employee") {
      filteredData = filteredData.filter((user) => user.isEmployee === "yes");
    }

    if (!(nameFilter || emailFilter || adresseFilter || filterEmployee)) {
      filteredData = users;
    }
    setData(
      filteredData.length > 0 ? (
        <DataTable
          className="dataTableClass"
          columns={columns}
          data={filteredData}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      ) : (
        <EmptyData />
      )
    );
  }, [
    hasUsersConsultPermission,
    users,
    nameFilter,
    emailFilter,
    adresseFilter,
    filterEmployee,
  ]);
  function applyFilters(data, filters) {
    let filteredData = data;
    for (const filter of filters) {
      filteredData = filter(filteredData);
    }

    if (nameFilter !== "") {
      sessionStorage.setItem("nameFilterUser", nameFilter);
    }
    if (emailFilter !== "") {
      sessionStorage.setItem("emailFilterUser", emailFilter);
    }
    if (adresseFilter !== "") {
      sessionStorage.setItem("adresseFilterUser", adresseFilter);
    }
    return filteredData;
  }
  function handleRestoreFilter() {
    const nameFilterUser = sessionStorage.getItem("nameFilterUser");
    const emailFilterUser = sessionStorage.getItem("emailFilterUser");
    const adresseFilterUser = sessionStorage.getItem("adresseFilterUser");
    if (nameFilterUser !== null) {
      setNameFilter(sessionStorage.getItem("nameFilterUser"));
    }
    if (emailFilterUser !== null) {
      setEmailFilter(sessionStorage.getItem("emailFilterUser"));
    }
    if (adresseFilterUser !== null) {
      setAdresseFilter(sessionStorage.getItem("adresseFilterUser"));
    }
    setTimeout(() => {
      sessionStorage.removeItem("nameFilterUser");
      sessionStorage.removeItem("emailFilterUser");
      sessionStorage.removeItem("adresseFilterUser");
    }, 100);
  }
  function filterUsersByName(data, searchText) {
    return data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function filterUsersByEmail(data, searchText) {
    return data.filter(
      (item) =>
        item.email &&
        item.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function filterUsersByAdresse(data, searchText) {
    const filteredData = data.filter((item) => {
      if (item.address === null) {
        return true;
      }
      return item.address.toLowerCase().includes(searchText.toLowerCase());
    });

    return filteredData;
  }
  const handleResetFilter = () => {
    setNameFilter("");
    setEmailFilter("");
    setAdresseFilter("");
  };
  async function enableDisable(user_id, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cet utilisateur?"
        : "Voulez vous Activer cet utilisateur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/users/toggleStatus/${user_id}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllUsers();
            }
          })
          .catch((error) => {
            DisplayError();
          });
      }
    });
  }
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    const button = document.querySelector('[data-toggle="filterButton"]');
    const svgPath = button.querySelector("svg path");

    if (button && svgPath) {
      button.classList.toggle("btn-primary");

      if (svgPath.getAttribute("stroke") === "white") {
        svgPath.setAttribute("stroke", "black");
      } else {
        svgPath.setAttribute("stroke", "white");
      }
    }
  };
  const [selectedForUpdate, setSelectedForUpdate] = useState(null);
  function handleUpdateButtonClick(userId) {
    setSelectedForUpdate(userId);
  }
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line icon_acceuil"></i>
                    <span className="acceuil_title">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="div_tabRoles">
        <div className="row">
          <div className="col-md-8 ">
            <h4 className="fw-bold m-4">Liste des utilisateurs</h4>
          </div>
          <div className="col-md-4 text-sm-end text-start justify-content-center  justify-content-lg-end d-flex d-lg-bloc">
            {hasUsersCreatePermission && hasRolesConsultPermission && (
              <button
                className="btn btn-primary m-2 m-md-4 ms-lg-4 w-100"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#ajouterUtilisateur"
                aria-controls="ajouterUtilisateur">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none">
                  <path
                    d="M9.99935 13.4167H6.24935C5.08638 13.4167 4.50489 13.4167 4.03173 13.5602C2.96639 13.8834 2.13272 14.717 1.80955 15.7824C1.66602 16.2555 1.66602 16.837 1.66602 18M15.8327 18V13M13.3327 15.5H18.3327M12.0827 6.75C12.0827 8.82107 10.4038 10.5 8.33268 10.5C6.26161 10.5 4.58268 8.82107 4.58268 6.75C4.58268 4.67893 6.26161 3 8.33268 3C10.4038 3 12.0827 4.67893 12.0827 6.75Z"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2"> Ajouter un utilisateur</span>
              </button>
            )}
          </div>
          <hr className="w-100"></hr>
          <div className="col-12 pb-2 mb-2">
            <div className="row mx-1">
              <div className=" col-12 col-lg-9 ps-3 align-items-center d-flex justify-content-center justify-content-lg-start">
                <div
                  className="btn-group flex-column flex-sm-row mb-5 mb-lg-0 w-75"
                  role="group"
                  aria-label="First group">
                  <button
                    type="button"
                    className="btn btn-light btnLightFiltre"
                    data-mdb-color="dark"
                    onClick={() => setFilterEmployee("")}>
                    <span className="f-5">Voir tous</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btnLightFiltre"
                    data-mdb-color="dark"
                    onClick={() => setFilterEmployee("manager")}>
                    <span className="f-5">Managers</span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-light btnLightFiltre"
                    data-mdb-color="dark"
                    onClick={() => setFilterEmployee("employee")}>
                    <span className="f-5">Employées</span>
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-3 px-2 mx-lg-0 ps-lg-0 d-flex justify-content-center justify-content-lg-end align-items-start">
                <button
                  className="w-100 w-lg-auto btn btn-primary but-filtre mt-3 mt-lg-0"
                  type="button"
                  onClick={toggleFilter}
                  data-toggle="filterButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ms-2">Filtres</span>
                </button>
              </div>
            </div>
          </div>
          {/*  filter component */}
          {isFilterVisible && (
            <div className="  filterCardBackground">
              <div className="row p-2 mb-2">
                <div className="col-lg-7 col-md-12 d-flex ps-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Nom</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par Nom"
                          className="form-control inputFiltre"
                          type="search"
                          value={nameFilter}
                          onChange={(e) => setNameFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Email</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par Email"
                          className="form-control inputFiltre"
                          type="search"
                          value={emailFilter}
                          onChange={(e) => setEmailFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Adresse</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par Adresse"
                          className="form-control inputFiltre"
                          type="search"
                          value={adresseFilter}
                          onChange={(e) => setAdresseFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-12 row justify-content-center  mt-3 pt-2">
                  <div className="col-lg-5 col-md-4 col-sm-12 pb-1 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-reset"
                      onClick={handleResetFilter}>
                      <img src={`../assets/img/repeat-04.svg`} /> Réinitialiser
                    </button>
                  </div>
                  <div className="col-lg-7 col-md-5 col-sm-12 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-restore"
                      onClick={handleRestoreFilter}>
                      {" "}
                      <i className="ri-arrow-left-line px-1"></i>Dernière
                      recherche
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <CreateUser
            getAllUsers={getAllUsers}
            roles={roles}
            getRoles={getRoles}
          />
          <UpdateUser
            getAllUsers={getAllUsers}
            roles={roles}
            getRoles={getRoles}
            selectedForUpdate={selectedForUpdate}
          />
          <UpdatePasswordUser selectedForUpdate={selectedForUpdate} />
        </div>
        <section className="section">
          <div className="row">
            <div>{data}</div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Users;
