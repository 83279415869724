import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import "react-phone-input-2/lib/style.css";
import DisplayError from "../ErrorComponants/DisplayError";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Tooltip from "@mui/material/Tooltip";

const ContactDisplay = () => {
  const [filter, setFilter] = useState("");
  const [managers, setManagers] = useState("");

  async function seachForManager() {
    await axios
      .post(`${baseURL}/managers/search`, { filter: filter })
      .then((response) => {
        if (response.data.error) return;
        setManagers(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (filter.length > 2) {
      seachForManager();
    }
  }, [filter]);

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="offcanvascontactDisplay"
      aria-labelledby="offcanvascontactDisplay">
      <div className="offcanvas-header ">
        <h4 id="offcanvascontactDisplay fw-bold">Trouver Un Contact</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div className="h-100 p-2 d-flex flex-column justify-content-between">
          <div className="row p-2">
            <label className="px-0 mb-2 text-500">Nom: *</label>
            <input
              type="text"
              className="form-control offcanvas-input"
              placeholder="Filtre"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
              required
            />
          </div>
          <div className=" p-2">
            {managers.length > 0
              ? managers.map((manager) => {
                  return (
                    <div className=" m-2 ">
                      <div className="consultant-card h-50">
                        <div>
                          <div className="consultant-first-titles-name mt-0 mt-3 mb-0 consultant-first-titles">
                            {manager.lname} {manager.fname}
                          </div>
                          <div className="consultant-second-titles">
                            {manager.designation}
                          </div>
                        </div>
                        <div>
                          <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none">
                              <path
                                d="M9.16329 1.75466L14.182 5.0168C14.3593 5.13207 14.448 5.18971 14.5122 5.26659C14.5691 5.33464 14.6118 5.41335 14.6379 5.49812C14.6673 5.59388 14.6673 5.69964 14.6673 5.91114V10.7989C14.6673 11.9191 14.6673 12.4791 14.4493 12.9069C14.2576 13.2833 13.9516 13.5892 13.5753 13.781C13.1475 13.9989 12.5874 13.9989 11.4673 13.9989H4.53398C3.41388 13.9989 2.85383 13.9989 2.426 13.781C2.04968 13.5892 1.74372 13.2833 1.55197 12.9069C1.33398 12.4791 1.33398 11.9191 1.33398 10.7989V5.91114C1.33398 5.69964 1.33398 5.59388 1.36343 5.49812C1.3895 5.41335 1.43222 5.33464 1.48908 5.26659C1.55332 5.18971 1.64199 5.13207 1.81933 5.0168L6.83801 1.75466M9.16329 1.75466C8.74246 1.48112 8.53205 1.34435 8.30534 1.29114C8.10494 1.2441 7.89637 1.2441 7.69597 1.29114C7.46925 1.34435 7.25884 1.48112 6.83801 1.75466M9.16329 1.75466L13.2914 4.43794C13.75 4.73601 13.9793 4.88504 14.0587 5.07404C14.1281 5.2392 14.1281 5.42535 14.0587 5.59052C13.9793 5.77952 13.75 5.92855 13.2914 6.22662L9.16329 8.9099C8.74247 9.18343 8.53205 9.3202 8.30534 9.37342C8.10494 9.42046 7.89637 9.42046 7.69597 9.37342C7.46925 9.3202 7.25884 9.18343 6.83801 8.90989L2.70989 6.22662C2.25133 5.92855 2.02204 5.77952 1.94263 5.59052C1.87324 5.42535 1.87324 5.2392 1.94263 5.07404C2.02204 4.88504 2.25133 4.73601 2.70989 4.43794L6.83801 1.75466M14.334 12.6656L9.90544 8.66561M6.09587 8.66561L1.66732 12.6656"
                                stroke="black"
                                stroke-width="1.06667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ms-2">Email :</span>
                          </div>
                          <Tooltip
                            title={
                              manager?.email
                                ? manager?.email
                                : "Aucune donnée trouvée"
                            }>
                            <div className="consultant-second-titles">
                              {manager?.email
                                ? manager.email.length > 25
                                  ? `${manager.email.substring(0, 25)}...`
                                  : manager.email
                                : "Aucune donnée trouvée"}
                            </div>
                          </Tooltip>
                        </div>
                        <div>
                          <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none">
                              <path
                                d="M10.0004 2.66667V6M10.0004 6L13.3337 6M10.0004 6L14.0004 2M6.81841 9.24205C6.01736 8.44099 5.38483 7.53523 4.92084 6.56882C4.88092 6.48569 4.86097 6.44413 4.84564 6.39154C4.79115 6.20463 4.83029 5.97513 4.94363 5.81684C4.97552 5.7723 5.01363 5.7342 5.08984 5.65799C5.32291 5.42492 5.43944 5.30838 5.51563 5.1912C5.80296 4.74927 5.80296 4.17955 5.51563 3.73762C5.43944 3.62044 5.32291 3.5039 5.08984 3.27083L4.95992 3.14092C4.60563 2.78662 4.42848 2.60947 4.23823 2.51324C3.85985 2.32186 3.41301 2.32186 3.03464 2.51324C2.84438 2.60947 2.66723 2.78662 2.31294 3.14092L2.20785 3.24601C1.85477 3.59909 1.67823 3.77563 1.54339 4.01565C1.39378 4.28199 1.28621 4.69565 1.28712 5.00113C1.28793 5.27643 1.34134 5.46458 1.44814 5.84087C2.02212 7.86314 3.1051 9.77138 4.69709 11.3634C6.28908 12.9554 8.19732 14.0383 10.2196 14.6123C10.5959 14.7191 10.784 14.7725 11.0593 14.7733C11.3648 14.7743 11.7785 14.6667 12.0448 14.5171C12.2848 14.3822 12.4614 14.2057 12.8145 13.8526L12.9195 13.7475C13.2738 13.3932 13.451 13.2161 13.5472 13.0258C13.7386 12.6474 13.7386 12.2006 13.5472 11.8222C13.451 11.632 13.2738 11.4548 12.9195 11.1005L12.7896 10.9706C12.5566 10.7376 12.44 10.621 12.3228 10.5448C11.8809 10.2575 11.3112 10.2575 10.8693 10.5448C10.7521 10.621 10.6355 10.7376 10.4025 10.9706C10.3263 11.0468 10.2882 11.0849 10.2436 11.1168C10.0853 11.2302 9.85582 11.2693 9.66892 11.2148C9.61633 11.1995 9.57476 11.1795 9.49164 11.1396C8.52523 10.6756 7.61946 10.0431 6.81841 9.24205Z"
                                stroke="black"
                                stroke-width="1.07"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <span className="ms-2">Téléphone :</span>
                          </div>
                          <Tooltip
                            title={
                              manager?.phone
                                ? manager?.phone
                                : "Aucune donnée trouvée"
                            }>
                            <div className="consultant-second-titles">
                              {manager.phone
                                ? manager.phone.length > 25
                                  ? `${manager.phone.substring(0, 25)}...`
                                  : manager.phone
                                : "Aucune donnée trouvée".length > 25
                                ? `${"Aucune donnée trouvée".substring(
                                    0,
                                    25
                                  )}...`
                                : "Aucune donnée trouvée"}
                            </div>
                          </Tooltip>
                        </div>
                        <div>
                          <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M11 11H6.2C5.07989 11 4.51984 11 4.09202 11.218C3.71569 11.4097 3.40973 11.7157 3.21799 12.092C3 12.5198 3 13.0799 3 14.2V21M21 21V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H14.2C13.0799 3 12.5198 3 12.092 3.21799C11.7157 3.40973 11.4097 3.71569 11.218 4.09202C11 4.51984 11 5.0799 11 6.2V21M22 21H2M14.5 7H17.5M14.5 11H17.5M14.5 15H17.5"
                                stroke="#667085"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span className="ms-2">société :</span>
                          </div>
                          <div className="consultant-second-titles">
                            <Link
                              className="color-msit "
                              to={`/company/details/${manager?.company?._id}`}>
                              <img
                                src={manager?.company?.logo}
                                className="rounded-circle me-2 ms-2"
                                width="35"
                                height="35"
                              />
                              <span className="text-black">
                                {manager?.company?.name
                                  ? manager?.company?.name
                                  : ""}
                              </span>
                              <svg
                                height={26}
                                width={26}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                  <g id="Interface / External_Link">
                                    <path
                                      id="Vector"
                                      d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11"
                                      stroke="#667085"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"></path>
                                  </g>
                                </g>
                              </svg>
                            </Link>
                          </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                          {/* <Dropdown className="w-auto">
                          <Dropdown.Toggle bsPrefix=" border-0 px-1  bg-primary ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none">
                              <path
                                d="M9.99935 10.834C10.4596 10.834 10.8327 10.4609 10.8327 10.0007C10.8327 9.54041 10.4596 9.16732 9.99935 9.16732C9.53911 9.16732 9.16602 9.54041 9.16602 10.0007C9.16602 10.4609 9.53911 10.834 9.99935 10.834Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.99935 5.00065C10.4596 5.00065 10.8327 4.62755 10.8327 4.16732C10.8327 3.70708 10.4596 3.33398 9.99935 3.33398C9.53911 3.33398 9.16602 3.70708 9.16602 4.16732C9.16602 4.62755 9.53911 5.00065 9.99935 5.00065Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.99935 16.6673C10.4596 16.6673 10.8327 16.2942 10.8327 15.834C10.8327 15.3737 10.4596 15.0007 9.99935 15.0007C9.53911 15.0007 9.16602 15.3737 9.16602 15.834C9.16602 16.2942 9.53911 16.6673 9.99935 16.6673Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="candidat-dropdown-menu">
                            <Dropdown.Item
                              className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasUpdateConsultant"
                              aria-controls="offcanvasUpdateConsultant"
                              onClick={() => {
                                handleUpdateConsultantButtonClick(manager._id);
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none">
                                <g clipPath="url(#clip0_192_15848)">
                                  <path
                                    d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                                    stroke="#344054"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_192_15848">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <span className="ms-2">
                                Modifier les informations
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              : "inserez au moins 3 caracteres pour filtrer"}
          </div>
          <div className="row p-2">
            <button
              id="cancelButton"
              type="button"
              className="btn but-restore"
              data-bs-dismiss="offcanvas"
              aria-label="Close">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactDisplay;
