import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { baseURL } from "../../config/config";
import "../../pages/mission/detailsMission.css";
import DisplayError from "../ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import { Switch, Tooltip } from "@mui/material";
import { useLayoutEffect } from "react";
import CreateNote from "../candidat/candidatNote";
import CadidatToEmplyeeOffCanvas from "../consultant/CadidatToEmplyeeOffCanvas";
import UpdateCandidat from "../candidat/updateStepsCandidats/Updatecandidat";
import Addcandidat from "../candidat/Addcandidat";
import { FaRegAddressCard } from "react-icons/fa";
import CvFrame from "../candidat/CvFrame";
function MissionEmployees({ mission, employees, getEmployeeByMissionId }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const { user } = useContext(UserContext);
  const [addCandidatModal, setaddCandidatModal] = useState();
  const [loadedEmployees, setLoadedEmployees] = useState([]);
  const [consultantCV, setConsultantCV] = useState();
  const noDataFound = "Aucune donnée trouvée";
  const hasMissionEmployeesAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "create"
  );

  const hasMissionEmployeesStatusPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "change_status"
  );
  const hasCandidatsEditPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "edit"
  );
  const hasCandidatsCreatePermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "create"
  );
  const hasCandidatsConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consult"
  );
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consulter_enabled"
  );
  const hasCandidatsStatusPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "change_status"
  );
  const hasCandidatsNotePermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "create_note"
  );
  const hasCandidatsNoteConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consulter_note"
  );
  const hasEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consult"
  );
  const hasEmployeesCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create"
  );
  const hasCvCreatePermission = hasPermission(user, PERMISSIONS.cv, "create");
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const hasMissionConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasExperienceCandidatPermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "consult"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasEmployeeCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create_from_candidat"
  );
  const [data, setData] = useState();
  const [steps, setSteps] = useState();

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [selectedForUpdate, setSelectedForUpdate] = useState("");
  function handleUpdateButtonClick(candidatId) {
    setSelectedForUpdate(candidatId);
  }
  const columnsMissionEmployees = [
    {
      name: "Actions",
      center: true,
      cell: (row) => (
        <div>
          {hasMissionEmployeesStatusPermission && (
            <Switch
              checked={row.consultant.isEnabled === "yes"}
              className=" btn text-primary  btn"
              onClick={() => {
                enableDisableMissionEmployee(
                  row.consultant?._id,
                  row.consultant?.isEnabled
                );
              }}></Switch>
          )}
        </div>
      ),
      id: "actions",
      hide: "md",
    },
    {
      name: "Nom",
      selector: (row) => (
        <div className="col-12">
          <Link
            to={`/candidats/details/${row.consultant?._id}`}
            className="color-msit d-flex align-items-center">
            <img
              src={`${baseURL}/${row.consultant?.avatar?.path}`}
              className="rounded-circle"
              width="35"
              height="35"
              alt=""
            />
            <div>
              <div>
                {row.consultant?.firstname} {row.consultant?.lastname}
              </div>
              <div className="email">
                <p className="text-nowrap">
                  <a
                    href={`mailto:${row.consultant?.email}`}
                    className="color-msit "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Envoyer mail">
                    {row.consultant?.email.length > 20
                      ? `${row.consultant?.email.substring(0, 20)}...`
                      : row.consultant?.email}
                  </a>
                </p>
              </div>
            </div>
          </Link>
        </div>
      ),
      sortable: true,
      id: "name",
    },
    {
      name: "Phone",
      center: true,
      cell: (row) => (
        <div>
          <div className="phone">
            <p className="text-nowrap">
              <a
                href={`tel:+${row.consultant?.phone}`}
                className="color-msit "
                data-toggle="tooltip"
                data-placement="top"
                title="Appeler">
                <i className="bi bi-phone"></i>
                {row.consultant?.phone}
              </a>
            </p>
          </div>
        </div>
      ),
      id: "phone",
      hide: "lg",
    },
    {
      name: "Adresse",
      selector: (row) => row.consultant?.address || "Aucune donnée trouvée",
      center: true,
      sortable: true,
      id: "address",
      hide: 1400,
    },

    {
      name: "poste",
      selector: (row) => row.consultant?.posteType?.name,
      center: true,
      sortable: true,
      id: "Type",
      hide: 1700,
    },
    {
      name: "CV",
      selector: (row) => (
        <button
          className="btn"
          onClick={() => {
            setConsultantCV(
              <CvFrame
                hideModal={() => setConsultantCV("")}
                data={row.cv}
                consultant={row.consultant}
              />
            );
          }}>
          <FaRegAddressCard />
        </button>
      ),
      center: true,
      sortable: true,
      id: "Type",
      hide: "md",
    },
    {
      name: "Nbr H",
      selector: (row) => row.hours ?? "0" + " H",
      center: true,
      sortable: true,
      id: "hours",
      hide: 1700,
    },
    {
      name: "Statut",
      center: true,
      cell: (row) => (
        <div className="row col-12">
          {hasMissionEmployeesAffectPermission && (
            <select
              value={row.status}
              className="form-select form-select-sm"
              onChange={(e) =>
                changeStatus(e.target.value, row.consultant?._id)
              }>
              <option value="waiting">Attente de Confirmation</option>
              <option value="in_progress">Encours d'entretien</option>
              <option value="rejected">Rejeté</option>
              <option value="validated">Validé</option>
            </select>
          )}
        </div>
      ),
      id: "status",
      hide: "sm",
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 1700 ? (
        <div className="row my-1">
          {data?.consultant?.type === "supplier" ? (
            <div className="col-md-6 my-1">
              <div className="ms-1 text-details">Société :</div>

              <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
                <Link
                  className="color-msit "
                  to={`/company/details/${data?.consultant?.company?._id}`}>
                  {data?.consultant?.company?.logo ? (
                    <img
                      src={
                        baseURL +
                        data?.consultant?.company?.logo?.path.substring(1)
                      }
                      className="rounded-circle me-2 ms-2"
                      width="35"
                      height="35"
                    />
                  ) : (
                    ""
                  )}
                  <span className="text-black">
                    {data?.consultant?.company?.name
                      ? data?.consultant?.company?.name
                      : noDataFound}
                  </span>
                </Link>
                {}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Nombre d'heures :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.hours ?? "0" + " H"}
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">poste :</div>
            {data?.consultant?.posteType?.name || noDataFound}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 1400 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Adresse :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.consultant?.address || noDataFound}
            </div>
          </div>
          {isMobile <= 1280 ? (
            <div className="col-md-6 my-1">
              <div className="ms-1 text-details">Phone :</div>

              <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
                <Tooltip title={data.consultant?.phone} arrow>
                  <a
                    href={`tel:${data.consultant?.phone}`}
                    className="text-color-datatable "
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Appeler">
                    {data.consultant?.phone}
                  </a>
                </Tooltip>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Activer/desactiver :</div>
            {hasMissionEmployeesStatusPermission && (
              <Switch
                checked={data.consultant.isEnabled === "yes"}
                className=" btn text-primary  btn"
                onClick={() => {
                  enableDisableMissionEmployee(
                    data.consultant?._id,
                    data.consultant?.isEnabled
                  );
                }}></Switch>
            )}
          </div>
          {isMobile <= 599 ? (
            <div className="col-md-6 my-1">
              <div className="ms-1 text-details">Statut :</div>
              {hasMissionEmployeesAffectPermission && (
                <select
                  value={data.status}
                  className="form-select form-select-sm"
                  onChange={(e) =>
                    changeStatus(e.target.value, data.consultant?._id)
                  }>
                  <option value="waiting">Attente de Confirmation</option>
                  <option value="in_progress">Encours d'entretien</option>
                  <option value="rejected">Rejeté</option>
                  <option value="validated">Validé</option>
                </select>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );
  function getMissionById() {
    if (mission._id === undefined) return;
    axios.get(`${baseURL}/missions/${mission._id}`).then((response) => {
      if (response.data.error) return;
      setSteps(response.data.data.steps);
    });
  }

  function changeStatus(status, consultantId) {
    let currentStepIndex = "";
    if (status === "rejected") {
      currentStepIndex = "rejected";
    } else if (status === "validated") {
      currentStepIndex = "affected";
    } else if (status === "waiting") {
      currentStepIndex = "notAffected";
    } else if (status === "in_progress") {
      currentStepIndex = steps[0].index ? steps[0].index : "";
    }

    axios
      .post(
        `${baseURL}/missionsConsultants/updateStatusConsultantMission/${consultantId}`,
        {
          missionId: mission._id,
          status: status,
          currentStepIndex: currentStepIndex,
        }
      )
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getEmployeeByMissionId();
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (employees.length > 0) {
      setData(
        <DataTable
          className="dataTableClass"
          columns={columnsMissionEmployees}
          data={employees}
          pagination
          paginationComponentOptions={{ noRowsPerPage: true }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1700}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [employees]);

  async function enableDisableMissionEmployee(employeeId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission d'employé ?"
        : "Voulez vous Activer cette  mission d'employé?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirmed) => {
      if (confirmed) {
        await axios
          .get(`${baseURL}/consultants/toggleStatus/${employeeId}`, {
            missionId: mission._id,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getEmployeeByMissionId();
            }
          });
      }
    });
  }

  useEffect(() => {
    getMissionById();
  }, []);
  async function getAllEmployees() {
    axios
      .get(`${baseURL}/consultants/all`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setLoadedEmployees(respData.slice(0, loadedEmployees.length + 6));
      })
      .catch(() => {
        DisplayError();
      });
  }
  return (
    <>
      <div className="col-md-12">{data}</div>
      <CreateNote
        selectedForUpdate={selectedForUpdate}
        setSelectedForUpdate={setSelectedForUpdate}
        destination={"candidates"}
      />

      <CadidatToEmplyeeOffCanvas
        setSelectedForUpdate={setSelectedForUpdate}
        candidatId={selectedForUpdate}
      />

      <UpdateCandidat
        getAllCandidats={getAllEmployees}
        setSelectedForUpdate={setSelectedForUpdate}
        id={selectedForUpdate}
      />
      <Addcandidat
        setaddEmployeeModalFromChild={setaddCandidatModal}
        getAllCandidats={getAllEmployees}
      />
      {consultantCV}
    </>
  );
}

export default MissionEmployees;
