import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import Select from "react-select";
import permissions from "../../components/permissions";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import Loader from "../../components/loader";
const initFormErrors = {
  name: [""],
  email: [""],
  phone: [""],
  password: [""],
  confirm_password: [""],
  address: [""],
  socialSecurityNumber: [""],
  role: [""],
};
const UpdateUser = ({ getAllUsers, roles, getRoles, selectedForUpdate }) => {
  const { user } = useContext(UserContext);
  const hasRolesCreatePermission = hasPermission(
    user,
    PERMISSIONS.roles,
    "create"
  );

  const [nameUpdate, setNameUpdate] = useState("");
  const [emailUpdate, setEmailUpdate] = useState("");
  const [oldEmailUpdate, setOldEmailUpdate] = useState("");
  const [addressUpdate, setAddressUpdate] = useState("");
  const [phoneUpdate, setPhoneUpdate] = useState("");
  const [roleUpdate, setRoleUpdate] = useState({ role: "", id: "" });
  const [avatarUpdate, setAvatarUpdate] = useState();
  const [socialSecurityNumberUpdate, setSocialSecurityNumberUpdate] =
    useState("");
  const [isInterviewerUpdate, setIsInterviewerUpdate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleIsInterviewerChangeUpdate = (event) => {
    setIsInterviewerUpdate(event.target.value);
  };
  const [compAddRoleVisible, setCompAddRoleVisible] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [permission, setPermission] = useState([]);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [checkPermission, setCheckPermission] = useState([]);
  const [roleAdd, setRoleAdd] = useState([]);
  const [searchText, setSearchText] = useState("");

  const msg = "Ce champ est obligatoire";
  const msgPermissions =
    "Les autorisations du rôle ne peuvent pas être nulles, il faut choisir au moins un accès";
  const openComponentAddRole = () => {
    setCompAddRoleVisible(!compAddRoleVisible);
  };
  const [avatarUploadedUpdate, setAvatarUploadedUpdate] = useState();
  const handleImageChangeUpdate = (e) => {
    setAvatarUpdate(e.target.files[0]);
    const selectedFileUpdate = e.target.files[0];

    if (selectedFileUpdate) {
      const readerUpdate = new FileReader();

      readerUpdate.onload = (event) => {
        setAvatarUploadedUpdate(event.target.result);
      };

      readerUpdate.readAsDataURL(selectedFileUpdate);
    }
  };
  const roleOptions =
    roles != null
      ? roles.map((role) => ({
          value: role.id,
          label: role.name,
        }))
      : [];
  const initFormErrorsUpdate = {
    nameUpdate: [""],
    emailUpdate: [""],
    phoneUpdate: [""],
    addressUpdate: [""],
    nirppUpdate: [""],
    roleUpdate: [""],
  };
  const [formerrorsUpdate, setFormErrorsUpdate] =
    useState(initFormErrorsUpdate);

  function getUser() {
    const userId = selectedForUpdate;
    if (userId === undefined) return;
    axios
      .get(`${baseURL}/users/${userId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const userData = resp.data.data;
        setNameUpdate(userData.name);
        setEmailUpdate(userData.email);
        setOldEmailUpdate(userData.email);
        setAddressUpdate(userData.address);
        setPhoneUpdate(userData.phone);
        setIsInterviewerUpdate(userData.isInterviewer);
        setAvatarUpdate(userData.avatar?.path);
        setRoleUpdate(userData.role?._id);
        setSocialSecurityNumberUpdate(userData.nirpp ? userData.nirpp : "");
        getRoles();
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (selectedForUpdate !== null) {
      getUser();
    }
  }, [selectedForUpdate]);

  function updateUser() {
    setIsLoading(true);
    const userId = selectedForUpdate;
    const formData = new FormData();
    formData.append("name", nameUpdate);
    formData.append("avatar", avatarUpdate);
    formData.append("email", emailUpdate);
    formData.append("oldEmail", oldEmailUpdate);
    formData.append("address", addressUpdate);
    formData.append("role_id", roleUpdate);
    formData.append("phone", phoneUpdate);
    formData.append("nirpp", socialSecurityNumberUpdate);
    formData.append("isInterviewer", isInterviewerUpdate);

    axios
      .put(`${baseURL}/users/${userId}`, formData)
      .then((resp) => {
        setFormErrorsUpdate(initFormErrorsUpdate);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setIsLoading(false);
        const boutonOffcanvas = document.getElementById("cancelButton2");
        boutonOffcanvas.click();
        getUser();
        getAllUsers();
        setAvatarUpdate("");
        setAvatarUploadedUpdate("");
      })
      .catch((error) => {
        setFormErrorsUpdate(error.response.data);
        setIsLoading(false);
      });
  }
  function resetFormErrors() {
    setFormErrorsUpdate(initFormErrorsUpdate);
  }

  function createRole() {
    axios
      .post(`${baseURL}/roles/create`, {
        name: roleAdd,
        permission: JSON.stringify(permission),
      })
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        openComponentAddRole();
        setCheckPermission("");
        setRoleAdd("");
        setSearchText("");
        getRoles();
        setErrorMessage();
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  }
  const checkPermissionCreate = ({ target: { value } }) => {
    setCheckPermission((prevCheck) => {
      const updatedList = prevCheck.includes(value)
        ? prevCheck.filter((val) => val !== value)
        : [...prevCheck, value];
      const [entity, permissionValue] = value.split("_");
      if (permissionValue !== "consult") {
        if (!updatedList.includes(`${entity}_consult`)) {
          updatedList.push(`${entity}_consult`);
        }
      } else {
        const otherPermissions = permissions[entity]?.items.map(
          (permission) => `${entity}_${permission.value}`
        );
        const hasOtherPermissions = otherPermissions.some((permissionKey) =>
          updatedList.includes(permissionKey)
        );
        if (!updatedList.includes(value) && hasOtherPermissions) {
          updatedList.push(value);
        }
      }
      const updatedPermission = {};
      updatedList.forEach((item) => {
        const [entity, ...permissionValue] = item.split("_");
        const fullPermissionValue = permissionValue.join("_");
        if (!updatedPermission[entity]) {
          updatedPermission[entity] = [];
        }
        updatedPermission[entity].push(fullPermissionValue);
      });

      setPermission(updatedPermission);
      return updatedList;
    });
  };

  function validateCreate() {
    const newErrorMessage = { ...initFormErrors };
    if (!roleAdd) {
      newErrorMessage.role = msg;
    } else if (
      typeof roleAdd !== "string" ||
      !roleAdd.match(/^[^-\s][a-zA-Z0-9_\s-]+$/)
    ) {
      newErrorMessage.role = "Veuillez entrer un nom valide.";
    } else {
      newErrorMessage.role = "";
    }
    if (checkPermission.length === 0) {
      newErrorMessage.permissionField = msgPermissions;
    } else {
      newErrorMessage.permissionField = "";
    }
    setErrorMessage(newErrorMessage);
    if (!newErrorMessage.role && !newErrorMessage.permissionField) {
      createRole();
    }
  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="modifierUtilisateur"
      aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel fw-bold">Modifier utilisateur</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetFormErrors}></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div className="mt-4">
          <div className="row d-flex justify-content-center">
            <div className="col-6">
              <div className="bg-white">
                <label
                  htmlFor="fileInput2"
                  className="d-flex justify-content-center">
                  <div className="mb-2">
                    {avatarUploadedUpdate ? (
                      <img
                        src={avatarUploadedUpdate}
                        alt="Uploaded"
                        className="imgUploaded "
                      />
                    ) : (
                      <img
                        src={
                          avatarUpdate
                            ? baseURL + avatarUpdate.toString().substring(1)
                            : ""
                        }
                        className="imgUploaded "
                      />
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id="fileInput2"
                  name="profile_picture2"
                  className="d-none"
                  accept="image/png, image/gif, image/jpeg"
                  required
                  onChange={handleImageChangeUpdate}
                />
              </div>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Nom: *</label>
            <input
              type="text"
              className="form-control offcanvas-input"
              placeholder="Nom"
              onChange={(e) => setNameUpdate(e.target.value)}
              value={nameUpdate}
              required
            />
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrorsUpdate.name}
              </span>
            </div>
          </div>
          <div className="row px-2 py-2">
            <label className="px-0 mb-2 text-500">
              Numéro de securité social : *
            </label>
            <input
              type="number"
              min="0"
              className="form-control offcanvas-input"
              placeholder="Numéro de securité social "
              onChange={(e) => setSocialSecurityNumberUpdate(e.target.value)}
              value={socialSecurityNumberUpdate}
              required
            />
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrorsUpdate.nirpp}
              </span>
            </div>
          </div>
          <div className="row px-2 py-2">
            <label className="px-0 mb-2 text-500">Rôle : *</label>
            <div className="input-selectRole">
              <Select
                id="inputState"
                className="col-11"
                options={roleOptions}
                value={roleOptions.find(
                  (option) => option.value === roleUpdate
                )}
                onChange={(selectedOption) =>
                  setRoleUpdate(selectedOption ? selectedOption.value : "")
                }
                placeholder="Select a role..."
                isMulti={false}
              />
              {hasRolesCreatePermission && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={openComponentAddRole}>
                  <i className="bi bi-plus-lg"></i>
                </button>
              )}
            </div>
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrorsUpdate?.role}
              </span>
            </div>
          </div>
          {compAddRoleVisible && (
            <div className="cardAddROle">
              <div className="mt-3 ms-3">
                <h4 id="fs-title">Ajouter un rôle</h4>
              </div>
              <div className="offcanvas-body offcanvas-body-scrollable">
                <div>
                  <form>
                    <div>
                      <label>Rôle</label>
                      <input
                        type="text"
                        className="form-control inputFiltre my-3"
                        placeholder="Ajouter un rôle"
                        onChange={(e) => setRoleAdd(e.target.value)}
                        value={roleAdd}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage?.message}
                        </span>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage?.role}
                        </span>
                      </div>
                    </div>
                    <hr className="w-75 mx-auto"></hr>
                    <div>
                      <div className="groupFiltre">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Chercher une permission"
                          className="form-control inputFiltre my-3"
                          type="search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>

                      <label>Permissions</label>
                      <div
                        className="accordion no-accordion-borders mt-3"
                        id="accordionPermissions">
                        {Object.keys(permissions).map(
                          (entity, index) =>
                            permissions[entity]?.name
                              .toLowerCase()
                              .includes(searchText.toLowerCase()) && (
                              <div className="accordion-item" key={entity}>
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}`}>
                                  <button
                                    className={`accordion-button ${
                                      activeAccordion === index
                                        ? ""
                                        : "collapsed"
                                    }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={
                                      activeAccordion === index
                                        ? "true"
                                        : "false"
                                    }
                                    aria-controls={`collapse${index}`}
                                    onClick={() => setActiveAccordion(index)}>
                                    {permissions[entity]?.name}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${index}`}
                                  className={`accordion-collapse collapse ${
                                    activeAccordion === index ? "show" : ""
                                  }`}
                                  aria-labelledby={`heading${index}`}
                                  data-bs-parent="#accordionExample">
                                  <div className="accordion-body row">
                                    <div className="m-2 border-start">
                                      {permissions[entity]?.items.map(
                                        (permission, permissionIndex) => (
                                          <div
                                            key={permission.value}
                                            className="permission-item form-check form-switch">
                                            <input
                                              className="form-check-input me-2"
                                              value={`${entity}_${permission.value}`}
                                              checked={checkPermission.includes(
                                                `${entity}_${permission.value}`
                                              )}
                                              type="checkbox"
                                              onChange={checkPermissionCreate}
                                            />
                                            <label className="form-check-label ms-1">
                                              {permission.name}
                                            </label>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                        <div className="input-error m-2">
                          <span className="text-danger">
                            {errorMessage?.permissionField}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-2 d-flex justify-content-center ">
                        <button
                          type="button"
                          className="btn btn-primary col-11"
                          onClick={validateCreate}>
                          Enregistrer
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary col-11  mt-2"
                          onClick={openComponentAddRole}>
                          Annuler
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          <div className="row px-2 py-2">
            <label className="px-0 mb-2 text-500">Adresse : *</label>
            <input
              type="text"
              className="form-control offcanvas-input"
              placeholder="Adresse"
              onChange={(e) => setAddressUpdate(e.target.value)}
              value={addressUpdate}
              required
            />
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrorsUpdate.address}
              </span>
            </div>
          </div>
          <div className="row px-2 py-2">
            <label className="px-0 mb-2 text-500">Téléphone : *</label>
            <div className="input-group px-0">
              <PhoneInput
                inputClass="phoneInput"
                className="offcanvas-input"
                placeholder="Téléphone"
                value={phoneUpdate}
                onChange={(value, country, event) =>
                  setPhoneUpdate(
                    event.target.value ? event.target.value : value
                  )
                }
              />
            </div>
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrorsUpdate?.phone}
              </span>
            </div>
          </div>
          <div className="row px-2 py-2">
            <div className="date-picker px-0">
              <label className="px-0 mb-2 text-500">Email : *</label>
              <div className="input-group mb-2 offcanvas-input">
                <input
                  placeholder="Email"
                  className="form-control"
                  onChange={(e) => setEmailUpdate(e.target.value)}
                  value={emailUpdate}
                  type="email"
                  required
                />
              </div>
            </div>
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrorsUpdate.email}
              </span>
            </div>
          </div>
          <div className="row px-2 py-2">
            <div className="date-picker px-0">
              <label className="px-0 mb-2 text-500">Interviewer : *</label>
              <div className="row pt-1">
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="yes"
                      checked={isInterviewerUpdate === "yes"}
                      onChange={handleIsInterviewerChangeUpdate}
                    />
                    <label className="form-check-label">Oui</label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="no"
                      checked={isInterviewerUpdate === "no"}
                      onChange={handleIsInterviewerChangeUpdate}
                    />
                    <label className="form-check-label">Non</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-2 pt-2 pb-1">
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateUser}
              disabled={isLoading}>
              {isLoading ? <Loader /> : "Modifier"}
            </button>
          </div>
          <div className="row px-2 pt-1 pb-2">
            <button
              id="cancelButton2"
              type="button"
              className="btn but-restore"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetFormErrors}>
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateUser;
