import React, { useContext, useEffect, useState } from "react";
import { EmployeeContext } from "../../pages/employee/createEmployees";
import { useHistory } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import AlertComponentEmp from "./AlertComponentEmp";
import EmployeesFormStep4 from "./EmployeesFormStep4";
import { baseURL } from "../../config/config";

const initFormErrors = {
  lname: "",
  fname: "",
  email: "",
  phone: "",
  address: "",
  cp: "",
  diplome: "",
  type: "",
  langues: "",
  avatar: "",
  nirpp: "",
  typeOfContract: "",
  startDate: "",
  salary: "",
  Job: "",
  cv: "",
  cityName: "",
};
const ConfirmEmp = ({ setChildComponent }) => {
  const { newEmployee, setNewEmployee } = useContext(EmployeeContext);
  const history = useHistory();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [errorAlert, setErrorAlert] = React.useState("");
  function sendData() {
    const formData = new FormData();
    formData.append("gender", newEmployee.gender);
    formData.append("firstname", newEmployee.fname);
    formData.append("lastName", newEmployee.lname);
    formData.append("email", newEmployee.email);
    formData.append("pays", newEmployee.countryid);
    formData.append("city", newEmployee.city);
    formData.append("address", newEmployee.address);
    formData.append("cp", newEmployee.cp);
    formData.append("phone", newEmployee.phone);
    formData.append("birthday", newEmployee.birthDay);
    formData.append("birthPlace", newEmployee.birthPlace);
    formData.append("diplomeDate", newEmployee.diplomeDate);
    formData.append("diplome", newEmployee.diplome);
    formData.append("type", newEmployee.type);
    formData.append("langues", newEmployee.langues);
    formData.append("status", newEmployee.status || "single");
    formData.append("skills", newEmployee.skills);
    formData.append("experience", newEmployee.experience);
    formData.append("avatar", newEmployee.avatar);
    formData.append("nirpp", newEmployee.nirpp);
    formData.append("job", newEmployee.job);
    formData.append("startDate", newEmployee.startDate);
    formData.append("salary", newEmployee.salary);
    formData.append("typeOfContract", newEmployee.typeOfContract);
    formData.append("cv", newEmployee.cv);
    formData.append("cvname", newEmployee.cvname);
    axios
      .post(`${baseURL}/employees/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/employees");
        }, 2400);
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
      });
  }
  return (
    <div>
      {errorAlert}
      <section className="section">
        <div className="row">
          <h1 className="text-center color-msit fw-bolder pb-5">
            Fiche de l'employé
          </h1>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-6">
                    <div>
                      <label className="color-msit fw-bold">
                        Nom et Prénom :
                      </label>
                      <span>
                        {newEmployee.lname} {newEmployee.fname}
                      </span>
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Téléphone : </label>
                      {newEmployee.phone !== "" ? (
                        newEmployee.phone
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label className="color-msit fw-bold">Email : </label>
                      {newEmployee.email}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">NIRPP : </label>
                      {newEmployee.nirpp}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Genre : </label>
                      {newEmployee != null &&
                      newEmployee.gender &&
                      newEmployee.gender === "male"
                        ? "Homme"
                        : newEmployee.gender === "femele"
                        ? "Femme"
                        : newEmployee.gender === "other"
                        ? "Autre"
                        : ""}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">langue :</label>
                      {newEmployee.langues && newEmployee.langues.length > 0 ? (
                        newEmployee.langues.map((langue, index) => (
                          <span key={index}>
                            {langue}
                            {index !== newEmployee.langues.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Etat civil :</label>
                      {newEmployee.status !== "" ? (
                        newEmployee.status
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">
                        Code postal :
                      </label>
                      {newEmployee.cp !== "" ? (
                        newEmployee.cp
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Ville : </label>
                      {newEmployee.cityName ? (
                        newEmployee.cityName
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Adresse : </label>
                      {newEmployee.address !== "" ? (
                        newEmployee.address
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">Expérience :</label>
                      {newEmployee.experience}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Poste : </label>
                      {newEmployee.job}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">Compétence :</label>
                      {newEmployee.skills}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">
                        Date début travail :
                      </label>
                      {newEmployee.startDate}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">Diplome:</label>
                      {newEmployee.diplome !== "" ? (
                        newEmployee.diplome
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Salaire : </label>
                      {newEmployee.salary}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">
                        Année de diplome :
                      </label>
                      {newEmployee.diplomeDate !== "" ? (
                        newEmployee.diplomeDate
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">
                        Type de contrat :
                      </label>
                      {newEmployee.typeOfContract}
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="fs-4">
          <span className="text-danger">{formerrors.email}</span>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-outline-secondary  me-2"
            onClick={() => {
              setChildComponent(
                <EmployeesFormStep4 setChildComponent={setChildComponent} />
              );
            }}>
            Retour
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              sendData();
            }}>
            Confirmer
          </button>
        </div>
      </section>
    </div>
  );
};

export default ConfirmEmp;
