import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import AffectMissionModal from "../../pages/employee/affectMissionModal";
import CreateNote from "../candidat/candidatNote";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";

const Employeeslist = ({
  getAllemployeUsers,
  employee,
  getAllEmployees,
  employeusers,
  missions,
}) => {
  const [affectMissionModal, setAffectMissionModal] = useState();
  const [addNote, setAddNote] = useState();
  function affectMissionToEmployee() {
    setAffectMissionModal(
      <AffectMissionModal
        SetAffectModalFromChild={setAffectMissionModal}
        employee={employee}
        missions={missions}
        getMissions={getAllemployeUsers}
      />
    );
  }

  const noDataFound = "Aucune donnée trouvée";
  const { user } = useContext(UserContext);

  const hasEmployeesEditPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "edit"
  );
  const hasEmployeesStatusPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "change_status"
  );
  const hasUsersCreatePermission = hasPermission(
    user,
    PERMISSIONS.users,
    "create"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionEmployeeAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "affect_employee_to_mission_process"
  );
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const hasEmployeesNoteConsult = hasPermission(
    user,
    PERMISSIONS.employees,
    "consulter_note"
  );
  const hasEmployeesNoteAddAndUpdate = hasPermission(
    user,
    PERMISSIONS.employees,
    "create_note"
  );

  async function enableDisable(employeeId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver ce employé?"
        : "Voulez vous Activer ce employé?";
    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${employeeId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllEmployees();
            }
          })
          .catch((error) => {
            DisplayError();
          });
      }
    });
  }
  async function employeetouser(employeeId) {
    swal({
      title: "voulez vous definire ce employé comme un utilisateur ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmed) => {
        if (confirmed) {
          getDetailsEmployee(employeeId);
        }
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function getDetailsEmployee(employeeId) {
    if (employeeId === undefined) return;
    axios
      .get(`${baseURL}/consultants/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        sendData(response.data.data, employeeId);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function sendData(employeetoadd, employeeId) {
    const userName = employeetoadd.firstname + " " + employeetoadd.lastname;
    axios
      .post(`${baseURL}/users/create`, {
        name: userName,
        avatar: employeetoadd.avatarid,
        email: employeetoadd.email,
        address: employeetoadd.address,
        phone: employeetoadd.phone,
        nirpp: employeetoadd?.nirpp?.toString() ?? "",
        employeeId: employeeId,
      })
      .then((resp) => {
        if (resp.data.error) return;
        getAllEmployees();
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.user) {
          swal(error?.response?.data?.user?.email, {
            title:
              "Un Utilisateur avec cet email deja existe voulez vous lier cet employé avec ce compte ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then((confirmed) => {
              if (confirmed) {
                axios
                  .post(`${baseURL}/users/LinkEmployeeUser`, {
                    userId: error?.response?.data?.user?._id,
                    employeeId: employeeId,
                  })
                  .then((resp) => {
                    getAllEmployees();
                    swal({
                      title: "Employé lié avec succès",
                      icon: "success",
                      timer: 2500,
                    });
                  })
                  .catch((error) => {
                    DisplayError();
                  });
              }
            })
            .catch((error) => {
              DisplayError();
            });
        }
      });
  }
  return (
    <div className="col-md-6 col-sm-12">
      <div className="info-box card employee-card-border">
        <div className=" row card-title d-flex justify-content-between px-4 pb-0 m-0">
          <div className="col-lg-6 fw-bold text-dark">
            <Link
              className="color-msit"
              to={`/employees/details/${employee._id}`}>
              <div>
                {employee.firstname.length > 10
                  ? employee.firstname.substring(0, 10) + "..."
                  : employee.firstname}{" "}
                {employee.lastname.length > 10
                  ? employee.lastname.substring(0, 10) + "..."
                  : employee.lastname}
              </div>
            </Link>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            {hasEmployeesEditPermission && (
              <Link
                to={"/employees/update/" + employee._id}
                className="btn text-primary"
                data-toggle="tooltip"
                data-placement="top"
                title="Modifier employée">
                <i className="bi bi-pen"></i>
              </Link>
            )}
            {hasEmployeesStatusPermission && (
              <>
                {employee.isEnabled === "yes" ? (
                  <button
                    type="button"
                    className="btn text-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Désactiver employée"
                    onClick={() => {
                      enableDisable(employee._id, employee.isEnabled);
                    }}>
                    <i className="bi bi-toggle2-on"></i>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn text-danger"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Activer employée"
                    onClick={() => {
                      enableDisable(employee._id, employee.isEnabled);
                    }}>
                    <i className="bi bi-toggle2-off"></i>
                  </button>
                )}
              </>
            )}
            <Link
              className="btn text-default "
              data-toggle="tooltip"
              data-placement="top"
              title="Profile employée"
              to={`/employees/details/${employee._id}`}>
              <i className="ri-eye-fill"></i>
            </Link>
            {hasUsersCreatePermission && (
              <>
                {employeusers.includes(employee._id) ? (
                  <button
                    className=" btn text-primary "
                    onClick={() => {
                      employeetouser(employee._id);
                    }}
                    disabled>
                    <i
                      className="bi bi-person-check-fill"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="l'employée est un utilisateur "></i>
                  </button>
                ) : (
                  <button
                    className=" btn text-primary "
                    onClick={() => {
                      employeetouser(employee._id);
                    }}>
                    <i
                      className="bi bi-person-plus-fill"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="ajouter l'employée comme un utilisateur "></i>
                  </button>
                )}
              </>
            )}
            {hasMissionEmployeeAffectPermission &&
              hasMissionsConsultPermission &&
              hasCvConsultPermission && (
                <button
                  type="button"
                  className="btn text-success"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Affecter employée à une mission "
                  onClick={() => {
                    affectMissionToEmployee();
                  }}>
                  <i className="ri-file-text-fill"></i>
                </button>
              )}
            <div className="col-3">
              {hasEmployeesNoteConsult && hasEmployeesNoteAddAndUpdate && (
                <button
                  className="btn text-warning"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Créer note"
                  onClick={() => {
                    setAddNote(
                      <CreateNote
                        setAddNoteModalFromChild={setAddNote}
                        id={employee._id}
                        destination={"employeeNote"}
                      />
                    );
                  }}>
                  <i className="ri-sticky-note-line"></i>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-4 text-center">
              <img
                className="avatar avatar-xxl avatar-border"
                src={baseURL + employee.avatar?.path.substring(1)}
                width="100%"
                alt=""
              />
            </div>
            <div className="col-8">
              <div className="mb-1">
                <a
                  href={`mailto:${employee.email}`}
                  className="color-msit "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Envoyer mail">
                  <i className="bi bi-envelope-fill"></i> {employee.email}
                </a>
              </div>
              <div className="mb-1">
                <a
                  href={`tel:+${employee.phone}`}
                  className="color-msit "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Appeler">
                  <i className="bi bi-telephone-fill"></i>
                  {employee?.phone ? employee.phone : null}
                </a>
                {!employee?.phone && (
                  <span className="color-black">{noDataFound}</span>
                )}
              </div>
              <div className="mb-1">
                <i className="bi bi-geo-alt  me-1"></i>

                <span>{employee.address ? employee.address : noDataFound}</span>
              </div>
              <div className="mb-1">
                <i className="bi bi-briefcase me-1"></i>
                <span>
                  {employee.job} ({employee.experience}ans)
                </span>
              </div>
              <div>
                {employee.isEnabled === "yes" ? (
                  <span className="badge bg-success">Actif</span>
                ) : (
                  <span className="badge bg-danger">Inactif</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addNote}
      {affectMissionModal}
    </div>
  );
};
export default Employeeslist;
