import React, { useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DisplayError from "../ErrorComponants/DisplayError";

function EditManager({
  getManagerByCompanyId,
  selectedForUpdate,
}) {
  const [lastName, setLastName] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [email, setEmails] = React.useState();
  const [designation, setDesignation] = React.useState();
  const [company, setCompany] = React.useState();
  const initFormErrors = {
    lname: [""],
    fname: [""],
    email: [""],
    phone: [""],
    designation: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  function getManager() {
    const managerId = selectedForUpdate;
    if (managerId === undefined) return;
    axios
      .get(`${baseURL}/managers/details/${managerId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const data = resp.data.data;
        setFirstName(data.fname);
        setLastName(data.lname);
        setPhone(data.phone);
        setEmails(data.email);
        setDesignation(data.designation);
        setCompany(data.company);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (selectedForUpdate !== null) {
      getManager();
    }
  }, [selectedForUpdate]);
  function sendData() {
    const managerId = selectedForUpdate;
    axios
      .put(`${baseURL}/managers/${managerId}`, {
        fname: firstName,
        lname: lastName,
        phone: phone,
        email: email,
        designation: designation,
        company_id: company,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const boutonOffcanvas = document.getElementById(
          "cancelButtonUpdateManager"
        );
        boutonOffcanvas.click();
        getManager();
        getManagerByCompanyId();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  function resetFormErrors() {
    setFormErrors(initFormErrors);
  }
  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="updateManager"
      aria-labelledby="updateManager"
    >
      <div className="offcanvas-header ">
        <h4 id="updateManager fw-bold">Modifier contact</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetFormErrors}
        ></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div>
          <div className="mt-4">
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Nom: *</label>
              <input
                type="text"
                className="form-control"
                id="lname"
                placeholder="Nom"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                required
              />
              <div className="input-error">
                <span className="text-danger">{formerrors.lname}</span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Prénom: *</label>
              <input
                type="text"
                className="form-control"
                id="lname"
                placeholder="Prénom"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                required
              />
              <div className="input-error">
                <span className="text-danger">{formerrors.fname}</span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Téléphone: *</label>
              <div className="input-group px-0">
                <PhoneInput
                  inputClass="phoneInput"
                  className="phoneInput"
                  placeholder="Téléphone"
                  value={phone}
                  onChange={(value, country, event) =>
                    setPhone(event.target.value ? event.target.value : value)
                  }
                />
              </div>
              <div className="input-error">
                <span className="text-danger">{formerrors.phone}</span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Email: *</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                onChange={(e) => setEmails(e.target.value)}
                value={email}
                required
              />
              <div className="input-error">
                <span className="text-danger">{formerrors.email}</span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Désignation: </label>
              <input
                type="text"
                className="form-control"
                id="designation"
                placeholder="Désignation"
                onChange={(e) => setDesignation(e.target.value)}
                value={designation}
                required
              />
              <div className="input-error">
                <span className="text-danger">{formerrors.designation}</span>
              </div>
            </div>
            <div className="row px-2 pt-2 pb-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
              >
                Modifier
              </button>
            </div>
            <div className="row px-2 pt-1 pb-2">
              <button
                id="cancelButtonUpdateManager"
                type="button"
                className="btn but-restore"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={resetFormErrors}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditManager;
