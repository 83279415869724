import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BiTrash } from "react-icons/bi";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DisplayError from "../../components/ErrorComponants/DisplayError";
const initFormErrors = {
  invoice_num: [""],
  invoice_date: [""],
  due_date: [""],
  total_HT: [""],
  invoice_total: [""],
  tax_total: [""],
  category_fact: [""],
  client_name: [""],
  client_email: [""],
  client_adress: [""],
  client_matFisc: [""],
  seller_name: [""],
  seller_email: [""],
  seller_adress: [""],
  seller_matFisc: [""],
  type: [""],
  PDF: [""],
};

const initFormErrorsProd = {
  product_name: [""],
  product_quantity: [""],
  product_unit_price: [""],
  product_total: [""],
};
const CreateInvoice = () => {
  const { employeeId } = useParams();
  const [data, setdata] = useState([]);
  const [errorMessages, setErrorMessages] = useState(initFormErrors);
  const [errorMessage, setErrorMessage] = useState(initFormErrorsProd);
  const [invoice_num, setInvoice_num] = useState("");
  const [invoice_date, setInvoice_date] = useState("");
  const [due_date, setDue_date] = useState("");
  const [invoice_total, setInvoice_total] = useState("");
  const [tax_total, setTax_total] = useState("");
  const [tax_percent, setTax_percent] = useState(0);
  const [category_fact, setCategory_fact] = useState("");
  const [note, setNote] = useState("");
  const [total_HT, setTotal_HT] = useState("");
  const [type, setType] = useState("");
  const [client_name, setClient_name] = useState("");
  const [client_email, setClient_email] = useState("");
  const [client_adress, setClient_adress] = useState("");
  const [client_matFisc, setClient_matFisc] = useState("");
  const [seller_name, setSeller_name] = useState("");
  const [seller_email, setSeller_email] = useState("");
  const [seller_adress, setSeller_adress] = useState("");
  const [seller_matFisc, setSeller_matFisc] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [product_quantity, setProduct_quantity] = useState(0);
  const [product_unit_price, setProduct_unit_price] = useState(0);
  const [product_total, setProduct_total] = useState(0);
  const [PDF, setPDF] = useState("");
  const [products, setProducts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setInvoice_total(tax_total + total_HT);
  }, [tax_total, total_HT]);

  useEffect(() => {
    setProduct_total(product_quantity * product_unit_price);
  }, [product_quantity, product_unit_price]);

  useEffect(() => {
    setTax_total((total_HT * tax_percent) / 100);
  }, [total_HT, tax_percent]);

  useEffect(() => {
    let total = 0;
    products.map((product) => {
      total += product.product_total;
      return null;
    });

    setTotal_HT(total);
  }, [products]);

  function addItem() {
    const msg = "Ce champs est obligatoire ";
    let error = false;
    setErrorMessage(initFormErrorsProd);

    if (!product_name || product_name === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        product_name: msg,
      }));
    }
    if (
      !product_quantity ||
      product_quantity === "" ||
      product_quantity === 0
    ) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        product_quantity: msg,
      }));
    }
    if (
      !product_unit_price ||
      product_unit_price === "" ||
      product_unit_price === 0
    ) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        product_unit_price: msg,
      }));
    }
    if (!product_total || product_total === "" || product_total <= 0) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        product_total: msg,
      }));
    }

    if (!error) {
      let newproduct = {
        product_name: product_name,
        product_quantity: product_quantity,
        product_unit_price: product_unit_price,
        product_total: product_total,
      };
      setProducts([...products, newproduct]);
      setProduct_name("");
      setProduct_quantity("");
      setProduct_unit_price("");
      setProduct_total("");
    }
  }

  function deleteItem(index) {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    setProducts(newProducts);
  }
  function Create() {
    const msg = "Ce champs est obligatoire ";
    let error = false;
    setErrorMessages(initFormErrors);

    if (!invoice_num || invoice_num === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        invoice_num: msg,
      }));
    }
    if (!invoice_date || invoice_date === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        invoice_date: msg,
      }));
    }
    if (!due_date || due_date === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        due_date: msg,
      }));
    }
    if (!type || type === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        type: msg,
      }));
    }
    if (!client_name || client_name === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        client_name: msg,
      }));
    }
    if (!client_email || client_email === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        client_email: msg,
      }));
    }
    if (!client_adress || client_adress === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        client_adress: msg,
      }));
    }
    if (!client_matFisc || client_matFisc === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        client_matFisc: msg,
      }));
    }
    if (!seller_name || seller_name === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        seller_name: msg,
      }));
    }
    if (!seller_email || seller_email === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        seller_email: msg,
      }));
    }
    if (!seller_adress || seller_adress === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        seller_adress: msg,
      }));
    }
    if (!seller_matFisc || seller_matFisc === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        seller_matFisc: msg,
      }));
    }
    if (!category_fact || category_fact === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        category_fact: msg,
      }));
    }
    if (!PDF || PDF === "") {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        PDF: msg,
      }));
    }
    if (!total_HT || total_HT === "" || total_HT === 0) {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        total_HT: msg,
      }));
    }
    if (!invoice_total || invoice_total === "" || invoice_total === 0) {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        invoice_total: msg,
      }));
    }
    if (!tax_total || tax_total === "" || tax_total === 0) {
      error = true;
      setErrorMessages((prevState) => ({
        ...prevState,
        tax_total: msg,
      }));
    }

    if (!error) {
      const formData = new FormData();
      formData.append("invoice_num", invoice_num);
      formData.append("invoice_date", invoice_date);
      formData.append("due_date", due_date);
      formData.append("type", type);
      formData.append("invoice_total", invoice_total);
      formData.append("tax_total", tax_total);
      formData.append("total_HT", total_HT);
      formData.append("note", note);
      formData.append("client_name", client_name);
      formData.append("client_email", client_email);
      formData.append("client_adress", client_adress);
      formData.append("seller_name", seller_name);
      formData.append("seller_email", seller_email);
      formData.append("seller_adress", seller_adress);
      formData.append("products", JSON.stringify(products));
      formData.append("category_fact", category_fact);
      // formData.append("tax_percent", tax_percent);
      formData.append("invoice", PDF);
      axios
        .post(`${baseURL}/invoices/createInvoice`, formData)
        .then((resp) => {
          if (resp.data.error) return;
          swal(resp.data.message, {
            icon: "success",
            timer: 2500,
          });
          setTimeout(() => {
            history.push("/allInvoices");
          }, 2400);
          setInvoice_num("");
          setInvoice_date("");
          setDue_date("");
          setInvoice_total("");
          setTax_total("");
          setType("");
          tax_percent("");
          setNote("");
          setTotal_HT("");
          setClient_name("");
          setClient_email("");
          setClient_adress("");
          setSeller_name("");
          setSeller_email("");
          setSeller_adress("");
          setSeller_matFisc("");
          setClient_matFisc("");
        })

        .catch((error) => {
          DisplayError();
        });
    }
  }

  const handlePDFChange = (event) => {
    setPDF(event.target.files[0]);
  };

  function getCategoryData() {
    axios
      .get(`${baseURL}/category/getAllCategory`)
      .then((res) => {
        setdata(res.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  async function getDetailsEmployee() {
    if (employeeId === undefined) return;
    axios
      .get(`${baseURL}/employees/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        setClient_name(response.data.data.fname + response.data.data.lname);
        setClient_email(response.data.data.email);
        setClient_adress(response.data.data.address);
        setClient_matFisc(response.data.data.nirpp);
        setSeller_matFisc("msit123");
        setSeller_name("MSIT");
        setSeller_email("Contact@msit-digital.com");
        setSeller_adress("kwest 2.1 B-A");
        getEmployeeWorkDays();
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function getEmployeeWorkDays() {
    if (employeeId === undefined) return;
    let hours = 0;
    await axios
      .get(`${baseURL}/workdays/days/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        for (const iterator in response.data.data) {
          const workday = response.data.data[iterator];
          let now = new Date();

          const year = Number(
            workday.date[0] +
              workday.date[1] +
              workday.date[2] +
              workday.date[3]
          );
          const month = Number(workday.date[5] + workday.date[6]);

          if (
            year === now.getFullYear() &&
            month === now.getMonth() + 1 &&
            workday.extendedProps.type === "travaile"
          ) {
            hours += workday.extendedProps.hour;
          }
        }
        let newproduct = {
          product_name: "heurs travaille",
          product_quantity: hours,
          product_unit_price: 6,
          product_total: 6 * hours,
        };
        setProducts([...products, newproduct]);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDue_date(today);
    setInvoice_date(today);
    getCategoryData();
    getDetailsEmployee();
  }, []);

  return (
    <>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Creer facture</h1>
          </div>
          <div className="col-md-4">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2">
                Retour
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Creer Facture</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-9 row">
          <div className="col md-8 lg-9">
            <div className=" card p-4 p-xl-5 my-3 my-xl-4">
              <div className=" card-header d-flex justify-content-center textcolorlikenav">
                <h1 className=" fw-bold pt-0 color-msit">FACTURE</h1>
              </div>
              <div className="card-body">
                <div className="d-flex flex-row align-items-start justify-content-between mb-3">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="mb-2">
                        <span className="fw-bold ">
                          Date De la facture:
                          <input
                            className="form-control"
                            value={invoice_date}
                            onChange={(e) => setInvoice_date(e.target.value)}
                            type="date"
                            name={"dateOfIssue"}
                            style={{
                              maxWidth: "150px",
                            }}
                            required="required"
                          />{" "}
                        </span>
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessages.invoice_date}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className=" flex-row align-items-center">
                      <span className="fw-bold d-block me-2">
                        Date D'échéance:
                      </span>
                      <input
                        className="form-control"
                        value={due_date}
                        onChange={(e) => setDue_date(e.target.value)}
                        type="date"
                        name={"dateOfIssue"}
                        style={{
                          maxWidth: "150px",
                        }}
                        required="required"
                      />

                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.due_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-row align-items-center">
                      <span className="fw-bold me-2">Numéro de facture:</span>
                      <input
                        placeholder={"Numéro"}
                        onChange={(e) => setInvoice_num(e.target.value)}
                        type="text"
                        value={invoice_num}
                        name={"invoiceNumber"}
                        min="1"
                        className=" qte  form-control"
                        style={{
                          maxWidth: "70px",
                        }}
                        required="required"
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessages.invoice_num}
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="mb-5 row">
                  <div className="col">
                    <label className=" Form.label fw-bold">Facture à:</label>
                    <div>
                      <input
                        placeholder={"À qui s'adresse cette facture?"}
                        rows={3}
                        type="text"
                        name="billTo"
                        className="my-2 form-control "
                        autoComplete="name"
                        value={client_name}
                        required="required"
                        onChange={(e) => setClient_name(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.client_name}
                        </span>
                      </div>
                    </div>{" "}
                    <div>
                      <input
                        placeholder={"adresse E-mail"}
                        type="email"
                        name="billToEmail"
                        className="my-2 form-control"
                        autoComplete="email"
                        required="required"
                        value={client_email}
                        onChange={(e) => setClient_email(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.client_email}
                        </span>
                      </div>
                    </div>{" "}
                    <div>
                      <input
                        placeholder={"Adresse de facturation"}
                        type="text"
                        name="billToAddress"
                        className="my-2 form-control"
                        autoComplete="address"
                        value={client_adress}
                        required="required"
                        onChange={(e) => setClient_adress(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.client_adress}
                        </span>
                      </div>
                    </div>
                    <div>
                      <input
                        placeholder={"Matricule fiscale"}
                        type="text"
                        name="billFromEmail"
                        className="my-2 form-control"
                        autoComplete="text"
                        value={client_matFisc}
                        required="required"
                        onChange={(e) => setClient_matFisc(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.client_matFisc}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <label className="fw-bold">Facture de:</label>
                    <div>
                      <input
                        placeholder={"De qui provient cette facture?"}
                        rows={3}
                        type="text"
                        name="billFrom"
                        className="my-2 form-control"
                        autoComplete="name"
                        value={seller_name}
                        required="required"
                        onChange={(e) => setSeller_name(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.seller_name}
                        </span>
                      </div>
                    </div>
                    <div>
                      <input
                        placeholder={"adresse E-mail"}
                        type="email"
                        name="billFromEmail"
                        className="my-2 form-control"
                        autoComplete="email"
                        value={seller_email}
                        required="required"
                        onChange={(e) => setSeller_email(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.seller_email}
                        </span>
                      </div>
                    </div>
                    <div>
                      <input
                        placeholder={"Adresse de facturation"}
                        type="text"
                        name="billFromAddress"
                        className="my-2 form-control"
                        autoComplete="address"
                        value={seller_adress}
                        required="required"
                        onChange={(e) => setSeller_adress(e.target.value)}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.seller_adress}
                        </span>
                      </div>
                    </div>
                    <div>
                      <input
                        placeholder={"Matricule fiscale"}
                        type="text"
                        name="billFromAddress"
                        className="my-2 form-control"
                        autoComplete="text"
                        value={seller_matFisc}
                        required="required"
                        onChange={(e) => setSeller_matFisc(e.target.value)}
                      />{" "}
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.seller_matFisc}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-5 row">
                  <hr className="my-4" />
                  <table>
                    <thead>
                      <tr>
                        <th className="champ th">ARTICLE</th>
                        <th className="champ th">QUANTITE</th>
                        <th className="champ th">PRIX UNITAIRE</th>
                        <th className="champ  th">TOTAL</th>
                        <th className="champ">ACTION</th>
                      </tr>
                    </thead>

                    <hr className="d-flex justify-content-center w-75" />
                    <tbody>
                      <tr>
                        <td key={"Product_name"} classname="p-2">
                          <input
                            type="text"
                            placeholder="Description"
                            className=" form-control"
                            value={product_name}
                            onChange={(e) => {
                              setProduct_name(e.target.value);
                              setErrorMessage(initFormErrorsProd);
                            }}
                            required
                          />
                        </td>
                        <td key={"Product_quantity"} classname="p-2">
                          {" "}
                          <input
                            type="number"
                            placeholder="Quantité"
                            className="  form-control"
                            min="0"
                            value={product_quantity}
                            onChange={(e) => {
                              setProduct_quantity(e.target.value);
                              setErrorMessage(initFormErrorsProd);
                            }}
                            required
                          />
                        </td>
                        <td key={"Product_price"} classname="p-2">
                          {" "}
                          <input
                            type="number"
                            placeholder="Prix Unitaire"
                            className=" form-control "
                            min="0"
                            value={product_unit_price}
                            onChange={(e) => {
                              setProduct_unit_price(e.target.value);
                              setErrorMessage(initFormErrorsProd);
                            }}
                            required
                          />
                        </td>
                        <td key={"Product_total"} classname="p-2">
                          {" "}
                          <input
                            className=" form-control"
                            type="number"
                            placeholder="Total"
                            min="0"
                            disabled
                            value={product_total}
                            onChange={(e) => setProduct_total(e.target.value)}
                            required
                          />
                        </td>
                        <td classname="p-2 d-flex justify-content-center">
                          <button
                            type="button"
                            className="fw-bold btn btn-primary"
                            onClick={() => {
                              addItem();
                            }}>
                            +
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage.product_name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage.product_quantity}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage.product_unit_price}
                            </span>
                          </div>
                        </td>
                      </tr>
                      <hr className="my-4" />
                      {products.map((newproduct, index) => {
                        return (
                          <tr key={index} index={index}>
                            <td key={index + "name"}>
                              <input
                                type="text"
                                placeholder="Description"
                                className="text form-control"
                                required
                                defaultValue={newproduct.product_name}
                                onChange={(e) => {
                                  products[index].product_name = e.target.value;
                                  setProducts([...products]);
                                }}
                              />
                            </td>
                            <td key={index + "Quantité"}>
                              <input
                                type="number "
                                placeholder="Quantité"
                                className=" qte form-control "
                                onChange={(e) => {
                                  products[index].product_quantity =
                                    e.target.value;
                                  products[index].product_total =
                                    e.target.value *
                                    products[index].product_unit_price;
                                  setProducts([...products]);
                                }}
                                required
                                defaultValue={newproduct.product_quantity}
                              />
                            </td>
                            <td key={index + "Prix"}>
                              {" "}
                              <input
                                type="number"
                                placeholder="Prix Unitaire"
                                className="text form-control"
                                min="0"
                                onChange={(e) => {
                                  products[index].product_unit_price =
                                    e.target.value;
                                  products[index].product_total =
                                    e.target.value *
                                    products[index].product_quantity;
                                  setProducts([...products]);
                                }}
                                defaultValue={newproduct.product_unit_price}
                                required
                              />
                            </td>
                            <td key={index + "Total"}>
                              <input
                                className="text form-control"
                                type="number"
                                placeholder="Total"
                                value={newproduct.product_total}
                                min="0"
                                disabled
                                required
                              />
                            </td>
                            <BiTrash
                              style={{
                                height: "33px",
                                width: "33px",
                                padding: "7px",
                              }}
                              onClick={deleteItem}
                              className="text-white mt-2  btn btn-danger icon"
                            />
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <hr className="" />
                </div>
                <div className=" row mt-4 justify-content-end">
                  <div className="col-lg-8">
                    {" "}
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Total HT :</span>
                      <input
                        className=" text form-control w-25 "
                        type="text"
                        placeholder="Total HT"
                        value={total_HT}
                        onChange={(e) => setTotal_HT(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      {" "}
                      <span className="fw-bold">TVA :</span>
                      <span className="  fw-bold   w-25 d-flex ">
                        <input
                          type="number"
                          className=" form-control input "
                          name="tva"
                          value={tax_percent}
                          onChange={(e) => setTax_percent(e.target.value)}
                          placeholder="0.0"
                          min="0.00"
                          step="0.01"
                          max="100.00"
                        />{" "}
                      </span>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessages.tax_total}
                        </span>
                      </div>
                      <div className="">
                        <input
                          className=" form-control  "
                          placeholder="%"
                          style={{ width: "40px" }}
                          disabled></input>
                      </div>
                      <span className="form-control w-25  "> {tax_total}</span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold"></span>
                      <span>
                        <span className="small "></span>
                      </span>
                    </div>
                    <hr />
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}>
                      <span className="fw-bold">Total TTC:</span>
                      <input
                        className=" text form-control  w-25"
                        type="text"
                        placeholder="Total"
                        value={invoice_total}
                        onChange={(e) => setInvoice_total(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <hr className="my-4" />

                <label className="fw-bold">Notes:</label>
                <textarea
                  placeholder="Thanks for your business!"
                  name="notes"
                  as="textarea"
                  className="my-2 form-control"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  rows={1}
                />

                <hr className="my-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-3  mt-4 ">
          <div className="card pt-4">
            <div className="card-header d-flex justify-content-center textcolorlikenav">
              <h2 className="color-msit fw-bold ">Actions</h2>
            </div>
            <div className="card-body">
              <div className="d-flex flex-column justify-content-center">
                <div className="mt-3">
                  <label className="fw-bold"> selectionner la catégorie</label>
                  <select
                    className="form-select mt-3"
                    onChange={(e) => setCategory_fact(e.target.value)}>
                    <option selected disabled>
                      selectionnez
                    </option>

                    {data &&
                      data.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.Name}
                        </option>
                      ))}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessages.category_fact}
                    </span>
                  </div>
                </div>
                <div className="mt-3">
                  <label className="fw-bold"> selectionner le type</label>
                  <select
                    className="form-select mt-3"
                    onChange={(e) => setType(e.target.value)}>
                    <option selected> ... </option>
                    <option> Achat </option>
                    <option> Vente </option>
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{errorMessages.type}</span>
                  </div>
                </div>
                <div className="mt-3">
                  <label className="fw-bold">
                    Ajouter le justificatif du facture :
                  </label>
                  <form onSubmit={Create}>
                    <input
                      type="file"
                      className=" my-2 form-control"
                      accept=".pdf, .docx"
                      onChange={handlePDFChange}
                    />{" "}
                    <div className="input-error">
                      <span className="text-danger">{errorMessages.PDF}</span>
                    </div>
                  </form>
                </div>
                <button
                  className="mt-5 w-40 btn btn-primary fw-bold  "
                  onClick={Create}>
                  Enregistrer
                </button>
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateInvoice;
