import React, { useContext, useState, useCallback, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CandidatContext } from "../../../context/candidatContext";
import { useDropzone } from "react-dropzone";
import { baseURL } from "../../../config/config";
const initFormErrors = {
  lastname: [""],
  firstname: [""],
  email: [""],
  phone: [""],
  birthday: [""],
  lieu_naissance: [""],
  avatar: [""],
};
const PageOneUpdate = ({ nextpage }) => {
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const msg = "Ce champ est obligatoire";
  const msg1 = "Saisissez une valeur valide ";
  const regex =
    /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
  const [avatarUploaded, setAvatarUploaded] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    setNewCandidat((prevState) => ({
      ...prevState,
      avatar: acceptedFiles[0],
    }));
    const selectedFile = acceptedFiles[0];
    if (selectedFile) {
      const file = new FileReader();
      file.onload = function () {
        setAvatarUploaded(file.result);
      };
      file.readAsDataURL(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/png": [".png", ".jpg", ".jpeg", ".gif"] },
  });
  const handleImageChange = (e) => {
    setNewCandidat((prevState) => ({
      ...prevState,
      avatar: e.target.files[0],
    }));
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setAvatarUploaded(event.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  useEffect(() => {
    if (newCandidat !== null) {
      setAvatarUploaded(`${baseURL + newCandidat?.avatar?.path?.substring(1)}`);
    }
  }, [newCandidat]);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);

    if (newCandidat.type !== "supplier") {
      if (newCandidat?.type === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          type: msg,
        }));
      }
      if (newCandidat?.lastname === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          lastname: msg,
        }));
      } else {
        if (newCandidat?.lastname.length < 3) {
          error = true;
          setErrorMessage((prevState) => ({
            ...prevState,
            lastname: msg1,
          }));
        }
      }
      if (newCandidat?.firstname === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          firstname: msg,
        }));
      } else {
        if (newCandidat?.firstname.length < 3) {
          error = true;
          setErrorMessage((prevState) => ({
            ...prevState,
            firstname: msg1,
          }));
        }
      }
      if (
        newCandidat.type !== "candidat" &&
        newCandidat.type !== "draft" &&
        newCandidat.type !== ""
      ) {
        if (newCandidat?.email === "") error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          email: msg,
        }));
      }
      if (newCandidat?.email !== "") {
        if (regex.test(newCandidat?.email) === false) {
          error = true;
          setErrorMessage((prevState) => ({
            ...prevState,
            email: msg1,
          }));
        }
      }
    }

    if (error === false) {
      nextpage("pagetwo");
    }
  }
  return (
    <div className="mt-2">
      <div className="row d-flex justify-content-center">
        <img src={`../assets/img/progressBar1.png`} />
        <div className="col-6 mt-4">
          <div className="bg-white">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="mb-2">
                {avatarUploaded ? (
                  <img
                    src={avatarUploaded}
                    alt="Uploaded"
                    className="imgUploaded "
                  />
                ) : (
                  <img
                    src="../../assets/img/input-pictures.png"
                    alt="Placeholder"
                    className=" uploadInput"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row px-2">
        <label className="px-0 text-500">Types : *</label>
        <div className="col-12 py-2 px-0 ">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptionsType"
              id="inlineRadioCandidat"
              value="candidat"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  type: e.target.value,
                }));
              }}
              checked={newCandidat.type === "candidat"}
            />
            <label className=" text-500" htmlFor="inlineRadioCandidat">
              Candidat
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptionsType"
              id="inlineRadioEmployee"
              value="employee"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  type: e.target.value,
                }));
              }}
              checked={newCandidat.type === "employee"}
            />
            <label className=" text-500" htmlFor="inlineRadioEmployee">
              Salarié
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptionsType"
              id="inlineRadioSupplier"
              value="supplier"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  type: e.target.value,
                }));
              }}
              checked={newCandidat.type === "supplier"}
            />
            <label s className=" text-500" htmlFor="inlineRadioSupplier">
              Partenaire
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptionsType"
              id="inlineRadioDraft"
              value="draft"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  type: e.target.value,
                }));
              }}
              checked={newCandidat.type === "draft"}
            />
            <label className=" text-500" htmlor="inlineRadioDraft">
              Brouillant
            </label>
          </div>
          <div className="input-error">
            <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
              {errorMessage?.type}
            </span>
          </div>
        </div>
      </div>
      <div className="row px-2">
        <label className="px-0 text-500">Sexe : *</label>
        <div className="col-12 py-2 px-0">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  gender: e.target.value,
                }));
              }}
              value="male"
              checked={newCandidat.gender === "male"}
            />
            <label className="text-500" htmlFor="inlineRadio1">
              Homme
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="femele"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  gender: e.target.value,
                }));
              }}
              checked={newCandidat.gender === "femele"}
            />
            <label className="text-500" htmlFor="inlineRadio2">
              Femme
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input offcanva-checkbox"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio3"
              value="other"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  gender: e.target.value,
                }));
              }}
              checked={newCandidat.gender === "other"}
            />
            <label className="text-500" htmlFor="inlineRadio3">
              Autre
            </label>
          </div>
          <div className="input-error">
            <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
              {errorMessage?.gender}
            </span>
          </div>
        </div>
      </div>
      <div className="row px-2">
        <label className="px-0 mb-2 text-500">Nom *</label>
        <input
          type="text"
          className="form-control offcanvas-input"
          placeholder="Nom"
          value={newCandidat?.lastname}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              lastname: e.target.value,
            }));
          }}
          required
          disabled={newCandidat?.isLocked === "yes"}
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.lastname}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Prénom *</label>
        <input
          type="text"
          className="form-control offcanvas-input"
          placeholder="Prénom"
          value={newCandidat?.firstname}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              firstname: e.target.value,
            }));
          }}
          required
          disabled={newCandidat?.isLocked === "yes"}
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.firstname}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Email</label>
        <input
          type="email"
          className="form-control offcanvas-input"
          placeholder="User@gmail.com"
          value={newCandidat?.email}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
          required
        />
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.email}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Téléphone</label>
        <div className="input-group px-0">
          <PhoneInput
            inputClass="phoneInput"
            className="offcanvas-input"
            value={newCandidat?.phone}
            onChange={(value, country, event) =>
              setNewCandidat((prevState) => ({
                ...prevState,
                phone: event.target.value ? event.target.value : value,
              }))
            }
          />
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.phone}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <div className="date-picker px-0">
          <label className="px-0 mb-2 text-500">Date de naissance</label>
          <div className="input-group mb-2 offcanvas-input">
            <input
              value={newCandidat?.birthday}
              className="form-control"
              onChange={(e) => {
                setNewCandidat((prevState) => ({
                  ...prevState,
                  birthday: e.target.value,
                }));
              }}
              type="date"
              id="datePicker"
              required
            />
          </div>
        </div>
        <div className="input-error">
          <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
            {errorMessage?.birthday}
          </span>
        </div>
      </div>
      <div className="row px-2 py-2">
        <label className="px-0 mb-2 text-500">Lieu de naissance</label>
        <input
          type="text"
          className="form-control offcanvas-input"
          placeholder="France"
          value={newCandidat?.birthPlace}
          onChange={(e) => {
            setNewCandidat((prevState) => ({
              ...prevState,
              birthPlace: e.target.value,
            }));
          }}
          required
        />
        <div className="input-error">
          <span className="text-danger"> {errorMessage?.birthPlace}</span>
        </div>
      </div>
      <div className="row px-2 pt-2 pb-1">
        <button type="button" className="btn btn-add" onClick={validate}>
          Continuer
        </button>
      </div>
      <div className="row px-2 pt-1 pb-2">
        <button
          type="button"
          className="btn but-restore"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasUpdateConsultant"
          aria-controls="offcanvasUpdateConsultant">
          Annuler
        </button>
      </div>
    </div>
  );
};

export default PageOneUpdate;
