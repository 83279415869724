import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import { Editor } from "@tinymce/tinymce-react";
function UpdateExperience({
  setAddExperienceModalFromChild,
  getExperienceByCandidatId,
  experienceId,
  candidatId,
}) {
  const [company, setCompany] = useState();
  const [post_title, setPost_title] = useState();
  const [sector, setSector] = useState();
  const [description, setDescription] = useState();
  const [contract_type, setContract_type] = useState();
  const [address, setAdresse] = useState();
  const [start_date, setStart_date] = useState();
  const [end_date, setEnd_date] = useState();
  const [duration, setduration] = useState();
  const [programminglanguage, setprogramminglanguage] = useState();
  const [actual, setActual] = useState(false);
  const editorRef = useRef(null);

  const initFormErrors = {
    companyname: [""],
    titlePoste: [""],
    secteur: [""],
    description: [""],
    typeContrat: [""],
    adress: [""],
    date_deb: [""],
    date_fin: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);
  useEffect(() => {
    axios.get(`${baseURL}/experience/${experienceId}`).then((resp) => {
      if (resp.data.error) return;
      const data = resp.data.data;
      setCompany(data.company);
      setPost_title(data.post_title);
      setSector(data.sector);
      setDescription(data.description);
      setContract_type(data.contract_type);
      setAdresse(data.address);
      setStart_date(data.start_date);
      setEnd_date(data.end_date);
      setprogramminglanguage(data.programminglanguage);
      setduration(data.duration);
      setActual(data.actual ?? false);
    });
  }, []);
  function experienceActual() {
    setActual(!actual);
  }
  function sendData() {
    axios
      .put(`${baseURL}/experience/${experienceId}`, {
        contract_type: contract_type,
        post_title: post_title,
        company: company,
        sector: sector,
        address: address,
        description: editorRef.current.getContent(),
        start_date: start_date,
        end_date: end_date,
        candidat: candidatId,
        programminglanguage: programminglanguage,
        duration: duration,
        actual: actual,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getExperienceByCandidatId();
        setAddExperienceModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 mt-2">
                  {" "}
                  <label> titre de poste *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="titre de post"
                    value={post_title != null && post_title}
                    onChange={(e) => setPost_title(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.post_title}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Secteur *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="secteur"
                    value={sector != null && sector}
                    onChange={(e) => setSector(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.sector} </span>
                  </div>
                </div>
              </div>

              <div className="row">
                {" "}
                <div className="col-md-12 mt-2">
                  <label> Nom de société *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    value={company != null && company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.company}</span>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <label> Déscription *</label>
                  <Editor
                    className="form-control"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={description != null && description}
                    init={{
                      height: 200,
                      menubar: true,
                      plugins: [
                        "advcode",
                        "advlist",
                        "advtable",
                        "anchor",
                        "autocorrect",
                        "autolink",
                        "autosave",
                        "casechange",
                        "charmap",
                        "checklist",
                        "codesample",
                        "directionality",
                        "editimage",
                        "emoticons",
                        "export",
                        "footnotes",
                        "formatpainter",
                        "help",
                        "image",
                        "insertdatetime",
                        "link",
                        "linkchecker",
                        "lists",
                        "media",
                        "mediaembed",
                        "mergetags",
                        "nonbreaking",
                        "pagebreak",
                        "permanentpen",
                        "powerpaste",
                        "searchreplace",
                        "table",
                        "tableofcontents",
                        "tinymcespellchecker",
                        "typography",
                        "visualblocks",
                        "visualchars",
                        "wordcount",
                      ],
                      menu: {
                        file: {
                          title: "File",
                          items:
                            "newdocument restoredraft | preview | export print | deleteallconversations",
                        },
                        edit: {
                          title: "Edit",
                          items:
                            "undo redo | cut copy paste pastetext | selectall | searchreplace",
                        },
                        view: {
                          title: "View",
                          items:
                            "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments",
                        },
                        insert: {
                          title: "Insert",
                          items:
                            "image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
                        },
                        format: {
                          title: "Format",
                          items:
                            "bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat",
                        },
                        tools: {
                          title: "Tools",
                          items:
                            "spellchecker spellcheckerlanguage | a11ycheck code wordcount",
                        },
                        table: {
                          title: "Table",
                          items:
                            "inserttable | cell row column | advtablesort | tableprops deletetable",
                        },
                        help: { title: "Help", items: "help" },
                      },
                      toolbar:
                        "undo redo spellcheckdialog  |" +
                        " blocks fontselect fontsizeselect  |" +
                        " bold italic underline forecolor backcolor | " +
                        "link image |" +
                        " align | " +
                        " lineheight checklist bullist numlist |" +
                        " indent outdent |" +
                        " removeformat typography | help",

                      content_style:
                        "body { font-family:Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n; font-size:14px }",
                      fontsize_formats:
                        "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                      font_formats: [
                        "Andale Mono=andale mono,times",
                        " Arial=arial,helvetica,sans-serif",
                        " Arial Black=arial black,avant garde",
                        " Book Antiqua=book antiqua,palatino",
                        " Comic Sans MS=comic sans ms,sans-serif",
                        " Courier New=courier new,courier",
                        " Georgia=georgia,palatino",
                        " Helvetica=helvetica",
                        " Impact=impact,chicago",
                        " Symbol=symbol",
                        " Tahoma=tahoma,arial,helvetica,sans-serif",
                        " Terminal=terminal,monaco",
                        " Times New Roman=times new roman,times",
                        " Trebuchet MS=trebuchet ms,geneva",
                        " Verdana=verdana,geneva",
                        " Webdings=webdings",
                        " Wingdings=wingdings,zapf dingbats",
                      ],
                    }}
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.description}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-2">
                  <label> Adresse *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Address"
                    value={address != null && address}
                    onChange={(e) => setAdresse(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.address}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label>langages*</label>{" "}
                  <span className="textSkills">separation par(,)</span>
                  <input
                    type="text"
                    className="form-control"
                    id="programminglanguage"
                    value={programminglanguage != null && programminglanguage}
                    onChange={(e) => setprogramminglanguage(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.programminglanguage}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-md-6 mt-2">
                  <label>Type de contrat *</label>
                  <select
                    className="form-select"
                    name="Type de contrat"
                    value={contract_type != null && contract_type}
                    onChange={(e) => setContract_type(e.target.value)}
                  >
                    <option disabled selected></option>
                    <option value="fulltime">À plein temps</option>
                    <option value="parialtime">temps partiel</option>
                    <option value="independent">indépendant</option>
                    <option value="freelancer">freelancer</option>
                    <option value="cdd">cdd</option>
                    <option value="cdi">cdi</option>
                    <option value="internship">stage</option>
                    <option value="alternation">alternance</option>
                    <option value="seasonal">saisonnier</option>
                  </select>
                  <div className="input-error">
                    <span className="text-danger">
                      {" "}
                      {formerrors.contract_type}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 mt-2 row">
                  <div className="col-md-8">
                    <label> Durée </label>
                    <input
                      type="text"
                      className="form-control "
                      id="duration"
                      value={duration !== null && duration}
                      onChange={(e) => setduration(e.target.value)}
                      disabled={actual}
                      required
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Actuelle
                      </label>
                      <input
                        checked={actual}
                        class="form-check-input "
                        type="checkbox"
                        onChange={() => experienceActual()}
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>

                  <div className="input-error">
                    <span className="text-danger">{formerrors.duration}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-md-6 mt-2">
                  <label> Date de début *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_d"
                    value={start_date != null && start_date}
                    onChange={(e) => setStart_date(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.start_date}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Date de fin *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_f"
                    value={end_date !== null && end_date}
                    onChange={(e) => setEnd_date(e.target.value)}
                    disabled={actual}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.end_date}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddExperienceModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default UpdateExperience;
