import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import EmployeesFormStep3 from "./EmployeesFormStep3";
import EmployeesFormStep1 from "./EmployeesFormStep1";
import { EmployeeContext } from "../../pages/employee/createEmployees";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";

const initFormErrors = {
  status: [""],
  city: [""],
  address: [""],
  cp: [""],
  nirpp: [""],
  country: [""],
};

const EmployeesFormStep2 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [countries, setCountries] = React.useState();
  const [city, setCity] = useState([]);
  const { newEmployee, setNewEmployee } = useContext(EmployeeContext);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [selectedCountryId, setSelectedCountryId] = useState(
    newEmployee.countryid
  );

  function getCountries() {
    axios
      .get(`${baseURL}/apiData/countries`)
      .then((resp) => {
        if (resp.data.error) return;
        setCountries(resp.data.data);
      })
      .catch((err) => {
        DisplayError();
      });
  }

  const handlecountry = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountryId(selectedCountryId);
    setNewEmployee((prevState) => ({
      ...prevState,
      countryid: selectedCountryId,
    }));
  };

  function getCities(countryid) {
    if (!countryid) {
      return;
    }
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      setCity(resp.data.data);
    });
  }

  useEffect(() => {
    getCities(selectedCountryId);
  }, [selectedCountryId]);
  useEffect(() => {
    getCountries();
  }, []);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newEmployee.nirpp === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        nirpp: msg,
      }));
    } else if (newEmployee.nirpp.length !== 13) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        nirpp: "Le nirpp doit contenir 13 caractères",
      }));
    }
    if (newEmployee.countryid !== "") {
      if (newEmployee.city === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          city: msg,
        }));
      }
    }
    if (error === false) {
      setChildComponent(
        <EmployeesFormStep3 setChildComponent={setChildComponent} />
      );
    }
  }

  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Etat Civil </label>
                <select
                  id="inputEtatCivil"
                  value={newEmployee.status}
                  className="form-select"
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      status: e.target.value,
                    }));
                  }}>
                  <option disabled selected value="">
                    Sélectionner votre êtat civil
                  </option>
                  <option value="single">Célibataire</option>
                  <option value="in_relationship">En couple</option>
                  <option value="married">Marié</option>
                  <option value="divorced">Divorcé</option>
                  <option value="pacs">Pacsé</option>
                </select>
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.status}</span>
                </div>
              </div>
              <div className="col-md-6 pb-3">
                <label>Adresse </label>
                <input
                  type="text"
                  value={newEmployee.address}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.address}</span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>pays </label>
                <div className="input-group ">
                  <select
                    id="inputState"
                    value={newEmployee.countryid}
                    className="form-select"
                    onChange={(e) => handlecountry(e)}>
                    <option value="" disabled>
                      Sélectionner une pays
                    </option>
                    {countries != null &&
                      countries.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.status}</span>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label>Ville </label>
                <div className="input-group">
                  <select
                    id="inputState"
                    value={newEmployee.city}
                    onChange={(e) => {
                      const selectedCityId = e.target.value;
                      const selectedCity = city.find(
                        (cityIteration) => cityIteration.id === selectedCityId
                      );
                      setNewEmployee((prevState) => ({
                        ...prevState,
                        city: selectedCityId,
                        cityName: selectedCity ? selectedCity.name : "",
                      }));
                    }}
                    className="form-select">
                    <option disabled value="">
                      Sélectionner une ville
                    </option>
                    {city != null &&
                      city.map((cityIteration, index) => {
                        return (
                          <option key={index} value={cityIteration.id}>
                            {cityIteration.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.city}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Code postal </label>
                <input
                  type="number"
                  value={newEmployee.cp}
                  min="0"
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      cp: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.cp}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>NIRPP *</label>
                <input
                  type="number"
                  value={newEmployee.nirpp}
                  min="0"
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      nirpp: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.nirpp}</span>
                </div>
              </div>
            </div>

            <div className="text-end mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary me-2"
                onClick={() => {
                  setChildComponent(
                    <EmployeesFormStep1 setChildComponent={setChildComponent} />
                  );
                }}>
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}>
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeesFormStep2;
