import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AddTypes from "../condidatesTypes/AddTypes";
import CandidatsExtractingFormStep2 from "./CandidatsExtractingFormStep2";
import { CandidatContext } from "../../context/candidatContext";
import { baseURL } from "../../config/config";
import Select from "react-select";
import CandidatsExtractingFormStep4 from "./CandidatsExtractingFormStep4";
import swal from "sweetalert";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";

const initFormErrors = {
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  cv: [""],
  skills: [""],
  experience: [""],
};

const CandidatsExtractingFormStep3 = ({ setChildComponent }) => {
  const errorNumber = "Le nombre d'année d'expériences doit être un nombre positive";
  const msg = "Ce champ est obligatoire";
  const [types, setTypes] = React.useState([]);
  const [addTypesModal, setaddTypesModal] = React.useState();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [selectedType, setSelectedType] = React.useState(null);
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const { user } = useContext(UserContext);
  const hasCandidatTypesCreatePermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "create"
  );
  function getAllTypes() {
    axios.get(`${baseURL}/candidatTypes/types`).then((resp) => {
      if (resp.data.error) return;
      const newTypes = resp.data.data.map((item) => ({
        value: JSON.stringify(item),
        label: item.name,
      }));
      setTypes(newTypes);
    });
  }

  function sendData() {
    const formData = new FormData();
    formData.append("gender", newCandidat.gender);
    formData.append("firstname", newCandidat.firstname);
    formData.append("lastname", newCandidat.lastname);
    formData.append("email", newCandidat.email);
    formData.append("pays", newCandidat.country);
    formData.append("city", newCandidat.city);
    formData.append("address", newCandidat.address);
    formData.append("cp", newCandidat.cp);
    formData.append("phone", newCandidat.phone);
    formData.append("birthday", newCandidat.birthDay);
    formData.append("birthPlace", newCandidat.birthPlace);
    formData.append("diplomeDate", newCandidat.diplomeDate);
    formData.append("diplome", newCandidat.diplome);
    formData.append("type", newCandidat.type);
    formData.append("langues", newCandidat.langues);
    formData.append("status", newCandidat.status || "single");
    formData.append("skills", newCandidat.skills);
    formData.append("experience", newCandidat.experience);
    formData.append("avatar", newCandidat.avatar);
    formData.append("cv", newCandidat.cv);
    formData.append("cvname", newCandidat.cvname);

    axios
      .post(`${baseURL}/candidates/create`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setNewCandidat((prevState) => ({
          ...prevState,
          candidateId: resp.data.candidateId,
        }));

        setChildComponent(
          <CandidatsExtractingFormStep4 setChildComponent={setChildComponent} />
        );
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }
  useEffect(() => {
    if (newCandidat && newCandidat.type) {
      const selectedTypeValue = {
        id: newCandidat.type,
        name: newCandidat.typename,
      };

      setSelectedType({
        value: JSON.stringify(selectedTypeValue),
        label: selectedTypeValue.name,
      });
    }
  }, [newCandidat]);
  useEffect(() => {
    getAllTypes();
  }, []);
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat.experience === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        experience: msg,
      }));
    } else if (isNaN(newCandidat.experience) || newCandidat.experience <= 0) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        experience: errorNumber,
      }));
    }
    if (newCandidat.skills === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        skills: msg,
      }));
    }
    if (newCandidat.type === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        type: msg,
      }));
    }
    if (newCandidat.cv === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cv: msg,
      }));
    }
    if (newCandidat.cvname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cvname: msg,
      }));
    }

    if (error === false) {
      sendData();
    }
  }

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "90%" }}
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Nombre d'année d'experience *</label>
                <input
                  type="number"
                  min="0"
                  value={newCandidat.experience}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      experience: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.experience}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>
                  Compétences *{" "}
                  <span className="textSkills">separation par ( ; )</span>
                </label>
                <input
                  type="text"
                  value={newCandidat.skills}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      skills: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.skills}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>langues </label>
                <Select
                  options={languesOptions}
                  isMulti
                  value={languesOptions.filter((option) =>
                    newCandidat.langues.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      langues: JSON.stringify(selectedValues),
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.langues}</span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <label>Dernier diplome obtenue </label>
                <input
                  type="text"
                  value={newCandidat.diplome}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      diplome: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.diplome}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Année de diplome </label>
                <input
                  type="number"
                  min="0"
                  value={newCandidat.diplomeDate}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      diplomeDate: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.diplomeDate}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Type de candidat *</label>
                <div className="row">
                  <div className="col-10">
                    <Select
                      options={types}
                      isSearchable
                      value={selectedType}
                      onChange={(selectedOption) => {
                        const selectedTypeValue = JSON.parse(
                          selectedOption.value
                        );
                        setSelectedType(selectedOption);

                        setNewCandidat((prevState) => ({
                          ...prevState,
                          type: selectedTypeValue.id,
                          typename: selectedTypeValue.name,
                        }));
                      }}
                    />
                  </div>
                  {hasCandidatTypesCreatePermission && (
                    <div className="col-2 ">
                      <button
                        type="button"
                        id="basic-addon1"
                        className="btn bg_bt"
                        onClick={() =>
                          setaddTypesModal(
                            <AddTypes
                              setAddTypesModalFromChild={setaddTypesModal}
                              getAllTypes={getAllTypes}
                            />
                          )
                        }>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.type}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Nom CV *</label>
                <div className=" mb-3">
                  <div className="custom-file">
                    <input
                      className="form-control"
                      type="text"
                      value={newCandidat.cvname}
                      onChange={(e) => {
                        setNewCandidat((prevState) => ({
                          ...prevState,
                          cvname: e.target.value,
                        }));
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.cvname}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-outline-secondary  me-2"
                  onClick={() => {
                    setChildComponent(
                      <CandidatsExtractingFormStep2
                        setChildComponent={setChildComponent}
                      />
                    );
                  }}>
                  Retour
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={validate}>
                  Continuer
                </button>
              </div>
            </div>
          </form>
          {addTypesModal}
        </div>
      </div>
    </div>
  );
};

export default CandidatsExtractingFormStep3;
