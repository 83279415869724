import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import Employeeslist from "../../components/employeesForm/EmployeesList";
import EmptyData from "../../components/emptyData";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Tooltip } from "@mui/material";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";
import ReplayIcon from "@mui/icons-material/Replay";

const Employees = () => {
  const { user } = useContext(UserContext);
  const hasEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consult"
  );
  const hasEmployeesCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const [employees, setEmployees] = useState([]);
  const [loadedEmployees, setLoadedEmployees] = useState([]);
  const [data, setData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [employeusers, setemployeUsers] = useState([]);
  const [missions, setMissions] = useState([]);

  function getLoadedEmployeeList() {
    const filteredData = filterEmployeeList(employees);
    let loadedSupplierLength = 0;

    if (nameFilter !== "" || emailFilter !== "" || phoneFilter !== "") {
      loadedSupplierLength = Math.min(filteredData.length, 6);
    } else {
      loadedSupplierLength = filteredData.length;
    }
    setLoadedEmployees(filteredData.slice(0, loadedSupplierLength));
  }
  const filterEmployeeList = (suppliers) => {
    const filteredSuppliers = suppliers.filter((employees) => {
      const nameMatch =
        employees.firstname.toLowerCase().includes(nameFilter.toLowerCase()) ||
        employees.lastname.toLowerCase().includes(nameFilter.toLowerCase());
      const emailMatch = employees.email
        .toLowerCase()
        .includes(emailFilter.toLowerCase());
      const phoneMatch = employees.phone.includes(phoneFilter);
      if (nameFilter !== "") {
        sessionStorage.setItem("nameFilterEmployee", nameFilter);
      }
      if (emailFilter !== "") {
        sessionStorage.setItem("emailFilterEmploye", emailFilter);
      }
      if (phoneFilter !== "") {
        sessionStorage.setItem("phoneFilterEmploye", phoneFilter);
      }
      return nameMatch && emailMatch && phoneMatch;
    });

    return filteredSuppliers;
  };
  function handleRestoreFilter() {
    const nameFilterEmployee = sessionStorage.getItem("nameFilterEmployee");
    const emailFilterEmploye = sessionStorage.getItem("emailFilterEmploye");
    const phoneFilterEmploye = sessionStorage.getItem("phoneFilterEmploye");
    if (nameFilterEmployee !== null) {
      setNameFilter(sessionStorage.getItem("nameFilterEmployee"));
    }
    if (emailFilterEmploye !== null) {
      setEmailFilter(sessionStorage.getItem("emailFilterEmploye"));
    }
    if (phoneFilterEmploye !== null) {
      setPhoneFilter(sessionStorage.getItem("phoneFilterEmploye"));
    }
    setTimeout(() => {
      sessionStorage.removeItem("nameFilterEmployee");
      sessionStorage.removeItem("emailFilterEmploye");
      sessionStorage.removeItem("phoneFilterEmploye");
    }, 100);
  }
  useEffect(() => {
    const filteredData = filterEmployeeList(employees);
    setLoadedEmployees(filteredData.slice(0, loadedEmployees.length + 6));

    if (filteredData.length === 0) {
      setData(<EmptyData />);
    } else {
      setData("");
    }
  }, [employees, nameFilter, emailFilter, phoneFilter]);
  async function getAllemployeUsers() {
    let tab = [];
    axios
      .get(`${baseURL}/employeeUser/all`)
      .then((response) => {
        if (response.data.error) return;
        response.data.data.map((emp) => {
          tab.push(emp?.consultant?.id);
        });
        setemployeUsers(tab);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function getAllMissions() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMissions(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (hasMissionsConsultPermission) {
      getAllMissions();
    }
  }, [hasMissionsConsultPermission]);
  async function getAllEmployees() {
    axios
      .get(`${baseURL}/consultants/all`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setEmployees(respData);
        setLoadedEmployees(respData.slice(0, loadedEmployees.length + 6));
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (employees.length === 0) {
      setData(<EmptyData />);
    }
  }, [employees]);
  useEffect(() => {
    if (hasEmployeesConsultPermission) {
      getAllEmployees();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasEmployeesConsultPermission]);
  const resetFilters = () => {
    setEmailFilter("");
    setNameFilter("");
    setPhoneFilter("");
  };
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les employés</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Employés</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-3 d-flex justify-content-end ">
            {hasEmployeesConsultPermission && (
              <Tooltip title={"Filtrer"} arrow>
                <button
                  className="btn btn-secondary btn-sm  mx-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample">
                  <FilterAltIcon />
                </button>
              </Tooltip>
            )}
            {hasEmployeesCreatePermission && (
              <Tooltip title={"Ajouter un employé"} arrow>
                <Link className="btn btn-primary  mx-2" to="/employees/create">
                  <i className="bi bi-plus-lg"></i>
                </Link>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="row d-flex justify-content-end">
          <div className="collapse p-3 row" id="collapseExample">
            <div className="col-lg-8 row">
              <div className="col-lg-4  col-11 px-2 ">
                <label>Nom</label>
                <input
                  value={nameFilter}
                  type="text"
                  onChange={(e) => setNameFilter(e.target.value)}
                  className="form-control"
                  placeholder="Filtrer par Nom"
                />
              </div>

              <div className="col-lg-4  col-11 px-2 pt-2 pt-md-0">
                <label>Tel</label>
                <input
                  value={phoneFilter}
                  type="text"
                  onChange={(e) => setPhoneFilter(e.target.value)}
                  className="form-control"
                  placeholder="Filtrer par Tel"
                />
              </div>
              <div className="col-lg-4  col-11 px-2 pt-2 pt-lg-0 ">
                <label>Email</label>
                <input
                  value={emailFilter}
                  type="text"
                  onChange={(e) => setEmailFilter(e.target.value)}
                  className="form-control"
                  placeholder="Filtrer par Email"
                />
              </div>
            </div>
            <div className="col-lg-4 row  mt-4 ">
              <div className="col-3  pt-2 pt-sm-0">
                <Tooltip title={"Réinitialiser le filtre"} arrow>
                  <button
                    type="button"
                    className="btn btn-secondary aligne-items-center"
                    onClick={resetFilters}>
                    <FilterAltOffIcon />
                  </button>
                </Tooltip>
              </div>
              <div className="col-6  pt-2 pt-sm-0">
                <Tooltip title={"Restaurer le dernier filtre"} arrow>
                  <button
                    type="button"
                    className="btn btn-secondary "
                    onClick={handleRestoreFilter}>
                    <i className="fa-solid fa-filter-circle-xmark"></i>
                    <ReplayIcon />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="card-body  pt-3 mt-2">{data}</div>
        <InfiniteScroll
          dataLength={loadedEmployees.length}
          next={getLoadedEmployeeList}
          hasMore={hasMore}>
          <div className="row">
            {loadedEmployees?.map((employee, key) => {
              return (
                <Employeeslist
                  key={key}
                  employee={employee}
                  getAllEmployees={getAllEmployees}
                  employeusers={employeusers}
                  getAllemployeUsers={getAllemployeUsers}
                  missions={missions}
                />
              );
            })}
          </div>
        </InfiniteScroll>
      </section>
    </div>
  );
};

export default Employees;
