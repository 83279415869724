import React from "react";
import "./NotAuthorized.css";

export default function NotAuthorized() {
  return (
    <>
      <div className="verrou">
        <i className="ri-lock-line lock-icon"></i>
      </div>
      <div className="message">
        <h1>Accès interdit - Erreur 403</h1>
        <p>Vous n'avez pas l'autorisation d'accéder à cette page.</p>
      </div>
    </>
  );
}