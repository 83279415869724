import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { baseURL } from "../../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/loader";

const CreateCompany = ({ getAllCompany }) => {
  const [type, setType] = useState("no");
  const [refresh, setRefresh] = useState("no");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [owner, setOwner] = useState("");
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const [webSite, setWebSite] = useState("");
  const [address, setAddress] = useState("");
  const [cities, setCities] = useState();
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState();
  const [description, setDescription] = useState("");
  const [siren, setSiren] = useState("");
  const [siret, setSiret] = useState("");
  const [tvaNumber, setTvaNumber] = useState("");
  const [rcs, setRcs] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    owner: [""],
    webSite: [""],
    address: [""],
    city: [""],
    logo: [""],
    country: [""],
    siren: [""],
    siret: [""],
    tva_number: [""],
    type: [""],
    rcs: [""],
    selectCity: "Veuillez sélectionner une ville.",
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [avatarUploaded, setAvatarUploaded] = useState();
  const handleImageChange = (e) => {
    setLogo(e.target.files[0]);
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setAvatarUploaded(event.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  async function getCities() {
    if (!country) return;
    await axios.get(`${baseURL}/apiData/cities/${country}`).then((resp) => {
      if (resp.data.error) return;
      setCities(resp.data.data);
    });
  }
  async function getCountries() {
    await axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }

  useEffect(() => {
    getCities();
  }, [country]);
  useEffect(() => {
    setRefresh("");
    setRefresh(0);
  }, [type]);

  useEffect(() => {
    getCountries();
  }, []);
  async function sendData() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("logo", logo);
    formData.append("email", email);
    formData.append("owner", owner);
    formData.append("phone", phone);
    formData.append("website", webSite);
    formData.append("address", address);
    formData.append("description", description);
    formData.append("siren", siren);
    formData.append("siret", siret);
    formData.append("tva_number", tvaNumber);
    formData.append("rcs", rcs);
    formData.append("isClient", type);
    if (country) {
      if (!city) {
        setFormErrors({ city: formerrors.selectCity });
        setIsLoading(false);
        return;
      }
      formData.append("city", city);
      formData.append("country", country);
    }
    await axios
      .post(`${baseURL}/companies/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById("cancelButton");
        offcanvasButton.click();
        getAllCompany();
        setName("");
        setType("");
        setEmail("");
        setOwner("");
        setPhone("");
        setAddress("");
        setWebSite("");
        setCity("");
        setCountry("");
        setDescription("");
        setSiret("");
        setSiren("");
        setAvatarUploaded("");
        setTvaNumber("");
        setRcs("");
        setLogo("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setIsLoading(false);
      });
  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="ajouterSociete"
      aria-labelledby="offcanvasRightLabel">
       <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel fw-bold">Ajouter une société</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div>
          <div className="mt-4">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="bg-white">
                  <label
                    htmlFor="fileInput"
                    className="d-flex justify-content-center">
                    <div className="mb-2">
                      {avatarUploaded ? (
                        <img
                          src={avatarUploaded}
                          alt="Uploaded"
                          className="imgUploaded "
                        />
                      ) : (
                        <img
                          src="../../assets/img/input-pictures.png"
                          alt="Placeholder"
                          className=" uploadInput"
                        />
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    name="profile_picture"
                    className="d-none"
                    accept="image/png, image/gif, image/jpeg"
                    required
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            <div className="row px-2">
              <label className="px-0 text-500">Type : *</label>
              <div className="col-12 py-2 px-0 ">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input offcanva-checkbox"
                    type="radio"
                    name="inlineRadioOptionsType"
                    id="inlineRadioCandidat"
                    value="no"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    checked={type === "no"}
                  />
                  <label class=" text-500" htmlFor="inlineRadioCandidat">
                    Esn
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input offcanva-checkbox"
                    type="radio"
                    name="inlineRadioOptionsType"
                    id="inlineRadioEmployee"
                    value="yes"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    checked={type === "yes"}
                  />
                  <label class=" text-500" htmlFor="inlineRadioEmployee">
                    Client
                  </label>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors?.type}
                  </span>
                </div>
              </div>
            </div>
            <div className="row px-2">
              <label className="px-0 mb-2 text-500">Nom: *</label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Nom"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.name}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Responsable : </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Responsable "
                onChange={(e) => setOwner(e.target.value)}
                value={owner}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.owner}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Téléphone : </label>
              <div className="input-group px-0">
                <PhoneInput
                  inputClass="phoneInput"
                  className="phoneInput"
                  placeholder="Téléphone"
                  value={phone}
                  onChange={(value, country, event) =>
                    setPhone(event.target.value ? event.target.value : value)
                  }
                />
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.phone}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Email : </label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.email}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <label className="px-0 mb-2 text-500">Site Web : </label>
              <div className="input-group px-0">
                <input
                  placeholder="Site Web"
                  type="text"
                  className="form-control"
                  onChange={(e) => setWebSite(e.target.value)}
                  value={webSite}
                />
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors?.website}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Adresse : </label>
                <div className="input-group mb-2">
                  <input
                    placeholder="Adresse"
                    className="form-control  offcanvas-input"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.address}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Description : </label>
                <div className="input-group mb-2 ">
                  <input
                    type="text"
                    placeholder="Description"
                    className="form-control offcanvas-input"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.description}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Siren : </label>
                <div className="input-group mb-2">
                  <input
                    placeholder="Siren"
                    className="form-control offcanvas-input"
                    value={siren}
                    onChange={(e) => setSiren(e.target.value)}
                    min="0"
                    type="number"
                    required
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.siren}
                </span>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Siret : </label>
                <div className="input-group mb-2 ">
                  <input
                    placeholder="Siret"
                    className="form-control offcanvas-input"
                    value={siret}
                    onChange={(e) => setSiret(e.target.value)}
                    min="0"
                    type="number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">
                  Numéro TVA intracommunautaire :
                </label>
                <div className="input-group mb-2">
                  <input
                    placeholder="Numéro TVA "
                    className="form-control offcanvas-input"
                    value={tvaNumber}
                    onChange={(e) => setTvaNumber(e.target.value)}
                    min="0"
                    type="number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">RCS : </label>
                <div className="input-group mb-2 ">
                  <input
                    placeholder="RCS"
                    className="form-control offcanvas-input"
                    value={rcs}
                    onChange={(e) => setRcs(e.target.value)}
                    min="0"
                    type="number"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Pays : </label>
                <div className="input-group mb-2">
                  <select
                    id="inputCountry"
                    className="form-select  offcanvas-input"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}>
                    <option value="" disabled>
                      Selectionner une pays ...
                    </option>
                    {countries != null &&
                      countries.map((country, key) => (
                        <option key={key} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.country}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-2 py-2">
              <div className="date-picker px-0">
                <label className="px-0 mb-2 text-500">Ville : </label>
                <div className="input-group mb-2">
                  <select
                    id="inputCountry"
                    className="form-select  offcanvas-input"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}>
                    <option value="" disabled>
                      Sectionner une ville ...
                    </option>
                    {cities != null &&
                      cities.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.city}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-2 pt-2 pb-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
                disabled={isLoading}
                >
                {isLoading ? <Loader /> : "Ajouter"}
              </button>
            </div>
            <div className="row px-2 pt-1 pb-2">
              <button
                id="cancelButton"
                type="button"
                className="btn but-restore"
                data-bs-dismiss="offcanvas"
                aria-label="Close">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateCompany;
