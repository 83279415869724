import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
const initFormErrors = {
  name: [""],
  etape: [""],
};

function Createcategory({ setaddCreateModal, getdata }) {
  const [Name, setName] = useState("");
  const [errorMessages, setErrorMessages] = useState(initFormErrors);
  const add = () => {
    if (Name === "") {
      setErrorMessages((prevState) => ({
        ...prevState,
        Name: "Ce champs est obligatoire",
      }));
    } else {
      axios
        .post(`${baseURL}/category/ajouterCategoryF`, {
          Name: Name,
        })
        .then((resp) => {
          if (resp.data.error) return;
          swal(resp.data.message, {
            icon: "success",
            timer: 2500,
          });
          setTimeout(() => {
            setaddCreateModal("");
            getdata();
          }, 2400);
        })
        .catch((err) => {
          setErrorMessages(err.response.data);
        });
    }
  };

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                Ajouter une catégorie
              </h3>
              <div className="row">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Nom :</label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="nom catégorie"
                    />
                    <div style={{ color: "#ff0000" }}>{errorMessages.name}</div>
                  </div>
                </form>
              </div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => setaddCreateModal("")}
                >
                  Annuler
                </button>

                <button type="button" className="btn btn-primary" onClick={add}>
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
export default Createcategory;
