import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";
import { baseURL } from "../../config/config";

import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import UpdateInterviewOffacnas from "../../components/interviews/updateInterview";

const MissionRdvList = ({ missionId }) => {
  const { user } = useContext(UserContext);

  const hasMissionsEditPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "edit"
  );
  const hasMissionsStatusPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "change_status"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionsCreatePermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "create"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const [rdvs, setRdvs] = useState(null);
  const [data, setData] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [societeFilter, setSocieteFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [consultantFilter, setConsultantFilter] = useState("");
  const noDataFound = "Aucune donnée trouvée";
  async function getMissionRdv() {
    await axios
      .get(`${baseURL}/rdv/mission/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data.sort(function (a, b) {
          if (a?.creation_date > b?.creation_date) {
            return -1;
          }
          return 0;
        });
        setRdvs(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (hasMissionsConsultPermission) {
      setData(<Loader />);
      getMissionRdv();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasMissionsConsultPermission]);
  async function toggleToDelete(selectedRdvId) {
    await axios
      .get(`${baseURL}/rdv/toggleDelete/${selectedRdvId}`)
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getMissionRdv();
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (rdvs == null) return;
    const filteredData = applyFilters(rdvs, [
      (data) => filterMissionByDescription(data, descriptionFilter),
      (data) => filterMissionByTitle(data, titleFilter),
      (data) => filterMissionBySociete(data, societeFilter),
      (data) => filterMissionByDate(data, dateFilter),
      (data) => filterRdvByConsultant(data, consultantFilter),
    ]);

    if (
      descriptionFilter ||
      titleFilter ||
      societeFilter ||
      dateFilter ||
      consultantFilter
    ) {
      if (filteredData.length > 0) {
        setData(
          <DataTable
            columns={columnsRdv}
            className="dataTableClass"
            data={filteredData}
            pagination
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            expandableRowsComponent={ExpandedComponent}
            expandableRows
          />
        );
      } else {
        setData(<EmptyData />);
      }
    } else {
      setData(
        <DataTable
          className="dataTableClass"
          columns={columnsRdv}
          data={rdvs}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows
        />
      );
    }
  }, [
    rdvs,
    descriptionFilter,
    titleFilter,
    societeFilter,
    dateFilter,
    consultantFilter,
  ]);

  async function enableDisableRdv(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`)
          .then((resp) => {
            let usersInfo = resp.data;

            if (!usersInfo.error) {
              getMissionRdv();
            }
          });
      }
    });
  }
  function applyFilters(data, filters) {
    let filteredData = data;

    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (descriptionFilter !== "") {
      sessionStorage.setItem("descriptionFilterRdv", descriptionFilter);
    }
    if (titleFilter !== "") {
      sessionStorage.setItem("titleFilterRdv", titleFilter);
    }
    if (societeFilter !== "") {
      sessionStorage.setItem("societeFilterRdv", societeFilter);
    }
    if (dateFilter !== "") {
      sessionStorage.setItem("DateFilterRdv", dateFilter);
    }

    if (consultantFilter !== "") {
      sessionStorage.setItem("consultantFilterRdv", consultantFilter);
    }
    return filteredData;
  }

  function filterMissionByDescription(data, searchText) {
    return data.filter(
      (item) =>
        item.description &&
        item.description.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function filterMissionByTitle(data, searchText) {
    return data.filter(
      (item) =>
        item.title &&
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function filterMissionBySociete(data, searchText) {
    return data.filter(
      (item) =>
        (item.company.name &&
          item.company.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.client.name &&
          item.client.name.toLowerCase().includes(searchText.toLowerCase()))
    );
  }
  function filterMissionByDate(data, selectedDate) {
    if (!selectedDate) return data;
    const selectedDateObj = new Date(selectedDate);
    return data.filter((item) => {
      const startDate = new Date(item.date);

      return startDate.toDateString() === selectedDateObj.toDateString();
    });
  }

  function filterRdvByConsultant(data, searchText) {
    return data.filter(
      (item) =>
        (item.consultant?.firstname &&
          item.consultant?.firstname
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (item.consultant?.lastname &&
          item.consultant?.lastname
            .toLowerCase()
            .includes(searchText.toLowerCase()))
    );
  }
  function handleRestoreFilter() {
    const titleFilterRdv = sessionStorage.getItem("titleFilterRdv");
    const descriptionFilterRdv = sessionStorage.getItem("descriptionFilterRdv");
    const societeFilterRdv = sessionStorage.getItem("societeFilterRdv");
    const DateFilterRdv = sessionStorage.getItem("DateFilterRdv");
    const consultantFilterRdv = sessionStorage.getItem("consultantFilterRdv");
    if (titleFilterRdv !== null) {
      setTitleFilter(sessionStorage.getItem("titleFilterRdv"));
    }
    if (descriptionFilterRdv !== null) {
      setDescriptionFilter(sessionStorage.getItem("descriptionFilterRdv"));
    }
    if (societeFilterRdv !== null) {
      setSocieteFilter(sessionStorage.getItem("societeFilterRdv"));
    }
    if (DateFilterRdv !== null) {
      setDateFilter(sessionStorage.getItem("DateFilterRdv"));
    }
    if (consultantFilterRdv !== null) {
      setConsultantFilter(sessionStorage.getItem("consultantFilterRdv"));
    }

    setTimeout(() => {
      sessionStorage.removeItem("titleFilterRdv");
      sessionStorage.removeItem("descriptionFilterRdv");
      sessionStorage.removeItem("societeFilterRdv");
      sessionStorage.removeItem("DateFilterRdv");
      sessionStorage.removeItem("consultantFilterRdv");
    }, 100);
  }
  const handleResetFilter = () => {
    setTitleFilter("");
    setDescriptionFilter("");
    setSocieteFilter("");
    setDateFilter("");
    setConsultantFilter("");
  };
  const [selectedRdvForUpdate, setSelectedRdvForUpdate] = useState();
  function handleUpdateRdvButtonClick(RdvId) {
    setSelectedRdvForUpdate(RdvId);
  }
  const columnsRdv = [
    {
      name: "Date ",
      selector: (row) => (
        <div>
          <div>{row?.date}</div>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Temps",
      selector: (row) => (
        <div>
          <div>{row?.time}</div>
        </div>
      ),
      hide: "md",
      sortable: true,
      center: true,
    },
    {
      name: "type de Presentation",
      selector: (row) => <div>{row?.type.toUpperCase()}</div>,
      sortable: true,
      hide: "md",
      center: true,
    },
    {
      name: "Mission",
      selector: (row) => (
        <div>
          <Link
            className="color-msit "
            to={`/missions/details/${row?.mission?._id}`}>
            {row?.title}
          </Link>
        </div>
      ),
      sortable: true,
      hide: "lg",
      center: true,
    },
    {
      name: "ESN",
      selector: (row) => (
        <div>
          {row?.company !== null ? (
            <Link to={`/company/details/${row?.company?._id}`}>
              {row?.company != null && row?.company?.name}
            </Link>
          ) : (
            noDataFound
          )}
        </div>
      ),
      sortable: true,
      hide: "lg",
      center: true,
    },
    {
      name: "Client",
      selector: (row) => (
        <div>
          {row?.client !== null ? (
            <Link to={`/company/details/${row?.client?._id}`}>
              {row?.client != null && row?.client?.name}
            </Link>
          ) : (
            noDataFound
          )}
        </div>
      ),
      sortable: true,
      hide: 1450,
      center: true,
    },
    {
      name: "Consultant",
      selector: (row) => (
        <div>
          <Link to={`/candidats/details/${row?.consultant?._id}`}>
            {row?.consultant != null && row?.consultant?.firstname}{" "}
            {row?.consultant != null && row?.consultant?.lastname}
          </Link>
        </div>
      ),
      sortable: true,
      hide: 1450,
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasMissionsStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisableRdv(row?._id, row?.isEnabled);
                      }}
                      checked={row?.isEnabled === "yes"}
                    />
                    Activer le Rendez-vous
                  </label>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                onClick={() => toggleToDelete(row?._id)}>
                <i className="text-danger mx-1 bi bi-trash"></i>

                <span className="ms-2">Supprimer Le rendez-vous</span>
              </Dropdown.Item>
              <Dropdown.Divider />

              {hasMissionsEditPermission &&
                hasCompanyConsultPermission &&
                hasManagersConsultPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasUpdateRdv"
                    aria-controls="offcanvasUpdateRdv"
                    onClick={() => handleUpdateRdvButtonClick(row?._id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier le Rendez-vous</span>
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Temps :</div>
            <div className=" fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.time}
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Type de Presentation :</div>
            {data?.type.toUpperCase()}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Mission :</div>

            <div className="cfs-14 fw-400 lh-20 py-1 ff-Inter">
              <Link to={`/missions/details/${data?.mission?._id}`}>
                {data?.title}
              </Link>
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">ESN :</div>
            {data?.company !== null ? (
              <Link to={`/company/details/${data?.company?._id}`}>
                {data?.company != null && data?.company?.name}
              </Link>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 1450 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Consultant :</div>
            {data.consultant !== null ? (
              <Link to={`/candidats/details/${data?.consultant?._id}`}>
                {data?.consultant != null && data?.consultant?.firstname}{" "}
                {data?.consultant != null && data?.consultant?.lastname}
              </Link>
            ) : (
              noDataFound
            )}
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">client :</div>
            {data.client !== null ? (
              <Link to={`/company/details/${data?.client?._id}`}>
                {data?.client != null && data?.client?.name}
              </Link>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row my-1">
        <div className="col-md-12 my-1">
          <div className="ms-1 text-details">Description :</div>

          {data.description ? (
            <Tooltip title={data.description} arrow>
              <div>
                {data?.description?.substring(0, 60)}
                {data?.description?.length > 60 ? "..." : ""}
              </div>
            </Tooltip>
          ) : (
            noDataFound
          )}
        </div>
      </div>

      <hr className="W-100"></hr>
    </pre>
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    const button = document.querySelector('[data-toggle="filterButton"]');
    const svgPath = button.querySelector("svg path");

    if (button && svgPath) {
      button.classList.toggle("btn-primary");

      if (svgPath.getAttribute("stroke") === "white") {
        svgPath.setAttribute("stroke", "black");
      } else {
        svgPath.setAttribute("stroke", "white");
      }
    }
  };
  return (
    <div>
      <div className="div_tabRoles">
        <div className="row">
          <div className="col-md-8 ">
            <h4 className="fw-bold m-4">Liste des Rendez-vous</h4>
          </div>
          <hr className="w-100"></hr>
          <div className="row pb-2">
            <div className="col text-sm-end  text-start row  ms-1 ">
              <div>
                <button
                  className="btn btn-primary but-filtre mt-3"
                  type="button"
                  onClick={toggleFilter}
                  data-toggle="filterButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ms-2">Filtres</span>
                </button>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <div className=" filterCardBackground">
              <div className="row p-2 mb-2">
                <div className="col-lg-7 col-md-12 d-flex ps-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Description</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par description"
                          className="form-control inputFiltre"
                          type="search"
                          value={descriptionFilter}
                          onChange={(e) => setDescriptionFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Societe</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par societe"
                          className="form-control inputFiltre"
                          type="search"
                          value={societeFilter}
                          onChange={(e) => setSocieteFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Date</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par date"
                          className="form-control inputFiltre"
                          type="date"
                          value={dateFilter}
                          onChange={(e) => setDateFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Consultant</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par consultant"
                          className="form-control inputFiltre"
                          type="search"
                          value={consultantFilter}
                          onChange={(e) => setConsultantFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 row  mt-3 pt-2">
                  <div className="col-lg-5 col-md-4 col-sm-12 pb-1 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-reset"
                      onClick={handleResetFilter}>
                      <img src={`../assets/img/repeat-04.svg`} /> Réinitialiser
                    </button>
                  </div>
                  <div className="col-lg-7 col-md-5 col-sm-12 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-restore"
                      onClick={handleRestoreFilter}>
                      <i className="ri-arrow-left-line px-1"></i>Dernière
                      recherche
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <section className="section">
          <div className="row card-body px-0">
            <div className="text-center">{data}</div>
          </div>
        </section>
      </div>

      <UpdateInterviewOffacnas
        selectedRdv={selectedRdvForUpdate}
        emptySelectedRdv={setSelectedRdvForUpdate}
        refreshFunction={getMissionRdv}
      />
    </div>
  );
};

export default MissionRdvList;
