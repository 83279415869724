import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import { CandidatContext } from "../../../context/candidatContext";
import { baseURL } from "../../../config/config";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import Loader from "../../loader";

const initFormErrors = {
  name: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthday: [""],
  lieu_naissance: [""],
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  avatar: [""],
};
export default function PageFourthUpdate({
  previuosPage,
  setSelectedForUpdate,
  getAllCandidats,
  candidatId,
  getSupplierByCompanyId,
}) {
  const { newCandidat } = useContext(CandidatContext);
  const noDataFound = "Aucune donnée trouvée";
  const [isLoading,setIsLoading] = useState(false);
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  function sendData() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("gender", newCandidat.gender);
    formData.append("firstname", newCandidat.firstname);
    formData.append("lastname", newCandidat.lastname);
    formData.append("email", newCandidat.email);
    formData.append(
      "pays",
      newCandidat.countryid && newCandidat.countryid != ""
        ? newCandidat.countryid
        : null
    );
    formData.append(
      "city",
      newCandidat.city && newCandidat.city != "" ? newCandidat.city : ""
    );
    formData.append("address", newCandidat.address);
    formData.append("cp", newCandidat.cp);
    formData.append("phone", newCandidat.phone);
    formData.append("birthday", newCandidat.birthday);
    formData.append("birthPlace", newCandidat.birthPlace);
    formData.append("diplomeDate", newCandidat.diplomeDate);
    formData.append("diplome", newCandidat.diplome);
    formData.append("type", newCandidat.type);
    formData.append("langues", newCandidat.langues);
    formData.append("status", newCandidat.status || "single");
    formData.append("skills", newCandidat.skills);
    formData.append("experience", newCandidat.experience);
    formData.append("avatar", newCandidat.avatar);
    formData.append("cv", newCandidat.cv);
    formData.append("nirpp", newCandidat.nirpp);
    formData.append("typeOfContract", newCandidat.typeOfContract);
    formData.append("salary", newCandidat.salary);
    formData.append("posteType", newCandidat.posteType);
    formData.append("startDate", newCandidat.startDate);
    formData.append("cvname", newCandidat.cvname);
    formData.append("company", newCandidat.company);

    axios
      .put(`${baseURL}/consultants/${candidatId}`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        const boutonOffcanvas = document.getElementById(
          "closeOffcanvasUpdateConsultantBtn"
        );
        boutonOffcanvas.click();
        Toastify({
          text: resp.data.message,
          duration: 2000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #0f8499 , #0f8499 )",
          },
        }).showToast();
        if (getAllCandidats) {
          getAllCandidats();
        }
        if (getSupplierByCompanyId) {
          getSupplierByCompanyId();
        }
        setSelectedForUpdate();
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
        setIsLoading(false);
        Object.keys(errors).map((key) => {
          if (key !== "error") {
            Toastify({
              text: errors[key],
              duration: 2000,
              close: true,
              gravity: "bottom",
              position: "right",
              stopOnFocus: true,
              style: {
                background: "linear-gradient(to right, #F44D , #F44D )",
              },
            }).showToast();
          }
        });
      });
  }
  return (
    <div className="mt-4">
      <div className="card-racap px-1 py-3">
        <div className="row">
          <div className="col-6">
            <p className="card-recap-title pb-0">Nom et prénom :</p>
            <p className="text-400">
              {newCandidat?.lastname} {newCandidat?.firstname}{" "}
            </p>
          </div>
          <div className="col-6">
            <p className="card-recap-title pb-0">Email :</p>
            <p className="text-400"> {newCandidat.email || noDataFound}</p>
          </div>
        </div>
        <div className="row pt-1">
          <div className="col-6">
            <p className="card-recap-title pb-0">Type de candidat :</p>
            <p className="text-400">{newCandidat?.typename || noDataFound} </p>
          </div>
          <div className="col-6">
            <p className="card-recap-title pb-0">Téléphone :</p>
            <p className="text-400"> {newCandidat?.phone || noDataFound}</p>
          </div>
        </div>
      </div>
      <div className=" card-racap px-1 py-3 ">
        <div className="row">
          <label className="card-recap-titre mb-3">
            Informations personnelles :
          </label>
          <div className="col-3">
            <p className="card-recap-title pb-0">Genre :</p>
            <p className="text-400">{newCandidat?.gender} </p>
          </div>
          <div className="col-4">
            <p className="card-recap-title pb-0">Etat civil :</p>
            <p className="text-400">
              {newCandidat?.status === "single" && "Célibataire"}
              {newCandidat?.status === "in_relationship" && "En couple"}
              {newCandidat?.status === "married" && "Marié"}
              {newCandidat?.status === "divorced" && "Divorcé"}
              {newCandidat?.status === "pacs" && "Pacsé"}
              {!newCandidat?.status && noDataFound}
            </p>
          </div>
          <div className="col-5">
            <p className="card-recap-title pb-0">Vile :</p>
            <p className="text-400">{newCandidat?.cityName || noDataFound}</p>
          </div>
        </div>
        <div className="row pt-1">
          <div className="col-6">
            <p className="card-recap-title pb-0">Adresse :</p>
            <p className="text-400"> {newCandidat?.address || noDataFound}</p>
          </div>
          <div className="col-6">
            <p className="card-recap-title pb-0">Code postal :</p>
            <p className="text-400"> {newCandidat?.cp || noDataFound}</p>
          </div>
        </div>
      </div>
      <div className="card-racap px-1 py-3 ">
        <div className="row">
          <label className="card-recap-titre mb-3">
            Informations professionnelles :
          </label>
          <div className="col-4">
            <p className="card-recap-title pb-0">Expérience :</p>
            <p className="text-400">{newCandidat.experience || noDataFound} </p>
          </div>
          <div className="col-4">
            <p className="card-recap-title pb-0">Compétence :</p>
            <p className="text-400">
              {" "}
              {newCandidat != null && newCandidat?.skills?.length > 10
                ? `${newCandidat.skills.substring(0, 10)}...`
                : newCandidat.skills || noDataFound}
            </p>
          </div>
          <div className="col-4">
            <p className="card-recap-title pb-0">Diplôme :</p>
            <p className="text-400">{newCandidat.diplome || noDataFound}</p>
          </div>
        </div>
        <div className="row pt-1">
          <div className="col-6">
            <p className="card-recap-title pb-0">Année de diplôme :</p>
            <p className="text-400">{newCandidat.diplomeDate || noDataFound}</p>
          </div>
          <div className="col-6">
            <p className="card-recap-title pb-0">Langues :</p>
            <p className="text-400">
              {newCandidat.langues
                ? JSON.parse(newCandidat.langues).map((lang, key) => lang + " ")
                : noDataFound}
            </p>
          </div>
        </div>
      </div>
      <div className="fs-4">
        <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
          {formerrors.email}
        </span>
      </div>
      <div className="row px-2 pt-2 pb-1">
        <button
          type="button"
          className="btn btn-add"
          onClick={() => {
            sendData();
          }}
          disabled={isLoading}
          >
            {isLoading ? <Loader/>: "Continuer"}          
        </button>
      </div>
      <div className="row px-2 pt-1 pb-2">
        <button
          type="button"
          className="btn but-restore"
          onClick={() => previuosPage("pagethree")}>
          Retour
        </button>
      </div>
      <div className="row px-2 pt-1 pb-2">
        <button
          type="button"
          id="closeOffcanvasUpdateConsultantBtn"
          className="btn but-restore"
          data-bs-dismiss="modal"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasUpdateConsultant"
          aria-controls="offcanvasUpdateConsultant">
          Annuler
        </button>
      </div>
    </div>
  );
}
