import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import CreateExperience from "./CreateExperience";
import { useParams } from "react-router";
import Content from "../ReadMore";
import UpdateExperience from "./UpdateExperience";
import DeleteExperience from "./DeleteExperience";
import ExperienceLink from "./ExperienceLink";
import DisplayError from "../ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
const ExperienceDetails = ({}) => {
  const { user } = useContext(UserContext);
  const hasExperienceConsultPermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "consult"
  );
  const hasExperienceCreatePermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "create"
  );
  const hasExperienceEditPermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "edit"
  );
  const hasExperienceDeletePermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "delete"
  );
  const hasCandidatsEditPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "edit"
  );
  const { candidatId } = useParams();
  const [experience, setExperience] = useState([]);
  const [addExperienceModal, setAddExperienceModal] = useState();
  function getExperienceByCandidatId() {
    axios
      .get(`${baseURL}/experience/consultant/${candidatId}`)
      .then((response) => {
        if (response.data.error) return;
        setExperience(response.data.data);
      })
      .catch((err) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (hasExperienceConsultPermission) {
      getExperienceByCandidatId();
    }
  }, [hasExperienceConsultPermission]);
  return (
    <div>
      <div className="d-grid gap-2 mt-3">
        <div className="d-flex justify-content-end">
          {hasExperienceCreatePermission && (
            <button
              href="#"
              className="btn btn-primary m-2"
              onClick={() =>
                setAddExperienceModal(
                  <CreateExperience
                    setAddExperienceModalFromChild={setAddExperienceModal}
                    getExperienceByCandidatId={getExperienceByCandidatId}
                    candidatId={candidatId}
                  />
                )
              }>
              Ajouter expérience
            </button>
          )}
          {hasCandidatsEditPermission && (
            <button
              href="#"
              className="btn btn-primary m-2"
              onClick={() =>
                setAddExperienceModal(
                  <ExperienceLink
                    setAddLinkExperienceModalFromChild={setAddExperienceModal}
                    candidatId={candidatId}
                  />
                )
              }>
              Générer un lien
            </button>
          )}
        </div>{" "}
      </div>
      {experience.map((experience, key) => {
        return (
          <div className="col-lg-12" key={key}>
            <div className="row mb-4">
              <div className="col-md-9">
                {" "}
                <h3>
                  {experience != null && experience.post_title}{" "}
                  <span className="style_ContratType">
                    ({experience != null && experience.contract_type})
                  </span>
                </h3>
              </div>
              <div className="col-md-3 d-flex justify-content-end">
                {hasExperienceEditPermission && (
                  <button
                    type="button"
                    className="btn text-primary"
                    onClick={() =>
                      setAddExperienceModal(
                        <UpdateExperience
                          setAddExperienceModalFromChild={setAddExperienceModal}
                          getExperienceByCandidatId={getExperienceByCandidatId}
                          experienceId={experience.id}
                          candidatId={candidatId}
                        />
                      )
                    }>
                    <i className="bi bi-pen"></i>
                  </button>
                )}
                {hasExperienceDeletePermission && (
                  <button
                    type="button"
                    onClick={() =>
                      setAddExperienceModal(
                        <DeleteExperience
                          setAddManagerModalFromChild={setAddExperienceModal}
                          getExperienceByCandidatId={getExperienceByCandidatId}
                          experienceId={experience.id}
                        />
                      )
                    }
                    className="btn text-danger">
                    <i className="bi bi-trash"></i>
                  </button>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {" "}
                <p>
                  {experience != null && experience.company}{" "}
                  <span>({experience != null && experience.sector})</span>
                </p>
                <p>
                  {" "}
                  <i className="bi bi-geo-alt"></i>{" "}
                  {experience != null && experience.address}
                </p>
              </div>

              <div className="col-md-6">
                {" "}
                <p>
                  <i className="bi bi-calendar-check"></i> du{" "}
                  {experience != null && experience.start_date}
                  {" jusqu'à "}
                  {experience != null
                    ? experience.actual === true
                      ? "Aujourd'hui"
                      : experience.end_date
                    : ""}
                </p>
                <p></p>
              </div>

              <div>
                <label>Déscription :</label>
                <Content experience={experience} />
              </div>
              <hr></hr>
              <div className="text-center"></div>
            </div>
          </div>
        );
      })}
      {addExperienceModal}
    </div>
  );
};

export default ExperienceDetails;
