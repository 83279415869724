import React, { useContext, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import permissions from "../../components/permissions";
import Select from "react-select";
import Loader from "../../components/loader";

const CreateUser = ({ getAllUsers, roles, getRoles }) => {
  const { user } = useContext(UserContext);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [role, setRole] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [avatar, setAvatar] = React.useState();
  const [avatarUploaded, setAvatarUploaded] = React.useState();
  const [socialSecurityNumber, setSocialSecurityNumber] = React.useState("");
  const [isInterviewer, setIsInterviewer] = useState("no");
  const [isLoading, setIsLoading] = useState(false);
  const handleIsInterviewerChange = (event) => {
    setIsInterviewer(event.target.value);
  };
  const hasRolesCreatePermission = hasPermission(
    user,
    PERMISSIONS.roles,
    "create"
  );

  const initFormErrors = {
    name: [""],
    email: [""],
    phone: [""],
    password: [""],
    confirm_password: [""],
    address: [""],
    socialSecurityNumber: [""],
    role: [""],
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const handleImageChange = (e) => {
    setAvatar(e.target.files[0]);
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setAvatarUploaded(event.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };
  const roleOptions =
    roles != null
      ? roles
          .sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((role) => ({
            value: role.id,
            label: role.name,
          }))
      : [];
  function sendData() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("avatar", avatar);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("role_id", role);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("confirm_password", confirmPassword);
    formData.append("nirpp", socialSecurityNumber);
    formData.append("isInterviewer", isInterviewer);
    axios
      .post(`${baseURL}/users/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById("cancelButton");
        offcanvasButton.click();
        getAllUsers();
        setName("");
        setEmail("");
        setAddress("");
        setPhone("");
        setRole("");
        setPassword("");
        setConfirmPassword("");
        setAvatar("");
        setAvatarUploaded("");
        setSocialSecurityNumber("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setIsLoading(false);
      });
  }

  const [activeAccordion, setActiveAccordion] = useState(null);
  const [permission, setPermission] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [checkPermission, setCheckPermission] = React.useState([]);
  const [roleCreated, setRoleCreated] = React.useState([]);
  const [searchText, setSearchText] = useState("");

  const msg = "Ce champ est obligatoire";
  const msgPermissions =
    "Les autorisations du rôle ne peuvent pas être nulles, il faut choisir au moins un accès";
  const [compAddRoleVisible, setCompAddRoleVisible] = useState(false);

  const openComponentAddRole = () => {
    setCompAddRoleVisible(!compAddRoleVisible);
  };
  function createRole() {
    setIsLoading(true);
    axios
      .post(`${baseURL}/roles/create`, {
        name: roleCreated,
        permission: JSON.stringify(permission),
      })
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        openComponentAddRole();
        setCheckPermission("");
        setRole("");
        setSearchText("");
        getRoles();
        setErrorMessage();
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
        setIsLoading(false);
      });
  }
  const onChangeCreate = ({ target: { value } }) => {
    setCheckPermission((prevCheck) => {
      const updatedList = prevCheck.includes(value)
        ? prevCheck.filter((val) => val !== value)
        : [...prevCheck, value];

      const [entity, permissionValue] = value.split("_");

      if (permissionValue !== "consult") {
        if (!updatedList.includes(`${entity}_consult`)) {
          updatedList.push(`${entity}_consult`);
        }
      } else {
        const otherPermissions = permissions[entity]?.items.map(
          (permission) => `${entity}_${permission.value}`
        );
        const hasOtherPermissions = otherPermissions.some((permissionKey) =>
          updatedList.includes(permissionKey)
        );
        if (!updatedList.includes(value) && hasOtherPermissions) {
          updatedList.push(value);
        }
      }
      const updatedPermission = {};
      updatedList.forEach((item) => {
        const [entity, ...permissionValue] = item.split("_");
        const fullPermissionValue = permissionValue.join("_");
        if (!updatedPermission[entity]) {
          updatedPermission[entity] = [];
        }
        updatedPermission[entity].push(fullPermissionValue);
      });

      setPermission(updatedPermission);
      return updatedList;
    });
  };

  function validateCreate() {
    const newErrorMessage = { ...initFormErrors };

    if (!roleCreated) {
      newErrorMessage.role = msg;
    } else if (
      typeof roleCreated !== "string" ||
      !roleCreated.match(/^[^-\s][a-zA-Z0-9_\s-]+$/)
    ) {
      newErrorMessage.role = "Veuillez entrer un nom valide.";
    } else {
      newErrorMessage.role = "";
    }
    if (checkPermission.length === 0) {
      newErrorMessage.permissionField = msgPermissions;
    } else {
      newErrorMessage.permissionField = "";
    }
    setErrorMessage(newErrorMessage);
    if (!newErrorMessage.role && !newErrorMessage.permissionField) {
      createRole();
    }
  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="ajouterUtilisateur"
      aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel" className="fw-bold">
          Ajouter un utilisateur
        </h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div>
          <form>
            <div className="mt-4">
              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <div className="bg-white">
                    <label
                      htmlFor="fileInput"
                      className="d-flex justify-content-center">
                      <div className="mb-2">
                        {avatarUploaded ? (
                          <img
                            src={avatarUploaded}
                            alt="Uploaded"
                            className="imgUploaded "
                          />
                        ) : (
                          <img
                            src="../../assets/img/input-pictures.png"
                            alt="Placeholder"
                            className=" uploadInput"
                          />
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      name="profile_picture"
                      className="d-none"
                      accept="image/png, image/gif, image/jpeg"
                      required
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-2">
                <label className="px-0 mb-2 text-500">Nom: *</label>
                <input
                  type="text"
                  className="form-control offcanvas-input"
                  placeholder="Nom"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.name}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">
                  Numéro de securité social : *
                </label>
                <input
                  type="number"
                  className="form-control offcanvas-input"
                  placeholder="Numéro de securité social "
                  onChange={(e) => setSocialSecurityNumber(e.target.value)}
                  value={socialSecurityNumber}
                  min="0"
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.nirpp}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Rôle : *</label>
                <div className="input-selectRole">
                  <Select
                    id="inputState"
                    className="col-11"
                    options={roleOptions}
                    value={roleOptions.find((option) => option.value === role)}
                    onChange={(selectedOption) =>
                      setRole(selectedOption ? selectedOption.value : "")
                    }
                    placeholder="Select a role..."
                    isMulti={false}
                  />
                  {hasRolesCreatePermission && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={openComponentAddRole}>
                      <i className="bi bi-plus-lg"></i>
                    </button>
                  )}
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors?.role}
                  </span>
                </div>
              </div>
              {compAddRoleVisible && (
                <div className="cardAddROle">
                  <div className="mt-3 ms-3">
                    <h4 id="fs-title">Ajouter un rôle</h4>
                  </div>
                  <div className="offcanvas-body offcanvas-body-scrollable">
                    <div>
                      <form>
                        <div>
                          <label>Rôle</label>
                          <input
                            type="text"
                            className="form-control inputFiltre my-3"
                            placeholder="Ajouter un rôle"
                            onChange={(e) => setRoleCreated(e.target.value)}
                            value={roleCreated}
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage?.message}
                            </span>
                          </div>
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage?.role}
                            </span>
                          </div>
                        </div>
                        <hr className="w-75 mx-auto"></hr>
                        <div>
                          <div className="groupFiltre">
                            <svg
                              className="iconFiltre"
                              aria-hidden="true"
                              viewBox="0 0 24 24">
                              <g>
                                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                              </g>
                            </svg>
                            <input
                              placeholder="Chercher une permission"
                              className="form-control inputFiltre my-3"
                              type="search"
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          </div>

                          <label>Permissions</label>
                          <div
                            className="accordion no-accordion-borders mt-3"
                            id="accordionPermissions">
                            {Object.keys(permissions).map(
                              (entity, index) =>
                                permissions[entity]?.name
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase()) && (
                                  <div className="accordion-item" key={entity}>
                                    <h2
                                      className="accordion-header"
                                      id={`heading${index}`}>
                                      <button
                                        className={`accordion-button ${
                                          activeAccordion === index
                                            ? ""
                                            : "collapsed"
                                        }`}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`}
                                        aria-expanded={
                                          activeAccordion === index
                                            ? "true"
                                            : "false"
                                        }
                                        aria-controls={`collapse${index}`}
                                        onClick={() =>
                                          setActiveAccordion(index)
                                        }>
                                        {permissions[entity]?.name}
                                      </button>
                                    </h2>
                                    <div
                                      id={`collapse${index}`}
                                      className={`accordion-collapse collapse ${
                                        activeAccordion === index ? "show" : ""
                                      }`}
                                      aria-labelledby={`heading${index}`}
                                      data-bs-parent="#accordionExample">
                                      <div className="accordion-body row">
                                        <div className="m-2 border-start">
                                          {permissions[entity]?.items.map(
                                            (permission, permissionIndex) => (
                                              <div
                                                key={permission.value}
                                                className="permission-item form-check form-switch">
                                                <input
                                                  className="form-check-input me-2"
                                                  value={`${entity}_${permission.value}`}
                                                  checked={checkPermission.includes(
                                                    `${entity}_${permission.value}`
                                                  )}
                                                  type="checkbox"
                                                  onChange={onChangeCreate}
                                                />
                                                <label className="form-check-label ms-1">
                                                  {permission.name}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                            <div className="input-error m-2">
                              <span className="text-danger">
                                {errorMessage?.permissionField}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2 d-flex justify-content-center ">
                            <button
                              type="button"
                              className="btn btn-primary col-11"
                              onClick={validateCreate}
                              disabled={isLoading}>
                              {isLoading ? <Loader /> : "Enregistrer"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-secondary col-11  mt-2"
                              onClick={openComponentAddRole}>
                              Annuler
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Adresse : *</label>
                <input
                  type="text"
                  className="form-control offcanvas-input"
                  placeholder="Adresse"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.address}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">Téléphone : *</label>
                <div className="input-group px-0">
                  <PhoneInput
                    inputClass="phoneInput"
                    className="offcanvas-input"
                    placeholder="Téléphone"
                    value={phone}
                    onChange={(value, country, event) =>
                      setPhone(event.target.value ? event.target.value : value)
                    }
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors?.phone}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Email : *</label>
                  <div className="input-group mb-2 offcanvas-input">
                    <input
                      placeholder="Email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.email}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Mot de Passe : *</label>
                  <div className="input-group mb-2 offcanvas-input">
                    <input
                      className="form-control offcanvas-input"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      type="password"
                      placeholder=" Mot de Passe"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.password}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">
                    Confirmation Mot de Passe : *
                  </label>
                  <div className="input-group mb-2 offcanvas-input">
                    <input
                      placeholder="Confirmation Mot de Passe"
                      className="form-control offcanvas-input"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password"
                      required
                    />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.confirm_password}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">Interviewer : *</label>
                  <div className="row pt-1">
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="myCheck"
                          value="yes"
                          checked={isInterviewer === "yes"}
                          onChange={handleIsInterviewerChange}
                        />
                        <label className="form-check-label">Oui</label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="myCheck"
                          value="no"
                          checked={isInterviewer === "no"}
                          onChange={handleIsInterviewerChange}
                        />
                        <label className="form-check-label">Non</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-2 pt-2 pb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendData}
                  disabled={isLoading}>
                  {isLoading ? <Loader /> : " Ajouter"}
                </button>
              </div>
              <div className="row px-2 pt-1 pb-2">
                <button
                  id="cancelButton"
                  type="button"
                  className="btn but-restore"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close">
                  Annuler
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateUser;
