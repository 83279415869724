import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { baseURL } from "../config/config";
import { UserContext } from "../context/UserContext";
import { Carousel } from "react-bootstrap";
import Loader from "../components/loader";

const Login = () => {
  const history = useHistory();
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    status: "",
  });

  function initErrorMessage() {
    setErrMsg({
      email: "",
      password: "",
      status: "",
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    initErrorMessage();
    try {
      await axios
        .post(`${baseURL}/login`, { email, password })
        .then((response) => {
          setIsLoading(false);
          setUser(response.data.data);
        });
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.message) {
        setErrMsg(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (user === undefined) return;

    history.replace("/");
  }, [user]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  return (
    <main className="row">
      <div className="col-12 col-lg-6 order-2 order-lg-1">
        <section className="section register  d-flex flex-column align-items-center  ">
          <div className="container">
            <div className=" d-flex flex-column align-items-center ">
              <div className="card mb-3 margin-form margin-formLogin login_card">
                <p aria-live="assertive"></p>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex justify-content-center SignInImg py-4 ">
                      <div className="logo d-flex align-items-center w-auto ">
                        <img
                          src="./assets/img/msit-logo.svg"
                          alt="Logo MSIT-Dashboard"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 pb-2">
                    <h5 className="text_title_login ">Se connecter</h5>
                    <p className="text-center  text_under_title_login">
                      Veuillez insérer votre adresse et mot de passe
                    </p>
                  </div>
                  <p className="text-danger" aria-live="assertive">
                    {errMsg.status != null && errMsg.status}
                  </p>
                  <form
                    className="row g-3 needs-validation"
                    onSubmit={handleSubmit}>
                    <div className="col-12">
                      <label className="form-label">Email</label>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          id="username"
                          name="username"
                          className="form-control "
                          autoComplete="off"
                          onChange={onChangeEmail}
                          placeholder="Email"
                          required
                        />
                      </div>
                      <p className="text-danger" aria-live="assertive">
                        {errMsg.email != null && errMsg.email}
                      </p>
                    </div>
                    <div className="col-12">
                      <label className="form-label">Mot de passe</label>
                      <div className="row">
                        <div className="input-group">
                          <input
                            type={passwordType}
                            onChange={onChangePassword}
                            name="password"
                            className="form-control"
                            placeholder="Mot de passe"
                          />
                          <div className="input-group-btn">
                            <button
                              className="btn btn-outline-primary"
                              type="button"
                              onClick={togglePassword}>
                              {passwordType === "password" ? (
                                <i className="bi bi-eye-slash"></i>
                              ) : (
                                <i className="bi bi-eye"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <p className="text-danger" aria-live="assertive">
                          {errMsg.password != null && errMsg.password}
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="remember"
                          value="true"
                          id="rememberMe"
                        />
                        <label className="form-check-label">
                          Rester connecté
                        </label>
                      </div>
                    </div> */}
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        disabled={isLoading}>
                        {isLoading ? <Loader /> : "Connexion"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="footer_Login  d-none d-lg-block">
              © MSIT-Dashboard - 2023
            </div>
          </div>
        </section>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <Carousel>
          <Carousel.Item>
            <div className="img_Login ps-5">
              <p className="carousel-imgs carousel-imgpadding">
                Vous êtes à la recherche de
              </p>
              <p className="carousel-imgs">votre futur collaborateur ?</p>
              <p className="carousel-imgs">MSIT Conseil est votre</p>
              <p className="carousel-imgs">partenaire de confiance</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="img_LoginTwo  ps-5">
              <p className="carousel-imgs carousel-imgpadding">
                Nous mettons à votre
              </p>
              <p className="carousel-imgs">disposition les meilleurs profils</p>
              <p className="carousel-imgs">pour vos besoins</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="img_LoginThree  ps-5">
              <p className="carousel-imgs carousel-imgpadding">
                Lancez votre carrière avec
              </p>
              <p className="carousel-imgs"> MSIT Conseil et trouvez la</p>
              <p className="carousel-imgs"> mission qui vous convient ! </p>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </main>
  );
};
export default Login;
