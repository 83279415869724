import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import Loader from "../../components/loader";
import EditMission from "../../components/mission/updateMission";
import EmptyData from "../../components/emptyData";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import AffectMissionModal from "./affectMissionModal";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";

const EmployeesDetails = () => {
  const { user } = useContext(UserContext);
  const hasMissionsEditPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "edit"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasMissionsEmployeesStatusPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "change_status"
  );
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionsEmployeeConsultPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "consult"
  );
  const hasMissionsEmployeeAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "create"
  );
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");

  const [employee, setEmployee] = useState([]);
  const { employeeId } = useParams();
  const [addMissionModal, setAddMissionModal] = useState();
  const [missionData, setMissionData] = useState([]);
  const [mission, setMission] = useState([]);
  const [missions, setMissions] = useState([]);
  const [affectMissionModal, setAffectMissionModal] = useState();
  const errors = {
    birthNull: "Date de naissance indisponible",
    lieuBirthNull: "Lieu de naissance indisponible",
    noDataFound: "Aucune donnée trouvée",
  };
  async function getAllMissions() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMissions(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (hasMissionsConsultPermission) {
      getAllMissions();
    }
  }, [hasMissionsConsultPermission]);
  async function getDetailsEmployee() {
    if (employeeId === undefined) return;
    axios
      .get(`${baseURL}/consultants/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        setEmployee(response.data.data);
      })
      .catch((err) => {
        DisplayError();
      });
  }
  async function getMission() {
    if (employeeId === null) return;
    axios
      .get(`${baseURL}/missionEmployees/missions/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {
        DisplayError();
      });
  }

  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`${baseURL}/missionEmployees/toggleStatus/${employeeId}`, {
            missionId: missionId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getMission();
            }
          })
          .catch((err) => {
            DisplayError();
          });
      }
    });
  }
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable
          className="dataTableClass"
          columns={columnsMission}
          data={mission}
          pagination
        />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);
  useEffect(() => {
    getDetailsEmployee();
  }, []);
  useEffect(() => {
    if (hasMissionsEmployeeConsultPermission) {
      setMissionData(<Loader />);
      getMission();
    }
  }, [hasMissionsEmployeeConsultPermission]);
  const columnsMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "6%",
      sortable: true,
    },
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <div>{row.mission.title}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.mission.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.mission.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.mission.period}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div>
            {row.mission.manager != null && row.mission.manager.fname}{" "}
            {row.mission.manager != null && row.mission.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="row col-12">
            {hasMissionsEditPermission &&
              hasCompanyConsultPermission &&
              hasMissionsConsultPermission &&
              hasManagersConsultPermission && (
                <button
                  className="col-3 btn text-primary me-2"
                  onClick={() =>
                    setAddMissionModal(
                      <EditMission
                        setAddMissionModalFromChild={setAddMissionModal}
                        missionId={row.mission._id}
                        getMissions={getMission}
                      />
                    )
                  }>
                  <i className="bi bi-pen"></i>
                </button>
              )}

            {hasMissionsEmployeesStatusPermission && (
              <button
                className="col-3 btn text-primary me-2 btn"
                onClick={() => {
                  enableDisableMission(row.mission._id, row.mission.isEnabled);
                }}>
                {row.isEnabled === "yes" ? (
                  <i className="text-success bi bi-toggle2-on"></i>
                ) : (
                  <i className="text-danger bi bi-toggle2-off"></i>
                )}
              </button>
            )}
            {hasMissionsConsultPermission && (
              <Link
                className="col-3 btn text-primary me-2"
                to={`/missions/details/${row.mission._id}`}>
                <i className="ri-arrow-right-s-fill"></i>
              </Link>
            )}
          </div>
        );
      },
      width: "15%",
    },
  ];

  function affectMissionToEmployee() {
    setAffectMissionModal(
      <AffectMissionModal
        SetAffectModalFromChild={setAffectMissionModal}
        employee={employee}
        missions={missions}
        getMissions={getMission}
      />
    );
  }
  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-8">
              <h1>Détails de l'employé</h1>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <Link to="/employees">
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2">
              Retour
            </button>
          </Link>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/employees">Les employés</Link>
            </li>
          </ol>
        </nav>
      </div>

      <section className="section profile">
        <div className="row">
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center text-uppercase ">
                <img
                  src={employee != null && employee.avatar}
                  alt="Profile"
                  className="avatar-border"
                />
                <h2 className="text-center">
                  {employee != null &&
                    (employee.firstname?.length + employee.lastname?.length > 15
                      ? `${employee.firstname.slice(
                          0,
                          8
                        )}... ${employee.lastname.slice(0, 4)}...`
                      : `${employee.firstname} ${employee.lastname}`)}
                </h2>
                <div className="row text-lowercase col-md-12 mt-4 flex">
                  <div className="row">
                    <div className="col-1 label ">
                      <i className="bi bi-envelope-fill"></i>
                    </div>
                    <div className="col-10">
                      <a
                        href={`mailto:${employee.email}`}
                        className="color-msit "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Envoyer mail">
                        {" "}
                        {employee != null && employee.email}
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1 label ">
                      <i className="bi-telephone me-1"></i>
                    </div>
                    <div className="col-10">
                      {employee && employee.phone ? (
                        <a
                          className="color-msit"
                          href={`tel:+${employee.phone}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Appeler">
                          {employee.phone}
                        </a>
                      ) : (
                        <span className="color-black">
                          {errors.noDataFound}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1 label">
                      <i className="bi bi-geo-alt  me-1"></i>
                    </div>
                    <div className="col-10">
                      {employee && employee.address
                        ? employee.address
                        : errors.noDataFound}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1 label">
                      <i className=" bi bi-briefcase me-1"></i>
                    </div>
                    <div className="col-10">
                      {employee != null && employee.job} ({employee.experience}
                      ans)
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1 label">
                      <i className=" bi bi-gift me-1"></i>
                    </div>
                    <div className="col-10">
                      {employee != null
                        ? employee.birthday || errors.birthNull
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div className="card">
              <div className="card-body pt-3">
                <h5 className="card-title">Compétences : </h5>
                <p className="small fst-italic">
                  {employee != null && employee.skills}
                </p>

                <h5 className="card-title">Détails de l'employé : </h5>
                <div className="row col-md-12">
                  <div className="row col-md-12 flex">
                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Dernier diplome obtenue
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee && employee.diplome
                          ? employee.diplome
                          : errors.noDataFound}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Année de diplome
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee && employee.diplomeDate
                          ? employee.diplomeDate
                          : errors.noDataFound}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        NIRPP
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.nirpp}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Ville
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee && employee.city?.name
                          ? employee.city?.name
                          : errors.noDataFound}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Code postal
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee && employee.cp
                          ? employee.cp
                          : errors.noDataFound}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Type de contrat
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.typeOfContract}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Date début de travail
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.startDate}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Salaire
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null && employee.salary}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        <h5 className="card-title-candidat color-msit ">
                          Langue
                        </h5>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <p className="small">
                          {employee?.langues ? (
                            employee.langues.split(",").map((lang, key) => (
                              <span
                                key={key}
                                className="badge bg-secondary me-2">
                                {lang.trim()}
                              </span>
                            ))
                          ) : (
                            <span>{errors.noDataFound}</span>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Genre
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null &&
                        employee.gender &&
                        employee.gender === "male"
                          ? "Homme"
                          : employee.gender === "femele"
                          ? "Femme"
                          : employee.gender === "other"
                          ? "Autre"
                          : ""}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Lieu de naissance
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null
                          ? employee.birthPlace || errors.lieuBirthNull
                          : ""}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-lg-6 col-md-6 label color-msit">
                        Etat civil
                      </div>
                      <div className="col-lg-6 col-md-6">
                        {employee != null &&
                        employee.status &&
                        employee.status === "single"
                          ? "Célibataire"
                          : employee.status === "married"
                          ? "Marié"
                          : employee.status === "in_relationship"
                          ? "En Couple"
                          : employee.status === "divorced"
                          ? "Divorcé"
                          : "Pacsé"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pagetitle">
        <h1>Les Mission</h1>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Mission</li>
              </ol>
            </nav>
          </div>
          {hasCvConsultPermission &&
            hasMissionsConsultPermission &&
            hasMissionsEmployeeAffectPermission && (
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-primary btn-rounded float-end text-sm"
                  onClick={() => affectMissionToEmployee()}>
                  <i className="bi bi-plus-lg"></i> Ajouter une mission
                </button>
              </div>
            )}
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-end">
                <div className="col-md-3"></div>
              </div>
              <div className="card-body  pt-3">{missionData}</div>
            </div>
          </div>
          {addMissionModal}
          {affectMissionModal}
        </div>
      </section>
    </div>
  );
};

export default EmployeesDetails;
