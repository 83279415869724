import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

function ImportCandidatCV({ hideModal, consultantId }) {
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [cvName, setCvName] = useState("");
  const [cvList, setCvList] = useState([]);
  const [formerrors, setFormErrors] = useState("");

  function handleUpload(e) {
    const file = e.target.files[0];
    setFileName(file.name);
    setCvName(file.name);
    setFile(file);
  }

  function sendData() {
    const formData = new FormData();
    formData.append("cvName", cvName);
    formData.append("consultant_cv", file);
    axios
      .post(`${baseURL}/consultants/import/${consultantId}/cv`, formData)
      .then((resp) => {
        if (resp.data.error) return;
        Toastify({
          text: "CV enregistré avec succees",
          duration: 2000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #0f84ff , #0f84ff )",
          },
        }).showToast();
        getLatestCVs();
        resetInputs();
      })
      .catch((error) => {
        Toastify({
          text: "une erreur est survenue",
          duration: 2000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #FF8A8A , #FF8A8A )",
          },
        }).showToast();
        setFormErrors(error.response.data.message);
        DisplayError();
      });
  }
  function getLatestCVs() {
    axios
      .get(`${baseURL}/consultants/import/${consultantId}/cv/latest`)
      .then((resp) => {
        if (resp.data.error) return;
        setCvList(resp.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  function resetInputs() {
    setFile(null);
    inputRef.current.value = null;
    setCvName("");
    setFileName("");
  }

  useEffect(() => {
    getLatestCVs();
  }, []);

  return (
    <div>
      <div
        className="modal fade show import-modal"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 class="modal-title">CV du candidat</h5>
              <button
                type="button"
                class="btn close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => hideModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6 class="modal-title">derniers cv ajoutés</h6>
              {cvList.map((cv) => {
                return (
                  <div className="row">
                    <div className="col-10">
                      {cv.cvname} ({cv.creation_date.slice(0, 10)})
                    </div>
                    <div className="col-2">
                      <a
                        className="btn text-primary"
                        href={baseURL + cv?.pathcv?.substring(1)}
                        target="blanc">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none">
                          <path
                            d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                            stroke="#344054"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
            <div className="modal-body">
              <h6 class="modal-title">Ajouter un CV</h6>
              <div className="import-bloc">
                <label className="px-0 mb-2 text-500" for="import-cv">
                  <div>
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none">
                        <path
                          d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                          stroke="#475467"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p>
                      {file != null
                        ? fileName
                        : "Cliquer pour choisir un cv ou glisser directement (PDF, Word ...)"}
                    </p>
                  </div>
                </label>
              </div>
              <input
                type="file"
                className="d-none"
                id="import-cv"
                ref={inputRef}
                onChange={(e) => {
                  handleUpload(e);
                }}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {/* {errorMessage?.lastname} */}
                </span>
              </div>
            </div>
            <div className="px-2 py-2">
              <label className="px-0 mb-2 text-500">Nom du CV</label>
              <input
                type="text"
                className="form-control offcanvas-input"
                placeholder="Nom du CV"
                defaultValue={cvName}
                onChange={(e) => {
                  setCvName(e.target.value);
                }}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {/* {errorMessage?.firstname} */}
                </span>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                onClick={() => hideModal(false)}>
                Annuler
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => resetInputs()}>
                Reset
              </button>
              <button type="button" onClick={sendData} className="btn btn-add">
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        onClick={() => {
          hideModal(false);
        }}></div>
    </div>
  );
}

export default ImportCandidatCV;
