import React from "react";

const CompanyDetailsComponent = ({ company }) => {
  const noDataFound = "Aucune donnée trouvée";
  return (
    <div className="tab-content pt-5 ms-4">
      <div
        className="tab-pane fade show active profile-overview"
        id="profile-overview">
        <div className="details_consultant">
          <h5 className="ms-3 text-details-company">Description</h5>
          <p className="small fst-italic ms-3">
            {company != null && company.description
              ? company.description
              : noDataFound}
          </p>
        </div>

        <div className="mt-5 text-details-company">Détails du Société</div>
        <div className="row ms-1">
          <div className="col-md-3 details_consultant my-3">
            <div className="col-lg-7 col-md-7 label text-details-company">
              Siren
            </div>
            <div className="col-lg-5 col-md-5">
              {company != null && company.siren ? company.siren : noDataFound}
            </div>
          </div>

          <div className="col-md-3 details_consultant my-3">
            <div className="col-lg-7 col-md-7 label text-details-company">
              Siret
            </div>
            <div className="col-lg-5 col-md-5">
              {company != null && company.siret ? company.siret : noDataFound}{" "}
            </div>
          </div>

          <div className="col-md-3 details_consultant my-3">
            <div className="col-lg-7 col-md-7 label text-details-company">
              Numéro TVA
            </div>
            <div className="col-lg-5 col-md-5">
              {company != null && company.tva_number
                ? company.tva_number
                : noDataFound}
            </div>
          </div>

          <div className="col-md-3 details_consultant my-3">
            <div className="col-lg-7 col-md-7 label text-details-company">
              Numéro Rcs
            </div>
            <div className="col-lg-5 col-md-5">
              {company != null && company.rcs ? company.rcs : noDataFound}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsComponent;
