import React, { useContext, useEffect, useMemo, useState } from "react";
import PageOneUpdate from "./pageOneUpdate";
import PageTwoUpdate from "./pageTwoUpdate";
import PageThreeUpdate from "./pageThreeUpdate";
import PageFourthUpdate from "./pageFourthUpdate";
import { CandidatContext } from "../../../context/candidatContext";
import axios from "axios";
import { baseURL } from "../../../config/config";

export default function UpdateCandidat({
  id,
  getAllCandidats,
  getSupplierByCompanyId,
  setSelectedForUpdate,
}) {
  const [newCandidat, setNewCandidat] = useState({
    gender: "",
    firstname: "",
    countryid: "",
    lastname: "",
    email: "",
    city: "",
    address: "",
    cp: "",
    phone: "",
    birthday: "",
    birthPlace: "",
    diplomeDate: "",
    diplome: "",
    typename: "",
    langues: "",
    status: "",
    skills: "",
    experience: "",
    avatar: "",
    cv: "",
    nirpp: "",
    typeOfContract: "",
    salary: "",
    posteType: "",
    startDate: "",
    posteTypeName: "",
    cvname: "",
    type: "",
    company: "",
    companyName: "",
  });

  const [page, setPage] = useState("pageone");

  const nextPage = (page) => {
    setPage(page);
  };
  const previuosPage = (page) => {
    setPage(page);
  };
  const value = useMemo(
    () => ({ newCandidat, setNewCandidat }),
    [newCandidat, setNewCandidat]
  );
  async function getData(id) {
    setPage("pageone");
    if (id === undefined || id === "" || id === null) return;
    await axios.get(`${baseURL}/consultants/${id}`).then((resp) => {
      if (resp.data.error) return;
      const candidatsData = resp.data.data;
      setNewCandidat({
        gender: candidatsData?.gender ?? "male",
        firstname: candidatsData?.firstname ?? "",
        email: candidatsData?.email ?? "",
        lastname: candidatsData?.lastname ?? "",
        address: candidatsData?.address ?? "",
        phone: candidatsData?.phone ?? "",
        cp: candidatsData?.cp ?? "",
        avatar: candidatsData?.avatar ?? "",
        diplome: candidatsData?.diplome ?? "",
        diplomeDate: candidatsData?.diplomeDate ?? "",
        birthday: candidatsData?.birthday ?? "",
        birthPlace: candidatsData?.birthPlace ?? "",
        city: candidatsData?.city?._id ?? "",
        countryid: candidatsData?.city?.country ?? "",
        status: candidatsData?.status ?? "",
        skills: candidatsData?.skills ?? "",
        langues: candidatsData?.langues ?? "",
        experience: candidatsData?.experience ?? "",
        type: candidatsData?.type ?? "",
        typename: candidatsData?.type?.name ?? "",
        cv: candidatsData?.cv?._id ?? "",
        nirpp: candidatsData?.nirpp ?? "",
        typeOfContract: candidatsData?.typeOfContract ?? "none",
        salary: candidatsData?.salary ?? "",
        posteType: candidatsData?.posteType?._id ?? "",
        posteTypeName: candidatsData?.posteType?.name ?? "",
        company: candidatsData?.company?._id ?? "",
        companyName: candidatsData?.company?.name ?? "",
        startDate: candidatsData?.startDate ?? "",
        isLocked: candidatsData?.isLocked ?? "no",
      });
    });
  }
  useEffect(() => {
    if (id === undefined || id === "") return;
    getData(id);
  }, [id]);
  return (
    <>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-1"
        id="offcanvasUpdateConsultant"
        aria-labelledby="offcanvasRightLabel"
        aria-modal="true"
        role="dialog">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">
            {page !== "pagefour" ? "Modifier candidat" : "Fiche de candidat"}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasUpdateConsultant"
            aria-controls="offcanvasUpdateConsultant"></button>
        </div>
        <div className="tr offcanvas-body">
          <CandidatContext.Provider value={value}>
            {
              {
                pageone: (
                  <PageOneUpdate
                    nextpage={nextPage}
                    previuosPage={previuosPage}
                    newCandidat={newCandidat}
                  />
                ),
                pagetwo: (
                  <PageTwoUpdate
                    nextpage={nextPage}
                    previuosPage={previuosPage}
                    newCandidat={newCandidat}
                  />
                ),
                pagethree: (
                  <PageThreeUpdate
                    nextpage={nextPage}
                    previuosPage={previuosPage}
                    newCandidat={newCandidat}
                  />
                ),
                pagefour: (
                  <PageFourthUpdate
                    previuosPage={previuosPage}
                    getAllCandidats={getAllCandidats}
                    getSupplierByCompanyId={getSupplierByCompanyId}
                    setSelectedForUpdate={setSelectedForUpdate}
                    newCandidat={newCandidat}
                    candidatId={id}
                  />
                ),
              }[page]
            }
          </CandidatContext.Provider>
        </div>
      </div>
    </>
  );
}
