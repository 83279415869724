import React from "react";
import { baseURL } from "../../config/config";
import "toastify-js/src/toastify.css";

function CvFrame({ hideModal, data, consultant }) {
  return (
    <div>
      <div
        className="modal fade show import-modal"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 class="modal-title">CV du candidat</h5>
              <button
                type="button"
                class="btn close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => hideModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6 class="modal-title text-center">
                CV du {consultant.firstname} {consultant.lastname}
              </h6>
              <div className="text-center">
                <iframe
                  className="responsivIframe"
                  src={baseURL + data?.pathcv.substring(1)}
                  title="cv"></iframe>
              </div>
            </div>
            <hr />

            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                onClick={() => hideModal(false)}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        onClick={() => {
          hideModal(false);
        }}></div>
    </div>
  );
}

export default CvFrame;
