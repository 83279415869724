import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../config/config";
import permissions from "../components/permissions";
import Loader from "../components/loader";
const initFormErrors = {
  role: [""],
  permissionField: [""],
};

const CreateRole = ({getAllRoles}) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [permission, setPermission] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [check, setCheck] = React.useState([]);
  const [roleAdd, setRoleAdd] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading,setIsLoading] = useState(false);

  const msg = "Ce champ est obligatoire";
  const msgPermissions =
    "Les autorisations du rôle ne peuvent pas être nulles, il faut choisir au moins un accès";

  function sendDataCreate() {
    setIsLoading(true);
    axios
      .post(`${baseURL}/roles/create`, {
        name: roleAdd,
        permission: JSON.stringify(permission),
      })
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById("annulerButton");
        offcanvasButton.click();
        setCheck("");
        setRoleAdd("");
        setSearchText("");
        getAllRoles();
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
        setIsLoading(false);
      });
  }
  const onChangeCreate = ({ target: { value } }) => {
    setCheck((prevCheck) => {
      const updatedList = prevCheck.includes(value)
        ? prevCheck.filter((val) => val !== value)
        : [...prevCheck, value];

      const [entity, permissionValue] = value.split("_");

      if (permissionValue !== "consult") {
        if (!updatedList.includes(`${entity}_consult`)) {
          updatedList.push(`${entity}_consult`);
        }
      } else {
        const otherPermissions = permissions[entity]?.items.map(
          (permission) => `${entity}_${permission.value}`
        );
        const hasOtherPermissions = otherPermissions.some((permissionKey) =>
          updatedList.includes(permissionKey)
        );
        if (!updatedList.includes(value) && hasOtherPermissions) {
          updatedList.push(value);
        }
      }
      const updatedPermission = {};
      updatedList.forEach((item) => {
        const [entity, ...permissionValue] = item.split("_");
        const fullPermissionValue = permissionValue.join("_");
        if (!updatedPermission[entity]) {
          updatedPermission[entity] = [];
        }
        updatedPermission[entity].push(fullPermissionValue);
      });

      setPermission(updatedPermission);
      return updatedList;
    });
  };

  function validateCreate() {
    const newErrorMessage = { ...initFormErrors };

    if (!roleAdd) {
      newErrorMessage.role = msg;
    } else if (
      typeof roleAdd !== "string" ||
      !roleAdd.match(/^[^-\s][a-zA-Z0-9_\s-]+$/)
    ) {
      newErrorMessage.role = "Veuillez entrer un nom valide.";
    } else {
      newErrorMessage.role = "";
    }
    if (check.length === 0) {
      newErrorMessage.permissionField = msgPermissions;
    } else {
      newErrorMessage.permissionField = "";
    }

    setErrorMessage(newErrorMessage);

    if (!newErrorMessage.role && !newErrorMessage.permissionField) {
      sendDataCreate();
    }
  }
  return (
    <div
    className="offcanvas offcanvas-end offcanvas-body-border"
    tabIndex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div className="offcanvas-header ">
      <h4 id="offcanvasRightLabel fw-bold">Ajouter un rôle</h4>
      <button
        type="button"
        className="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        onClick={() => {
          setErrorMessage(initFormErrors);
        }}
      ></button>
    </div>
    <div className="offcanvas-body offcanvas-body-scrollable">
          <div>
            <label>Rôle</label>
            <input
              type="text"
              className="form-control inputFiltre my-3"
              placeholder="Ajouter un rôle"
              onChange={(e) => setRoleAdd(e.target.value)}
              value={roleAdd}
            />
            <div className="input-error">
              <span className="text-danger">{errorMessage.message}</span>
            </div>
            <div className="input-error">
              <span className="text-danger">{errorMessage.role}</span>
            </div>
          </div>
          <hr className="w-75 mx-auto"></hr>
          <div>
            <div className="groupFiltre">
              <svg
                className="iconFiltre"
                aria-hidden="true"
                viewBox="0 0 24 24"
              >
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                </g>
              </svg>
              <input
                placeholder="Chercher une permission"
                className="form-control inputFiltre my-3"
                type="search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <label>Permissions</label>
            <div
              className="accordion no-accordion-borders mt-3"
              id="accordionPermissions"
              >
              {Object.keys(permissions).map(
                (entity, index) =>
                  permissions[entity]?.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) && (
                    <div className="accordion-item" key={entity}>
                      <h2
                        className="accordion-header"
                        id={`heading${index}`}
                      >
                        <button
                          className={`accordion-button ${
                            activeAccordion === index ? "" : "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded={
                            activeAccordion === index ? "true" : "false"
                          }
                          aria-controls={`collapse${index}`}
                          onClick={() => setActiveAccordion(index)}
                        >
                          {permissions[entity]?.name}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          activeAccordion === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body row">
                          <div className="m-2 border-start">
                            {permissions[entity]?.items.map(
                              (permission, permissionIndex) => (
                                <div
                                  key={permission.value}
                                  className="permission-item form-check form-switch"
                                >
                                  <input
                                    className="form-check-input me-2"
                                    value={`${entity}_${permission.value}`}
                                    checked={check.includes(
                                      `${entity}_${permission.value}`
                                    )}
                                    type="checkbox"
                                    onChange={onChangeCreate}
                                  />
                                  <label className="form-check-label ms-1">
                                    {permission.name}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
              <div className="input-error m-2">
                <span className="text-danger">
                  {errorMessage.permissionField}
                </span>
              </div>
            </div>
            <div className="row mt-2 d-flex justify-content-center ">
              <button
                type="button"
                className="btn btn-primary col-11"
                onClick={validateCreate}
                disabled={isLoading}
              >
                {isLoading ? <Loader /> : "Enregistrer"}                
              </button>
              <button
                type="button"
                id="annulerButton"
                className="btn btn-outline-secondary col-11  mt-2"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  setErrorMessage(initFormErrors);
                }}
              >
                Annuler
              </button>
            </div>
          </div>
    </div>
  </div>
  );
};
export default CreateRole;
