import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { baseURL } from "../../config/config";
import "./dndProcessMission.css";
import Tooltip from "@mui/material/Tooltip";
import swal from "sweetalert";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { FaRegAddressCard } from "react-icons/fa";
import ConsultantMissionDetails from "./ConsultantMissionDetails";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { Link } from "@mui/material";
import { lightGreen } from "@mui/material/colors";

const DndProcessMission = () => {
  const { user } = useContext(UserContext);
  const { missionId } = useParams();
  const [columns, setColumns] = useState({});
  const [selectedColumn, setSelectedColumn] = useState();
  const [selectedConsultant, setSelectedConsultant] = useState({});
  const [steps, setSteps] = useState();
  const [itemsListAcceptedToUser, setItemsListAcceptedToUser] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [employeetoadd, setEmployeeToAdd] = useState();
  const hasCandidatsConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consult"
  );
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      // if the source and destination droppableIds are different it means the item is moved from one column to another
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      let step;
      let status;

      // determine the step and status based on the destination droppableId using a switch statement
      switch (destination.droppableId) {
        case "Affecter":
          step = "affected";
          status = "validated";
          break;
        case "Rejeter":
          step = "rejected";
          status = "rejected";
          break;
        case "Not affected":
          step = "notAffected";
          status = "waiting";
          break;
        default:
          step = destColumn.index;
          status = "in_progress";
      }
      updateEmployeeStep(removed.consultant._id, step, status);

      // update the columns state with the new source and destination items arrays
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      // update the columns state with the new items array for the same column
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  async function getMissionById() {
    if (missionId === undefined) return;
    axios
      .get(`${baseURL}/missions/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        setSteps(response.data.data.steps);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  function sortItems(data) {
    const itemslistNotAffected = data.filter(
      (item) => item?.currentStepIndex === "notAffected"
    );
    const itemslistAffected = data.filter(
      (item) => item?.currentStepIndex === "affected"
    );
    const initColumn = {
      name: "Non Affectés",
      items: itemslistNotAffected,
    };

    const cols = {
      "Not affected": initColumn,
    };

    steps.forEach((step) => {
      const itemsSorted = data.filter(
        (item) => item.currentStepIndex === step.index.toString()
      );
      cols[step.name] = {
        name: step.name,
        index: step.index,
        items: itemsSorted,
      };
    });

    const itemslistAccepted = itemslistAffected.filter(
      (item) => item.currentStepIndex === "affected"
    );

    setItemsListAcceptedToUser(itemslistAccepted);

    const finalColumn = {
      name: "Affectés",
      items: itemslistAccepted,
    };

    cols["Affecter"] = finalColumn;

    const itemslistRejected = data.filter(
      (item) => item.currentStepIndex === "rejected"
    );

    const rejectColumn = {
      name: "Rejetés",
      items: itemslistRejected,
    };

    cols["Rejeter"] = rejectColumn;
    setColumns(cols);
  }
  function getEmployeeByMissionId() {
    if (missionId === null) return;
    axios
      .get(`${baseURL}/missionsConsultants/getConsultantByMission/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        sortItems(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  async function updateEmployeeStep(employeeId, step) {
    let status = "";
    if (step === "notAffected") {
      status = "waiting";
    } else {
      if (step === "affected") {
        status = "validated";
      } else {
        if (step === "rejected") {
          status = "rejected";
        } else {
          status = "in_progress";
        }
      }
    }
    await axios
      .post(
        `${baseURL}/missionsConsultants/affectConsultantToMissionProcess/${employeeId}`,
        {
          missionId: missionId,
          currentStepIndex: step,
          status: status,
        }
      )
      .then((response) => {
        if (response.data.error) return;
        getEmployeeByMissionId();
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function getDetailsEmployee() {
    if (employeeId === undefined || employeeId === "") return;
    axios.get(`${baseURL}/consultants/${employeeId}`).then((response) => {
      if (response.data.error) return;
      const name =
        response.data.data?.firstame + " " + response?.data?.data?.lastname;
      swal(name, {
        title: "voulez vous Ajouter cette employe en tant que utilisateur? ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((confirmed) => {
        if (confirmed) {
          setEmployeeToAdd(response.data.data);
          setEmployeeId("");
        } else {
          setEmployeeId("");
        }
      });
    });
  }
  async function sendData() {
    const userName = employeetoadd?.firstname + employeetoadd?.lastname;
    await axios
      .post(`${baseURL}/users/create`, {
        name: userName,
        avatar: employeetoadd?.avatarid,
        email: employeetoadd?.email,
        address: employeetoadd?.address,
        phone: employeetoadd?.phone,
        nirpp: employeetoadd?.nirpp.toString(),
        employeeId: employeeId,
      })
      .then((resp) => {
        if (resp.data.error) return;
        setEmployeeId("");

        swal({
          title: "utilisateur ajoute avec succee  ",
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (employeeId === null) {
      return;
    }
    getDetailsEmployee();
  }, [employeeId]);
  useEffect(() => {
    if (employeetoadd === null || employeetoadd === undefined) {
      return;
    }
    sendData();
  }, [employeetoadd]);
  useEffect(() => {
    getMissionById();
  }, []);
  useEffect(() => {
    if (steps === undefined) return;
    getEmployeeByMissionId();
  }, [steps]);
  return (
    <div style={{ display: "flex", height: "100%" }} className="process-list">
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
        {Object.entries(columns).map(([columnId, column]) => {
          return (
            <>
              <div
                className="card card-type-candidat col-3  mb-5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "",
                  borderColor: "",
                  borderRadius: 16,
                  width: "fit-content",
                }}
                key={columnId}>
                <div
                  id="step-draggable-card"
                  className="m-3 step-draggable-card card card-type-candidat"
                  style={{
                    border: "1px solid #eaecf0 ",
                    margin: 8,
                    boxShadow:
                      "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important",

                    borderRadius: "16px",
                  }}>
                  <Droppable droppableId={columnId} key={column}>
                    {(provided, snapshot) => {
                      return (
                        <div>
                          <div
                            style={{
                              fontSize: 18,
                            }}
                            className=" d-flex mb-2 justify-content-around border_column_steps_title fw-bold text-uppercase pt-2 color-msit">
                            <Tooltip title={column.name}>
                              <h5 className="fw-bold">
                                {column.name?.length > 17
                                  ? `${column.name.substring(0, 17)}...`
                                  : column.name}
                              </h5>
                            </Tooltip>
                            {/*upgrade : remove list unaffected and it becoms a list for +button to select not affected person to add */}
                            {/* <button className="btn btn-primary">
                              <svg
                                width="20"
                                height="25"
                                viewBox="0 0 11 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M5.57422 2.54102V7.46021"
                                  stroke="white"
                                  stroke-width="0.771639"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.17926 5.00098H2.9707"
                                  stroke="white"
                                  stroke-width="0.771639"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button> */}
                          </div>
                          <div
                            className="step-draggable-card-c border_column_steps "
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "rgb(236 236 236)"
                                : "white",
                              padding: 4,
                              width: 250,
                              minHeight: 500,
                              overflowY: "scroll",
                              maxHeight: "500px",
                            }}>
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.consultant._id}
                                  draggableId={item.consultant._id}
                                  index={index}
                                  value={item.consultant._id}>
                                  {(provided, snapshot) => {
                                    return (
                                      <Tooltip
                                        title={
                                          itemsListAcceptedToUser.includes(
                                            item
                                          ) ? (
                                            <div
                                              style={{
                                                background:
                                                  snapshot.isDraggingOver
                                                    ? "lightblue"
                                                    : "#f6f7fb",
                                                padding: 0,
                                                display: "flex",
                                                justifycontent: "center",
                                                alignitems: "center",
                                              }}>
                                              <h4>Ajouter comme utilisateur</h4>
                                              <button
                                                onClick={() =>
                                                  setEmployeeId(
                                                    item.consultant._id
                                                  )
                                                }
                                                className=" btn text-primary ">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  className="bi bi-person-add"
                                                  viewBox="0 0 16 16">
                                                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                                  <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                                </svg>
                                              </button>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        }
                                        arrow>
                                        <div
                                          className="d-flex row drag-item mt-3 ms-3 me-3 "
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: "0 0 8px 0",
                                            minHeight: "20px",
                                            boxShadow: `rgba(0, 0, 0, 0.12) 0px 1px 3px , rgba(0, 0, 0, 0.24) 0px 1px 2px`,
                                            backgroundColor: snapshot.isDragging
                                              ? `#cee0f5`
                                              : itemsListAcceptedToUser.includes(
                                                  item
                                                )
                                              ? `#ECFDF3`
                                              : "#EAF4FF",
                                            color: snapshot.isDragging
                                              ? "black"
                                              : `black`,
                                            borderRadius: 8,
                                            fontWeight: "bold",
                                            ...provided.draggableProps.style,
                                          }}>
                                          <div className="col-11 p-0 d-flex align-items-center">
                                            <img
                                              src={
                                                item?.consultant?.avatar !==
                                                null
                                                  ? baseURL +
                                                    item?.consultant?.avatar?.path?.substring(
                                                      1
                                                    )
                                                  : baseURL +
                                                    "/public/company-logo/default_logo.jpg"
                                              }
                                              className="rounded-circle"
                                              width="35"
                                              height="35"
                                              alt=""
                                            />
                                            <div className="d-block">
                                              <p>
                                                {item.consultant.firstname
                                                  ?.length > 10
                                                  ? `${item.consultant.firstname.substring(
                                                      0,
                                                      17
                                                    )}...`
                                                  : item.consultant
                                                      .firstname}{" "}
                                                {item.consultant.lastname
                                                  ?.length > 10
                                                  ? `${item.consultant.lastname.substring(
                                                      0,
                                                      17
                                                    )}...`
                                                  : item.consultant.lastname}
                                              </p>
                                              <p>
                                                {item.consultant.email?.length >
                                                10
                                                  ? `${item.consultant.email.substring(
                                                      0,
                                                      17
                                                    )}...`
                                                  : item.consultant.email}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-1 p-0">
                                            <button
                                              className=" p-0 btn text-primary"
                                              data-bs-toggle="offcanvas"
                                              data-bs-placement="top"
                                              title="Créer note"
                                              data-bs-target="#offcanvasDetailsSteps"
                                              aria-controls="offcanvasDetailsSteps"
                                              onClick={() => {
                                                setSelectedConsultant(item);
                                                setSelectedColumn(column);
                                              }}>
                                              <FaRegAddressCard />
                                            </button>
                                          </div>
                                        </div>
                                      </Tooltip>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            </>
          );
        })}
      </DragDropContext>

      <ConsultantMissionDetails
        consultantDetails={selectedConsultant.consultant}
        stepName={selectedColumn?.name ?? null}
        stepIndex={selectedColumn?.index ?? null}
        consultantId={selectedConsultant.consultant?._id}
        mission={missionId}
        getEmployeeByMissionId={getEmployeeByMissionId}
        setSelectedConsultant={setSelectedConsultant}
        setSelectedColumn={setSelectedColumn}
      />
    </div>
  );
};

export default DndProcessMission;
