import "./App.css";
import React, { useEffect, useMemo, useState } from "react";
import {
  HashRouter,
  Route,
  Redirect,
  Switch,
  useHistory,
  Link,
} from "react-router-dom";
import Home from "./pages/home";
import Header from "./components/header";
import SideBar from "./components/sidebar";
import Footer from "./components/footer";
import Users from "./pages/user/users";
import Role from "./pages/role";
import CreateUser from "./pages/user/createUser";
import UpdateUser from "./pages/user/updateUser";
import Login from "./pages/Login";
import { UserContext } from "./context/UserContext";
import Company from "./pages/company/Company";
import CreateCompany from "./pages/company/CreateCompany";
import CompanyDetails from "./pages/company/CompanyDetails";
import UpdateCompany from "./pages/company/UpdateCompany";
import Candidats from "./pages/candidat/Candidats";
import CreateCandidats from "./pages/candidat/CreateCandidats";
import CondidatsTypes from "./pages/candidat/CondidatsTypes";
import CreateEmployees from "./pages/employee/createEmployees";
import Employees from "./pages/employee/employees";
import CandidatDetails from "./pages/candidat/CandidatDetails";
import UpdateEmployees from "./pages/employee/updateEmployees";
import Supplier from "./pages/supplier/Supplier";
import CreateSupplier from "./pages/supplier/CreateSupplier";
import EmployeesDetails from "./pages/employee/detailsEmployees";
import SupplierDetails from "./pages/supplier/SupplierDetails";
import Process from "./pages/process/Process";
import Missions from "./pages/mission/Missions";
import MissionDetails from "./pages/mission/detailsMission";
import UpdateSupplier from "./components/supplier_component/UpdateSupplier";
import UpdateCandidats from "./pages/candidat/UpdateCandidats";
import Entretien from "./components/Entretien";
import dndProcessMission from "./pages/mission/dndProcessMission";
import AddExperience from "./pages/experience/experience";
import ExpiredLink from "./pages/expiredLink/expiredLink";
import CreateRole from "./pages/CreateRole";
import EditRole from "./pages/EditRole";
import CreateCV from "./pages/candidat/CreateCvPage";
import UnauthorizedPage from "./components/UnauthorizedPage";
import axios from "axios";
import { baseURL } from "./config/config";
import EmplyeeHome from "./pages/emplyeeHome";
import { SpeedDial } from "@mui/material";
import { SpeedDialAction } from "@mui/material";
import { SpeedDialIcon } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AddchartIcon from "@mui/icons-material/Addchart";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CreateInvoice from "./pages/Invoice/CreateInvoice";
import AllInvoices from "./pages/Invoice/AllInvoices";
import Getcategory from "./pages/Invoice/invoiceCategory/Getcategory";
import { InvoiceDetails } from "./components/invoices/InvoiceDetails";
import { UpdateInvoice } from "./components/invoices/UpdateInvoice";
import FileExtraction from "./components/candidatsForm/fileExtraction";
import { PERMISSIONS, hasPermission } from "./components/checkPermissions";
import DisplayError from "./components/ErrorComponants/DisplayError";
import NotAuthorized from "./components/NotAuthorized";
import InterviewPage from "./pages/interviews/interview";
axios.defaults.withCredentials = true;

function App() {
  const [user, setUser] = useState();
  const [actions, setActions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentUrl = window.location.pathname.split("/");
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const history = useHistory();

  function actionButtonSpeedDial(directTo) {
    history.push(directTo);
  }
  function logout() {
    axios
      .post(`${baseURL}/logout`)
      .then((response) => {
        setUser(undefined);
        localStorage.removeItem("user-data");
        history.replace("/login");
      })
      .catch((err) => {
        DisplayError();
      });
  }
  async function getUserPermissions() {
    await axios.get(`${baseURL}/checkAuth`).then((response) => {
      if (response.data.error) return;
      if (response.data.data === null) {
        setIsLoggedIn(true);
      } else {
        setUser(response.data.data);
      }
    });
  }
  async function checkPermissions(user) {
    if (
      hasPermission(user, PERMISSIONS.missions, "create") &&
      hasPermission(user, PERMISSIONS.company, "consult") &&
      hasPermission(user, PERMISSIONS.manager, "consult")
    ) {
      const newArray = actions;
      newArray.push({
        icon: <PlaylistAddIcon />,
        name: "Nouveau Mission",
        onClick: actionButtonSpeedDial,
        directTo: "/missions",
      });
      setActions(newArray);
    }
    if (hasPermission(user, PERMISSIONS.processEntretiens, "create")) {
      const newArray = actions;
      newArray.push({
        icon: <AddchartIcon />,
        name: "Nouveau Processe d'entretien",
        onClick: actionButtonSpeedDial,
        directTo: "/process",
      });
      setActions(newArray);
    }
    if (
      hasPermission(user, PERMISSIONS.candidats, "create") &&
      hasPermission(user, PERMISSIONS.candidatTypes, "consulter_enabled")
    ) {
      const newArray = actions;
      newArray.push({
        icon: <GroupAddIcon />,
        name: "Nouveau Candidat",
        onClick: actionButtonSpeedDial,
        directTo: "/Ajouter_candidats",
      });
      setActions(newArray);
    }
    if (hasPermission(user, PERMISSIONS.employees, "create")) {
      const newArray = actions;
      newArray.push({
        icon: <GroupAddIcon />,
        name: "Nouveau Employe",
        onClick: actionButtonSpeedDial,
        directTo: "/employees/create",
      });
      setActions(newArray);
    }
    if (hasPermission(user, PERMISSIONS.company, "create")) {
      const newArray = actions;
      newArray.push({
        icon: <DomainAddIcon />,
        name: "Nouveau Societe",
        onClick: actionButtonSpeedDial,
        directTo: "/company/create",
      });
      setActions(newArray);
    }
    if (
      hasPermission(user, PERMISSIONS.users, "create") &&
      hasPermission(user, PERMISSIONS.roles, "consult")
    ) {
      const newArray = actions;
      newArray.push({
        icon: <PersonAddAlt1Icon />,
        name: "Nouveau utilisateur",
        onClick: actionButtonSpeedDial,
        directTo: "/users/create",
      });
      setActions(newArray);
    }
  }
  useEffect(() => {
    getUserPermissions();
  }, []);
  useEffect(() => {
    if (user !== undefined) {
      setActions([]);
      checkPermissions(user);
    }
  }, [user]);
  return (
    <>
      {currentUrl[1] === "experience" && currentUrl[2] === "link" ? (
        <HashRouter>
          <Switch>
            <Route
              exact
              path="/experience/link/:linkId"
              component={AddExperience}></Route>
            <Route exact path="/expired" component={ExpiredLink}></Route>
          </Switch>
        </HashRouter>
      ) : (
        <UserContext.Provider value={value}>
          <div className="overflow-hidden">
            <HashRouter>
              <Switch>
                <React.Fragment>
                  <Route exact path="/login" component={Login}></Route>

                  {value.user == null ? (
                    isLoggedIn ? (
                      <Redirect to="/login" />
                    ) : (
                      ""
                    )
                  ) : (
                    <div>
                      <SideBar />
                      <Header />
                      <main id="main" className="main">
                        <Route exact path="/" component={Home}></Route>
                        <Route exact path="/users" component={Users}></Route>
                        <Route exact path="/roles" component={Role}></Route>
                        <Route
                          exact
                          path="/roles/create"
                          component={CreateRole}></Route>
                        <Route
                          exact
                          path="/roles/update/:roleId"
                          component={EditRole}></Route>
                        <Route
                          exact
                          path="/types-candidats"
                          component={CondidatsTypes}></Route>
                        <Route
                          exact
                          path="/candidats"
                          component={Candidats}></Route>
                        <Route
                          exact
                          path="/candidats/extraction"
                          component={FileExtraction}></Route>
                        <Route
                          exact
                          path="/candidats/edit/:candidatId"
                          component={UpdateCandidats}></Route>
                        <Route
                          exact
                          path="/candidats/createCv/:consultantId"
                          component={CreateCV}></Route>
                        <Route
                          exact
                          path="/candidats/details/:candidatId"
                          component={CandidatDetails}></Route>
                        <Route
                          exact
                          path="/Ajouter_candidats"
                          component={CreateCandidats}></Route>
                        <Route
                          exact
                          path="/users/create"
                          component={CreateUser}></Route>
                        <Route
                          exact
                          path="/users/edit/:userId"
                          component={UpdateUser}></Route>
                        <Route
                          exact
                          path="/company/create"
                          component={CreateCompany}></Route>
                        <Route
                          exact
                          path="/Company"
                          component={Company}></Route>
                        <Route
                          exact
                          path="/Company/details/:companyId"
                          component={CompanyDetails}></Route>
                        <Route
                          exact
                          path="/Company/update/:companyId"
                          component={UpdateCompany}></Route>
                        <Route
                          exact
                          path="/employees/create"
                          component={CreateEmployees}></Route>
                        <Route
                          exact
                          path="/employees"
                          component={Employees}></Route>
                        <Route
                          exact
                          path="/employees/update/:employeeId"
                          component={UpdateEmployees}></Route>
                        <Route
                          exact
                          path="/supplier"
                          component={Supplier}></Route>
                        <Route
                          exact
                          path="/supplier/create"
                          component={CreateSupplier}></Route>
                        <Route
                          exact
                          path="/supplier/edit/:supplierId"
                          component={UpdateSupplier}></Route>
                        <Route
                          exact
                          path="/suppliers/details/:supplierId"
                          component={SupplierDetails}></Route>
                        <Route
                          exact
                          path="/employees/details/:employeeId"
                          component={EmployeesDetails}></Route>
                        <Route
                          exact
                          path="/employees/Home/"
                          component={EmplyeeHome}></Route>
                        <Route
                          exact
                          path="/process"
                          component={Process}></Route>
                        <Route
                          exact
                          path="/entretien/:processId"
                          component={Entretien}></Route>
                        <Route
                          exact
                          path="/missions"
                          component={Missions}></Route>
                        <Route
                          exact
                          path="/missions/details/:missionId"
                          component={MissionDetails}></Route>
                        <Route
                          exact
                          path="/missions/process/:missionId"
                          component={dndProcessMission}></Route>
                        <Route
                          exact
                          path="/error_page"
                          component={UnauthorizedPage}></Route>
                        <Route
                          exact
                          path="/CreateInvoice"
                          component={CreateInvoice}></Route>
                        <Route
                          exact
                          path="/CreateInvoice/:employeeId"
                          component={CreateInvoice}></Route>
                        <Route
                          exact
                          path="/allinvoices"
                          component={AllInvoices}></Route>{" "}
                        <Route
                          exact
                          path="/allCategories"
                          component={Getcategory}></Route>
                        <Route
                          exact
                          path="/updateInvoice/:invoiceId"
                          component={UpdateInvoice}></Route>
                        <Route
                          exact
                          path="/allinvoices/:invoiceId"
                          component={InvoiceDetails}></Route>
                        <Route
                          exact
                          path="/interviews"
                          component={InterviewPage}></Route>
                        {/* <Route exact path="*" component={NotAuthorized}></Route> */}
                      </main>
                      {actions.length > 0 ? (
                        <SpeedDial
                          ariaLabel="SpeedDial"
                          sx={{ position: "absolute", bottom: 16, right: 16 }}
                          icon={<SpeedDialIcon />}
                          className="speeddial">
                          {actions.map((action) => (
                            <SpeedDialAction
                              key={action.name}
                              icon={action.icon}
                              tooltipTitle={action.name}
                              component={Link}
                              to={action.directTo}
                            />
                          ))}
                        </SpeedDial>
                      ) : (
                        ""
                      )}
                      <Footer />
                    </div>
                  )}
                </React.Fragment>
              </Switch>
            </HashRouter>
          </div>
        </UserContext.Provider>
      )}
    </>
  );
}
export default App;
