import React, { useContext, useState } from "react";
import EmployeesFormStep2 from "./EmployeesFormStep2";
import { EmployeeContext } from "../../pages/employee/createEmployees";
import EmployeesFormStep4 from "./EmployeesFormStep4";
import Select from "react-select";

const initFormErrors = {
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  skills: [""],
  experience: [""],
  cv: [""],
};

const EmployeesFormStep3 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newEmployee, setNewEmployee } = useContext(EmployeeContext);

  function validate() {
    let error = false;
    error = false;
    setErrorMessage(initFormErrors);
    if (newEmployee.experience === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        experience: msg,
      }));
    }
    if (newEmployee.skills === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        skills: msg,
      }));
    }
    if (newEmployee.cv === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cv: msg,
      }));
    }
    if (newEmployee.cvname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cvname: msg,
      }));
    }
    if (error === false) {
      setChildComponent(
        <EmployeesFormStep4 setChildComponent={setChildComponent} />
      );
    }
  }
  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  const [pdfurl, setpdfurl] = React.useState();
  const fileinputref = React.useRef(null);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setpdfurl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Expérience *</label>
                <input
                  type="number"
                  value={newEmployee.experience}
                  min="0"
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      experience: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.experience}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>
                  Compétences *
                  <span className="textSkills">separation par ( ; )</span>
                </label>
                <input
                  type="text"
                  value={newEmployee.skills}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      skills: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.skills}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>langues </label>
                <Select
                  options={languesOptions}
                  isMulti
                  value={languesOptions.filter((option) =>
                    newEmployee.langues.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      langues: selectedValues,
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.langues}</span>
                </div>
              </div>

              <div className="col-md-6 pb-3">
                <label>Dernier diplome obtenue </label>
                <input
                  type="text"
                  value={newEmployee.diplome}
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      diplome: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.diplome}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Année de diplome </label>
                <input
                  type="number"
                  value={newEmployee.diplomeDate}
                  min="0"
                  onChange={(e) => {
                    setNewEmployee((prevState) => ({
                      ...prevState,
                      diplomeDate: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.diplomeDate}
                  </span>
                </div>
              </div>

              <div className="col-md-6 ">
                <label>Upload CV *</label>
                <div className="custom-file">
                  <input
                    className="form-control"
                    accept=".pdf, .docx"
                    type="file"
                    ref={fileinputref}
                    required
                    onChange={(e) => {
                      handleImageUpload(e);
                      setNewEmployee((prevState) => ({
                        ...prevState,
                        cv: e.target.files[0],
                      }));
                    }}></input>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.cv}</span>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <label>Nom CV *</label>
                <div className="custom-file">
                  <input
                    className="form-control"
                    type="text"
                    value={newEmployee.cvname}
                    onChange={(e) => {
                      setNewEmployee((prevState) => ({
                        ...prevState,
                        cvname: e.target.value,
                      }));
                    }}
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.cvname}</span>
                  </div>
                </div>
                {newEmployee.cv ? (
                  <iframe
                    src={pdfurl}
                    title="CV Document"
                    width="200%"
                    height="400px"
                    style={{ border: "none" }}></iframe>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <EmployeesFormStep2 setChildComponent={setChildComponent} />
                  );
                }}>
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}>
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployeesFormStep3;
