import React, { useContext, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import { useEffect } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import DisplayError from "../ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
export default function AffectEmployeeModal({
  employees,
  mission,
  getEmployeeByMissionId,
}) {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const hasWorkDaysConsultPermission = hasPermission(
    user,
    PERMISSIONS.workdays,
    "consult"
  );

  const initFormErrors = {
    cv: [""],
    employee: [""],
    cvname: [""],
  };
  const errors = {
    birthNull: "Date de naissance indisponible",
    noDataFound: "Aucune donnée trouvée",
  };
  const [file, setFile] = useState();
  const [cvname, setCvname] = useState();
  const [upload, setUpload] = useState();
  const [select, setSelect] = useState("active");
  const [childComponent, setChildComponent] = useState();
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [employee, setEmployee] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [cv, setCv] = useState();
  const [allcvs, setAllCvs] = useState();
  const msg = "Ce Champs est Obligatoire";
  const msgerrorcv =
    "Veuillez sélectionner ou télécharger un CV, ce champ est obligatoire. ";
  async function affectEmployeeToMission() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("mission", mission.id);
    formData.append("consultant", employee);
    if (!cv && file) {
      formData.append("cv", file);
      formData.append("cvname", cvname);
    } else {
      formData.append("cv", cv);
    }

    await axios
      .post(
        `${baseURL}/missionsConsultants/affectConsultantToMission`,
        formData
      )
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        }).then(async (value) => {
          getEmployeeByMissionId();
          setSelectedEmployee(null);
          setAllCvs([]);
          setSelectedEmployeeId("empty");
          setEmployee(null);
          setCv("");
          setUpload("");
          const boutonOffcanvas = document.getElementById(
            "closeOffcanvasAffectEmploee"
          );
          boutonOffcanvas.click();
        });
      })
      .catch((error) => {
        DisplayError(error.response.data.message);
        setIsLoading(false);
      });
  }
  async function getEmployee(ConsultantId) {
    axios
      .get(`${baseURL}/consultants/${ConsultantId}`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setSelectedEmployee(respData);
      })
      .catch(() => {
        DisplayError();
      });
  }
  async function getCvs(consultantId) {
    await axios
      .get(`${baseURL}/cv/allCvs/${consultantId}`)
      .then((response) => {
        if (response.data.error) return;
        setAllCvs(response.data.data);
        setCv(response.data.data[0]?.id ?? "");
      })
      .catch(() => {
        DisplayError();
      });
  }
  function validate() {
    let error = false;
    if (!employee) {
      error = true;
      setFormErrors((prevState) => ({
        ...prevState,
        employee: msg,
      }));
    }
    if (!cv && !file) {
      error = true;
      setFormErrors((prevState) => ({
        ...prevState,
        cv: msgerrorcv,
      }));
    }
    if (!cvname && file) {
      error = true;
      setFormErrors((prevState) => ({
        ...prevState,
        cv: "Le nom du CV est requis lorsque vous téléchargez un nouveau CV.",
      }));
    }
    if (!error) {
      affectEmployeeToMission();
    }
  }
  useEffect(() => {
    setChildComponent(
      <div className="mb-4 row px-2">
        <select
          value={cv}
          id="inputEmployee"
          onChange={(e) => {
            setCv(e.target.value);
            setFormErrors((prevState) => ({
              ...prevState,
              cv: "",
            }));
          }}
          className="form-select offcanvas-input">
          <option value={""} disabled selected>
            Sélectionner un CV
          </option>
          {allcvs != null &&
            allcvs.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.cvname ? item.cvname : "CV sans nom"}
                </option>
              );
            })}
        </select>
      </div>
    );
  }, []);

  useEffect(() => {
    if (allcvs) {
      setChildComponent(<Loader />);
      setChildComponent(
        <div className="mb-4 row px-2">
          <select
            value={cv}
            id="inputEmployee"
            onChange={(e) => {
              setCv(e.target.value);
              setFormErrors((prevState) => ({
                ...prevState,
                cv: "",
              }));
            }}
            className="form-select offcanvas-input">
            <option value={""} disabled selected>
              Sélectionner un CV
            </option>
            {allcvs != null &&
              allcvs.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.cvname ? item.cvname : "CV sans nom"}
                  </option>
                );
              })}
          </select>
        </div>
      );
    }
  }, [allcvs]);
  return (
    <div>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border pb-3"
        tabIndex="-1"
        id="offcanvasAffectEmployee"
        aria-labelledby="offcanvasRightLabel"
        aria-modal="true"
        role="dialog"
        style={{ visibility: "visible", width: "30rem" }}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Affecter un employee</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAffectEmployee"
            aria-controls="offcanvasAffectEmployee"></button>
        </div>
        <hr className="light-hr  " />
        <div className="offcanvas-body pt-0 p-4">
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div>
              <label>Mission :</label>
              <h3 className="col-md-10 mb-3">
                <Link
                  className="color-msit "
                  to={`/missions/details/${mission.id}`}>
                  {mission.title}
                </Link>
              </h3>
            </div>
            <div className="mb-4 row px-2">
              <label className="px-0 mb-2 text-500">
                selectioner un employee :
              </label>
              <select
                value={selectedEmployeeId}
                id="inputEmployee"
                onChange={(e) => {
                  setEmployee(e.target.value);
                  getEmployee(e.target.value);
                  setSelectedEmployeeId(e.target.value);
                  getCvs(e.target.value);
                  setCv("");
                  setSelect("active");
                  setUpload("");
                  setFormErrors((prevState) => ({
                    ...prevState,
                    employee: "",
                  }));
                  if (hasCvConsultPermission) {
                    getCvs(e.target.value);
                  }
                }}
                className="form-select offcanvas-input">
                <option value={"empty"} disabled selected>
                  Sélectionner un employé
                </option>
                {employees != null &&
                  employees
                    .sort(function (a, b) {
                      if (
                        a?.firstname?.toLowerCase() <
                        b?.firstname?.toLowerCase()
                      ) {
                        return -1;
                      }
                      if (
                        a?.firstname?.toLowerCase() >
                        b?.firstname?.toLowerCase()
                      ) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item, index) => {
                      if (item.isEnabled === "yes") {
                        return (
                          <option key={index} value={item._id}>
                            {item.firstname} {item.lastname}
                          </option>
                        );
                      }
                    })}
              </select>
            </div>

            <ul className="mt-4 nav nav-tabs nav-tabs-bordered mb-4">
              <li className="nav-item">
                <button
                  className={`nav-link ${select}`}
                  data-bs-toggle="tab"
                  onClick={() => {
                    setSelect("active");
                    setUpload("");
                    setCvname("");
                    setFile("");
                    setChildComponent(
                      <div className="mb-4 row px-2">
                        <select
                          value={cv}
                          id="inputEmployee"
                          onChange={(e) => {
                            setCv(e.target.value);
                            setFormErrors((prevState) => ({
                              ...prevState,
                              cv: "",
                            }));
                          }}
                          className="form-select offcanvas-input">
                          <option value={""} disabled selected>
                            Sélectionner un CV
                          </option>
                          {allcvs != null &&
                            allcvs.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.cvname ? item.cvname : "CV sans nom"}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    );
                  }}>
                  Choisir un CV
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${upload}`}
                  data-bs-toggle="tab"
                  onClick={() => {
                    setSelect("");
                    setFile("");
                    setCv("");
                    setUpload("active");
                    setChildComponent(
                      <>
                        <div className="mb-4 row px-2">
                          <label className="px-0 mb-2 text-500">CV *</label>
                          <div className="custom-file">
                            <input
                              className="form-control offcanvas-input"
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                              type="file"
                              accept=".pdf, .docx"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-4 row px-2">
                          <label className="px-0 mb-2 text-500">Nom CV *</label>
                          <div className="custom-file">
                            <input
                              type="text"
                              className="form-control offcanvas-input"
                              placeholder="Nom"
                              onChange={(e) => {
                                setCvname(e.target.value);
                                setFormErrors((prevState) => ({
                                  ...prevState,
                                  cvname: "",
                                }));
                              }}
                              required
                            />
                          </div>
                        </div>
                      </>
                    );
                  }}>
                  télécharger un CV
                </button>
              </li>
            </ul>
            <div className="input-error">
              <span className="text-danger"> {formerrors.cv}</span>
            </div>
            {childComponent}
            {selectedEmployee ? (
              <div className="col-12 col-sm-10">
                <div className="shadow bg-white card-rounded card mb-0 ">
                  <div className="row ">
                    <div className="col-12 col-md-4 text-center justify-content-center align-items-center">
                      <img
                        src={`${baseURL}/${selectedEmployee?.avatar?.path}`}
                        height="70%"
                        width="90%"
                        alt="Avatar"
                        className="rounded-circle p-2"
                      />
                    </div>

                    <div className="col-12 col-md-8 ps-4 pt-2">
                      <div className="d-flex justify-content-between px-3 px-md-4">
                        <div>
                          <h5 className="">
                            {selectedEmployee.fname} {selectedEmployee.lname}
                          </h5>
                        </div>
                      </div>
                      <hr className="light-hr limitwidth " />

                      <div className="d-flex justify-content-between px-3 px-md-4 div2">
                        <div className="">
                          <p className="grey">
                            <i className="bi-envelope-fill me-1" />{" "}
                            {selectedEmployee.email}
                          </p>
                          <p className="grey ">
                            <i className="bi-telephone-fill me-1" />{" "}
                            {selectedEmployee.phone || errors.noDataFound}
                          </p>
                          <p className="grey ">
                            <i className="bi bi-geo-alt  me-1" />{" "}
                            {selectedEmployee != null
                              ? selectedEmployee.type === "employee"
                                ? "Employé"
                                : "Fournisseur"
                              : ""}
                          </p>
                          <p className="grey ">
                            <i className=" bi bi-briefcase me-1" />{" "}
                            {selectedEmployee.job} {selectedEmployee.experience}{" "}
                            ans
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row mt-4">
              <div className=" row justify-content-center  ">
                <div className="col-4 d-flex justify-content-center "></div>
              </div>
            </div>
          </div>

          <div className="row px-2 pt-2 pb-1">
            <button
              type="button"
              className="btn btn-add"
              onClick={validate}
              disabled={isLoading}>
              {isLoading ? <Loader /> : "Enregistrer"}
            </button>
          </div>
          <div className="row px-2 pt-1 pb-2">
            <button
              type="button"
              id="closeOffcanvasAffectEmploee"
              className="btn but-restore"
              data-bs-dismiss="modal"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasAffectMission"
              aria-controls="offcanvasAffectMission">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
