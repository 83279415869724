import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import Loader from "../../components/loader";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import { baseURL, csvPath } from "../../config/config";
import CreateNote from "../../components/candidat/candidatNote";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";
import Dropdown from "react-bootstrap/Dropdown";
import Addcandidat from "../../components/candidat/Addcandidat";
import UpdateCandidat from "../../components/candidat/updateStepsCandidats/Updatecandidat";
import ImportCandidatFromCSV from "../../components/candidat/importCandidatFromCSV";
import ImportCandidatCV from "../../components/candidat/importCandidatCV";
import CadidatToEmplyeeOffCanvas from "../../components/consultant/CadidatToEmplyeeOffCanvas";
import ImportFromCv from "../../components/candidat/importFromCv";
import Badge from "@mui/material/Badge";
import { Tooltip } from "@mui/material";

const Candidats = () => {
  const [employees, setEmployees] = useState([]);
  const [loadedEmployees, setLoadedEmployees] = useState([]);
  const [notificationfNumber, setNotificationfNumber] = useState();
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [posteFilter, setPosteFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("all");
  const [skillFilter, setSkillFilter] = useState("");
  const [data, setData] = useState();
  const [importConsultantCV, setimportConsultantCV] = useState();
  const [addCandidatModal, setaddCandidatModal] = useState();
  const noDataFound = "Aucune donnée trouvée";
  const { user } = useContext(UserContext);
  const [filter, setFilter] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [importCandidatFromCSVModal, setImportCandidatFromCSVModal] =
    useState(false);
  const [importCandidatFromCVModal, setImportCandidatFromCVModal] =
    useState(false);
  const hasCandidatsEditPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "edit"
  );
  const hasCandidatsCreatePermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "create"
  );
  const hasCandidatsConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consult"
  );
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consulter_enabled"
  );
  const hasCandidatsStatusPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "change_status"
  );
  const hasCandidatsNotePermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "create_note"
  );
  const hasCandidatsNoteConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consulter_note"
  );
  const hasCandidatsLockPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "lock"
  );
  const hasEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consult"
  );
  const hasEmployeesCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create"
  );
  const hasCvCreatePermission = hasPermission(user, PERMISSIONS.cv, "create");
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const hasMissionConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasExperienceCandidatPermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "consult"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasEmployeeCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create_from_candidat"
  );
  const typeNAmes = {
    supplier: "Partenaire",
    employee: "Salarié",
    candidat: "Candidat",
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (hasEmployeesConsultPermission) {
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasEmployeesConsultPermission]);
  async function getAllEmployees() {
    await axios
      .get(`${baseURL}/consultants/all`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setEmployees(respData);
        setLoadedEmployees(respData.slice(0, loadedEmployees.length + 6));
        setNotificationfNumber(
          respData.filter((item) => item?.type && item?.type === "draft").length
        );
      })
      .catch(() => {
        DisplayError();
      });
  }
  async function getDetailsEmployee(employeeId) {
    if (employeeId === undefined) return;
    await axios
      .get(`${baseURL}/consultants/${employeeId}`)
      .then((response) => {
        if (response.data.error) return;
        sendDataToCreateUser(response.data.data, employeeId);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function sendDataToCreateUser(employeetoadd, employeeId) {
    const userName = employeetoadd.fname + " " + employeetoadd.lname;
    await axios
      .post(`${baseURL}/users/create`, {
        name: userName,
        avatar: employeetoadd.avatarid,
        email: employeetoadd.email,
        address: employeetoadd.address,
        phone: employeetoadd.phone,
        nirpp: employeetoadd?.nirpp?.toString() ?? "",
        employeeId: employeeId,
      })
      .then((resp) => {
        if (resp.data.error) return;
        getAllEmployees();
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.user) {
          swal(error?.response?.data?.user?.email, {
            title:
              "Un Utilisateur avec cet email deja existe voulez vous lier cet employé avec ce compte ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then((confirmed) => {
              if (confirmed) {
                axios
                  .post(`${baseURL}/users/LinkEmployeeUser`, {
                    userId: error?.response?.data?.user?._id,
                    employeeId: employeeId,
                  })
                  .then((resp) => {
                    getAllEmployees();
                    swal({
                      title: "Employé lié avec succès",
                      icon: "success",
                      timer: 2500,
                    });
                  })
                  .catch((error) => {
                    DisplayError();
                  });
              }
            })
            .catch((error) => {
              DisplayError();
            });
        }
      });
  }
  async function downloadCsvTemplate() {
    fetch(csvPath, {
      method: "GET",
      headers: {
        "Content-Type": "file/csv",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Consultants.csv`);
        link.click();
      });
  }
  useEffect(() => {
    if (hasCandidatsConsultPermission) {
      setData(<Loader />);
      getAllEmployees();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasCandidatsConsultPermission]);

  useEffect(() => {
    if (!hasCandidatsConsultPermission) {
      return;
    }
    const filteredData = applyFilters(employees, [
      (data) => filterCandidatsByName(data, nameFilter),
      (data) => filterCandidatsBySkill(data, skillFilter),
      (data) => filterCandidatsByPost(data, posteFilter),
      (data) => filterCandidatsByEmail(data, emailFilter),
      (data) => filterCandidatsByPhone(data, phoneFilter),
      (data) => filterCandidatsByType(data, typeFilter),
    ]);
    if (
      typeFilter ||
      nameFilter ||
      skillFilter ||
      posteFilter ||
      emailFilter ||
      phoneFilter
    ) {
      if (filteredData.length > 0) {
        setData(
          <DataTable
            className="dataTableClass"
            columns={columns}
            data={filteredData}
            pagination
            expandableRows={isMobile <= 1280}
            expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={{ noRowsPerPage: true }}
          />
        );
      } else {
        setData(<EmptyData />);
      }
    } else {
      setData(
        <DataTable
          className="dataTableClass"
          columns={columns}
          data={employees}
          pagination
          expandableRows={isMobile <= 1280}
          expandableRowsComponent={ExpandedComponent}
          paginationComponentOptions={{ noRowsPerPage: true }}
        />
      );
    }
  }, [
    hasCandidatsConsultPermission,
    phoneFilter,
    nameFilter,
    skillFilter,
    posteFilter,
    emailFilter,
    employees,
    typeFilter,
  ]);
  const [selectedForUpdate, setSelectedForUpdate] = useState("");
  function handleUpdateButtonClick(candidatId) {
    setSelectedForUpdate(candidatId);
  }
  async function deletedConsultants(candidatId, toDelete) {
    let message =
      toDelete === "no"
        ? "voulez vous supprimer ce consultant?"
        : "Voulez vous annuler la suppression ce consultant?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/consultants/toggleToDelete/${candidatId}`)
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllEmployees();
            }
          });
      }
    });
  }
  async function lockUnlock(candidatId, isLocked) {
    let message =
      isLocked === "yes"
        ? "voulez vous déverrouiller ce consultant?"
        : "Voulez vous verrouiller ce consultant?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/consultants/toggleLock/${candidatId}`)
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllEmployees();
            }
          });
      }
    });
  }
  async function enableDisable(candidatId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous désactiver ce consultant?"
        : "Voulez vous activer ce consultant?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/consultants/toggleStatus/${candidatId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllEmployees();
            }
          });
      }
    });
  }
  async function employeetouser(employeeId) {
    swal({
      title: "voulez vous definire ce employé comme un utilisateur ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmed) => {
        if (confirmed) {
          getDetailsEmployee(employeeId);
        }
      })
      .catch((error) => {
        DisplayError();
      });
  }
  const columns = [
    {
      name: "Consultant",
      cell: (row) => (
        <div className="row w-100 py-2">
          <div className="col-12 col-sm-3">
            <Link
              to={`/candidats/details/${row?._id}
                `}
              className="">
              <img
                src={baseURL + row?.avatar?.path?.substring(1)}
                className="rounded-circle"
                width="35"
                height="35"
                alt=""
              />
            </Link>
          </div>
          <div className="col-12 col-sm-9">
            <Link to={`/candidats/details/${row?._id}`} className="">
              <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
                {row?.firstname + " " + row?.lastname}
              </div>
            </Link>
            <div className="">
              {row?.email !== null &&
              row?.email !== undefined &&
              row?.email !== "" ? (
                <Tooltip title={row?.email ?? "noDataFound"}>
                  <a
                    href={`mailto:${row?.email}`}
                    className="text-400"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Envoyer mail">
                    {row?.email?.length > 15
                      ? `${row?.email.slice(0, 15)}...`
                      : row?.email}
                  </a>
                </Tooltip>
              ) : (
                <div>
                  <span className="text-400"> {noDataFound}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      ),
      center: true,
      id: "name",
    },
    {
      name: "Téléphone",
      selector: (row) => (
        <div>
          {row?.phone ? (
            <a
              href={`tel:+${row?.phone}`}
              className="text-400"
              data-toggle="tooltip"
              data-placement="top"
              title="Appeler">
              {row?.phone}
            </a>
          ) : (
            <span className="text-400">{noDataFound}</span>
          )}
        </div>
      ),
      hide: "md",
      center: true,
      id: "phone",
    },
    {
      name: "Type",
      selector: (row) => (
        <div>
          <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
            {typeNAmes[row?.type]}
          </div>
        </div>
      ),
      hide: "md",
      center: true,
      id: "Type",
    },
    {
      name: "Experiences",
      selector: (row) => (
        <div>
          <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
            {row?.posteType?.name}
          </div>
          <div className="text-400">{row?.experience} ans d'éxpériences</div>
        </div>
      ),
      center: true,
      id: "Poste",
      hide: "lg",
    },

    {
      name: "Compétences",
      selector: (row) => (
        <div>
          {row?.skills?.length > 15
            ? `${row?.skills.substring(0, 15)}...`
            : row?.skills}
          ,
        </div>
      ),
      sortable: true,
      center: true,
      id: "competences",
      hide: "lg",
      style: {
        color: "#475467",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
        FontFamily: "Inter",
      },
    },
    {
      name: "CV",
      selector: (row) => (
        <div>
          <button
            className="btn text-primary"
            onClick={() => {
              setimportConsultantCV(
                <ImportCandidatCV
                  hideModal={() => setimportConsultantCV("")}
                  consultantId={row?._id}
                />
              );
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                stroke="#344054"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      ),
      center: true,
      id: "address",
      center: true,
      hide: "lg",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasCandidatsStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisable(row?._id, row?.isEnabled);
                      }}
                      checked={row?.isEnabled === "yes"}
                    />
                    Activer le Profil
                  </label>
                </Dropdown.Item>
              )}
              {hasCandidatsLockPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        lockUnlock(row?._id, row?.isLocked);
                      }}
                      checked={row?.isLocked === "yes"}
                    />
                    verrouiller le Profil
                  </label>
                </Dropdown.Item>
              )}
              {/* {hasCandidatsLockPermission && ( */}
              <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                <label className="form-check form-check-single form-switch">
                  <input
                    className="form-check-input"
                    data-placement="top"
                    type="checkbox"
                    onChange={() => {
                      deletedConsultants(row?._id, row?.toDelete);
                    }}
                    checked={row?.toDelete === "yes"}
                  />
                  Supprimer le Profil
                </label>
              </Dropdown.Item>
              {/* )} */}
              <Dropdown.Divider />
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                href={`#/candidats/details/${row?._id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2"> Consulter le profil </span>
              </Dropdown.Item>
              {hasCandidatTypesConsultPermission &&
                hasCandidatsEditPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasUpdateConsultant"
                    aria-controls="offcanvasUpdateConsultant"
                    onClick={() => {
                      handleUpdateButtonClick(row?._id);
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier les informations </span>
                  </Dropdown.Item>
                )}
              {isMobile <= 1280
                ? hasCandidatsNotePermission &&
                  hasCandidatsNoteConsultPermission && (
                    <Dropdown.Item
                      className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                      onClick={() => {
                        setimportConsultantCV(
                          <ImportCandidatCV
                            hideModal={() => setimportConsultantCV("")}
                            consultantId={row?._id}
                          />
                        );
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none">
                        <path
                          d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="ms-2"> Ajouter un CV</span>
                    </Dropdown.Item>
                  )
                : ""}
              {hasCandidatsNotePermission &&
                hasCandidatsNoteConsultPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#addNoteCandidat"
                    aria-controls="addNoteCandidat"
                    onClick={() => handleUpdateButtonClick(row?._id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M9.33268 1.51367V4.26737C9.33268 4.64073 9.33268 4.82742 9.40534 4.97003C9.46926 5.09547 9.57125 5.19745 9.69669 5.26137C9.8393 5.33403 10.026 5.33403 10.3993 5.33403H13.153M7.99935 12.0007V8.00065M5.99935 10.0007H9.99935M9.33268 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H10.1327C11.2528 14.6673 11.8128 14.6673 12.2407 14.4493C12.617 14.2576 12.9229 13.9516 13.1147 13.5753C13.3327 13.1475 13.3327 12.5874 13.3327 11.4673V5.33398L9.33268 1.33398Z"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="ms-2"> Ajouter une note</span>
                  </Dropdown.Item>
                )}
              {hasCompanyConsultPermission && hasEmployeeCreatePermission && (
                //--------------
                // avoir creation par type design
                //--------------
                <Dropdown.Item
                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                  data-bs-toggle={row?.type === "candidat" ? `offcanvas` : ""}
                  data-bs-target={
                    row?.type === "candidat" ? `#TransformToEmployee` : ""
                  }
                  aria-controls={
                    row?.type === "candidat" ? `TransformToEmployee` : ""
                  }
                  onClick={() => {
                    row?.type === "candidat"
                      ? setSelectedForUpdate(row?._id)
                      : employeetouser(row?._id);
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M10.6673 12L12.0007 13.3333L14.6673 10.6667M8.00065 10H5.33398C4.09147 10 3.47022 10 2.98016 10.203C2.32675 10.4736 1.80762 10.9928 1.53697 11.6462C1.33398 12.1362 1.33398 12.7575 1.33398 14M10.334 2.19384C11.3113 2.58943 12.0007 3.54754 12.0007 4.66667C12.0007 5.78579 11.3113 6.7439 10.334 7.13949M9.00065 4.66667C9.00065 6.13943 7.80674 7.33333 6.33398 7.33333C4.86123 7.33333 3.66732 6.13943 3.66732 4.66667C3.66732 3.19391 4.86123 2 6.33398 2C7.80674 2 9.00065 3.19391 9.00065 4.66667Z"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ms-2">
                    Transformer en{" "}
                    {row?.type === "candidat" ? "salarié" : "utilisateur"}
                  </span>
                </Dropdown.Item>
              )}
              {hasCvCreatePermission &&
                hasMissionConsultPermission &&
                hasExperienceCandidatPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    href={`#/candidats/createCv/${row?._id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M13.3327 8.33398V4.53398C13.3327 3.41388 13.3327 2.85383 13.1147 2.426C12.9229 2.04968 12.617 1.74372 12.2407 1.55197C11.8128 1.33398 11.2528 1.33398 10.1327 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H7.99935M9.33268 7.33398H5.33268M6.66602 10.0007H5.33268M10.666 4.66732H5.33268M9.66602 12.6673L10.9993 14.0007L13.9993 11.0007"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="ms-2"> Créer un cv</span>
                  </Dropdown.Item>
                )}
              {/* <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Supprimer
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
      id: "actions",
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Téléphone :</div>

            <div className="phone text-nowrap">
              <a
                href={`tel:+${data.phone}`}
                className="text-dark "
                data-placement="top"
                title="Appeler">
                {data.phone || noDataFound}
              </a>
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Type :</div>
            {typeNAmes[data?.type] || noDataFound}
          </div>
        </div>
      ) : (
        ""
      )}

      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">éxpériences :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.posteType?.name}
            </div>
            <div className="text-400">{data?.experience} ans d'éxpériences</div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Compétences :</div>
            {data?.skills}
          </div>
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );
  function applyFilters(data, filters) {
    let filteredData = data;

    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (
      nameFilter !== "" ||
      emailFilter !== "" ||
      skillFilter !== "" ||
      posteFilter !== "" ||
      phoneFilter !== ""
    ) {
      const currentFilters = {};
      if (nameFilter !== "") {
        currentFilters["name"] = nameFilter;
      }
      if (emailFilter !== "") {
        currentFilters["email"] = emailFilter;
      }
      if (skillFilter !== "") {
        currentFilters["skill"] = skillFilter;
      }
      if (posteFilter !== "") {
        currentFilters["post"] = posteFilter;
      }
      if (phoneFilter !== "") {
        currentFilters["phone"] = phoneFilter;
      }
      sessionStorage.setItem("filters", JSON.stringify(currentFilters));
    }
    return filteredData;
  }
  function filterCandidatsByName(data, searchText) {
    if (searchText !== "" && searchText !== null) {
      return data.filter(
        (item) =>
          (item.firstname &&
            item.firstname.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.lastname &&
            item.lastname.toLowerCase().includes(searchText.toLowerCase()))
      );
    } else {
      return data;
    }
  }
  function filterCandidatsBySkill(data, searchText) {
    if (searchText !== "" && searchText !== null) {
      return data.filter(
        (item) =>
          item.skills &&
          item.skills.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      return data;
    }
  }
  function filterCandidatsByPost(data, searchText) {
    if (searchText !== "" && searchText !== null) {
      return data.filter(
        (item) =>
          item?.posteType &&
          item?.posteType?.name &&
          item?.posteType?.name.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      return data;
    }
  }
  function filterCandidatsByType(data, searchText) {
    if (searchText !== "all" && searchText !== null) {
      return data.filter((item) => item?.type && item?.type === searchText);
    } else {
      return data;
    }
  }
  function filterCandidatsByEmail(data, searchText) {
    if (searchText !== "" && searchText !== null) {
      return data.filter(
        (item) =>
          item.email &&
          item.email.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      return data;
    }
  }
  function filterCandidatsByPhone(data, searchText) {
    if (searchText !== "" && searchText !== null) {
      return data.filter(
        (item) => item.phone && item.phone.includes(searchText.toLowerCase())
      );
    } else {
      return data;
    }
  }
  function handleRestoreFilter() {
    const restoredFilters = JSON.parse(sessionStorage.getItem("filters")) || {};

    if (restoredFilters) {
      const nameFilterCandidat = restoredFilters["name"];
      const emailFilterCandidat = restoredFilters["email"];
      const skillFilterCandidat = restoredFilters["skill"];
      const phoneFilterCandidat = restoredFilters["phone"];
      const posteFilterCandidat = restoredFilters["post"];

      if (nameFilterCandidat !== undefined) {
        setNameFilter(nameFilterCandidat);
      }
      if (emailFilterCandidat !== undefined) {
        setEmailFilter(emailFilterCandidat);
      }
      if (skillFilterCandidat !== undefined) {
        setSkillFilter(skillFilterCandidat);
      }
      if (phoneFilterCandidat !== undefined) {
        setPhoneFilter(phoneFilterCandidat);
      }
      if (posteFilterCandidat !== undefined) {
        setPosteFilter(posteFilterCandidat);
      }
    }
    setTimeout(() => {
      sessionStorage.removeItem("filters");
    }, 100);
  }
  const handleResetFilter = () => {
    setEmailFilter("");
    setNameFilter("");
    setSkillFilter("");
    setPosteFilter("");
    setPhoneFilter("");
  };
  function changeDisplayType(type) {
    setTypeFilter(type);
  }
  return (
    <div>
      <div className="row d-flex justify-content-between mb-1">
        <div className="col-lg-4 col-md-4 col-sm-6 d-flex align-items-center">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line color-blue me-1"></i>
                    <span className="color-blue">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card card-type-condidat ">
        <div className="card-title py-1 row px-3 mt-3 ">
          <div className="row d-flex justify-content-between mb-4">
            <div className="col-lg-6 ">
              <h4 className="fw-bold m-4">Listes des consultants</h4>
              {/* breadcrumb à voir après le designer le finir  */}
            </div>
            <div className="col-lg-5 ms-2 ">
              <div className="row">
                {hasCandidatTypesConsultPermission &&
                  hasCandidatsCreatePermission && (
                    <div className="col-lg-6 col-sm-12 px-1">
                      <Dropdown className="w-auto">
                        <Dropdown.Toggle
                          bsPrefix="but-dropdown border-0 px-0 px-lg-1 py-lg-0  text-dark w-100"
                          style={{ background: "none" }}>
                          <button className="btn btn_download_cv w-100">
                            Importer
                            <svg
                              width="12"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1 1.5L6 6.5L11 1.5"
                                stroke="#667085"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="candidat-dropdown-menu ">
                          {hasCvCreatePermission &&
                            hasCandidatsCreatePermission &&
                            hasCvConsultPermission && (
                              <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54 ">
                                <button
                                  type="button"
                                  className="w-100 justify-content-start btn btn_download_cv border-0"
                                  onClick={() => {
                                    setImportCandidatFromCVModal(true);
                                  }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <path
                                      d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                                      stroke="#344054"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  via cv
                                </button>
                              </Dropdown.Item>
                            )}
                          {hasCvCreatePermission &&
                            hasCandidatsCreatePermission && (
                              <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                                <button
                                  type="button"
                                  className="w-100 justify-content-start btn btn_download_cv border-0"
                                  onClick={() => {
                                    setImportCandidatFromCSVModal(true);
                                  }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <path
                                      d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                                      stroke="#344054"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  via csv
                                </button>
                              </Dropdown.Item>
                            )}
                          {hasCvCreatePermission &&
                            hasCandidatsCreatePermission && (
                              <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                                <button
                                  type="button"
                                  className="w-100 justify-content-start btn btn_download_cv border-0"
                                  onClick={downloadCsvTemplate}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <path
                                      d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                                      stroke="#344054"
                                      stroke-width="1.66667"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  Csv template
                                </button>
                              </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                {hasCandidatTypesConsultPermission &&
                  hasCandidatsCreatePermission && (
                    <div className="col-lg-6 col-sm-12 px-1">
                      <button
                        type="button"
                        className="btn btn_add_candidat w-100"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasCreate"
                        aria-controls="offcanvasCreate">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none">
                          <path
                            d="M9.99996 13.4167H6.24996C5.08699 13.4167 4.5055 13.4167 4.03234 13.5602C2.96701 13.8834 2.13333 14.717 1.81016 15.7824C1.66663 16.2555 1.66663 16.837 1.66663 18M15.8333 18V13M13.3333 15.5H18.3333M12.0833 6.75C12.0833 8.82107 10.4044 10.5 8.33329 10.5C6.26222 10.5 4.58329 8.82107 4.58329 6.75C4.58329 4.67893 6.26222 3 8.33329 3C10.4044 3 12.0833 4.67893 12.0833 6.75Z"
                            stroke="white"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        &nbsp;Ajouter un consultant
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <hr className="w-100"></hr>

          <div className=" col-lg-8 d-flex justify-content-center justify-content-lg-start">
            <div
              className="btn-group flex-column flex-sm-row mb-5  w-75"
              role="group"
              aria-label="First group">
              <button
                type="button"
                className="btn btn-light btnLightFiltre"
                data-mdb-color="dark"
                onClick={() => changeDisplayType("all")}>
                <span className="f-5">Voir tous</span>
              </button>
              <button
                type="button"
                className="btn btn-light btnLightFiltre"
                data-mdb-color="dark"
                onClick={() => changeDisplayType("candidat")}>
                <span className="f-5">Candidats</span>
              </button>
              <button
                type="button"
                className="btn btn-light btnLightFiltre"
                data-mdb-color="dark"
                onClick={() => changeDisplayType("employee")}>
                <span className="f-5">Salariés</span>
              </button>
              <button
                type="button"
                className="btn btn-light btnLightFiltre"
                data-mdb-color="dark"
                onClick={() => changeDisplayType("supplier")}>
                <span className="f-5"> Partenaires</span>
              </button>
              <Badge badgeContent={notificationfNumber} color="primary">
                <button
                  type="button"
                  className="btn btn-light btnLightFiltre w-100"
                  data-mdb-color="dark"
                  onClick={() => changeDisplayType("draft")}>
                  <span className="f-5">Importées</span>
                </button>
              </Badge>
            </div>
          </div>
          <div className="col-lg-2 ps-lg-0 mb-2 d-flex d-lg-block justify-content-center justify-content-lg-start">
            <div className="px-1 w-100">
              {/* <label className="text-500 mb-1">Nom</label> */}
              <div className="groupFiltre ">
                <svg
                  className="iconFiltre"
                  aria-hidden="true"
                  viewBox="0 0 24 24">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
                <input
                  type="text"
                  className="form-control inputFiltre"
                  id="myInput"
                  placeholder="Filtrer par Nom..."
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          {hasCandidatTypesConsultPermission &&
            hasCandidatsCreatePermission && (
              <div className="col-lg-2 ps-lg-0 mb-2 d-flex d-lg-block justify-content-center justify-content-lg-start">
                <button
                  type="button"
                  className={
                    filter ? "btn but-restore w-100" : "btn btn-add w-100"
                  }
                  onClick={() => setFilter(!filter)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      stroke={filter ? "black" : "white"}
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  &nbsp;Filtres
                </button>
              </div>
            )}
        </div>
        <div className="card-body px-1">
          {filter && (
            <div className="row p-2 mb-2">
              <div className="col-lg-7 col-md-12 d-flex ps-4">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                    <label className="text-500 mb-1">Téléphone</label>
                    <div className="groupFiltre ">
                      <svg
                        className="iconFiltre"
                        aria-hidden="true"
                        viewBox="0 0 24 24">
                        <g>
                          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                        </g>
                      </svg>
                      <input
                        type="text"
                        className="form-control inputFiltre"
                        id="myInput"
                        placeholder="Filtrer par Téléphone..."
                        value={phoneFilter}
                        onChange={(e) => setPhoneFilter(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                    <label className="text-500 mb-1">Poste</label>
                    <div className="groupFiltre ">
                      <svg
                        className="iconFiltre"
                        aria-hidden="true"
                        viewBox="0 0 24 24">
                        <g>
                          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                        </g>
                      </svg>
                      <input
                        type="text"
                        className="form-control inputFiltre"
                        id="myInput"
                        placeholder="Filtrer par Type..."
                        value={posteFilter}
                        onChange={(e) => setPosteFilter(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                    <label className="text-500 mb-1">Compétences</label>
                    <div className="groupFiltre ">
                      <svg
                        className="iconFiltre"
                        aria-hidden="true"
                        viewBox="0 0 24 24">
                        <g>
                          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                        </g>
                      </svg>
                      <input
                        type="text"
                        className="form-control inputFiltre"
                        id="myInput"
                        placeholder="Filtrer par Compétences..."
                        value={skillFilter}
                        onChange={(e) => setSkillFilter(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 row   mt-3 pt-2">
                <div className="col-lg-5 col-md-4 col-sm-12 pb-1 ps-4 pe-0">
                  <button
                    type="button"
                    className="btn w-100 px-1 btn-reset"
                    onClick={handleResetFilter}>
                    <img src={`../assets/img/repeat-04.svg`} /> Réinitialiser
                  </button>
                </div>
                <div className="col-lg-7 col-md-5 col-sm-12 ps-4 pe-0">
                  <button
                    type="button"
                    className="btn w-100 px-1 btn-restore"
                    onClick={handleRestoreFilter}>
                    <i className="ri-arrow-left-line px-1"></i>Dernière
                    recherche
                  </button>
                </div>
              </div>
            </div>
          )}
          {data}
        </div>
        {addCandidatModal}
        <CreateNote
          selectedForUpdate={selectedForUpdate}
          setSelectedForUpdate={setSelectedForUpdate}
          destination={"candidates"}
        />
        {importCandidatFromCSVModal && (
          <ImportCandidatFromCSV hideModal={setImportCandidatFromCSVModal} />
        )}
        {importCandidatFromCVModal && (
          <ImportFromCv
            getAllEmployees={getAllEmployees}
            hideModal={setImportCandidatFromCVModal}
          />
        )}
        {importConsultantCV}
        <CadidatToEmplyeeOffCanvas
          setSelectedForUpdate={setSelectedForUpdate}
          candidatId={selectedForUpdate}
        />

        <UpdateCandidat
          getAllCandidats={getAllEmployees}
          setSelectedForUpdate={setSelectedForUpdate}
          id={selectedForUpdate}
        />
        <Addcandidat
          setaddEmployeeModalFromChild={setaddCandidatModal}
          getAllCandidats={getAllEmployees}
        />
      </div>
    </div>
  );
};

export default Candidats;
