import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function DeleteRole({ setAddRoleModalFromChild, getAllRoles, roleId }) {

  function deleteRole() {
    axios.delete(`${baseURL}/roles/${roleId}`).then((resp) => {
      if (resp.data.error) return;
      swal("Ce rôle est supprimé avec succés!", {
        icon: "success",
        timer: 2000,
      });
      getAllRoles();
      setTimeout(() => {
        setAddRoleModalFromChild("");
      }, 2400);
    });
  }

  React.useEffect(() => {
    swal({
      title: "êtes vous sûr de supprimer ce rôle?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer ce rôle!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        deleteRole();
      } else {
        setAddRoleModalFromChild("");
      }
    });
  }, []);
  return <div></div>;
}

export default DeleteRole;
