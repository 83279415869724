import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router";
import { baseURL } from "../../config/config";
import { Link } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DisplayError from "../../components/ErrorComponants/DisplayError";
const initFormErrors = {
  lastname: [""],
  firstname: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  diplomeDate: [""],
  type: [""],
  cv: [""],
};

const UpdateEmployees = () => {
  const [countries, setCountries] = React.useState([]);
  const [city, setCity] = useState([]);
  const [gender, setGender] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [pays, setPays] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [cp, setCp] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [avatar, setAvatar] = useState();
  const [lieu_naissance, setLieu_naissance] = useState("");
  const [diplome, setDiplome] = useState("");
  const [diplomeDate, setDiplomeDate] = useState("");
  const [nirpp, setNirpp] = useState("");
  const [job, setJob] = useState("");
  const [typeOfContract, setTypeOfContract] = useState("");
  const [salary, setSalary] = useState("");
  const [startDate, setStartDate] = useState("");
  const [type, setType] = useState("");
  const history = useHistory();
  const { employeeId } = useParams();
  const [cities, setCities] = useState([]);
  const [experience, setExperience] = useState("");
  const [status, setStatus] = useState("");
  const [skills, setSkills] = useState("");
  const [langues, setLangues] = useState("");
  const [loadingCity, setLoadingCity] = useState(false);
  const [cv, setcv] = useState("");
  const msg = "Ce champ est obligatoire";

  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  React.useEffect(() => {
    if (employeeId === undefined) return;
    axios.get(`${baseURL}/consultants/${employeeId}`).then((resp) => {
      if (resp.data.error) return;
      const employeesData = resp.data.data;
      setFname(employeesData.fname);
      setLname(employeesData.lname);
      setGender(employeesData.gender);
      setEmail(employeesData.email);
      setNirpp(employeesData.nirpp);
      setPhone(employeesData.phone);
      setAddress(employeesData.address);
      setCp(employeesData.cp);
      setDiplome(employeesData.diplome);
      setDiplomeDate(employeesData.diplomeDate);
      setBirthDay(employeesData.birthday);
      setLieu_naissance(employeesData.birthPlace);
      setStatus(employeesData.status);
      setSkills(employeesData.skills);
      setLangues(employeesData.langues);
      setExperience(employeesData.experience);
      setType(employeesData.type);
      setTypeOfContract(employeesData.typeOfContract);
      setJob(employeesData.job);
      setSalary(employeesData.salary);
      setStartDate(employeesData.startDate);
      setCity(employeesData.city?.id);
      setPays(employeesData.country?.id);
    });
    getCountries();
  }, [employeeId]);

  function getCountries() {
    axios
      .get(`${baseURL}/apiData/countries`)
      .then((resp) => {
        if (resp.data.error) return;
        resp.data.data.map((item, index) => {
          return setCountries((prevState) => [
            ...prevState,
            { value: item.id, label: item.name },
          ]);
        });
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (pays === "") return;
    setCities([]);
    getCities(pays);
  }, [pays]);

  function getCities(countryid) {
    if (countryid) {
      axios
        .get(`${baseURL}/apiData/cities/${countryid}`)
        .then((resp) => {
          if (resp.data.error) return;
          resp.data.data.map((item, index) => {
            return setCities((prevState) => [
              ...prevState,
              { value: item.id, label: item.name },
            ]);
          });
          setLoadingCity(false);
        })
        .catch((error) => {
          DisplayError();
        });
    }
  }

  useEffect(() => {
    getCountries();
  }, []);

  function sendData() {
    if (pays && !city) {
      setErrorMessage({ ...errorMessage, city: msg });
      return;
    }
    const formData = new FormData();
    formData.append("gender", gender);
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("pays", pays);
    formData.append("city", city);
    formData.append("address", address);
    formData.append("cp", cp);
    formData.append("phone", phone);
    formData.append("birthday", birthDay);
    formData.append("birthPlace", lieu_naissance);
    formData.append("diplomeDate", diplomeDate);
    formData.append("diplome", diplome);
    formData.append("type", type);
    formData.append("langues", langues);
    formData.append("status", status);
    formData.append("skills", skills);
    formData.append("experience", experience);
    formData.append("nirpp", nirpp);
    formData.append("job", job);
    formData.append("startDate", startDate);
    formData.append("salary", salary);
    formData.append("typeOfContract", typeOfContract);
    formData.append("cv", cv);
    formData.append("avatar", avatar);

    axios
      .put(`${baseURL}/consultants/${employeeId}`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/employees");
        }, 2400);
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  }

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  return (
    <div>
      <section className="section">
        <div className="row">
          <h1 className="text-center color-msit fw-bolder pb-5">
            Modifier Fiche de l'employé
          </h1>
          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>
                      <i className="bi bi-house-door"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/candidats"}>Employés</Link>
                  </li>
                  <li className="breadcrumb-item active">employé</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-4">
              <Link to="/employees">
                <button
                  type="button"
                  className="btn btn-outline-secondary mb-2 float-end  me-2">
                  Retour
                </button>
              </Link>
            </div>
          </div>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Vous étes :</label>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label className="form-check-label">Homme</label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="myCheck"
                        value="femele"
                        checked={gender === "femele"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label className="form-check-label">Femme</label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="myCheck"
                        value="other"
                        checked={gender === "other"}
                        onChange={(e) => setGender(e.target.value)}
                      />

                      <label className="form-check-label">Autre</label>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.gender}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Nom * :</label>
                    <input
                      name="fname"
                      type="text"
                      className="form-control"
                      onChange={(e) => setLname(e.target.value)}
                      value={lname}
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.lname}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Prénom * :</label>
                    <input
                      name="lname"
                      type="text"
                      className="form-control"
                      onChange={(e) => setFname(e.target.value)}
                      value={fname}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.fname}</span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Email * : </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.email}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Téléphone : </label>
                    <PhoneInput
                      inputClass="phoneInput"
                      className="phoneInput"
                      placeholder="Téléphone"
                      value={phone}
                      onChange={(value, country, event) =>
                        setPhone(
                          event.target.value ? event.target.value : value
                        )
                      }
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.phone}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">NIRPP * : </label>
                    <input
                      type="number"
                      min="0"
                      className="form-control"
                      onChange={(e) => setNirpp(e.target.value)}
                      value={nirpp}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.nirpp}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Etat civil :</label>
                      <select
                        id="inputEtatCivil"
                        className="form-select"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}>
                        <option disabled selected>
                          {""}
                        </option>
                        <option value="single">Célibataire</option>
                        <option value="in_relationship">En couple</option>
                        <option value="married">Marié</option>
                        <option value="divorced">Divorcé</option>
                        <option value="pacs">Pacsé</option>
                      </select>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.status}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Pays :</label>
                    <Select
                      value={countries.filter(
                        (option) => option.value === pays
                      )}
                      options={countries}
                      isSearchable
                      onChange={(e) => {
                        setLoadingCity(true);
                        setPays(e.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Ville :</label>
                    <Select
                      value={cities.filter((option) => option.value === city)}
                      options={cities}
                      isLoading={loadingCity}
                      isSearchable
                      onChange={(e) => {
                        setCity(e.value);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.city}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Adresse : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.address}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Code postal :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        onChange={(e) => setCp(e.target.value)}
                        value={cp}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.cp}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <div className="date-picker">
                        <label className="color-msit fw-bold">
                          Date de naissance :
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="datePicker"
                          onChange={(e) => setBirthDay(e.target.value)}
                          value={birthDay}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.birthDay}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>

                <div className="col-md-12">
                  <div className="row pb-3">
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">
                          Expérience * :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={experience}
                          onChange={(e) => setExperience(e.target.value)}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.experience}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">
                          Upload CV *:
                        </label>
                        <input
                          className="form-control mb-4"
                          type="file"
                          accept=".pdf, .docx"
                          required
                          onChange={(e) => {
                            setcv(e.target.files[0]);
                          }}></input>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.typeOfContract}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">
                          Compétence * :
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSkills(e.target.value)}
                          value={skills}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.skills}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">Diplome :</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setDiplome(e.target.value)}
                          value={diplome}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.diplome}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">
                          Année de diplome :
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDiplomeDate(e.target.value)}
                          min="0"
                          value={diplomeDate}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.diplomeDate}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">Langue :</label>
                        <Select
                          options={languesOptions}
                          value={
                            langues !== "" &&
                            languesOptions.filter((option) =>
                              langues.includes(option.value.toString())
                            )
                          }
                          isMulti
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions.map(
                              (option) => option.value
                            );
                            setLangues(selectedValues);
                          }}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.langues}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">Poste * :</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setJob(e.target.value)}
                          value={job}
                        />
                      </div>
                      <div className="input-error">
                        <span className="text-danger"> {errorMessage.job}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="box">
                        <div className="date-picker ">
                          <label className="color-msit fw-bold">
                            Date debut de travail *
                          </label>
                          <input
                            className="form-control"
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            value={startDate}
                            type="date"
                            id="datePicker"
                            required
                          />
                        </div>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.startDate}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div>
                        <label className="color-msit fw-bold">
                          Type de contrat * :
                        </label>
                        <select
                          className="form-select"
                          name="typeOfContract"
                          onChange={(e) => setTypeOfContract(e.target.value)}
                          value={typeOfContract}>
                          <option disabled selected></option>
                          <option value="cdd">CDD</option>
                          <option value="cdi">CDI</option>
                          <option value="internship">INTERNSHIP</option>
                        </select>
                      </div>
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.typeOfContract}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="color-msit fw-bold">Salaire * :</label>
                      <input
                        name="fname"
                        type="number"
                        className="form-control"
                        onChange={(e) => setSalary(e.target.value)}
                        min="0"
                        value={salary}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.salary}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className="color-msit fw-bold">Avatar :</label>
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        className="form-control"
                        onChange={(e) => setAvatar(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="text-end">
          <Link to="/employees">
            <button type="button" className="btn btn-outline-secondary  me-2">
              Annuler
            </button>
          </Link>
          <button type="button" className="btn btn-primary" onClick={sendData}>
            Enregistrer
          </button>
        </div>
      </section>
    </div>
  );
};

export default UpdateEmployees;
