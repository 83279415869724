import React from "react";

export default function Loader() {
  return (
    <div>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>{" "}
      Loading...{" "}
    </div>
  );
}
