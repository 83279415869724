import React from "react";
import { Link } from "react-router-dom";

const UnauthorizedPage = () => {
  return (
    <div>
      <div className="wrapper">
        <h2>Oops! Missing Permissions.</h2>
        <div>
          <img src="images/401.svg" alt="401" />
        </div>
        <h4>you are not authorized.</h4>
        <Link to="/">
          <button type="button" className="main-btn">
            GO BACK HOME
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
