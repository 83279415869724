import React, { useContext, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";
import Loader from "../loader";

function AddRelaunchMission({ getRelaunchMissionByMissionId, mission }) {
  const { user } = useContext(UserContext);
  const hasRelaunchMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.relaunchMission,
    "consult"
  );
  const [title, setTitle] = useState();
  const [date, setDate] = useState();
  const [time, setTime] = useState("");
  const [description, setDescription] = useState();
  const today = new Date().toISOString().substr(0, 10);
  const [isLoading, setIsLoading] = useState(false);

  const initFormErrors = {
    title: [""],
    date: [""],
    description: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);
  function sendData() {
    setIsLoading(true);
    axios
      .post(`${baseURL}/relaunchMission/create/${mission}`, {
        title: title,
        date: date,
        description: description,
        mission: mission,
        time: time,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        if (hasRelaunchMissionsConsultPermission) {
          getRelaunchMissionByMissionId();
        }
        const boutonOffcanvas = document.getElementById("cancelButton");
        boutonOffcanvas.click();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-1"
        id="relaunchMision_offCanvas"
        aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header ">
          <h4 id="offcanvasRightLabel" className="fw-bold">
            Ajouter un rappel
          </h4>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
        </div>
        <div className="offcanvas-body offcanvas-body-scrollable">
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Titre: *</label>
            <input
              type="text"
              className="form-control offcanvas-input"
              placeholder="Titre"
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.title}
              </span>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Date: *</label>
            <input
              type="date"
              className="form-control offcanvas-input"
              onChange={(e) => setDate(e.target.value)}
              required
              id="date"
              placeholder="Date"
              min={today}
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.date}
              </span>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Temps: *</label>
            <input
              type="time"
              defaultValue={time}
              className="form-control offcanvas-input"
              onChange={(e) => {
                setTime(e.target.value);
              }}
              required
              id="time"
              placeholder="time"
              min={today}
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.time}
              </span>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Description: *</label>
            <input
              className="form-control offcanvas-input"
              type="text"
              id="description"
              placeholder="Description"
              rows="4"
              onChange={(e) => setDescription(e.target.value)}
              required
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {formerrors.description}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2 pb-1">
            <button
              type="button"
              className="btn btn-primary"
              onClick={sendData}
              disabled={isLoading}>
              {isLoading ? <Loader /> : "Ajouter"}
            </button>
          </div>
          <div className="row px-2 pt-1 pb-2">
            <button
              id="cancelButton"
              type="button"
              className="btn but-restore"
              data-bs-dismiss="offcanvas"
              aria-label="Close">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddRelaunchMission;
