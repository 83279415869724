import React, { useState } from "react";
import "../App.css";

const ReadMore = ({ children}) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};


const Content = ({experience}) => {
    
  return (
    <div>
      <h2>
        <ReadMore>{experience != null && experience.description}</ReadMore>
      </h2>
    </div>
  );
};

export default Content;
