import React from "react";

const ExpiredLink = () => {
  return (
    <div>
      <div className="pagetitle row container">
        <header
          id="header"
          className="header container fixed-top d-flex align-items-center ps-5 pe-5 mb-5"
        >
          <div className="d-flex align-items-center justify-content-between col-4">
            <a href="" className="logo d-flex align-items-center">
              <img src="../../assets/img/msit-logo.png" alt="" />
              <span className="d-none d-lg-block">MSIT Dashboard</span>
            </a>
          </div>
        </header>
        <div className="col-md-12 row mt-5 pt-5">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <div>
              <h1 className="">Le lien n'est plus valide</h1>
              <h6 className="">Oops! Le lien est expiré</h6>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src="/assets/img/expired_link.png"
              alt="Ce lien a expiré"
              width="80%"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredLink;
