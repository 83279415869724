import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../loader";

function CadidatToEmplyeeOffCanvas({
  setaddEmployeeModalFromChild,
  candidatId,
  getAllCandidats,
}) {
  const history = useHistory();

  const [nirpp, setNirpp] = useState();
  const [company, setCompany] = useState();
  const [posteType, setPosteType] = useState();
  const [job, setJob] = useState();
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [typeOfContract, setTypeOfContract] = useState("cdd");
  const [salary, setSalary] = useState();
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [avatar, setAvatar] = useState();
  const [user, setUser] = useState();
  const [birthday, setBirthday] = useState();
  const [birthPlace, setBirthPlace] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [cp, setCp] = useState();
  const [gender, setGender] = useState();
  const [isEnabled, setIsEnabled] = useState();
  const [status, setStatus] = useState();
  const [diplome, setDiplome] = useState();
  const [skills, setSkills] = useState();
  const [type, setType] = useState();
  const [langues, setLangues] = useState();
  const [experience, setExperience] = useState();
  const [diplomeDate, setDiplomeDate] = useState();
  const [companies, setCompanies] = useState();
  const [emailCheck, setEmailCheck] = useState();
  const [cv, setCv] = useState();
  const [isLoading,setIsLoading] = useState(false);
  const initFormErrors = {
    nirpp: [""],
    company: [""],
    job: [""],
    startDate: [""],
    typeOfContract: [""],
    salary: [""],
    msg: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);

  useEffect(() => {
    if (candidatId) {
      getCandidat();
    }
    getAllCompanies();
  }, [candidatId]);
  async function getAllCompanies() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        const fetchedCompanies = response.data.data;
        setCompanies(fetchedCompanies);
        if (fetchedCompanies.length > 0) {
          setCompany(fetchedCompanies[0].id);
        }
      })
      .catch(() => {
        DisplayError();
      });
  }
  async function getCandidat() {
    axios
      .get(`${baseURL}/consultants/${candidatId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const data = resp.data.data;
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setEmail(data.email);
        setEmailCheck(data.email);
        setPhone(data.phone);
        setAvatar(data.avatarid);
        setUser(data.user);
        setBirthday(data.birthday);
        setBirthPlace(data.birthPlace);
        setCity(data.city?.id);
        setAddress(data.address);
        setCp(data.cp);
        setGender(data.gender);
        setIsEnabled(data.isEnabled);
        setStatus(data.status);
        setDiplome(data.diplome);
        setSkills(data.skills);
        setLangues(data.langues);
        setExperience(data.experience);
        setDiplomeDate(data.diplomeDate);
        setType(data.type);
        setCv(data.cv?._id);
        setPosteType(data.posteType?._id);
        setJob(data.type.name);
      })
      .catch((err) => {
        DisplayError();
      });
  }

  function sendData() {
    swal({
      title: "Voulez vous Ajouter cette candidat en tant qu'employe ? ",
      icon: "warning",
      buttons: true,
    }).then((confirmed) => {
      if (confirmed) {
        setIsLoading(true);
        axios
          .put(`${baseURL}/consultants/${candidatId}`, {
            nirpp: nirpp,
            company: company,
            job: job,
            startDate: startDate,
            typeOfContract: typeOfContract,
            salary: salary,
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone.toString(),
            avatar: avatar?._id,
            user: user,
            birthday: birthday,
            birthPlace: birthPlace,
            city: city,
            address: address,
            cp: cp,
            gender: gender,
            isEnabled: isEnabled,
            status: status,
            diplome: diplome,
            type: type,
            langues: langues,
            experience: experience,
            diplomeDate: diplomeDate,
            skills: skills,
            candidatId: candidatId,
            cv: cv,
          })
          .then((resp) => {
            setFormErrors(initFormErrors);
            setIsLoading(false);
            if (resp.data.error) return;
            swal(resp.data.message, {
              icon: "success",
              timer: 2500,
            });
            if (getAllCandidats) {
              getAllCandidats();
            }
            setaddEmployeeModalFromChild("");
          })
          .catch((error) => {
            setIsLoading(false);
            DisplayError(
              error.response.data.msg,
              error.response.data.employeeID
                ? error.response.data.employeeID
                : null,
              error.response.data.employeeID ? "/employees/details/" : null,

              history
            );
            setFormErrors(error.response.data);
          });
      }
    });
  }

  return (
    <div
      className="offcanvas offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="TransformToEmployee"
      aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabe" className="fw-bold">
          Transformer en Salarié
        </h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div>
          <form>
            <div>
              <div className="row px-2">
                <label className="px-0 text-500">Types : *</label>
                <div className="col-12 py-2 px-0 ">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input offcanva-checkbox"
                      type="radio"
                      name="inlineRadioOptionsType"
                      id="inlineRadioEmployee"
                      value="employee"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      checked={type === "employee"}
                    />
                    <label class=" text-500" for="inlineRadioEmployee">
                      Salarié
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input offcanva-checkbox"
                      type="radio"
                      name="inlineRadioOptionsType"
                      id="inlineRadioSupplier"
                      value="supplier"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      checked={type === "supplier"}
                    />
                    <label s className=" text-500" for="inlineRadioSupplier">
                      Partenaire
                    </label>
                  </div>
                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {formerrors?.type}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">
                  Numéro de securité social : *
                </label>
                <input
                  type="number"
                  className="form-control offcanvas-input"
                  placeholder="Numéro de securité social "
                  min="0"
                  onChange={(e) => setNirpp(e.target.value)}
                  value={nirpp}
                  required
                  id="nirpp"
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.nirpp}
                  </span>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">TJM : *</label>
                <input
                  type="number"
                  className="form-control offcanvas-input"
                  min="0"
                  id="salary"
                  placeholder="Salary"
                  onChange={(e) => setSalary(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors.salary}
                  </span>
                </div>
              </div>
              {emailCheck === "" ? (
                <div className="row px-2 py-2">
                  <div className="date-picker px-0">
                    <label className="px-0 mb-2 text-500">Email : *</label>
                    <div className="input-group mb-2 offcanvas-input">
                      <input
                        placeholder="Email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {formerrors.email}
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="row px-2 py-2">
                <div className="date-picker px-0">
                  <label className="px-0 mb-2 text-500">
                    Date début de mission :
                  </label>
                  <input
                    type="date"
                    className="form-control offcanvas-input"
                    id="startDate"
                    placeholder="Date début de mission"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {formerrors.startDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">
                  Sélectionner une societe: *
                </label>
                <select
                  id="companyId"
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                  className="form-select">
                  <option disabled selected value=""></option>
                  {companies != null &&
                    companies.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {`${item.name}`}
                        </option>
                      );
                    })}
                </select>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors?.company}
                  </span>
                </div>
              </div>

              <div className="row px-2 py-2">
                <label className="px-0 mb-2 text-500">
                  Sélectionner un type de contrat: *
                </label>
                <select
                  id="typeOfContract"
                  onChange={(e) => {
                    setTypeOfContract(e.target.value);
                  }}
                  className="form-select"
                  defaultValue="cdd">
                  <option disabled value="" selected></option>

                  <option value={"cdd"}>cdd</option>
                  <option value={"cdi"}>cdi</option>
                  <option value={"internship"}>internship</option>
                </select>
                <div className="input-error">
                  <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                    {formerrors?.typeOfContract}
                  </span>
                </div>
              </div>
            </div>
            <div className="row px-2 pt-2 pb-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
                disabled={isLoading}
                >
                {isLoading ? <Loader /> : "Ajouter"}
              </button>
            </div>
            <div className="row px-2 pt-1 pb-2">
              <button
                id="cancelButton"
                type="button"
                className="btn but-restore"
                data-bs-dismiss="offcanvas"
                aria-label="Close">
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CadidatToEmplyeeOffCanvas;
