import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseURL, localUrl } from "../config/config";
import { UserContext } from "../context/UserContext";
import DisplayError from "./ErrorComponants/DisplayError";
import { NavLink, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import AllNotificationsOffCanvas from "./notifications/allNotificationsOffCanvas";
import { Badge } from "@mui/material";
const Header = () => {
  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const [hasNotification, setHasNotification] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  function getAllNotification() {
    axios
      .get(`${baseURL}/notifications/allNotifications/${user.id}`)
      .then((response) => {
        if (response) {
          const hasValueUnreadNotififcataion = response.data.data.some(
            function (val) {
              return val.isRead === "no";
            }
          );
          if (hasValueUnreadNotififcataion) {
            setHasNotification(true);
          } else {
            setHasNotification(false);
          }
        }
      })
      .catch((err) => {
        DisplayError();
      });
  }
  function logout() {
    axios
      .post(`${baseURL}/logout`)
      .then((response) => {
        setUser(undefined);
        localStorage.removeItem("user-data");
        history.replace("/login");
      })
      .catch((err) => {
        DisplayError();
      });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (user !== null) {
      getAllNotification();
    }
  }, [user]);
  return (
    <>
      <header
        id="header"
        className="header header-msit-color text-white fixed-top d-flex align-items-center display_header">
        <div className="d-flex align-items-center justify-content-between">
          <a href={`${localUrl}`} className="logo d-flex align-items-center">
            <img src="../assets/img/msit-logoo.svg" alt="" />
          </a>
          <i
            className="bi bi-list toggle-sidebar-btn"
            style={{ color: "rgb(102, 112, 133)" }}
            onClick={toggleSidebar}></i>
        </div>
        <div>
          <button
            className="btn btn-outline-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#allNotificationsOffCanvas"
            aria-controls="allNotificationsOffCanvas">
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              invisible={!hasNotification}
              color="primary"
              variant="dot">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V13M13 17H7M15 13H7M20.1213 3.87868C21.2929 5.05025 21.2929 6.94975 20.1213 8.12132C18.9497 9.29289 17.0503 9.29289 15.8787 8.12132C14.7071 6.94975 14.7071 5.05025 15.8787 3.87868C17.0503 2.70711 18.9497 2.70711 20.1213 3.87868Z"
                    stroke="#667085"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Notification
              </div>
            </Badge>
          </button>
        </div>
      </header>
      <aside
        id="sidebar"
        className={` ps-2 ${sidebarOpen ? "sidebar_header" : ""}`}>
        {sidebarOpen && (
          <ul className="sidebar-nav sidebar_ul_header" id="sidebar-nav">
            <li className=" mb-2">
              <NavLink
                to="/"
                className="nav-link collapsed"
                activeclassname="nav-link"
                onClick={toggleSidebar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M8 17.0007H16M11.0177 2.76473L4.23539 8.03986C3.78202 8.39248 3.55534 8.56879 3.39203 8.78959C3.24737 8.98518 3.1396 9.20552 3.07403 9.43978C3 9.70425 3 9.99143 3 10.5658V17.8007C3 18.9208 3 19.4809 3.21799 19.9087C3.40973 20.285 3.71569 20.591 4.09202 20.7827C4.51984 21.0007 5.07989 21.0007 6.2 21.0007H17.8C18.9201 21.0007 19.4802 21.0007 19.908 20.7827C20.2843 20.591 20.5903 20.285 20.782 19.9087C21 19.4809 21 18.9208 21 17.8007V10.5658C21 9.99143 21 9.70425 20.926 9.43978C20.8604 9.20552 20.7526 8.98518 20.608 8.78959C20.4447 8.56879 20.218 8.39248 19.7646 8.03986L12.9823 2.76473C12.631 2.49148 12.4553 2.35485 12.2613 2.30233C12.0902 2.25599 11.9098 2.25599 11.7387 2.30233C11.5447 2.35485 11.369 2.49148 11.0177 2.76473Z"
                    stroke="#667085"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="sidebar_navlink ps-2">Acceuil </span>
              </NavLink>
            </li>
            <li className="nav-item mb-2">
              <NavLink
                to="/"
                className="nav-link collapsed"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#allNotificationsOffCanvas"
                aria-controls="allNotificationsOffCanvas"
                activeclassname="nav-link">
                <Badge
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  invisible={!hasNotification}
                  color="primary"
                  variant="dot">
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H15.2C16.8802 21 17.7202 21 18.362 20.673C18.9265 20.3854 19.3854 19.9265 19.673 19.362C20 18.7202 20 17.8802 20 16.2V13M13 17H7M15 13H7M20.1213 3.87868C21.2929 5.05025 21.2929 6.94975 20.1213 8.12132C18.9497 9.29289 17.0503 9.29289 15.8787 8.12132C14.7071 6.94975 14.7071 5.05025 15.8787 3.87868C17.0503 2.70711 18.9497 2.70711 20.1213 3.87868Z"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Notification
                  </div>
                </Badge>
              </NavLink>
            </li>
            {user != null && user.userpermissions.includes("users") ? (
              <li className="nav-item mt-1 mb-2">
                <NavLink
                  onClick={toggleSidebar}
                  to="/users"
                  className={`nav-link collapsed${
                    location.pathname.includes("users") ? "nav-link" : ""
                  }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                      stroke="#667085"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="sidebar_navlink ps-2">Les utilisateurs</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {user != null && user.userpermissions.includes("roles") ? (
              <li className="nav-item mt-1 mb-2">
                <NavLink
                  onClick={toggleSidebar}
                  to="/roles"
                  className={`nav-link collapsed${
                    location.pathname.includes("roles") ? "nav-link" : ""
                  }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M16 5L18 7L22 3M22 12V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C19.7202 22 18.8802 22 17.2 22H6.8C5.11984 22 4.27976 22 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H12M2.14551 19.9263C2.61465 18.2386 4.16256 17 5.99977 17H12.9998C13.9291 17 14.3937 17 14.7801 17.0769C16.3669 17.3925 17.6073 18.6329 17.9229 20.2196C17.9998 20.606 17.9998 21.0707 17.9998 22M14 9.5C14 11.7091 12.2091 13.5 10 13.5C7.79086 13.5 6 11.7091 6 9.5C6 7.29086 7.79086 5.5 10 5.5C12.2091 5.5 14 7.29086 14 9.5Z"
                      stroke="#667085"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="sidebar_navlink ps-2">Les roles </span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {user != null && user.userpermissions.includes("candidats") ? (
              sidebarOpen ? (
                <li className="nav-item mt-1 mb-2">
                  <div className="nav-link collapsed">
                    <a
                      href="#submenu2"
                      data-bs-toggle="collapse"
                      className="nav-link px-0 align-middle ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z"
                          stroke="#667085"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="sidebar_navlink ps-2">
                        Les consultants
                      </span>
                    </a>
                  </div>
                  <ul
                    className="collapse nav ms-3"
                    id="submenu2"
                    data-bs-parent="#menu">
                    {user != null &&
                    user.userpermissions.includes("candidatTypes") ? (
                      <li className="nav-item mt-1 mb-2 w-100">
                        <NavLink
                          onClick={toggleSidebar}
                          to="/types-candidats"
                          className={`nav-link collapsed${
                            location.pathname.includes("types-candidats")
                              ? "nav-link"
                              : ""
                          }`}>
                          <span className="sub-link ps-2">
                            Types des consultants
                          </span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="nav-item mt-1 mb-2 w-100">
                      <NavLink
                        onClick={toggleSidebar}
                        to="/candidats"
                        className={`nav-link collapsed${
                          location.pathname.includes("candidats") &&
                          !location.pathname.includes("types")
                            ? "nav-link"
                            : ""
                        }`}>
                        <span className="sub-link ps-2">Consultants </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item mt-1 mb-2">
                  <Button
                    id="fade-button"
                    aria-controls={"fade-menu"}
                    aria-haspopup="true"
                    aria-expanded={"true"}
                    className="nav-link collapsed ps-1"
                    onClick={handleClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none">
                      <path
                        d="M4.00002 21.8174C4.6026 22 5.41649 22 6.8 22H17.2C18.5835 22 19.3974 22 20 21.8174M4.00002 21.8174C3.87082 21.7783 3.75133 21.7308 3.63803 21.673C3.07354 21.3854 2.6146 20.9265 2.32698 20.362C2 19.7202 2 18.8802 2 17.2V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H17.2C18.8802 2 19.7202 2 20.362 2.32698C20.9265 2.6146 21.3854 3.07354 21.673 3.63803C22 4.27976 22 5.11984 22 6.8V17.2C22 18.8802 22 19.7202 21.673 20.362C21.3854 20.9265 20.9265 21.3854 20.362 21.673C20.2487 21.7308 20.1292 21.7783 20 21.8174M4.00002 21.8174C4.00035 21.0081 4.00521 20.5799 4.07686 20.2196C4.39249 18.6329 5.63288 17.3925 7.21964 17.0769C7.60603 17 8.07069 17 9 17H15C15.9293 17 16.394 17 16.7804 17.0769C18.3671 17.3925 19.6075 18.6329 19.9231 20.2196C19.9948 20.5799 19.9996 21.0081 20 21.8174M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5Z"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    TransitionComponent={Fade}>
                    <MenuItem onClick={handleClose}>
                      {user != null &&
                      user.userpermissions.includes("candidatTypes") ? (
                        <NavLink
                          onClick={toggleSidebar}
                          to="/types-candidats"
                          className={`nav-link p-0 collapsed${
                            location.pathname.includes("types-candidats")
                              ? "nav-link"
                              : ""
                          }`}>
                          <span className="sub-link ps-2">
                            Types des consultants
                          </span>
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink
                        onClick={toggleSidebar}
                        to="/candidats"
                        className={`nav-link p-0 collapsed${
                          location.pathname.includes("candidats") &&
                          !location.pathname.includes("types")
                            ? "nav-link"
                            : ""
                        }`}>
                        <span className="sub-link ps-2">Les consultants </span>
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </li>
              )
            ) : (
              ""
            )}
            {user != null && user.userpermissions.includes("missions") ? (
              <li className="nav-item mt-1 mb-2">
                <NavLink
                  onClick={toggleSidebar}
                  to="/missions"
                  className={`nav-link collapsed${
                    location.pathname.includes("missions") ? "nav-link" : ""
                  }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.76121C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38643 22 10.6868 22 12M12 2V12M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5229 22 12M12 2C17.5228 2 22 6.47716 22 12M22 12L12 12M22 12C22 13.5781 21.6265 15.1338 20.9101 16.5399C20.1936 17.946 19.1546 19.1626 17.8779 20.0902L12 12"
                      stroke="#667085"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="sidebar_navlink ps-2">les missions</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {user != null && user.userpermissions.includes("company") ? (
              <li className="nav-item mt-1 mb-2">
                <NavLink
                  onClick={toggleSidebar}
                  to="/company"
                  className={`nav-link collapsed${
                    location.pathname.includes("company") ? "nav-link" : ""
                  }`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      d="M11 11H6.2C5.07989 11 4.51984 11 4.09202 11.218C3.71569 11.4097 3.40973 11.7157 3.21799 12.092C3 12.5198 3 13.0799 3 14.2V21M21 21V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H14.2C13.0799 3 12.5198 3 12.092 3.21799C11.7157 3.40973 11.4097 3.71569 11.218 4.09202C11 4.51984 11 5.0799 11 6.2V21M22 21H2M14.5 7H17.5M14.5 11H17.5M14.5 15H17.5"
                      stroke="#667085"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="sidebar_navlink ps-2">Les sociétés </span>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className="mt-5">
              <div className="h-100 d-flex align-items-end ">
                <div className="col-12 d-flex">
                  <div className="col-9 d-flex">
                    <div
                      className={
                        sidebarOpen
                          ? "col-3"
                          : "col-12 d-flex justify-content-center sidebar_padding"
                      }>
                      {sidebarOpen && (
                        <img
                          src={`${baseURL + user.avatar.path.substring(1)}`}
                          className="sidebar_avatar"
                          alt="photo de profil"
                        />
                      )}
                    </div>
                    {sidebarOpen && (
                      <div className="col-9">
                        <p className="mb-0 profile_name">
                          {user != null && user?.name}
                        </p>
                        <p className="profile_role mb-0">
                          {user !== undefined && user?.role?.name}
                        </p>
                      </div>
                    )}
                  </div>
                  {sidebarOpen && (
                    <div className="col-2">
                      <button onClick={logout} className="btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none">
                          <path
                            d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5"
                            stroke="#667085"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </li>
          </ul>
        )}
        <AllNotificationsOffCanvas></AllNotificationsOffCanvas>
      </aside>
    </>
  );
};

export default Header;
