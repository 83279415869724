import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function CadidatToEmplyee({
  setaddEmployeeModalFromChild,
  candidatId,
  getAllCandidats,
}) {
  const history = useHistory();

  const [nirpp, setNirpp] = useState();
  const [company, setCompany] = useState();
  const [job, setJob] = useState();
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [typeOfContract, setTypeOfContract] = useState("cdd");
  const [salary, setSalary] = useState();
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [avatar, setAvatar] = useState();
  const [user, setUser] = useState();
  const [birthday, setBirthday] = useState();
  const [birthPlace, setBirthPlace] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [cp, setCp] = useState();
  const [gender, setGender] = useState();
  const [isEnabled, setIsEnabled] = useState();
  const [status, setStatus] = useState();
  const [diplome, setDiplome] = useState();
  const [skills, setSkills] = useState();
  const [type, setType] = useState();
  const [langues, setLangues] = useState();
  const [experience, setExperience] = useState();
  const [diplomeDate, setDiplomeDate] = useState();
  const [companies, setCompanies] = useState();
  const [emailCheck, setEmailCheck] = useState();
  const [cv, setCv] = useState();
  const initFormErrors = {
    nirpp: [""],
    company: [""],
    job: [""],
    startDate: [""],
    typeOfContract: [""],
    salary: [""],
    msg: [""],
  };
  const [formerrors, setFormErrors] = useState(initFormErrors);

  useEffect(() => {
    getCandidat();
    getAllCompanies();
  }, [candidatId]);
  async function getAllCompanies() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        const fetchedCompanies = response.data.data;
        setCompanies(
          fetchedCompanies.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
        if (fetchedCompanies.length > 0) {
          setCompany(fetchedCompanies[0].id);
        }
      })
      .catch(() => {
        DisplayError();
      });
  }
  async function getCandidat() {
    axios
      .get(`${baseURL}/consultants/${candidatId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const data = resp.data.data;
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setEmail(data.email);
        setEmailCheck(data.email);
        setPhone(data.phone);
        setAvatar(data.avatarid);
        setUser(data.user);
        setBirthday(data.birthday);
        setBirthPlace(data.birthPlace);
        setCity(data.city?.id);
        setAddress(data.address);
        setCp(data.cp);
        setGender(data.gender);
        setIsEnabled(data.isEnabled);
        setStatus(data.status);
        setDiplome(data.diplome);
        setSkills(data.skills);
        setLangues(data.langues);
        setExperience(data.experience);
        setDiplomeDate(data.diplomeDate);
        setType("employee");
        setCv(data.cv?._id);
        setJob(data.type.name);
      })
      .catch((err) => {
        DisplayError();
      });
  }

  function sendData() {
    swal({
      title: "Voulez vous Ajouter cette candidat en tant qu'employe ? ",
      icon: "warning",
      buttons: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .put(`${baseURL}/consultants/${candidatId}`, {
            nirpp: nirpp,
            company: company,
            job: job,
            startDate: startDate,
            typeOfContract: typeOfContract,
            salary: salary,
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone.toString(),
            avatar: avatar?._id,
            user: user,
            birthday: birthday,
            birthPlace: birthPlace,
            city: city,
            address: address,
            cp: cp,
            gender: gender,
            isEnabled: isEnabled,
            status: status,
            diplome: diplome,
            type: type,
            langues: langues,
            experience: experience,
            diplomeDate: diplomeDate,
            skills: skills,
            candidatId: candidatId,
            cv: cv,
          })
          .then((resp) => {
            setFormErrors(initFormErrors);
            if (resp.data.error) return;
            swal(resp.data.message, {
              icon: "success",
              timer: 2500,
            });
            if (getAllCandidats) {
              getAllCandidats();
            }
            setaddEmployeeModalFromChild("");
          })
          .catch((error) => {
            DisplayError(
              error.response.data.msg,
              error.response.data.employeeID
                ? error.response.data.employeeID
                : null,
              error.response.data.employeeID ? "/employees/details/" : null,

              history
            );
            setFormErrors(error.response.data);
          });
      }
    });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="col-md-12 mt-2">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  id="nirpp"
                  placeholder="Nirpp"
                  onChange={(e) => setNirpp(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.nirpp}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  id="salary"
                  placeholder="Salary"
                  onChange={(e) => setSalary(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.salary}</span>
                </div>
              </div>
              {emailCheck === "" ? (
                <div className="col-md-12 mt-2">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.email}</span>
                  </div>
                </div>
              ) : null}
              <div className="col-md-12 mt-2">
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  placeholder="Date début de mission"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.startDate}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <select
                  id="companyId"
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                  className="form-select">
                  <option disabled selected value="">
                    Sélectionner une societe
                  </option>
                  {companies != null &&
                    companies.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {`${item.name}`}
                        </option>
                      );
                    })}
                </select>
                <div className="input-error">
                  <span className="text-danger">{formerrors.company}</span>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <select
                  id="typeOfContract"
                  onChange={(e) => {
                    setTypeOfContract(e.target.value);
                  }}
                  className="form-select"
                  defaultValue="cdd">
                  <option disabled value="" selected>
                    Sélectionner un type de contrat
                  </option>

                  <option value={"cdd"}>cdd</option>
                  <option value={"cdi"}>cdi</option>
                  <option value={"internship"}>internship</option>
                </select>
                <div className="input-error">
                  <span className="text-danger">
                    {formerrors.typeOfContract}
                  </span>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setaddEmployeeModalFromChild("")}>
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default CadidatToEmplyee;
