import axios from "axios";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import ManagersComponent from "../../components/manager/managerDetails";
import CompanyDetailsComponent from "../../components/company/companyDetailsComponent";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import CreateMission from "../../components/mission/createMission";
import EditMission from "../../components/mission/updateMission";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import { Dropdown } from "react-bootstrap";
import CreateNote from "../../components/candidat/candidatNote";
import UpdateCandidat from "../../components/candidat/updateStepsCandidats/Updatecandidat";
import CadidatToEmplyeeOffCanvas from "../../components/consultant/CadidatToEmplyeeOffCanvas";
import { useHistory } from "react-router-dom";
import { Switch, Tooltip } from "@mui/material";
import UpdateCompany from "./UpdateCompany";

const CompanyDetails = () => {
  const { user } = useContext(UserContext);

  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasFournisseurConsultPermission = hasPermission(
    user,
    PERMISSIONS.fournisseur,
    "consult"
  );
  const hasManagerConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const hasMissionsEditPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "edit"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const hasMissionsStatusPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "change_status"
  );
  const hasMissionsCreatePermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "create"
  );
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consulter_enabled"
  );
  const hasCandidatsEditPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "edit"
  );
  const hasCandidatsNotePermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "create_note"
  );
  const hasCandidatsNoteConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consulter_note"
  );
  const hasEmployeeCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create_from_candidat"
  );
  const hasCompanyUpdatePermission = hasPermission(
    user,
    PERMISSIONS.company,
    "edit"
  );
  const hasCvCreatePermission = hasPermission(user, PERMISSIONS.cv, "create");
  const hasMissionConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasExperienceCandidatPermission = hasPermission(
    user,
    PERMISSIONS.experience,
    "consult"
  );
  const hasCompanyStatusPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "change_status"
  );
  const [supplier, setSupplier] = useState([]);
  const [company, setCompany] = useState([]);
  const [childComponent, setChildComponent] = useState();
  const [activeDetails, setActiveDetails] = useState("active");
  const [activeDManagers, setActiveDManagers] = useState("");
  const { companyId } = useParams();
  const [mission, setMission] = useState([]);
  const [managers, setManagers] = useState([]);
  const [missionData, setMissionData] = useState([]);
  const noDataFound = "Aucune donnée trouvée";
  const history = useHistory();
  const [selectedMissionForUpdate, setSelectedMissionForUpdate] =
    useState(null);
  function handleUpdateMissionButtonClick(missionId) {
    setSelectedMissionForUpdate(missionId);
  }
  async function getAllCompany() {
    if (companyId === undefined) return;
    await axios.get(`${baseURL}/companies/${companyId}`).then((response) => {
      if (response.data.error) return;
      setCompany(response.data.data);
    });
  }

  function getManagerByCompanyId() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/managers/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setManagers(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    getAllCompany();
  }, []);
  useEffect(() => {
    if (hasManagerConsultPermission) {
      getManagerByCompanyId();
    }
  }, [hasManagerConsultPermission]);
  useEffect(() => {
    // if (hasFournisseurConsultPermission) {
    getSupplierByCompanyId();
    // }
  }, [companyId]);
  useEffect(() => {
    if (hasMissionsConsultPermission) {
      getMissionByCompanyId();
    }
  }, [hasMissionsConsultPermission]);
  async function getSupplierByCompanyId() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/consultants/companies/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setSupplier(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  async function getMissionByCompanyId() {
    if (companyId === undefined) return;
    axios
      .get(`${baseURL}/missions/company/${companyId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (activeDetails === "active") {
      setChildComponent(
        <CompanyDetailsComponent
          getAllCompany={getAllCompany}
          company={company}
        />
      );
    } else if (activeDManagers === "active") {
      <ManagersComponent company_id={companyId} />;
    }
  }, [company]);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 575);
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 575);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable
          columns={columnsMission}
          data={mission}
          className="dataTableClass"
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);

  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getMissionByCompanyId();
            }
          });
      }
    });
  }

  async function enableDisable(supplierId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous désactiver ce consultant?"
        : "Voulez vous activer ce consultant?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/consultants/toggleStatus/${supplierId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getSupplierByCompanyId();
            }
          });
      }
    });
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "4%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <div>
          <Link to={`/suppliers/details/${row._id}`} className="color-msit">
            <img
              src={baseURL + row.avatar?.path.substring(1)}
              className="rounded-circle"
              width="35"
              height="35"
            />
            {row.lname} {row.fname}
          </Link>
        </div>
      ),
      width: "15%",
      sortable: true,
    },

    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div className=" col-md-12">
            <p className="p-0 m-0 col-md-12">
              {row.email ? (
                <a
                  href={`mailto:${row.email}`}
                  className="color-msit "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Envoyer mail">
                  <i className="bi bi-envelope-fill"></i> {row.email}
                </a>
              ) : (
                <span className="color-msit">{noDataFound}</span>
              )}
            </p>
            <p className="p-0 m-0 col-md-12">
              {row.phone ? (
                <a
                  href={`tel:+${row.phone}`}
                  className="color-msit "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Appeler">
                  <i className="bi bi-telephone-fill"></i> {row.phone}
                </a>
              ) : (
                <span className="color-msit">{noDataFound}</span>
              )}
            </p>
          </div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Adresse",
      selector: (row) => (
        <div>
          <div> {row.address || noDataFound}</div>
          {row.city?.name || noDataFound}
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "type de contrat",
      selector: (row) => (
        <div>
          <div>{row.typeOfContract || noDataFound}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Expérience",
      selector: (row) => (
        <div>
          <div>{row.experience} ans</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="row col-12">
          <Link
            className="col-3 btn text-primary me-2"
            to={`/supplier/edit/${row._id}`}>
            <i className="bi bi-pen"></i>
          </Link>
          <button
            className="col-3 btn text-primary me-2 btn"
            onClick={() => {
              enableDisable(row._id, row.isEnabled);
            }}>
            {row.isEnabled === "yes" ? (
              <i className="text-success bi bi-toggle2-on"></i>
            ) : (
              <i className="text-danger bi bi-toggle2-off"></i>
            )}
          </button>
          <Link
            className="col-3 btn text-primary me-2"
            to={`/suppliers/details/${row._id}`}>
            <i className="ri-arrow-right-s-fill"></i>
          </Link>
        </div>
      ),
    },
  ];

  const columnsMission = [
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <Link className="color-msit " to={`/missions/details/${row?._id}`}>
            {row?.title}
          </Link>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>
            {row.description ? (
              <>
                {row?.description?.substring(0, 15)}
                {row?.description?.length > 15 ? "..." : ""}
              </>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ),
      hide: "md",
      sortable: true,
      center: true,
    },
    {
      name: "Date de début ",
      selector: (row) => (
        <div>
          <div>{row?.startDate}</div>
        </div>
      ),
      hide: "lg",
      sortable: true,
      center: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row?.period}</div>
        </div>
      ),
      hide: "lg",
      sortable: true,
      center: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div>
            {row?.manager != null && row?.manager?.fname}
            {row?.manager != null && row?.manager?.lname}
            {" / "}
            {row?.manager != null && row?.company?.name}
          </div>
        </div>
      ),
      sortable: true,
      hide: "sm",
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasMissionsStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisableMission(row?._id, row?.isEnabled);
                      }}
                      checked={row?.isEnabled === "yes"}
                    />
                    Activer la mission
                  </label>
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                href={`#/missions/details/${row?._id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2"> Consulter la mission </span>
              </Dropdown.Item>
              {hasMissionsEditPermission &&
                hasCompanyConsultPermission &&
                hasManagersConsultPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#modifierMission"
                    aria-controls="modifierMission"
                    onClick={() => handleUpdateMissionButtonClick(row?._id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier la mission</span>
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Date de début :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.startDate || noDataFound}
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Période :</div>
            {data?.period || noDataFound}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 599 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Contact :</div>
            {data.manager !== null
              ? data?.manager?.fname +
                " " +
                data?.manager?.lname +
                " / " +
                company?.name
              : noDataFound}
          </div>
          <div className="col-md-12 my-1">
            <div className="ms-1 text-details">Description :</div>

            {data.description ? (
              <Tooltip title={data?.description}>
                <div>
                  {data?.description?.substring(0, 20)}
                  {data?.description?.length > 20 ? "..." : ""}
                </div>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 959 && isMobile >= 600 ? (
        <div className="row my-1">
          <div className="col-md-12 my-1">
            <div className="ms-1 text-details">Description :</div>

            {data.description ? (
              <Tooltip title={data.description} arrow>
                <div>
                  {data?.description?.substring(0, 60)}
                  {data?.description?.length > 60 ? "..." : ""}
                </div>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );
  async function enableDisable(companyId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette sociètè?"
        : "Voulez vous Activer cette sociètè?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/companies/toggleStatus/${companyId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            swal(resp.data.message, {
              icon: "success",
              timer: 2500,
            });
            if (!usersInfo.error) {
              getAllCompany();
            }
          });
      }
    });
  }
  const secondOffcanvasRef = useRef();

  const ouvrirSecondOffcanvas = () => {
    const secondOffcanvas = new window.bootstrap.Offcanvas(
      secondOffcanvasRef.current
    );
    secondOffcanvas.show();
  };

  const [selectedForUpdate, setSelectedForUpdate] = useState(null);
  function handleUpdateConsultantButtonClick(candidatId) {
    setSelectedForUpdate(candidatId);
  }
  async function employeetouser(employeeId) {
    swal({
      title: "voulez vous definire ce employé comme un utilisateur ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmed) => {
        if (confirmed) {
          // getDetailsEmployee(employeeId);
        }
      })
      .catch((error) => {
        DisplayError();
      });
  }
  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <h1>Détails du Société</h1>
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-outline-secondary mb-2 float-end  me-2"
            onClick={history.goBack}>
            Retour
          </button>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/company">Les Sociétés</Link>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section candidat">
        <div className="row card-candidat d-lg-flex ms-0  pb-3">
          <div className="col-1  my-4">
            <img className="img-company" alt="" src={company.logo} />
          </div>
          <div className="col-9 d-flex flex-column row justify-content-center align-items-center align-content-center  ms-4 ms-lg-2">
            <div className="row">
              <h2 className="ms-4 ms-lg-0 margin-details margin-name">
                {company?.name &&
                  `${company.name.substring(0, 25)}${
                    company.name.length > 25 ? "..." : ""
                  }`}
              </h2>
            </div>
          </div>
          <div className="col-2 text-center">
            <Dropdown className="w-auto">
              <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none">
                  <path
                    d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                    stroke="#98A2B3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu className="candidat-dropdown-menu">
                {hasCompanyStatusPermission && (
                  <Dropdown.Item
                    onClick={() => {
                      enableDisable(company._id, company.isEnabled);
                    }}
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                    <label className="form-check form-check-single form-switch">
                      <input
                        className="form-check-input"
                        data-placement="top"
                        type="checkbox"
                        checked={company.isEnabled === "yes"}
                      />
                      Activer la société
                    </label>
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />

                {hasCompanyUpdatePermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#modifierUtilisateur"
                    aria-controls="modifierUtilisateur">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier la société</span>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex justify-content-lg-end justify-content-start margin-details">
            <div className="col-lg-11 row">
              <div className="col-xl-6 row">
                <div className="col-6 ">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M9.16329 1.75466L14.182 5.0168C14.3593 5.13207 14.448 5.18971 14.5122 5.26659C14.5691 5.33464 14.6118 5.41335 14.6379 5.49812C14.6673 5.59388 14.6673 5.69964 14.6673 5.91114V10.7989C14.6673 11.9191 14.6673 12.4791 14.4493 12.9069C14.2576 13.2833 13.9516 13.5892 13.5753 13.781C13.1475 13.9989 12.5874 13.9989 11.4673 13.9989H4.53398C3.41388 13.9989 2.85383 13.9989 2.426 13.781C2.04968 13.5892 1.74372 13.2833 1.55197 12.9069C1.33398 12.4791 1.33398 11.9191 1.33398 10.7989V5.91114C1.33398 5.69964 1.33398 5.59388 1.36343 5.49812C1.3895 5.41335 1.43222 5.33464 1.48908 5.26659C1.55332 5.18971 1.64199 5.13207 1.81933 5.0168L6.83801 1.75466M9.16329 1.75466C8.74246 1.48112 8.53205 1.34435 8.30534 1.29114C8.10494 1.2441 7.89637 1.2441 7.69597 1.29114C7.46925 1.34435 7.25884 1.48112 6.83801 1.75466M9.16329 1.75466L13.2914 4.43794C13.75 4.73601 13.9793 4.88504 14.0587 5.07404C14.1281 5.2392 14.1281 5.42535 14.0587 5.59052C13.9793 5.77952 13.75 5.92855 13.2914 6.22662L9.16329 8.9099C8.74247 9.18343 8.53205 9.3202 8.30534 9.37342C8.10494 9.42046 7.89637 9.42046 7.69597 9.37342C7.46925 9.3202 7.25884 9.18343 6.83801 8.90989L2.70989 6.22662C2.25133 5.92855 2.02204 5.77952 1.94263 5.59052C1.87324 5.42535 1.87324 5.2392 1.94263 5.07404C2.02204 4.88504 2.25133 4.73601 2.70989 4.43794L6.83801 1.75466M14.334 12.6656L9.90544 8.66561M6.09587 8.66561L1.66732 12.6656"
                        stroke="black"
                        stroke-width="1.06667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="ms-1 text-details">Email :</div>
                  </div>
                  <span className="mt-2">
                    {company?.email
                      ? `${company.email.substring(0, 17)}${
                          company.email.length > 17 ? "..." : ""
                        }`
                      : "Aucune donnée trouvée"}
                  </span>
                </div>
                <div className="col-6 ">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M10.0004 2.66667V6M10.0004 6L13.3337 6M10.0004 6L14.0004 2M6.81841 9.24205C6.01736 8.44099 5.38483 7.53523 4.92084 6.56882C4.88092 6.48569 4.86097 6.44413 4.84564 6.39154C4.79115 6.20463 4.83029 5.97513 4.94363 5.81684C4.97552 5.7723 5.01363 5.7342 5.08984 5.65799C5.32291 5.42492 5.43944 5.30838 5.51563 5.1912C5.80296 4.74927 5.80296 4.17955 5.51563 3.73762C5.43944 3.62044 5.32291 3.5039 5.08984 3.27083L4.95992 3.14092C4.60563 2.78662 4.42848 2.60947 4.23823 2.51324C3.85985 2.32186 3.41301 2.32186 3.03464 2.51324C2.84438 2.60947 2.66723 2.78662 2.31294 3.14092L2.20785 3.24601C1.85477 3.59909 1.67823 3.77563 1.54339 4.01565C1.39378 4.28199 1.28621 4.69565 1.28712 5.00113C1.28793 5.27643 1.34134 5.46458 1.44814 5.84087C2.02212 7.86314 3.1051 9.77138 4.69709 11.3634C6.28908 12.9554 8.19732 14.0383 10.2196 14.6123C10.5959 14.7191 10.784 14.7725 11.0593 14.7733C11.3648 14.7743 11.7785 14.6667 12.0448 14.5171C12.2848 14.3822 12.4614 14.2057 12.8145 13.8526L12.9195 13.7475C13.2738 13.3932 13.451 13.2161 13.5472 13.0258C13.7386 12.6474 13.7386 12.2006 13.5472 11.8222C13.451 11.632 13.2738 11.4548 12.9195 11.1005L12.7896 10.9706C12.5566 10.7376 12.44 10.621 12.3228 10.5448C11.8809 10.2575 11.3112 10.2575 10.8693 10.5448C10.7521 10.621 10.6355 10.7376 10.4025 10.9706C10.3263 11.0468 10.2882 11.0849 10.2436 11.1168C10.0853 11.2302 9.85582 11.2693 9.66892 11.2148C9.61633 11.1995 9.57476 11.1795 9.49164 11.1396C8.52523 10.6756 7.61946 10.0431 6.81841 9.24205Z"
                        stroke="black"
                        stroke-width="1.07"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="ms-1 text-details">Téléphone :</div>
                  </div>
                  <span className="mt-2">
                    {company?.phone
                      ? `${company.phone.substring(0, 25)}${
                          company.phone.length > 25 ? "..." : ""
                        }`
                      : "Aucune donnée trouvée"}
                  </span>
                </div>
              </div>
              <div className="col-xl-6 row">
                <div className="col-6">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clip-path="url(#clip0_6420_67834)">
                        <path
                          d="M1.33398 8.00065H14.6673M1.33398 8.00065C1.33398 11.6826 4.31875 14.6673 8.00065 14.6673M1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398M14.6673 8.00065C14.6673 11.6826 11.6826 14.6673 8.00065 14.6673M14.6673 8.00065C14.6673 4.31875 11.6826 1.33398 8.00065 1.33398M8.00065 1.33398C9.66817 3.15955 10.6158 5.52867 10.6673 8.00065C10.6158 10.4726 9.66817 12.8418 8.00065 14.6673M8.00065 1.33398C6.33313 3.15955 5.38548 5.52867 5.33398 8.00065C5.38548 10.4726 6.33313 12.8418 8.00065 14.6673"
                          stroke="black"
                          stroke-width="1.06667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6420_67834">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <div className="ms-1 text-details">Website :</div>
                  </div>
                  <span className="mt-2">
                    {company?.website
                      ? `${company.website.substring(0, 25)}${
                          company.website.length > 25 ? "..." : ""
                        }`
                      : "Aucune donnée trouvée"}
                  </span>
                </div>
                <div className="col-6">
                  <div className="d-flex ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M7.99935 8.66732C9.10392 8.66732 9.99935 7.77189 9.99935 6.66732C9.99935 5.56275 9.10392 4.66732 7.99935 4.66732C6.89478 4.66732 5.99935 5.56275 5.99935 6.66732C5.99935 7.77189 6.89478 8.66732 7.99935 8.66732Z"
                        stroke="black"
                        stroke-width="1.07"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.99935 14.6673C10.666 12.0007 13.3327 9.61284 13.3327 6.66732C13.3327 3.7218 10.9449 1.33398 7.99935 1.33398C5.05383 1.33398 2.66602 3.7218 2.66602 6.66732C2.66602 9.61284 5.33268 12.0007 7.99935 14.6673Z"
                        stroke="black"
                        stroke-width="1.07"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="ms-1 text-details">Adresse :</div>
                  </div>
                  <span className="mt-2">
                    {company?.address
                      ? `${company.address.substring(0, 25)}${
                          company.address.length > 25 ? "..." : ""
                        }`
                      : "Aucune donnée trouvée"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pt-3 ms-2 card-candidat">
        <ul className="nav nav-tabs nav-tabs-bordered">
          <li className="nav-item">
            <button
              className={`nav-link ${activeDetails}`}
              data-bs-toggle="tab"
              data-bs-target="#profile-overview"
              onClick={() => {
                setActiveDetails("active");
                setActiveDManagers("");
                setChildComponent(
                  <CompanyDetailsComponent
                    getAllCompany={getAllCompany}
                    company={company}
                  />
                );
              }}>
              Détails
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeDManagers}`}
              data-bs-toggle="tab"
              data-bs-target="#profile-overview"
              onClick={() => {
                setActiveDetails("");
                setActiveDManagers("active");
                setChildComponent(<ManagersComponent company_id={companyId} />);
              }}>
              Contacts
            </button>
          </li>
        </ul>
        {childComponent}
      </div>
      <div className=" card-candidat">
        <div className=" row p-4">
          <div className="col-md-8">
            <h5 className="fw-bold mb-3">Liste des missions</h5>
          </div>
          {hasMissionsCreatePermission &&
            hasCompanyConsultPermission &&
            hasManagersConsultPermission && (
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-primary btn-rounded float-end text-sm"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ajouterMission"
                  aria-controls="ajouterMission">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M9.99935 10.8333V5.83333M7.49935 8.33333H12.4993M15.8327 17.5V6.5C15.8327 5.09987 15.8327 4.3998 15.5602 3.86502C15.3205 3.39462 14.9381 3.01217 14.4677 2.77248C13.9329 2.5 13.2328 2.5 11.8327 2.5H8.16602C6.76588 2.5 6.06582 2.5 5.53104 2.77248C5.06063 3.01217 4.67818 3.39462 4.4385 3.86502C4.16602 4.3998 4.16602 5.09987 4.16602 6.5V17.5L9.99935 14.1667L15.8327 17.5Z"
                      stroke="white"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="ms-2">Ajouter une mission</span>
                </button>
              </div>
            )}
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">{missionData}</div>
          </div>
        </div>
      </div>
      <div className=" card-candidat">
        <div className=" row p-4">
          <div className="col-md-8">
            <h5 className="fw-bold mb-3">Liste des consultants</h5>
          </div>
          {/* <div className="col-md-4">
            <button
              type="button"
              className="btn btn-primary btn-rounded float-end text-sm"
              // data-bs-toggle="offcanvas"
              // data-bs-target="#ajouterMission"
              // aria-controls="ajouterMission"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.99935 10.8333V5.83333M7.49935 8.33333H12.4993M15.8327 17.5V6.5C15.8327 5.09987 15.8327 4.3998 15.5602 3.86502C15.3205 3.39462 14.9381 3.01217 14.4677 2.77248C13.9329 2.5 13.2328 2.5 11.8327 2.5H8.16602C6.76588 2.5 6.06582 2.5 5.53104 2.77248C5.06063 3.01217 4.67818 3.39462 4.4385 3.86502C4.16602 4.3998 4.16602 5.09987 4.16602 6.5V17.5L9.99935 14.1667L15.8327 17.5Z"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span className="ms-2">Ajouter un consultant</span>
            </button>
          </div> */}
        </div>
        <div>
          <div className="row mx-0 gap-3 gap-md-0 mb-4">
            {supplier ? (
              supplier.map((supp) => {
                return (
                  <div className="col-lg-3  col-md-4  mt-2 ">
                    <div className="consultant-card">
                      <div className="row col-12">
                        <div className="col-8">
                          <img
                            src={baseURL + supp.avatar?.path.substring(1)}
                            className="rounded-circle"
                            width="65"
                            height="65"
                          />
                        </div>
                        <div className="col-4 ">
                          <label className="form-check float-end float-lg-none float-xl-end form-check-single form-switch">
                            <input
                              className="form-check-input"
                              data-placement="top"
                              type="checkbox"
                              onChange={() => {
                                enableDisable(supp._id, supp.isEnabled);
                              }}
                              checked={supp.isEnabled === "yes"}
                            />
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="consultant-first-titles-name mt-0 mt-3 mb-0 consultant-first-titles">
                          {supp.lastname} {supp.firstname}
                        </div>
                        <div className="consultant-second-titles">
                          {supp.posteType?.name}
                        </div>
                      </div>
                      <div>
                        <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M9.16329 1.75466L14.182 5.0168C14.3593 5.13207 14.448 5.18971 14.5122 5.26659C14.5691 5.33464 14.6118 5.41335 14.6379 5.49812C14.6673 5.59388 14.6673 5.69964 14.6673 5.91114V10.7989C14.6673 11.9191 14.6673 12.4791 14.4493 12.9069C14.2576 13.2833 13.9516 13.5892 13.5753 13.781C13.1475 13.9989 12.5874 13.9989 11.4673 13.9989H4.53398C3.41388 13.9989 2.85383 13.9989 2.426 13.781C2.04968 13.5892 1.74372 13.2833 1.55197 12.9069C1.33398 12.4791 1.33398 11.9191 1.33398 10.7989V5.91114C1.33398 5.69964 1.33398 5.59388 1.36343 5.49812C1.3895 5.41335 1.43222 5.33464 1.48908 5.26659C1.55332 5.18971 1.64199 5.13207 1.81933 5.0168L6.83801 1.75466M9.16329 1.75466C8.74246 1.48112 8.53205 1.34435 8.30534 1.29114C8.10494 1.2441 7.89637 1.2441 7.69597 1.29114C7.46925 1.34435 7.25884 1.48112 6.83801 1.75466M9.16329 1.75466L13.2914 4.43794C13.75 4.73601 13.9793 4.88504 14.0587 5.07404C14.1281 5.2392 14.1281 5.42535 14.0587 5.59052C13.9793 5.77952 13.75 5.92855 13.2914 6.22662L9.16329 8.9099C8.74247 9.18343 8.53205 9.3202 8.30534 9.37342C8.10494 9.42046 7.89637 9.42046 7.69597 9.37342C7.46925 9.3202 7.25884 9.18343 6.83801 8.90989L2.70989 6.22662C2.25133 5.92855 2.02204 5.77952 1.94263 5.59052C1.87324 5.42535 1.87324 5.2392 1.94263 5.07404C2.02204 4.88504 2.25133 4.73601 2.70989 4.43794L6.83801 1.75466M14.334 12.6656L9.90544 8.66561M6.09587 8.66561L1.66732 12.6656"
                              stroke="black"
                              stroke-width="1.06667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="ms-2">Email :</span>
                        </div>
                        <div className="consultant-second-titles">
                          {supp?.email
                            ? supp.email.length > 17
                              ? `${supp.email.substring(0, 17)}...`
                              : supp.email
                            : "Aucune donnée trouvée"}
                        </div>
                      </div>
                      <div>
                        <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M10.0004 2.66667V6M10.0004 6L13.3337 6M10.0004 6L14.0004 2M6.81841 9.24205C6.01736 8.44099 5.38483 7.53523 4.92084 6.56882C4.88092 6.48569 4.86097 6.44413 4.84564 6.39154C4.79115 6.20463 4.83029 5.97513 4.94363 5.81684C4.97552 5.7723 5.01363 5.7342 5.08984 5.65799C5.32291 5.42492 5.43944 5.30838 5.51563 5.1912C5.80296 4.74927 5.80296 4.17955 5.51563 3.73762C5.43944 3.62044 5.32291 3.5039 5.08984 3.27083L4.95992 3.14092C4.60563 2.78662 4.42848 2.60947 4.23823 2.51324C3.85985 2.32186 3.41301 2.32186 3.03464 2.51324C2.84438 2.60947 2.66723 2.78662 2.31294 3.14092L2.20785 3.24601C1.85477 3.59909 1.67823 3.77563 1.54339 4.01565C1.39378 4.28199 1.28621 4.69565 1.28712 5.00113C1.28793 5.27643 1.34134 5.46458 1.44814 5.84087C2.02212 7.86314 3.1051 9.77138 4.69709 11.3634C6.28908 12.9554 8.19732 14.0383 10.2196 14.6123C10.5959 14.7191 10.784 14.7725 11.0593 14.7733C11.3648 14.7743 11.7785 14.6667 12.0448 14.5171C12.2848 14.3822 12.4614 14.2057 12.8145 13.8526L12.9195 13.7475C13.2738 13.3932 13.451 13.2161 13.5472 13.0258C13.7386 12.6474 13.7386 12.2006 13.5472 11.8222C13.451 11.632 13.2738 11.4548 12.9195 11.1005L12.7896 10.9706C12.5566 10.7376 12.44 10.621 12.3228 10.5448C11.8809 10.2575 11.3112 10.2575 10.8693 10.5448C10.7521 10.621 10.6355 10.7376 10.4025 10.9706C10.3263 11.0468 10.2882 11.0849 10.2436 11.1168C10.0853 11.2302 9.85582 11.2693 9.66892 11.2148C9.61633 11.1995 9.57476 11.1795 9.49164 11.1396C8.52523 10.6756 7.61946 10.0431 6.81841 9.24205Z"
                              stroke="black"
                              stroke-width="1.07"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="ms-2">Téléphone :</span>
                        </div>
                        <div className="consultant-second-titles">
                          {supp.phone
                            ? supp.phone.length > 15
                              ? `${supp.phone.substring(0, 15)}...`
                              : supp.phone
                            : "Aucune donnée trouvée".length > 15
                            ? `${"Aucune donnée trouvée".substring(0, 15)}...`
                            : "Aucune donnée trouvée"}
                        </div>
                      </div>
                      <div>
                        <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M10.0004 2.66667V6M10.0004 6L13.3337 6M10.0004 6L14.0004 2M6.81841 9.24205C6.01736 8.44099 5.38483 7.53523 4.92084 6.56882C4.88092 6.48569 4.86097 6.44413 4.84564 6.39154C4.79115 6.20463 4.83029 5.97513 4.94363 5.81684C4.97552 5.7723 5.01363 5.7342 5.08984 5.65799C5.32291 5.42492 5.43944 5.30838 5.51563 5.1912C5.80296 4.74927 5.80296 4.17955 5.51563 3.73762C5.43944 3.62044 5.32291 3.5039 5.08984 3.27083L4.95992 3.14092C4.60563 2.78662 4.42848 2.60947 4.23823 2.51324C3.85985 2.32186 3.41301 2.32186 3.03464 2.51324C2.84438 2.60947 2.66723 2.78662 2.31294 3.14092L2.20785 3.24601C1.85477 3.59909 1.67823 3.77563 1.54339 4.01565C1.39378 4.28199 1.28621 4.69565 1.28712 5.00113C1.28793 5.27643 1.34134 5.46458 1.44814 5.84087C2.02212 7.86314 3.1051 9.77138 4.69709 11.3634C6.28908 12.9554 8.19732 14.0383 10.2196 14.6123C10.5959 14.7191 10.784 14.7725 11.0593 14.7733C11.3648 14.7743 11.7785 14.6667 12.0448 14.5171C12.2848 14.3822 12.4614 14.2057 12.8145 13.8526L12.9195 13.7475C13.2738 13.3932 13.451 13.2161 13.5472 13.0258C13.7386 12.6474 13.7386 12.2006 13.5472 11.8222C13.451 11.632 13.2738 11.4548 12.9195 11.1005L12.7896 10.9706C12.5566 10.7376 12.44 10.621 12.3228 10.5448C11.8809 10.2575 11.3112 10.2575 10.8693 10.5448C10.7521 10.621 10.6355 10.7376 10.4025 10.9706C10.3263 11.0468 10.2882 11.0849 10.2436 11.1168C10.0853 11.2302 9.85582 11.2693 9.66892 11.2148C9.61633 11.1995 9.57476 11.1795 9.49164 11.1396C8.52523 10.6756 7.61946 10.0431 6.81841 9.24205Z"
                              stroke="black"
                              stroke-width="1.07"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="ms-2">Adresse :</span>
                        </div>
                        <div className="consultant-second-titles">
                          {supp.address
                            ? supp.address.length > 17
                              ? `${supp.address.substring(0, 17)}...`
                              : supp.address
                            : "Aucune donnée trouvée".length > 15
                            ? `${"Aucune donnée trouvée".substring(0, 15)}...`
                            : "Aucune donnée trouvée"}
                        </div>
                      </div>
                      <div>
                        <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M13.3327 7.96144V4.53398C13.3327 3.41388 13.3327 2.85383 13.1147 2.426C12.9229 2.04968 12.617 1.74372 12.2407 1.55197C11.8128 1.33398 11.2528 1.33398 10.1327 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H9.33268M9.33268 7.33398H5.33268M6.66602 10.0007H5.33268M10.666 4.66732H5.33268M9.99935 11.334H13.9993"
                              stroke="black"
                              stroke-width="1.07"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="ms-2">Expérience :</span>
                        </div>
                        <div className="consultant-second-titles">
                          {supp.experience
                            ? supp.experience.toString().length > 17
                              ? `${supp.experience
                                  .toString()
                                  .substring(0, 17)}... ans`
                              : `${supp.experience} ans`
                            : "Aucune donnée trouvée".length > 15
                            ? `${"Aucune donnée trouvée".substring(0, 15)}...`
                            : "Aucune donnée trouvée"}
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-end">
                        <Dropdown className="w-auto">
                          <Dropdown.Toggle bsPrefix=" border-0 px-1  bg-primary ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none">
                              <path
                                d="M9.99935 10.834C10.4596 10.834 10.8327 10.4609 10.8327 10.0007C10.8327 9.54041 10.4596 9.16732 9.99935 9.16732C9.53911 9.16732 9.16602 9.54041 9.16602 10.0007C9.16602 10.4609 9.53911 10.834 9.99935 10.834Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.99935 5.00065C10.4596 5.00065 10.8327 4.62755 10.8327 4.16732C10.8327 3.70708 10.4596 3.33398 9.99935 3.33398C9.53911 3.33398 9.16602 3.70708 9.16602 4.16732C9.16602 4.62755 9.53911 5.00065 9.99935 5.00065Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.99935 16.6673C10.4596 16.6673 10.8327 16.2942 10.8327 15.834C10.8327 15.3737 10.4596 15.0007 9.99935 15.0007C9.53911 15.0007 9.16602 15.3737 9.16602 15.834C9.16602 16.2942 9.53911 16.6673 9.99935 16.6673Z"
                                stroke="white"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="candidat-dropdown-menu">
                            <Dropdown.Item
                              className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                              href={`#/candidats/details/${supp._id}`}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none">
                                <path
                                  d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                                  stroke="#344054"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                                  stroke="#344054"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span className="ms-2">Consulter le profil</span>
                            </Dropdown.Item>
                            {hasCandidatTypesConsultPermission &&
                              hasCandidatsEditPermission && (
                                <Dropdown.Item
                                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasUpdateConsultant"
                                  aria-controls="offcanvasUpdateConsultant"
                                  onClick={() => {
                                    handleUpdateConsultantButtonClick(supp._id);
                                  }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none">
                                    <g clipPath="url(#clip0_192_15848)">
                                      <path
                                        d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                                        stroke="#344054"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_192_15848">
                                        <rect
                                          width="16"
                                          height="16"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <span className="ms-2">
                                    Modifier les informations
                                  </span>
                                </Dropdown.Item>
                              )}
                            {hasCandidatsNotePermission &&
                              hasCandidatsNoteConsultPermission && (
                                <Dropdown.Item
                                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#addNoteCandidat"
                                  aria-controls="addNoteCandidat"
                                  onClick={() =>
                                    handleUpdateConsultantButtonClick(supp._id)
                                  }>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                      d="M9.33268 1.51367V4.26737C9.33268 4.64073 9.33268 4.82742 9.40534 4.97003C9.46926 5.09547 9.57125 5.19745 9.69669 5.26137C9.8393 5.33403 10.026 5.33403 10.3993 5.33403H13.153M7.99935 12.0007V8.00065M5.99935 10.0007H9.99935M9.33268 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H10.1327C11.2528 14.6673 11.8128 14.6673 12.2407 14.4493C12.617 14.2576 12.9229 13.9516 13.1147 13.5753C13.3327 13.1475 13.3327 12.5874 13.3327 11.4673V5.33398L9.33268 1.33398Z"
                                      stroke="#344054"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span className="ms-2">
                                    {" "}
                                    Ajouter une note
                                  </span>
                                </Dropdown.Item>
                              )}
                            {/* {hasCompanyConsultPermission &&
                          hasEmployeeCreatePermission && (
                            <Dropdown.Item
                              className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                              data-bs-toggle={
                                supp.type === "candidat" ? `offcanvas` : ""
                              }
                              data-bs-target={
                                supp.type === "candidat"
                                  ? `#TransformToEmployee`
                                  : ""
                              }
                              aria-controls={
                                supp.type === "candidat"
                                  ? `TransformToEmployee`
                                  : ""
                              }
                              onClick={() => {
                                supp.type === "candidat"
                                  ? setSelectedForUpdate(supp._id)
                                  : employeetouser(supp._id);
                              }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none">
                                <path
                                  d="M10.6673 12L12.0007 13.3333L14.6673 10.6667M8.00065 10H5.33398C4.09147 10 3.47022 10 2.98016 10.203C2.32675 10.4736 1.80762 10.9928 1.53697 11.6462C1.33398 12.1362 1.33398 12.7575 1.33398 14M10.334 2.19384C11.3113 2.58943 12.0007 3.54754 12.0007 4.66667C12.0007 5.78579 11.3113 6.7439 10.334 7.13949M9.00065 4.66667C9.00065 6.13943 7.80674 7.33333 6.33398 7.33333C4.86123 7.33333 3.66732 6.13943 3.66732 4.66667C3.66732 3.19391 4.86123 2 6.33398 2C7.80674 2 9.00065 3.19391 9.00065 4.66667Z"
                                  stroke="#344054"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <span className="ms-2">
                                Transformer en
                                {supp.type === "candidat"
                                  ? "employer"
                                  : "utilisateur"}
                              </span>
                            </Dropdown.Item>
                          )} */}
                            {hasCvCreatePermission &&
                              hasMissionConsultPermission &&
                              hasExperienceCandidatPermission && (
                                <Dropdown.Item
                                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                                  href={`#/candidats/createCv/${supp._id}`}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                      d="M13.3327 8.33398V4.53398C13.3327 3.41388 13.3327 2.85383 13.1147 2.426C12.9229 2.04968 12.617 1.74372 12.2407 1.55197C11.8128 1.33398 11.2528 1.33398 10.1327 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H7.99935M9.33268 7.33398H5.33268M6.66602 10.0007H5.33268M10.666 4.66732H5.33268M9.66602 12.6673L10.9993 14.0007L13.9993 11.0007"
                                      stroke="#344054"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  <span className="ms-2"> Créer un cv</span>
                                </Dropdown.Item>
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyData></EmptyData>
            )}
          </div>
        </div>
      </div>
      <CreateMission
        companyId={companyId}
        getMissionByCompanyId={getMissionByCompanyId}
      />
      <EditMission
        getManagerByCompanyId={getManagerByCompanyId}
        managers={managers}
        companyData={company}
        selectedMissionForUpdate={selectedMissionForUpdate}
        getMissionByCompanyId={getMissionByCompanyId}
      />
      <CreateNote
        selectedForUpdate={selectedForUpdate}
        setSelectedForUpdate={setSelectedForUpdate}
        destination={"candidates"}
      />
      <UpdateCandidat
        getSupplierByCompanyId={getSupplierByCompanyId}
        id={selectedForUpdate}
      />
      <UpdateCompany
        getAllCompany={getAllCompany}
        selectedForUpdate={companyId}
      />
      <CadidatToEmplyeeOffCanvas candidatId={selectedForUpdate} />
    </div>
  );
};

export default CompanyDetails;
