import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
const initFormErrors = {
  name: [""],
  etape: [""],
  steps: [""],
};
const UpdateProcess = ({
  setAddProcessModalFromChild,
  processId,
  getAllProcess,
}) => {
  const [processName, setProcessName] = useState([]);
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [processSteps, setProcessSteps] = useState([""]);
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const [deletedStepIndex, setDeletedStepIndex] = useState([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...processSteps];
    list[index] = { name: value, index: list[index].index ?? currentStepIndex };
    setProcessSteps(list);
  };

  const handleRemoveClick = (indexToDelete) => {
    setDeletedStepIndex([
      ...deletedStepIndex,
      processSteps[indexToDelete].index,
    ]);
    const updatedSteps = [...processSteps];
    updatedSteps.splice(indexToDelete, 1);
    setProcessSteps(updatedSteps);
  };

  const handleAddClick = () => {
    if (
      processSteps.length === 0 ||
      processSteps[processSteps.length - 1].name !== ""
    ) {
      setProcessSteps([...processSteps, { name: "" }]);
      setCurrentStepIndex(currentStepIndex + 1);
      setFormErrors(initFormErrors);
    }
  };

  React.useEffect(() => {
    axios.get(`${baseURL}/process/details/${processId}`).then((resp) => {
      if (resp.data.error) return;
      const data = resp.data.data;
      setProcessName(data.name);
      setProcessSteps(data.steps);
      setCurrentStepIndex(resp.data.currentIndex);
    });
  }, []);

  function sendData() {
    const updatedSteps = processSteps.map((step) => ({
      index: step.index,
      name: step.name,
    }));
    axios
      .put(`${baseURL}/process/${processId}`, {
        name: processName,
        steps: updatedSteps,
        deletedStepIndices: deletedStepIndex,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getAllProcess();
        setAddProcessModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                Modifier process
              </h3>

              <div className="row">
                <div className="col-md-10 mb-3">
                  <input
                    type="text"
                    name="ProcessName"
                    className="form-control"
                    placeholder="Nom de process"
                    onChange={(e) => setProcessName(e.target.value)}
                    value={processName != null && processName}
                  />
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.name}</span>
                  </div>
                </div>

                <span className="text-muted small">
                  * Veuillez mieux entrer une étape sans espaces ni caractères
                  spéciaux (ex. Étape 1)
                </span>
                {processSteps.map((step, key) => {
                  return (
                    <div className="row" key={key}>
                      <div className=" mt-2 col-11 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="Etape"
                          value={step.name}
                          onChange={(e) => handleInputChange(e, key)}
                          placeholder={`Etape `}
                        />

                        {processSteps.length - 1 === key && (
                          <button
                            type="button"
                            onClick={handleAddClick}
                            disabled={step.name === ""}
                            className="btn button_steps_bgradd ms-2"
                          >
                            <i className="bi bi-plus button_steps_add">
                              ajouter autre etape
                            </i>
                          </button>
                        )}
                      </div>
                      <div className="col-1 m-2 m-sm-0 ">
                        {processSteps.length !== 1 && (
                          <button
                            type="button"
                            className="btn button_steps_bgrdash p-1"
                            onClick={() => handleRemoveClick(key)}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="input-error">
                  <span className="text-danger">{formerrors.steps}</span>
                </div>
              </div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => setAddProcessModalFromChild("")}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendData}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default UpdateProcess;
