import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../config/config";
import swal from "sweetalert";
import permissions from "../components/permissions";
import Loader from "../components/loader";

const initFormErrors = {
  role: [""],
  permissionField: [""],
};
const EditRole = ({getAllRoles,selectedForUpdate}) => {
  const [roleUpdate, setRoleUpdate] = React.useState([]);
  const [checkUpdate, setCheckUpdate] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [searchText, setSearchText] = useState("");
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isLoading,setIsLoading] = useState(false);

  const msg = "Ce champ est obligatoire";
  const msgPermissions =
    "Les autorisations du rôle ne peuvent pas être nulles, il faut choisir au moins un accès";


  const onChangeUpdate = ({ target: { value } }) => {
    setCheckUpdate((oldList) => {
      const newList = oldList.includes(value)
        ? oldList.filter((val) => val !== value)
        : [...oldList, value];

      const [entité, valeurPermission] = value.split("_");

      if (valeurPermission !== "consult") {
        const permissionConsult = `${entité}_consult`;
        if (!newList.includes(permissionConsult)) {
          newList.push(permissionConsult);
        }
      } else {
        const otherPermissions = permissions[entité]?.items.map(
          (permission) => `${entité}_${permission.value}`
        );
        const otherPermissionsUpdated = otherPermissions.some((permissionKey) =>
          newList.includes(permissionKey)
        );
        if (!newList.includes(value) && otherPermissionsUpdated) {
          newList.push(value);
        }
      }

      setCheckUpdate(newList);
      return newList;
    });
  };
  const sendDataUpdate = () => {
    setIsLoading(true);
    const roleId = selectedForUpdate;
    const entites = Array.from(
      new Set(checkUpdate.map((val) => val.split("_")[0]))
    );
    const permissionUpdate = {};
    entites.forEach((entité) => {
      const entitePermissions = checkUpdate.filter((val) =>
        val.startsWith(entité)
      );

      const filteredPermissions = entitePermissions.reduce((result, val) => {
        const parts = val.split("_");
        if (parts.length > 1 && !parts[1].startsWith("_")) {
          result.push(parts.slice(1).join("_"));
        }
        return result;
      }, []);
      permissionUpdate[entité] = [...filteredPermissions];
    });
    axios
      .put(`${baseURL}/roles/${roleId}`, {
        id: selectedForUpdate,
        name: roleUpdate,
        permission: JSON.stringify(permissionUpdate),
      })
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const boutonOffcanvas = document.getElementById("annulerButton2");
        boutonOffcanvas.click();
        setCheckUpdate([]);
        setRoleUpdate("");
        getAllRoles();
        getRole();
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
        setIsLoading(false);
      });
  };

  function getRole() {
    const roleId = selectedForUpdate;
    if (roleId === undefined) return;
    axios
      .get(`${baseURL}/roles/${roleId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const userData = resp.data.data;
        const permissionsData = JSON.parse(userData.permissions);
        const roleName = userData.name;
        setRoleUpdate(roleName);

        const selectedPermissions = Object.keys(permissionsData).reduce(
          (formattedPermissions, entity) => {
            const entityPermissions = permissionsData[entity].map(
              (permission) => `${entity}_${permission}`
            );
            return formattedPermissions.concat(entityPermissions);
          },
          []
        );
        setCheckUpdate(selectedPermissions);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function validateUpdate() {
    const newErrorMessage = { ...initFormErrors };

    if (!roleUpdate) {
      newErrorMessage.role = msg;
    } else if (
      typeof roleUpdate !== "string" ||
      !roleUpdate.match(/^[^-\s][a-zA-Z0-9_\s-]+$/)
    ) {
      newErrorMessage.role = "Veuillez entrer un nom valide.";
    } else {
      newErrorMessage.role = "";
    }
    if (checkUpdate.length === 0) {
      newErrorMessage.permissionField = msgPermissions;
    } else {
      newErrorMessage.permissionField = "";
    }

    setErrorMessage(newErrorMessage);

    if (!newErrorMessage.role && !newErrorMessage.permissionField) {
      sendDataUpdate();
    }
  }
  useEffect(() => {
    if (selectedForUpdate !== null) {
      getRole();
    }
  }, [selectedForUpdate]);

  return (
    <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-1"
        id="offcanvasRight2"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header ">
          <h4 id="offcanvasRightLabel fw-bold">Modifier rôle</h4>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setErrorMessage(initFormErrors);
            }}
          ></button>
        </div>
        <div className="offcanvas-body offcanvas-body-scrollable">
              <div>
                <label>Rôle</label>
                <input
                  type="text"
                  className="form-control my-3 inputFiltre"
                  placeholder="Ajouter un rôle"
                  onChange={(e) => setRoleUpdate(e.target.value)}
                  value={roleUpdate != null && roleUpdate}
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.message}</span>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.role}</span>
                </div>
              </div>
              <hr className="w-75 mx-auto"></hr>
              <div>
                <div className="groupFiltre">
                  <svg
                    className="iconFiltre"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                    </g>
                  </svg>
                  <input
                    className="form-control inputFiltre my-3"
                    type="text"
                    placeholder="Chercher une permission"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>

                <label>Permissions</label>
                <div
                  className="accordion no-accordion-borders mt-3"
                  id="accordionPermissions"
                >
                  {Object.keys(permissions).map(
                    (entity, index) =>
                      permissions[entity]?.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase()) && (
                        <div className="accordion-item" key={entity}>
                          <h2
                            className="accordion-header"
                            id={`heading${index}`}
                          >
                            <button
                              className={`accordion-button ${
                                activeAccordion === index ? "" : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index}`}
                              aria-expanded={
                                activeAccordion === index ? "true" : "false"
                              }
                              aria-controls={`collapse${index}`}
                              onClick={() => setActiveAccordion(index)}
                            >
                              {permissions[entity]?.name}
                            </button>
                          </h2>
                          <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${
                              activeAccordion === index ? "show" : ""
                            }`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionPermissions"
                          >
                            <div className="accordion-body row">
                              <div className="m-2 border-start">
                                {permissions[entity]?.items.map(
                                  (permission) => (
                                    <div
                                      key={permission.value}
                                      className="permission-item form-check form-switch"
                                    >
                                      <input
                                        className="form-check-input me-2"
                                        value={`${entity}_${permission.value}`}
                                        checked={checkUpdate.includes(
                                          `${entity}_${permission.value}`
                                        )}
                                        type="checkbox"
                                        onChange={onChangeUpdate}
                                      />
                                      <label className="form-check-label ms-1">
                                        {permission.name}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                  <div className="input-error m-2">
                    <span className="text-danger">
                      {errorMessage.permissionField}
                    </span>
                  </div>
                </div>
                <div className="row mt-2 d-flex justify-content-center ">
                  <button
                    type="button"
                    className="btn btn-primary col-11"
                    onClick={validateUpdate}
                    disabled={isLoading}
                  >
                    {isLoading ? <Loader /> : "Enregistrer"}                    
                  </button>
                  <button
                    type="button"
                    id="annulerButton2"
                    className="btn btn-outline-secondary col-11  mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => {
                      setErrorMessage(initFormErrors);
                    }}
                  >
                    Annuler
                  </button>
                </div>
              </div>
        </div>
      </div>
  );
};
export default EditRole;
