import React, { useContext} from "react";
import { SupplierContext } from "../../pages/supplier/CreateSupplier";

import { useHistory } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import SupplierFormStep4 from "./SupplierFormStep4";
import { baseURL } from "../../config/config";
const initFormErrors = {
  lname: "",
  fname: "",
  email: "",
  phone: "",
  address: "",
  cp: "",
  diplome: "",
  type: "",
  langues: "",
  avatar: "",
  nirpp: "",
  typeOfContract: "",
  startDate: "",
  salary: "",
  Job: "",
  cv: "",
  cityName: "",
};
const ConfirmSupplier = ({ setChildComponent }) => {
  const { newSupplier} = useContext(SupplierContext);

  const history = useHistory();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  function sendData() {
    const formData = new FormData();
    formData.append("gender", newSupplier.gender);
    formData.append("fname", newSupplier.fname);
    formData.append("lname", newSupplier.lname);
    formData.append("email", newSupplier.email);
    formData.append("pays", newSupplier.countryid);
    formData.append("city", newSupplier.city);
    formData.append("address", newSupplier.address);
    formData.append("cp", newSupplier.cp);
    formData.append("phone", newSupplier.phone);
    formData.append("birthday", newSupplier.birthDay);
    formData.append("birthPlace", newSupplier.birthPlace);
    formData.append("diplomeDate", newSupplier.diplomeDate);
    formData.append("diplome", newSupplier.diplome);
    formData.append("type", newSupplier.type);
    formData.append("langues", newSupplier.langues);
    formData.append("status", newSupplier.status || "single");
    formData.append("skills", newSupplier.skills);
    formData.append("experience", newSupplier.experience);
    formData.append("avatar", newSupplier.avatar);
    formData.append("nirpp", newSupplier.nirpp);
    formData.append("job", newSupplier.job);
    formData.append("startDate", newSupplier.startDate);
    formData.append("salary", newSupplier.salary);
    formData.append("typeOfContract", newSupplier.typeOfContract || "cdd");
    formData.append("cv", newSupplier.cv);
    formData.append("company", newSupplier.companyid);
    formData.append("cvname", newSupplier.cvname);

    axios
      .post(`${baseURL}/employees/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/supplier");
        }, 2400);
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
      });
  }

  return (
    <div>
      <section className="section">
        <div className="row">
          <h1 className="text-center color-msit fw-bolder pb-5">
            Fiche de fournisseur
          </h1>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-5">
                    <div>
                      <label className="color-msit fw-bold">
                        Nom et Prénom :{" "}
                      </label>{" "}
                      <span>
                        {newSupplier.lastname} {newSupplier.fname}
                      </span>
                    </div>
                    <div>
                      <label className="color-msit fw-bold ">
                        Type de l'employé :{" "}
                      </label>{" "}
                      {newSupplier.type}
                    </div>
                  </div>
                  <div className="col-md-5">
                    {" "}
                    <div>
                      <label className="color-msit fw-bold">Email : </label>{" "}
                      {newSupplier.email !== "" ? (
                        newSupplier.email
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Téléphone : </label>{" "}
                      {newSupplier.phone !== "" ? (
                        newSupplier.phone
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Genre : </label>{" "}
                      {newSupplier.gender}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">NIRPP : </label>{" "}
                      {newSupplier.nirpp !== "" ? (
                        newSupplier.nirpp
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Etat civil :
                      </label>{" "}
                      {newSupplier.status !== "" ? (
                        newSupplier.status
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Ville : </label>{" "}
                      {newSupplier.cityName !== "" ? (
                        newSupplier.cityName
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Adresse : </label>{" "}
                      {newSupplier.address !== "" ? (
                        newSupplier.address
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">
                        Code postal :
                      </label>{" "}
                      {newSupplier.cp !== "" ? (
                        newSupplier.cp
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">
                        Expérience :{" "}
                      </label>
                      {newSupplier.experience}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Poste : </label>
                      {newSupplier.job}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">
                        Compétence :{" "}
                      </label>
                      {newSupplier.skills}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">
                        Date début travail :
                      </label>
                      {" "}
                      {newSupplier.startDate !== "" ? (
                        newSupplier.startDate
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">Diplome:</label>
                      {" "}
                      {newSupplier.diplome !== "" ? (
                        newSupplier.diplome
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Salaire : </label>{" "}
                      {newSupplier.salary !== "" ? (
                        newSupplier.salary
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">
                        Année de diplome :
                      </label>{" "}
                      {newSupplier.diplomeDate !== "" ? (
                        newSupplier.diplomeDate
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">
                        Type de contrat :
                      </label>{" "}
                      {newSupplier.typeOfContract !== "" ? (
                        newSupplier.typeOfContract
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">langue : </label>{" "}
                      {newSupplier.langues && newSupplier.langues.length > 0 ? (
                        newSupplier.langues.map((langue, index) => (
                          <span key={index}>
                            {langue}
                            {index !== newSupplier.langues.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span>Aucune donnée trouvée</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold">
                        Nom du société :
                      </label>{" "}
                      {newSupplier.companyname}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="fs-4">
          <span className="text-danger">{formerrors.email}</span>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-outline-secondary  me-2"
            onClick={() => {
              setChildComponent(
                <SupplierFormStep4 setChildComponent={setChildComponent} />
              );
            }}
          >
            Retour
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              sendData();
            }}
          >
            Confirmer
          </button>
        </div>
      </section>
    </div>
  );
};

export default ConfirmSupplier;
