import swal from "sweetalert";

export default async function DisplayError(
  title,
  id,
  path,
  historyHook,
  timer
) {
  const erreurMessage = "Une erreur est survenue ,veuillez réessayer ";
  const linkToDetails = path + id;
  function redirect() {
    historyHook.replace(linkToDetails);
  }
  const value = await swal({
    title: title ? title : erreurMessage,
    buttons: path
      ? {
          catch: {
            text: "Visiter",
            value: "visit",
          },
          cancel: "Fermer",
        }
      : { cancel: "Fermer" },
    icon: "error",
    timer: timer ? timer : 10000,
    dangerMode: true,
  });
  switch (value) {
    case "visit":
      redirect();
      break;

    default:
      swal.close();
  }
}
