import React, { createContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import EmployeesFormStep1 from "../../components/employeesForm/EmployeesFormStep1";

export const EmployeeContext = createContext({
  gender: "",
  firstname: "",
  lastname: "",
  email: "",
  city: "",
  cityname: "",
  address: "",
  cp: "",
  phone: "",
  birthday: "",
  birthPlace: "",
  diplomeDate: "",
  diplome: "",
  typename: "",
  langues: "",
  status: "",
  skills: "",
  experience: "",
  avatar: "",
  nirpp: "",
  typeOfContract: "none",
  salary: "",
  posteType: "",
  posteTypeName: "",
  startDate: "",
  cv: "",
  countryid: "",
  cvname: "",
  type: "",
  company: "",
  companyName: "",
});

const CreateEmployees = () => {
  const [childComponent, setChildComponent] = useState();

  const [newEmployee, setNewEmployee] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    cityname: "",
    address: "",
    cp: "",
    phone: "",
    birthday: "",
    birthPlace: "",
    diplomeDate: "",
    diplome: "",
    typename: "",
    langues: "",
    status: "",
    skills: "",
    experience: "",
    avatar: "",
    nirpp: "",
    typeOfContract: "none",
    salary: "",
    posteType: "",
    posteTypeName: "",
    startDate: "",
    cv: "",
    countryid: "",
    cvname: "",
    type: "",
    company: "",
    companyName: "",
  });

  useEffect(() => {
    setChildComponent(
      <EmployeesFormStep1 setChildComponent={setChildComponent} />
    );
  }, []);

  const value = useMemo(
    () => ({ newEmployee, setNewEmployee }),
    [newEmployee, setNewEmployee]
  );
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Ajouter un Employé</h1>
          </div>
          <div className="col-md-4">
            <Link to="/employees">
              <button
                type="button"
                className="btn btn-outline-secondary mb-2 float-end  me-2">
                Retour
              </button>
            </Link>
          </div>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/employees"}>Employés</Link>
            </li>
            <li className="breadcrumb-item active">Ajouter un employé</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div>
            <div>
              <EmployeeContext.Provider value={value}>
                {childComponent}
              </EmployeeContext.Provider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateEmployees;
