import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import Loader from "../loader";

function CreateNote({ selectedForUpdate, setSelectedForUpdate }) {
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formerrors, setFormErrors] = useState("");

  async function fetchData(candidatId) {
    if (candidatId === undefined || candidatId === null) return;

    await axios
      .get(`${baseURL}/ConsultantNotes/note/${candidatId}`)
      .then((resp) => {
        if (resp.data.error) return;
        setNote(resp.data.data.note);
      })
      .catch((error) => {
        DisplayError(error?.response?.data?.message);
      });
  }

  useEffect(() => {
    setNote("");
    if (
      selectedForUpdate === undefined ||
      (selectedForUpdate !== null && selectedForUpdate !== "")
    ) {
      fetchData(selectedForUpdate);
    }
  }, [selectedForUpdate]);

  function sendData() {
    setIsLoading(true);
    const candidatId = selectedForUpdate;
    axios
      .post(`${baseURL}/ConsultantNotes/note/${candidatId}`, { note: note })
      .then((resp) => {
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        fetchData(candidatId);
        const offcanvasButton = document.getElementById(
          "cancelButtonNoteOffcanvas"
        );
        offcanvasButton.click();
      })
      .catch((error) => {
        setFormErrors(error?.response?.data?.message);
        setIsLoading(false);
        DisplayError(error?.response?.data?.message);
      });
  }
  function resetInputClose() {
    setNote("");
    if (setSelectedForUpdate) {
      setSelectedForUpdate("");
    }
  }
  return (
    <div>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-1"
        id="addNoteCandidat"
        aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header ">
          <h4 id="offcanvasRightLabel fw-bold">Ajouter note</h4>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={resetInputClose}></button>
        </div>
        <div className="offcanvas-body offcanvas-body-scrollable">
          <div className="mt-4">
            <div className="row px-2 py-2">
              <textarea
                type="text"
                className="form-control"
                id="role_name"
                value={note}
                placeholder="veuillez saisir une note"
                onChange={(e) => setNote(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors}
                </span>
              </div>
            </div>
            <div className="row px-2 pt-2 pb-1">
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendData}
                disabled={isLoading}>
                {isLoading ? <Loader /> : "Enregistrer"}
              </button>
            </div>
            <div className="row px-2 pt-1 pb-2">
              <button
                id="cancelButtonNoteOffcanvas"
                type="button"
                className="btn but-restore"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={resetInputClose}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNote;
