import { createContext } from "react";

export const CandidatContext = createContext({
  gender: "",
  firstname: "",
  lastname: "",
  email: "",
  city: "",
  cityname: "",
  address: "",
  cp: "",
  phone: "",
  birthday: "",
  birthPlace: "",
  diplomeDate: "",
  diplome: "",
  typename: "",
  langues: "",
  status: "",
  skills: "",
  experience: "",
  avatar: "",
  nirpp: "",
  typeOfContract: "none",
  salary: "",
  posteType: "",
  posteTypeName: "",
  startDate: "",
  cv: "",
  countryid: "",
  cvname: "",
  type: "",
  company: "",
  companyName: "",
  isLocked: "",
});
