import React, { useContext, useState } from "react";
import axios from "axios";
import { baseURL } from "../../config/config";
import { useEffect } from "react";
import swal from "sweetalert";
import Loader from "../../components/loader";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider";
export default function AffectMissionModal({ employee }) {
  const initFormErrors = {
    cv: [""],
    employee: [""],
    cvname: [""],
  };
  const { user } = useContext(UserContext);

  const hasMissionsEmployeeConsultPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "consult"
  );

  const [file, setFile] = useState();
  const [cvname, setCvname] = useState();
  const [upload, setUpload] = useState();
  const [select, setSelect] = useState("active");
  const [childComponent, setChildComponent] = useState();
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [mission, setMission] = useState();
  const [missions, setMissions] = useState();
  const [cv, setCv] = useState();
  const [allcvs, setAllCvs] = useState();
  const msg = "Ce Champs est Obligatoire";
  const msgerrorcv =
    "Veuillez sélectionner ou télécharger un CV, ce champ est obligatoire. ";
  async function affectMissionToEmployee() {
    const formData = new FormData();
    formData.append("consultant", employee._id ? employee._id : employee.id);
    formData.append("mission", mission);
    if (!cv && file) {
      formData.append("cv", file);
      formData.append("cvname", cvname);
    } else {
      formData.append("cv", cv);
    }

   await axios
      .post(
        `${baseURL}/missionsConsultants/affectConsultantToMission`,
        formData
      )
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        }).then(async (value) => {
          const boutonOffcanvas = document.getElementById(
            "closeOffcanvasAffectMission"
          );
          boutonOffcanvas.click();
        });
      })
      .catch((error) => {
        DisplayError(
          error.response.data.message ? error.response.data.message : null
        );
      });
  }

  async function getCvs() {
    if (employee != []) {
      axios
        .get(`${baseURL}/cv/allCvs/${employee?._id}`)
        .then((response) => {
          if (response.data.error) return;
          setAllCvs(response.data.data);
        })
        .catch(() => {
          DisplayError();
        });
    }
  }
  useEffect(() => {
    if (Array.isArray(employee)) return;
    getCvs();
  }, [employee]);
  function validate() {
    let error = false;
    if (!mission) {
      error = true;
      setFormErrors((prevState) => ({
        ...prevState,
        mission: msg,
      }));
    }
    if (!cv && !file) {
      error = true;
      setFormErrors((prevState) => ({
        ...prevState,
        cv: msgerrorcv,
      }));
    }
    if (!cvname && file) {
      error = true;
      setFormErrors((prevState) => ({
        ...prevState,
        cv: "Le nom du CV est requis lorsque vous téléchargez un nouveau CV.",
      }));
    }
    if (!error) {
      affectMissionToEmployee();
    }
  }
  useEffect(() => {
    setChildComponent(
      <div className="mb-4 row px-2">
        <select
          id="inputEmployee"
          onChange={(e) => {
            setCv(e.target.value);
            setFormErrors((prevState) => ({
              ...prevState,
              cv: "",
            }));
          }}
          className="form-select offcanvas-input">
          <option disabled selected>
            Sélectionner un CV
          </option>
          {allcvs != null &&
            allcvs.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.cvname ? item.cvname : "CV sans nom"}
                </option>
              );
            })}
        </select>
      </div>
    );
    getMissions();
  }, []);
  useEffect(() => {
    if (allcvs) {
      setChildComponent(<Loader />);
      setChildComponent(
        <div className="mb-4 row px-2">
          <select
            id="inputEmployee"
            onChange={(e) => {
              setCv(e.target.value);
              setFormErrors((prevState) => ({
                ...prevState,
                cv: "",
              }));
            }}
            className="form-select offcanvas-input">
            <option disabled selected>
              Sélectionner un CV
            </option>
            {allcvs != null &&
              allcvs.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.cvname ? item.cvname : "CV sans nom"}
                  </option>
                );
              })}
          </select>
        </div>
      );
    }
  }, [allcvs]);
  async function getMissions() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMissions(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  return (
    <div>
      <div
        className="offcanvas offcanvas-end offcanvas-body-border pb-3"
        tabIndex="-1"
        id="offcanvasAffectMission"
        aria-labelledby="offcanvasRightLabel"
        aria-modal="true"
        role="dialog"
        style={{ visibility: "visible", width: "30rem" }}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Affecter une mission</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasAffectMission"
            aria-controls="offcanvasAffectMission"></button>
        </div>
        <div className="offcanvas-body pt-0 p-4">
          <hr className="light-hr  " />
          <div className="row d-flex flex-column align-items-center justify-content-center">
            <div>
              <label>Employee :</label>
              <h3 className="col-md-10 mb-3">
                {employee.firstname} {employee.lastname}
              </h3>
            </div>
            <div className="mb-4 row px-2">
              <label className="px-0 mb-2 text-500">
                selectioner une mission :*
              </label>
              <select
                id="inputMission"
                onChange={(e) => {
                  setMission(e.target.value);
                  setFormErrors((prevState) => ({
                    ...prevState,
                    mission: "",
                  }));
                }}
                className="form-select offcanvas-input">
                <option selected disabled>
                  Sélectionner une mission
                </option>
                {missions != null &&
                  missions.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    );
                  })}
              </select>
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {formerrors.mission}
                </span>
              </div>
            </div>

            <ul className="mt-4 nav nav-tabs nav-tabs-bordered mb-4">
              <li className="nav-item">
                <button
                  className={`nav-link ${select}`}
                  data-bs-toggle="tab"
                  onClick={() => {
                    setSelect("active");
                    setUpload("");
                    setCvname("");
                    setFile("");
                    setChildComponent(
                      <div className="mb-4 row px-2">
                        <select
                          id="inputEmployee"
                          onChange={(e) => {
                            setCv(e.target.value);
                            setFormErrors((prevState) => ({
                              ...prevState,
                              cv: "",
                            }));
                          }}
                          className="form-select offcanvas-input">
                          <option disabled selected>
                            Sélectionner un CV
                          </option>
                          {allcvs != null &&
                            allcvs.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.cvname ? item.cvname : "CV sans nom"}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    );
                  }}>
                  Choisir un CV
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${upload}`}
                  data-bs-toggle="tab"
                  onClick={() => {
                    setSelect("");
                    setFile("");
                    setCv("");
                    setUpload("active");
                    setChildComponent(
                      <>
                        <div className="mb-4 row px-2">
                          <label className="px-0 mb-2 text-500">CV *</label>
                          <div className="custom-file">
                            <input
                              className="form-control offcanvas-input"
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                              type="file"
                              accept=".pdf, .docx"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-4 row px-2">
                          <label className="px-0 mb-2 text-500">Nom CV *</label>
                          <div className="custom-file">
                            <input
                              type="text"
                              className="form-control offcanvas-input"
                              placeholder="Nom"
                              onChange={(e) => {
                                setCvname(e.target.value);
                                setFormErrors((prevState) => ({
                                  ...prevState,
                                  cvname: "",
                                }));
                              }}
                              required
                            />
                          </div>
                        </div>
                      </>
                    );
                  }}>
                  télécharger un CV
                </button>
              </li>
            </ul>
            <div className="input-error">
              <span className="text-danger"> {formerrors.cv}</span>
            </div>
            {childComponent}
            <div className="row mt-4">
              <div className=" row justify-content-center  ">
                <div className="col-4 d-flex justify-content-center "></div>
              </div>
            </div>
          </div>

          <div className="row px-2 pt-2 pb-1">
            <button type="button" className="btn btn-add" onClick={validate}>
              Enregistrer
            </button>
          </div>
          <div className="row px-2 pt-1 pb-2">
            <button
              type="button"
              id="closeOffcanvasAffectMission"
              className="btn but-restore"
              data-bs-dismiss="modal"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasAffectMission"
              aria-controls="offcanvasAffectMission">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
