import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router";
import { baseURL } from "../../config/config";
import AddTypes from "../../components/condidatesTypes/AddTypes";
import { Link } from "react-router-dom";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const initFormErrors = {
  lastname: [""],
  firstname: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  anneeGraduation: [""],
  type: [""],
};

const UpdateCandidats = () => {
  const [types, setTypes] = React.useState([]);
  const [loadingCity, setLoadingCity] = useState(false);
  const [addTypesModal, setaddTypesModal] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [city, setCity] = useState([]);
  const [gender, setGender] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [pays, setPays] = useState("");
  const [cities, setCities] = useState([]);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [avatar, setAvatar] = useState("");
  const [phone, setPhone] = useState("");
  const [cp, setCp] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [lieu_naissance, setLieu_naissance] = useState("");
  const [diplome, setDiplome] = useState("");
  const [anneeGraduation, setAnneeGraduation] = useState("");
  const [type, setType] = useState("");
  const history = useHistory();
  const { candidatId } = useParams();
  const msg = "Ce champ est obligatoire";
  const [experience, setExperience] = useState("");
  const [status, setStatus] = useState("");
  const [skills, setSkills] = useState("");
  const [langues, setLangues] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  useEffect(() => {
    if (candidatId === undefined) return;
    axios.get(`${baseURL}/candidates/${candidatId}`).then((resp) => {
      if (resp.data.error) return;
      const candidatsData = resp.data.data;
      setFirstname(candidatsData.firstname);
      setGender(candidatsData.gender);
      setLastname(candidatsData.lastname);
      setEmail(candidatsData.email);
      setAddress(candidatsData.address);
      setPhone(candidatsData.phone);
      setCp(candidatsData.cp);
      setAvatar(candidatsData.avatar);
      setDiplome(candidatsData.diplome);
      setAnneeGraduation(candidatsData.diplomeDate);
      setBirthDay(candidatsData.birthday);
      setLieu_naissance(candidatsData.birthPlace);
      setCity(candidatsData.city?.id);
      setPays(candidatsData.country?.id);
      setStatus(candidatsData.status);
      setSkills(candidatsData.skills);
      setLangues(candidatsData.langues);
      setExperience(candidatsData.experience);
      setType(candidatsData.type._id);
    });
  }, [candidatId]);

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCountries((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }

  useEffect(() => {
    if (pays === "") return;
    setCities([]);
    getCities(pays);
  }, [pays]);

  function getCities(countryid) {
    if (countryid === undefined) return;
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCities((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
      setLoadingCity(false);
    });
  }

  useEffect(() => {
    getAllTypes();
  }, []);

  function getAllTypes() {
    axios.get(`${baseURL}/candidatTypes/types`).then((resp) => {
      if (resp.data.error) return;

      const newTypes = resp.data.data.map((item) => ({
        value: item,
        label: item.name,
      }));
      setTypes(newTypes);
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  function sendData() {
    if (pays && !city) {
      setErrorMessage({ ...errorMessage, city: msg });
      return;
    }
    const formData = new FormData();
    formData.append("gender", gender);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("email", email);
    formData.append("pays", pays);
    formData.append("city", city);
    formData.append("address", address);
    formData.append("cp", cp);
    formData.append("phone", phone.toString());
    formData.append("birthday", birthDay);
    formData.append("birthPlace", lieu_naissance);
    formData.append("diplomeDate", anneeGraduation);
    formData.append("diplome", diplome);
    formData.append("type", type);
    formData.append("langues", langues);
    formData.append("status", status);
    formData.append("skills", skills);
    formData.append("experience", experience);
    formData.append("avatar", avatar);
    axios
      .put(`${baseURL}/candidates/${candidatId}`, formData )
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/candidats");
        }, 2400);
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  }

  const statusOptions = [
    { value: "single", label: "Célibataire" },
    { value: "in_relationship", label: "En couple" },
    { value: "married", label: "Marié" },
    { value: "divorced", label: "Divorcé" },
    { value: "pacs", label: "Pacsé" },
  ];

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  return (
    <div>
      <section className="section">
        <div className="row">
          <h1 className="text-center color-msit fw-bolder pb-5">
            Modifier Fiche de candidat
          </h1>
          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>
                      <i className="bi bi-house-door"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/candidats"}>Candidats</Link>
                  </li>
                  <li className="breadcrumb-item active">candidat</li>
                </ol>
              </nav>
            </div>
            <div className="col-md-4">
              <Link to="/candidats">
                <button
                  type="button"
                  className="btn btn-outline-secondary mb-2 float-end  me-2">
                  Retour
                </button>
              </Link>
            </div>
          </div>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Vous étes :</label>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="male"
                        value="male"
                        checked={gender === "male"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label className="form-check-label">Homme</label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="myCheck"
                        value="femele"
                        checked={gender === "femele"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <label className="form-check-label">Femme</label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="myCheck"
                        value="other"
                        checked={gender === "other"}
                        onChange={(e) => setGender(e.target.value)}
                      />

                      <label className="form-check-label">Autre</label>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.gender}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Nom * :</label>
                    <input
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname}
                      name="name"
                      type="text"
                      className="form-control"
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.lastname}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Prénom * :</label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(e) => setFirstname(e.target.value)}
                      value={firstname}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.firstname}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Email : </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.email}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Téléphone : </label>
                    <PhoneInput
                      inputClass="phoneInput"
                      className="phoneInput"
                      placeholder="Téléphone"
                      value={phone}
                      onChange={(value, country, event) =>
                        setPhone(
                          event.target.value ? event.target.value : value
                        )
                      }
                    />
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.phone}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">avatar</label>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      className="form-control"
                      onChange={(e) => setAvatar(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Etat civil :</label>
                      <Select
                        value={statusOptions.filter(
                          (option) => option.value === status
                        )}
                        options={statusOptions}
                        onChange={(e) => {
                          setStatus(e.value);
                        }}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.status}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">pays </label>
                    <Select
                      value={countries.filter(
                        (option) => option.value === pays
                      )}
                      options={countries}
                      isSearchable
                      onChange={(e) => {
                        setLoadingCity(true);
                        setPays(e.value);
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="color-msit fw-bold">Ville </label>
                    <Select
                      value={cities.filter((option) => option.value === city)}
                      options={cities}
                      isLoading={loadingCity}
                      isSearchable
                      onChange={(e) => {
                        setCity(e.value);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.city}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Adresse : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.address}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Code postal :
                      </label>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        onChange={(e) => setCp(e.target.value)}
                        value={cp}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger"> {errorMessage.cp}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Lieu naissance :{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setLieu_naissance(e.target.value)}
                        value={lieu_naissance}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.lieu_naissance}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <div className="date-picker">
                        <label className="color-msit fw-bold">
                          Date de naissance
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="datePicker"
                          onChange={(e) => setBirthDay(e.target.value)}
                          value={birthDay}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.birthDay}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Expérience * :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setExperience(e.target.value)}
                        value={experience}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.experience}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Compétence * :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSkills(e.target.value)}
                        value={skills}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.skills}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">Diplome :</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDiplome(e.target.value)}
                        value={diplome}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {" "}
                        {errorMessage.diplome}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Année de diplome :
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setAnneeGraduation(e.target.value)}
                        min="0"
                        value={anneeGraduation}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.diplomeDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">langue : </label>
                      <Select
                        options={languesOptions}
                        value={
                          langues !== "" &&
                          languesOptions.filter((option) =>
                            JSON.parse(langues).includes(option.value)
                          )
                        }
                        isMulti
                        onChange={(e) => {
                          let lg = [];
                          e.forEach((lang, key) => {
                            lg.push(lang.value);
                          });
                          setLangues(JSON.stringify(lg));
                        }}
                      />
                    </div>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.langues}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div>
                      <label className="color-msit fw-bold">
                        Type de candidat *
                      </label>
                      <div className="row">
                        <div className="col-10 ">
                          <Select
                            value={types.find(
                              (option) => option.value.id === type
                            )}
                            options={types}
                            isSearchable
                            onChange={(selectedOption) => {
                              setType(selectedOption.value.id);
                            }}
                          />
                        </div>
                        <div className="col-2 ">
                          <button
                            type="button"
                            id="basic-addon1"
                            className="btn bg_bt"
                            onClick={() =>
                              setaddTypesModal(
                                <AddTypes
                                  setAddTypesModalFromChild={setaddTypesModal}
                                  getAllTypes={getAllTypes}
                                />
                              )
                            }>
                            <i className="bi bi-plus-lg"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.type}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="text-end">
          <Link to="/candidats">
            <button
              type="button"
              className="btn btn-outline-secondary  me-2"
              onClick={() => {
                history.goBack();
              }}>
              Annuler
            </button>
          </Link>
          <button type="button" className="btn btn-primary" onClick={sendData}>
            Enregistrer
          </button>
        </div>
      </section>
      {addTypesModal}
    </div>
  );
};

export default UpdateCandidats;
