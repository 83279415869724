import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router";
import EditManager from "./updateManager";
import DeleteManager from "./deleteManager";
import AddManager from "./createManager";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import EmptyData from "../emptyData";
import DataTable from "react-data-table-component";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@mui/material";

const ManagersComponent = ({ company_id }) => {
  const [managers, setManagers] = useState([]);
  const [addManagerModal, setAddManagerModal] = useState();
  const [managersData, setManagersData] = useState([]);

  const { companyId } = useParams();
  const { user } = useContext(UserContext);
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const hasManagersCreatePermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "create"
  );
  const hasManagersEditPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "edit"
  );
  const hasManagersDeletePermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "delete"
  );
  const [selectedForUpdate, setSelectedForUpdate] = useState(null);
  function handleUpdateButtonClick(managerId) {
    setSelectedForUpdate(managerId);
  }
  function getManagerByCompanyId() {
    if (company_id === undefined) return;
    axios
      .get(`${baseURL}/managers/${company_id}`)
      .then((response) => {
        if (response.data.error) return;
        setManagers(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const noDataFound = "Aucune donnée trouvée";
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (managers == null) return;
    if (managers.length > 0) {
      setManagersData(
        <DataTable
          className="dataTableClass"
          columns={columnsManagers}
          data={managers}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      );
    } else {
      setManagersData(<EmptyData />);
    }
  }, [managers]);
  useEffect(() => {
    if (hasManagersConsultPermission) {
      getManagerByCompanyId();
    }
  }, [hasManagersConsultPermission]);
  const columnsManagers = [
    {
      name: "Nom",
      selector: (row) => (
        <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
          {row.fname + " " + row.lname}
        </div>
      ),
      center: true,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.phone}</div>
        </div>
      ),
      center: true,
      sortable: true,
      hide: "md",
    },
    {
      name: "Email",
      selector: (row) => <div>{row.email}</div>,
      center: true,
      hide: "md",
      sortable: true,
    },
    {
      name: "Désignation",
      selector: (row) => (
        <div>{row.designation || "Aucune donnée trouvée"}</div>
      ),
      center: true,
      hide: "lg",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasManagersEditPermission && (
                <Dropdown.Item
                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#updateManager"
                  aria-controls="updateManager"
                  onClick={() => handleUpdateButtonClick(row._id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <g clipPath="url(#clip0_192_15848)">
                      <path
                        d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_192_15848">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ms-2">Modifier le contact </span>
                </Dropdown.Item>
              )}
              {hasManagersDeletePermission && (
                <Dropdown.Item
                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                  onClick={() =>
                    setAddManagerModal(
                      <DeleteManager
                        setAddManagerModalFromChild={setAddManagerModal}
                        getManagerByCompanyId={getManagerByCompanyId}
                        managerId={row._id}
                      />
                    )
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333"
                      stroke="#344054"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="ms-2"> Supprimer</span>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
      sortable: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">
              <i className="bi bi-envelope-fill text-color-datatable"></i> Email
              :
            </div>

            {data.email ? (
              <Tooltip title={data?.email} arrow>
                <a
                  href={`mailto:${data?.email}`}
                  className="text-color-datatable "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Envoyer mail">
                  {data?.email?.substring(0, 25)}
                  {data?.email?.length > 25 ? "..." : ""}
                </a>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">
              <i className="bi bi-telephone-fill text-color-datatable"></i>{" "}
              Téléphone :
            </div>
            <div className="text-color-datatable">
              <Tooltip title={data.phone} arrow>
                <a
                  href={`tel:${data.phone}`}
                  className="text-color-datatable "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Appeler">
                  {data.phone}
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Désignation :</div>
            {data.designation !== null && data.designation !== ""
              ? data.designation
              : noDataFound}
          </div>
        </div>
      ) : (
        ""
      )}

      <hr className="W-100"></hr>
    </pre>
  );
  return (
    <div className="mt-4 row mx-2">
      {hasManagersCreatePermission && (
        <div class="mb-3">
          <button
            type="button"
            className="btn btn-primary btn-rounded float-end text-sm me-3"
            data-bs-toggle="offcanvas"
            data-bs-target="#addManager"
            aria-controls="addManager">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M9.99935 12.9167H6.24935C5.08638 12.9167 4.50489 12.9167 4.03173 13.0602C2.96639 13.3834 2.13272 14.217 1.80955 15.2824C1.66602 15.7555 1.66602 16.337 1.66602 17.5M15.8327 17.5V12.5M13.3327 15H18.3327M12.0827 6.25C12.0827 8.32107 10.4038 10 8.33268 10C6.26161 10 4.58268 8.32107 4.58268 6.25C4.58268 4.17893 6.26161 2.5 8.33268 2.5C10.4038 2.5 12.0827 4.17893 12.0827 6.25Z"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="ms-2">Ajouter un contact</span>
          </button>
        </div>
      )}
      <div className="card px-0">
        <div className="card-body px-0">{managersData}</div>
      </div>
      {addManagerModal}
      <AddManager
        setAddManagerModalFromChild={setAddManagerModal}
        getManagerByCompanyId={getManagerByCompanyId}
        companyId={companyId}
      />
      <EditManager
        setAddManagerModalFromChild={setAddManagerModal}
        getManagerByCompanyId={getManagerByCompanyId}
        selectedForUpdate={selectedForUpdate}
      />
    </div>
  );
};

export default ManagersComponent;
