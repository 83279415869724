import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AddTypes from "../condidatesTypes/AddTypes";
import CandidatsFormStep2 from "./CandidatsFormStep2";
import Confirm from "./Confirm";
import { CandidatContext } from "../../context/candidatContext";
import { baseURL } from "../../config/config";
import Select from "react-select";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";

const initFormErrors = {
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  cv: [""],
  skills: [""],
  experience: [""],
};

const CandidatsFormStep3 = ({ setChildComponent }) => {
  const { user } = useContext(UserContext);
  const hasCandidatTypesCreatePermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "create"
  );
  const msg = "Ce champ est obligatoire";
  const [types, setTypes] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState(null);

  const [addTypesModal, setaddTypesModal] = React.useState();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  function getAllTypes() {
    axios.get(`${baseURL}/candidatTypes/types`).then((resp) => {
      if (resp.data.error) return;

      const newTypes = resp.data.data.map((item) => ({
        value: JSON.stringify(item),
        label: item.name,
      }));
      setTypes(newTypes);
    });
  }
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  useEffect(() => {
    if (newCandidat && newCandidat.type) {
      const selectedTypeValue = {
        id: newCandidat.type,
        name: newCandidat.typename,
      };

      setSelectedType({
        value: JSON.stringify(selectedTypeValue),
        label: selectedTypeValue.name,
      });
    }
  }, [newCandidat]);
  useEffect(() => {
    getAllTypes();
  }, []);
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat.experience === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        experience: msg,
      }));
    }
    if (newCandidat.skills === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        skills: msg,
      }));
    }
    if (newCandidat.type === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        type: msg,
      }));
    }
    if (newCandidat.cv === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cv: msg,
      }));
    }
    if (newCandidat.cvname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cvname: msg,
      }));
    }

    if (error === false) {
      setChildComponent(<Confirm setChildComponent={setChildComponent} />);
    }
  }

  const languesOptions = [
    { value: "français", label: "Français" },
    { value: "english", label: "Anglais" },
    { value: "arabic", label: "Arabe" },
    { value: "spanish", label: "Espagnole" },
    { value: "deutsh", label: "Allemand" },
    { value: "Italien", label: "Italien" },
  ];

  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "90%" }}
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Nombre d'année d'experience *</label>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    value={newCandidat.experience}
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        experience: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </div>

                <div className="input-error">
                  <span className="text-danger">{errorMessage.experience}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>
                  Compétences *{" "}
                  <span className="textSkills">separation par ( ; )</span>
                </label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    value={newCandidat.skills}
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        skills: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.skills}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>langues </label>
                <div className=" mb-2">
                  <Select
                    options={languesOptions}
                    isMulti
                    value={languesOptions.filter((option) =>
                      newCandidat.langues.includes(option.value)
                    )}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        langues: JSON.stringify(selectedValues),
                      }));
                    }}
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.langues}</span>
                </div>
              </div>

              <div className="col-md-6 ">
                <label>Dernier diplome obtenue </label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    value={newCandidat.diplome}
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        diplome: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.diplome}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Année de diplome </label>
                <div className="input-group mb-2">
                  <input
                    min="0"
                    type="number"
                    value={newCandidat.diplomeDate}
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        diplomeDate: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger">
                    {errorMessage.diplomeDate}
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Type de candidat *</label>
                <div className="row mb-2">
                  <div className="col-10">
                    <Select
                      options={types}
                      isSearchable
                      value={selectedType}
                      onChange={(selectedOption) => {
                        const selectedTypeValue = JSON.parse(
                          selectedOption.value
                        );
                        setSelectedType(selectedOption);

                        setNewCandidat((prevState) => ({
                          ...prevState,
                          type: selectedTypeValue.id,
                          typename: selectedTypeValue.name,
                        }));
                      }}
                    />
                  </div>
                  {hasCandidatTypesCreatePermission && (
                    <div className="col-2 ">
                      <button
                        type="button"
                        id="basic-addon1"
                        className="btn bg_bt"
                        onClick={() =>
                          setaddTypesModal(
                            <AddTypes
                              setAddTypesModalFromChild={setaddTypesModal}
                              getAllTypes={getAllTypes}
                            />
                          )
                        }>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.type}</span>
                </div>
              </div>

              <div className="col-md-6">
                <span>Upload CV *</span>
                <div className=" mb-2">
                  <div className="custom-file">
                    <input
                      type="file"
                      accept=".pdf, .docx"
                      onChange={(e) => {
                        setNewCandidat((prevState) => ({
                          ...prevState,
                          cv: e.target.files[0],
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="input-error">
                  <span className="text-danger">{errorMessage.cv}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Nom CV *</label>
                <div className=" mb-3">
                  <div className="custom-file">
                    <input
                      className="form-control"
                      type="text"
                      value={newCandidat.cvname}
                      onChange={(e) => {
                        setNewCandidat((prevState) => ({
                          ...prevState,
                          cvname: e.target.value,
                        }));
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.cvname}</span>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-outline-secondary  me-2"
                    onClick={() => {
                      setChildComponent(
                        <CandidatsFormStep2
                          setChildComponent={setChildComponent}
                        />
                      );
                    }}>
                    Retour
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={validate}>
                    Continuer
                  </button>
                </div>
              </div>
            </div>
          </form>
          {addTypesModal}
        </div>
      </div>
    </div>
  );
};

export default CandidatsFormStep3;
