import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CircularProgress from "@mui/material/CircularProgress";
import DisplayError from "../../components/ErrorComponants/DisplayError";

function CreateCVPage() {
  const { consultantId } = useParams();
  const [path, setPath] = useState();
  const [experiences, setExperiences] = useState([]);
  const [experience, setExperience] = useState({ items: [] });
  const [mission, setMission] = useState();
  const [allmission, setAllMission] = useState();
  const [errorMessage, setErrorMessage] = useState({ cv: [""] });
  const [cvname, setCvname] = useState("");
  function generate() {
    let error = false;
    if (cvname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        cv: "Nom CV est requis",
      }));
    }
    if (!error) {
      swal({
        title: "Voulez vous générer un CV pour cette consultant ?  ",
        icon: "warning",
        buttons: true,
      }).then(async (confirmed) => {
        if (confirmed) {
          setPath("waiting");
          const formData = new FormData();
          formData.append("cvname", cvname);
          if (mission) {
            formData.append("mission", mission ? mission : null);
          }

          formData.append(
            "experienceDetails",
            experience.items.length > 0 ? experience.items : []
          );

          await axios
            .post(`${baseURL}/cv/create/${consultantId}`, formData)
            .then((response) => {
              swal(response.data.message, {
                icon: "success",
                timer: 2500,
              });
              setPath(baseURL + response.data.data);
            })

            .catch(() => {
              setPath(null);
              DisplayError();
            });
        }
      });
    }
  }
  function handleCheckBox(expID) {
    if (experience.items.includes(expID)) {
      setExperience((prev) => {
        return {
          ...prev,
          items: [...prev.items.filter((item) => item !== expID)],
        };
      });
    } else {
      setExperience((prev) => {
        return { ...prev, items: [...prev.items, expID] };
      });
    }
  }

  async function getExperienceByCandidatId() {
    await axios
      .get(`${baseURL}/experience/consultant/${consultantId}`)
      .then((response) => {
        if (response.data.error) return;
        setExperiences(response.data.data);
        for (const iterator of response.data.data) {
          setExperience((prev) => {
            return { ...prev, items: [...prev.items, iterator.id] };
          });
        }
      })
      .catch((err) => {
        DisplayError();
      });
  }
  async function getAllMission() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setAllMission(respData);
      })
      .catch((err) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (
      consultantId === null ||
      consultantId === "" ||
      consultantId === undefined
    ) {
      return;
    }
    getAllMission();
    getExperienceByCandidatId();
  }, [consultantId]);

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line icon_acceuil"></i>
                    <span className="acceuil_title">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card detail_cv_card  ">
        <div className="card-header">
          <div className="pagetitle row">
            <div className="col-md-8">
              <h1>Générer un CV</h1>
            </div>

            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/candidats">les candidats</Link>
                </li>
                <li className="breadcrumb-item color-msit">Générer un CV</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-10 px-2 mb-2">
              <label className="px-0 mb-2 text-500">
                Selectioner une mission :
              </label>
              <select
                id="inputMission"
                onChange={(e) => {
                  setMission(e.target.value);
                }}
                className="form-control offcanvas-input">
                <option selected disabled>
                  Sélectionner une Mission
                </option>
                {allmission != null &&
                  allmission.map((item, index) => {
                    return <option value={item.id}>{item.title}</option>;
                  })}
              </select>

              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {errorMessage?.cv}
                </span>
              </div>
              <span className="text-danger"> {errorMessage.mission}</span>
            </div>
            <div className="col-md-2 d-flex justify-content-center align-items-end ">
              <button
                className="btn btn-primary d-flex align-items-center gap-2"
                onClick={generate}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.6673 8.74935V5.66602C16.6673 4.26588 16.6673 3.56582 16.3948 3.03104C16.1552 2.56063 15.7727 2.17818 15.3023 1.9385C14.7675 1.66602 14.0674 1.66602 12.6673 1.66602H7.33398C5.93385 1.66602 5.23379 1.66602 4.69901 1.9385C4.2286 2.17818 3.84615 2.56063 3.60647 3.03104C3.33398 3.56582 3.33398 4.26588 3.33398 5.66602V14.3327C3.33398 15.7328 3.33398 16.4329 3.60647 16.9677C3.84615 17.4381 4.2286 17.8205 4.69901 18.0602C5.23379 18.3327 5.93385 18.3327 7.33398 18.3327H10.0007M11.6673 9.16602H6.66732M8.33398 12.4993H6.66732M13.334 5.83268H6.66732M15.0007 17.4993V12.4993M12.5007 14.9993H17.5007"
                    stroke="white"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Générer PDF
              </button>
            </div>
          </div>
          <div className="row px-2">
            <label className="px-0 mb-2 text-500">Nom du Cv *</label>
            <input
              type="text"
              className="form-control offcanvas-input"
              placeholder="Nom"
              required
              defaultValue={cvname}
              onChange={(e) => {
                setErrorMessage("");
                setCvname(e.target.value);
              }}
            />
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {errorMessage?.cv}
              </span>
            </div>
          </div>

          <hr className="light-hr  " />

          <div className="row">
            <div className="">
              <section>
                <h4 className="d-flex fw-bold justify-content-center mt-3 mb-">
                  selectioner des experiences
                </h4>
                {experiences.length > 0 ? (
                  <div className="">
                    <div className="row justify-content-center">
                      {experiences.map((exp, index) => {
                        return (
                          <div className="p-0 card-exp rounded-important card-header card col-xl-5 col-11 shadow m-sm-4 m-3 bg-body form-check form-check-inline">
                            <input
                              type="checkbox"
                              name="experiencecheckbox"
                              id={`${index}checkbox`}
                              className="exp_card_input_element"
                              onChange={() => {
                                handleCheckBox(exp.id);
                              }}
                              defaultChecked={true}
                            />
                            <label
                              name={"exp_card_input_element"}
                              htmlFor={`${index}checkbox`}>
                              <div>
                                <div
                                  className={`${
                                    document.getElementById(index + "checkbox")
                                      ?.checked
                                      ? "card-header-experience "
                                      : ""
                                  }rounded-topOnly card-header   d-flex card-input`}>
                                  <div className="row w-100">
                                    <div className="col-2 d-flex justify-content-center align-items-center">
                                      <svg
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect
                                          x="2"
                                          y="2"
                                          width="32"
                                          height="32"
                                          rx="16"
                                          fill="#BEDEFF"
                                        />
                                        <path
                                          d="M24 18.0007L16 18.0007M24 14.0007L16 14.0007M24 22.0007L16 22.0007M13.3333 18.0007C13.3333 18.3688 13.0349 18.6673 12.6667 18.6673C12.2985 18.6673 12 18.3688 12 18.0007C12 17.6325 12.2985 17.334 12.6667 17.334C13.0349 17.334 13.3333 17.6325 13.3333 18.0007ZM13.3333 14.0007C13.3333 14.3688 13.0349 14.6673 12.6667 14.6673C12.2985 14.6673 12 14.3688 12 14.0007C12 13.6325 12.2985 13.334 12.6667 13.334C13.0349 13.334 13.3333 13.6325 13.3333 14.0007ZM13.3333 22.0007C13.3333 22.3688 13.0349 22.6673 12.6667 22.6673C12.2985 22.6673 12 22.3688 12 22.0007C12 21.6325 12.2985 21.334 12.6667 21.334C13.0349 21.334 13.3333 21.6325 13.3333 22.0007Z"
                                          stroke="#0C6FD6"
                                          strokeWidth="1.33333"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <rect
                                          x="2"
                                          y="2"
                                          width="32"
                                          height="32"
                                          rx="16"
                                          stroke="#D4E9FF"
                                          stroke-width="4"
                                        />
                                      </svg>
                                    </div>

                                    <h3 className="card-title col-8 d-flex p-0 m-0 align-items-center justify-content-center">
                                      {exp.post_title}({exp.contract_type})
                                    </h3>
                                    <div className="d-flex col-2 justify-content-center align-items-center">
                                      {document.getElementById(
                                        index + "checkbox"
                                      )?.checked ? (
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="7.5"
                                            fill="#0C6FD6"
                                          />
                                          <path
                                            d="M11.3327 5.5L6.74935 10.0833L4.66602 8"
                                            stroke="white"
                                            stroke-width="1.66667"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="7.5"
                                            stroke="#0C6FD6"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="7.5"
                                            fill="white"
                                          />
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="7.5"
                                            stroke="#D0D5DD"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body  card-input">
                                  <p>
                                    {exp.company} {exp.start_date} jusqu’à{" "}
                                    {exp.end_date}
                                  </p>
                                  <ul>
                                    <li>
                                      <span className="card-title-candidat  color-msit">
                                        sector :
                                      </span>
                                      <span>{exp.sector}</span>
                                    </li>
                                    <li>
                                      <span className="card-title-candidat  color-msit">
                                        address :
                                      </span>
                                      <span>{exp.address}</span>
                                    </li>
                                    <li>
                                      <span className="card-title-candidat  color-msit">
                                        programming languages :
                                      </span>
                                      <span>{exp.programminglanguage}</span>
                                    </li>
                                    <li className="">
                                      <span className="card-title-candidat  color-msit">
                                        duration :
                                      </span>
                                      <span>{exp.duration}</span>
                                    </li>
                                    <li className="">
                                      <span className="card-title-candidat  color-msit">
                                        description :
                                      </span>
                                      <span>{exp.description}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="card-exp card shadow col-5 p-2 m-5 bg-body rounded  ">
                      <div className="card-header d-flex justify-content-center">
                        <WarningAmberIcon
                          sx={{ color: "red", fontSize: 100 }}
                        />
                      </div>
                      <div className="card-body  card-input">
                        <h3 className="card-title d-flex p-2 bd-highlight justify-content-center">
                          Cette candidat n'a pas des experiences
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
            <div className="  ">
              {path ? (
                path !== "waiting" ? (
                  <>
                    <h4 className="d-flex fw-bold justify-content-center m-2">
                      CV Généré :
                    </h4>
                    <iframe
                      src={path}
                      title="cv"
                      width="100%"
                      height="500px"></iframe>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center">
                      <CircularProgress />
                    </div>
                  </>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateCVPage;
