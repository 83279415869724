import React from "react";

const EmptyData = () => {
  return (
    <div>
      <span className="card-title">Aucune Information trouvée !.</span>
    </div>
  );
};

export default EmptyData;
