import React  from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
function ChartStats({ data, name }) { 
  const chartData = data.map((item) => ({
    name: item.name,
    [name]: item.uv,
  }));

  return (
    <ComposedChart width={300} height={300} data={chartData}>
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey={name} barSize={20} fill="#413ea0" />
    </ComposedChart>
  );
}
export default ChartStats;
