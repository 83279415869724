import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { baseURL } from "../config/config";
import { Link } from "react-router-dom";
import ApexCharts from "apexcharts";
import swal from "sweetalert";
import DisplayError from "../components/ErrorComponants/DisplayError";
import ChartStats from "../components/stats/Samedatachart";
import { PERMISSIONS, hasPermission } from "../components/checkPermissions";

const Home = () => {
  const { user } = useContext(UserContext);
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasCandidatsConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consult"
  );
  const hasProcessEntretienConsultPermission = hasPermission(
    user,
    PERMISSIONS.processEntretiens,
    "consult"
  );
  const hasUserEditPermission = hasPermission(user, PERMISSIONS.users, "edit");
  const hasUsersStatusPermission = hasPermission(
    user,
    PERMISSIONS.users,
    "change_status"
  );
  const [compStatAff, setCompStatAff] = useState(0);
  const [affcompstat, setaffcompstat] = useState();
  const [company, setCompany] = React.useState([]);
  const [process, setProcess] = React.useState([]);
  const [mission, setMission] = React.useState([]);
  const [candidatsData, setCandidatsData] = useState([]);
  const [dataCompany, setDataCompany] = React.useState([]);
  const [missionData, setMissionData] = React.useState([]);
  const [userStat, setUserStat] = React.useState({ data: [] });
  const [statMission, setStatMission] = React.useState({
    thismonth: [],
    lastmonth: [],
  });
  const [statComp, setStatComp] = React.useState({
    thismonth: [],
    lastmonth: [],
  });
  const [statCondidat, setStatCondidat] = React.useState({
    thismonth: [],
    lastmonth: [],
  });
  const [nbrMission, setNbrMission] = useState();
  const [date, setDate] = React.useState(new Date().toLocaleDateString());
  const [nbrCandidats, setNbrCandidats] = useState();

  let values = [];
  let labels = [];

  async function getAllProcess() {
    axios
      .get(`${baseURL}/process/`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setProcess(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  function getMissionByUser() {
    axios
      .get(`${baseURL}/missions/getMissionByUser/`)
      .then((response) => {
        if (response.data.error) return;

        const respData = response.data.data;
        for (const item of respData) {
          labels.push(item?.user?.name);
          values.push(item?.count);
        }

        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();
        setUserStat({ data: respData });
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function enableDisable(user_id, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cet utilisateur?"
        : "Voulez vous Activer cet utilisateur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/users/toggleStatus/${user_id}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
            }
          });
      }
      getMissionByUser();
    });
  }
  async function getAllCompany() {
    try {
      const response = await axios.get(`${baseURL}/companies`);
      if (response.data.error) {
        return;
      }

      const respData = response.data.data;
      setCompany(respData);

      const currentDate = new Date();
      const currentMonthStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ).toISOString();
      const prevMonthStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      ).toISOString();

      const testdate = Date.parse(currentMonthStart);
      const testdateprev = Date.parse(prevMonthStart);

      for (const iterator of response.data.data) {
        const compdate = Date.parse(iterator.creation_date);
        if (compdate >= testdate) {
          statComp.thismonth.push(iterator);
        } else if (compdate >= testdateprev && compdate < testdate) {
          statComp.lastmonth.push(iterator);
        }
      }

      let data = [
        {
          name: "Mois dernier",
          uv: statComp.lastmonth.length,
          pv: 1000,
        },
        {
          name: "Ce mois-ci",
          uv: statComp.thismonth.length,
          pv: 1000,
        },
      ];

      setDataCompany(data);

      const compStatAff =
        ((statComp.thismonth.length - statComp.lastmonth.length) /
          Math.abs(statComp.lastmonth.length)) *
        100;
      setCompStatAff(compStatAff);

      setaffcompstat(
        compStatAff !== 0 ? (
          <div className="col-3">
            <div className="card p-3 fw-bold style_process_title  text-center">
              Company Growth {compStatAff}%
            </div>
          </div>
        ) : null
      );
    } catch (error) {
      DisplayError("Une erreur est survenue, veuillez réessayer.");
    }
  }

  async function getAllMission() {
    try {
      const response = await axios.get(`${baseURL}/missions/allMissions`);
      if (response.data.error) return;

      let respData = response.data.data;
      let activeData = respData.filter(
        (iterator) => iterator.isEnabled === "yes"
      );
      setNbrMission(Object.keys(activeData).length);
      setMission(respData);

      const prev = new Date(new Date().setDate(0)).toISOString();
      let prevm = new Date(new Date().setDate(0));
      prevm = new Date(prevm.setDate(0)).toISOString();
      const testdate = Date.parse(prev);
      const testdateprev = Date.parse(prevm);

      let thisMonthMissions = [];
      let lastMonthMissions = [];

      for (const iterator of respData) {
        const compdate = Date.parse(iterator.creation_date);
        if (compdate >= testdate) {
          thisMonthMissions.push(iterator);
        } else if (compdate >= testdateprev && compdate <= testdate) {
          lastMonthMissions.push(iterator);
        }
      }

      setStatMission({
        thismonth: thisMonthMissions,
        lastmonth: lastMonthMissions,
      });

      let data = [
        {
          name: "Mois dernier",
          uv: lastMonthMissions.length,
          pv: 1000,
        },
        {
          name: "Ce mois-ci",
          uv: thisMonthMissions.length,
          pv: 1000,
        },
      ];
      setMissionData(data);
    } catch (error) {
      DisplayError();
    }
  }
  async function getAllCandidats() {
    try {
      const response = await axios.get(`${baseURL}/consultants/all`);
      if (response.data.error) return;

      let respData = response.data.data;
      setNbrCandidats(respData.length);

      const prev = new Date(new Date().setDate(0)).toISOString();
      let prevm = new Date(new Date().setDate(0));
      prevm = new Date(prevm.setDate(0)).toISOString();
      const testdate = Date.parse(prev);
      const testdateprev = Date.parse(prevm);

      let thisMonthCandidates = [];
      let lastMonthCandidates = [];

      for (const candidate of respData) {
        const compdate = Date.parse(candidate.creation_date);
        if (compdate >= testdate) {
          thisMonthCandidates.push(candidate);
        } else if (compdate >= testdateprev && compdate <= testdate) {
          lastMonthCandidates.push(candidate);
        }
      }

      setStatCondidat({
        thismonth: thisMonthCandidates,
        lastmonth: lastMonthCandidates,
      });

      let data = [
        {
          name: "Mois dernier",
          uv: lastMonthCandidates.length,
          pv: 1000,
        },
        {
          name: "Ce mois-ci",
          uv: thisMonthCandidates.length,
          pv: 1000,
        },
      ];
      setCandidatsData(data);
    } catch (error) {
      DisplayError();
    }
  }

  var options = {
    series: values,
    labels: labels,
    yaxis: {
      show: false,
    },
    chart: {
      type: "polarArea",
      height: 386,
      animations: {
        enabled: false,
      },
    },
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value) {
          return value + " missions";
        },
      },
    },
  };
  useEffect(() => {
    if (hasCandidatsConsultPermission) {
      getAllCandidats();
    }
  }, [hasCandidatsConsultPermission]);
  useEffect(() => {
    if (hasCompanyConsultPermission) {
      getAllCompany();
    }
  }, [hasCompanyConsultPermission]);
  useEffect(() => {
    if (hasProcessEntretienConsultPermission) {
      getAllProcess();
    }
  }, [hasProcessEntretienConsultPermission]);
  useEffect(() => {
    if (hasMissionsConsultPermission) {
      getAllMission();
      getMissionByUser();
    }
  }, [hasMissionsConsultPermission]);

  return (
    <div>
      <h1> Bonjour {user && user.name}</h1>
      <section>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="card p-3 fw-bold style_process_title  text-center">
              {date}
            </div>
          </div>
          {/* {affcompstat} */}
          <div className="col-md-6 col-lg-3">
            <div className="card p-3 fw-bold style_process_title  text-center">
              Croissance de l'entreprise {compStatAff}%
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card p-3 fw-bold style_process_title  text-center">
              Nombre des missions {nbrMission}
            </div>{" "}
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card p-3 fw-bold style_process_title  text-center">
              Nombre des candidats {nbrCandidats}
            </div>{" "}
          </div>
        </div>
      </section>
      {hasMissionsConsultPermission && (
        <div className="row">
          <div className="col-lg-6">
            <div className="card statcards">
              <div className="card-header d-flex justify-content-center">
                <h3>Statistiques des commerciaux</h3>
              </div>
              <div id="chart"></div>
            </div>
          </div>
          <div className="col-lg-6 statcards">
            <div className="card statcards">
              <div className="card-header d-flex justify-content-center">
                <h3>Details des commerciaux</h3>
              </div>
              <div className="card-body">
                <div
                  id="carouselExampleAutoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel">
                  <div className="carousel-inner">
                    {userStat.data.length > 0
                      ? userStat.data.map((item, index) => (
                          <div
                            className={
                              index === 0
                                ? "carousel-item  active"
                                : "carousel-item"
                            }
                            key={index}>
                            <div className="card">
                              <div className="container">
                                <div className="bg-blue"></div>
                                <div className="row">
                                  <div className="col-12 border-bottom px-4 py-2">
                                    <div className="row p-0">
                                      <div className=" col-md-2 d-flex justify-content-center">
                                        <img
                                          className={
                                            "border" +
                                            index +
                                            " pic mb-md-0 mb-3"
                                          }
                                          src={
                                            item?.user?.avatar?.path
                                              ? baseURL +
                                                "/" +
                                                item?.user?.avatar?.path
                                                  .split("./")
                                                  .pop()
                                              : `${baseURL}assets/img/msit-logo.png`
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-md-10 ps-4 ">
                                        <div className="d-flex justify-content-between">
                                          <p className="textmuted"></p>
                                        </div>
                                        <h3 className="text-primary">
                                          {item?.user?.name}{" "}
                                        </h3>
                                        <p className="textmuted">
                                          {item?.user?.role?.name}
                                        </p>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                          <div className="col-5">
                                            {hasUserEditPermission && (
                                              <Link
                                                className="col-3 btn text-primary "
                                                to={`/users/edit/${item?.user?._id}`}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Modifier utilisateur">
                                                <i className="bi bi-pen"></i>
                                              </Link>
                                            )}
                                            {hasUsersStatusPermission && (
                                              <button
                                                className="col-3 btn text-primary me-2 btn"
                                                onClick={() => {
                                                  enableDisable(
                                                    item?.user?._id,
                                                    item?.user?.isEnabled
                                                  );
                                                }}>
                                                {item?.user?.isEnabled ===
                                                "yes" ? (
                                                  <i
                                                    className="text-success bi bi-toggle2-on"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Désactiver utilisateur"></i>
                                                ) : (
                                                  <i
                                                    className="text-danger bi bi-toggle2-off"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Activer utilisateur"></i>
                                                )}
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 py-2 px-9 d-flex justify-content-center">
                                    <button
                                      className="btn btn-primary px-1 d-flex justify-content-center"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={
                                        ".multi-collapse" + item?.user?._id
                                      }
                                      aria-expanded="false"
                                      aria-controls={item?.user?._id}>
                                      Voir plus
                                    </button>
                                  </div>
                                  <div className="col-12 py-2 px-9 statcardsdetails">
                                    <div className="row">
                                      {item?.missionIds?.map((mission) => (
                                        <div className="col-12">
                                          <div
                                            className={
                                              "collapse multi-collapse" +
                                              item?.user?._id
                                            }
                                            id={item?.user?._id}>
                                            <div className="border-bottom py-2">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                  <p className="p-blue">
                                                    {mission?.title}
                                                  </p>
                                                  <p className="textmuted">
                                                    {mission?.description}
                                                  </p>
                                                </div>
                                                <div className="arrow">
                                                  <Link
                                                    className="col-3 btn text-primary me-2"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="details mission"
                                                    to={`/missions/details/${mission._id}`}>
                                                    <i className="ri-arrow-right-s-fill"></i>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="hide d-flex justify-content-center"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {hasCompanyConsultPermission && (
        <div className=" row mt-3 mt-lg-0">
          <div className="col-lg-4">
            {dataCompany.length > 0 ? (
              <div className="card">
                <div className="card-header d-flex justify-content-center">
                  <h4>Statistiques des Sociètès</h4>
                </div>
                <div className="card-body">
                  <ChartStats
                    data={dataCompany}
                    statMission={statComp}
                    name={"Sociètès"}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {hasMissionsConsultPermission && (
            <div className="col-lg-4">
              {missionData.length > 0 ? (
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h4>Statistiques des Missions </h4>
                  </div>
                  <div className="card-body">
                    <ChartStats
                      data={missionData}
                      name={"Missions"}
                      statMission={statMission}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
          {hasCandidatsConsultPermission && (
            <div className="col-lg-4">
              {candidatsData.length > 0 ? (
                <div className="card">
                  <div className="card-header d-flex justify-content-center">
                    <h4>Statistiques des Candidats</h4>
                  </div>
                  <div className="card-body">
                    <ChartStats
                      data={candidatsData}
                      name={"Candidats"}
                      statMission={statCondidat}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
      {hasMissionsConsultPermission && (
        <section>
          <h4 className="color-msit">Liste des missions</h4>
          <div className="row">
            {mission
              .slice(0)
              .reverse()
              .map((item, key) => {
                if (key < 7) {
                  return (
                    <div className="col-md-3">
                      <div className="card p-3 fw-bold style_process_title  text-center">
                        <Link
                          className="color-msit"
                          to={`/missions/details/${item.id}`}>
                          {item.title}
                        </Link>
                      </div>
                    </div>
                  );
                }
                if (key === 7) {
                  return (
                    <>
                      <div className="col-md-3">
                        <div className=" card-style">
                          <div className="row">
                            <div className="col-md-8 p-0">
                              {" "}
                              <Link
                                className="nav-link collapsed"
                                to="/missions">
                                <button
                                  type="button"
                                  className="btn ms-2 color-white">
                                  Tout les missions
                                </button>
                              </Link>
                            </div>
                            <div className="col-md-4">
                              {" "}
                              <Link
                                className="nav-link collapsed"
                                to="/missions">
                                <div className="col-md-4 arrow_style">
                                  <i className="bi bi-arrow-right "></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </section>
      )}
      {hasCompanyConsultPermission && (
        <section>
          <h4 className="color-msit">Les Sociétés</h4>
          <div className="row">
            {company
              .slice(0)
              .reverse()
              .map((item, key) => {
                if (key < 7) {
                  return (
                    <div className="col-md-3">
                      <div className="card p-3 fw-bold style_process_title  text-center">
                        <Link
                          className="color-msit"
                          to={`/company/details/${item._id}`}>
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  );
                }
                if (key === 7) {
                  return (
                    <>
                      <div className="col-md-3">
                        <div className=" card-style">
                          <div className="row">
                            <div className="col-md-8 p-0">
                              {" "}
                              <Link
                                className="nav-link collapsed"
                                to="/company">
                                <button
                                  type="button"
                                  className="btn ms-2 color-white">
                                  Tout les Sociétés
                                </button>
                              </Link>
                            </div>
                            <div className="col-md-4">
                              {" "}
                              <Link
                                className="nav-link collapsed"
                                to="/company">
                                <div className="col-md-4 arrow_style">
                                  <i className="bi bi-arrow-right "></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </section>
      )}
      {hasProcessEntretienConsultPermission && (
        <section>
          <h4 className="color-msit">Les processes d'entretien</h4>
          <div className="row">
            {process
              .slice(0)
              .reverse()
              .map((item, key) => {
                if (key < 7) {
                  return (
                    <div className="col-md-3">
                      <div className="card p-3 fw-bold style_process_title  text-center">
                        <Link
                          className="color-msit"
                          to={`/entretien/${item.id}`}>
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  );
                }
                if (key === 7) {
                  return (
                    <>
                      <div className="col-md-3">
                        <div className=" card-style">
                          <div className="row">
                            <div className="col-md-8 p-0">
                              <Link
                                className="nav-link collapsed"
                                to="/process">
                                <button
                                  type="button"
                                  className="btn ms-2 color-white pe-0">
                                  Tout les processes
                                </button>
                              </Link>
                            </div>
                            <div className="col-md-4">
                              {" "}
                              <Link
                                className="nav-link collapsed"
                                to="/process">
                                <div className="col-md-4 arrow_style ">
                                  <i className="bi bi-arrow-right "></i>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </section>
      )}
    </div>
  );
};
export default Home;
