import React, { useContext, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
const initFormErrors = {
  name: [""],
  etape: [""],
};

function CreateProcess({ setAddProcessModalFromChild, getAllProcess }) {
  const { user } = useContext(UserContext);
  const hasProcessEntretienConsultPermission = hasPermission(
    user,
    PERMISSIONS.processEntretiens,
    "consult"
  );
  const [processName, setProcessName] = useState([]);
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [processSteps, setProcessSteps] = useState([{ index: 0, name: "" }]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedSteps = [...processSteps];
    updatedSteps[index] = { name: value, index };
    setProcessSteps(updatedSteps);
  };

  const handleRemoveClick = (index) => {
    //create a new array without the step with the specified index
    const updatedSteps = processSteps.filter((_, i) => i !== index);
    //update the indices of the remainingSteps
    const updatedStepsWithIndices = updatedSteps.map((step, i) => ({
      ...step,
      index: i,
    }));
    setProcessSteps(updatedStepsWithIndices);
  };

  const handleAddClick = () => {
    // get the index for the new step
    const newIndex = processSteps.length;
    //check if the last step in the array has a notempty name before adding a new step
    if (processSteps[newIndex - 1]?.name.trim() !== "") {
      //create a new array with the added step and its index
      setProcessSteps([...processSteps, { index: newIndex, name: "" }]);
      setFormErrors(initFormErrors);
    }
  };

  function sendData() {
    axios
      .post(`${baseURL}/process/create`, {
        name: processName,
        steps: processSteps,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        if (hasProcessEntretienConsultPermission) {
          getAllProcess();
        }
        setAddProcessModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5">
                Créer un process
              </h3>
              <div className="row">
                <div className="col-md-10 mb-3">
                  <input
                    type="text"
                    name="ProcessName"
                    className="form-control"
                    placeholder="Nom de process"
                    onChange={(e) => setProcessName(e.target.value)}
                  />
                  <div className="input-error">
                    <span className="text-danger"> {formerrors.name}</span>
                  </div>
                </div>
                <span className="text-muted small">
                  * Veuillez mieux entrer une étape sans espaces ni caractères
                  spéciaux (ex. Étape1)
                </span>
                {processSteps.map((step, key) => {
                  const currentStep = processSteps[key];
                  return (
                    <div className="row mt-2" key={key}>
                      <div className="col-md-11 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="Etape"
                          onChange={(e) => handleInputChange(e, key)}
                          value={currentStep.name}
                          placeholder={`Etape `}
                        />
                        {processSteps.length - 1 === key && (
                          <button
                            type="button"
                            onClick={handleAddClick}
                            className="btn button_steps_bgradd ms-2"
                            disabled={currentStep.name?.trim() === ""}
                          >
                            <i className="bi bi-plus button_steps_add">
                              ajouter autre etape
                            </i>
                          </button>
                        )}
                      </div>
                      <div className="col-md-1">
                        {processSteps.length !== 1 && (
                          <button
                            type="button"
                            className="btn button_steps_bgrdash p-1"
                            onClick={() => handleRemoveClick(key)}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-4"> </div>
                      </div>
                    </div>
                  );
                })}
                <div className="input-error">
                  <span className="text-danger">{formerrors.steps}</span>
                </div>
              </div>
              <div className="modal-footer mt-4">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => setAddProcessModalFromChild("")}
                >
                  Annuler
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendData}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}
export default CreateProcess;
