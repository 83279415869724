import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CandidatsExtractingFormStep3 from "./CandidatsExtractingFormStep3";
import CandidatsExtractingFormStep1 from "./CandidatsExtractingFormStep1";
import { CandidatContext } from "../../context/candidatContext";
import { baseURL } from "../../config/config";
import Select from "react-select";

const initFormErrors = {
  status: [""],
  city: [""],
  address: [""],
  cp: [""],
};

const CandidatsExtractingFormStep2 = ({
  setChildComponent,
}) => {
  const msg = "Ce champ est obligatoire";
  const [countries, setCountries] = React.useState([]);

  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [city, setCity] = useState([]);

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }
  const handlecountry = (event) => {
    const getcountryid = event.target.value;
    setNewCandidat((prevState) => ({
      ...prevState,
      country: getcountryid,
    }));
    getCities(getcountryid);
  };


  function getCities(countryid) {
    if (countryid === undefined) return;
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) return;
      setCity(resp.data.data);
    });
  }

  useEffect(() => {
    getCountries();
  }, []);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
  
    if (!newCandidat.city && newCandidat.country) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        city: "Veuillez sélectionner une ville.",
      }));
      error = true;
    }
  
    if (error === false) {
      setChildComponent(
        <CandidatsExtractingFormStep3 setChildComponent={setChildComponent} />
      );
    }
  }

  const statusOptions = [
    { value: "single", label: "Célibataire" },
    { value: "in_relationship", label: "En couple" },
    { value: "married", label: "Marié" },
    { value: "divorced", label: "Divorcé" },
    { value: "pacs", label: "Pacsé" },
  ];

  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "66%" }}
          aria-valuenow="66"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Etat Civil </label>
                <Select
                  options={statusOptions}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      status: e.value,
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.status}</span>
                </div>
              </div>
              <div className="col-md-6 pb-3">
                <label>Adresse </label>
                <input
                  type="text"
                  value={newCandidat?.address}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      address: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.address}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>pays </label>
                <div className="input-group mb-3">
                  <select
                    id="inputState"
                    value={newCandidat?.countries}
                    className="form-select"
                    onChange={(e) => handlecountry(e)}>
                    <option selected>Sélectionner une pays</option>
                    {countries != null &&
                      countries.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label>Ville </label>
                <div className="input-group mb-3">
                  <select
                    id="inputState"
                    value={newCandidat?.selectedCity}
                    onChange={(e) => {
                      const selectedCity = JSON.parse(e.target.value);
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        city: selectedCity.id,
                        cityName: selectedCity.name,
                      }));
                    }}
                    className="form-select">
                    <option selected>Sélectionner une ville</option>
                    {city != null &&
                      city.map((cityIteration, index) => {
                        return (
                          <option
                            key={index}
                            value={JSON.stringify(cityIteration)}>
                            {cityIteration.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.city}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Code postal </label>
                <input
                  type="number"
                  min="0"
                  value={newCandidat?.cp}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      cp: e.target.value,
                    }));
                  }}
                  className="form-control"
                />
                <div className="input-error">
                  <span className="text-danger">{errorMessage.cp}</span>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <CandidatsExtractingFormStep1
                      setChildComponent={setChildComponent}
                    />
                  );
                }}>
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}>
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CandidatsExtractingFormStep2;
