import React, { useContext } from "react";
import { CandidatContext } from "../../context/candidatContext";
import CandidatsFormStep3 from "./CandidatsFormStep3";
import { useHistory } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

const initFormErrors = {
  name: [""],
  email: [""],
  phone: [""],
  address: [""],
  cp: [""],
  birthDay: [""],
  lieu_naissance: [""],
  diplome: [""],
  annéeGraduation: [""],
  type: [""],
  langues: [""],
  avatar: [""],
};
const Confirm = ({ setChildComponent }) => {
  const { newCandidat } = useContext(CandidatContext);
  const noDataFound = "Aucune donnée trouvée";

  const history = useHistory();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  function sendData() {
    const formData = new FormData();
    formData.append("gender", newCandidat.gender);
    formData.append("firstname", newCandidat.firstname);
    formData.append("lastname", newCandidat.lastname);
    formData.append("email", newCandidat.email);
    formData.append("pays", newCandidat.countryid);
    formData.append("city", newCandidat.city);
    formData.append("address", newCandidat.address);
    formData.append("cp", newCandidat.cp);
    formData.append("phone", newCandidat.phone);
    formData.append("birthday", newCandidat.birthDay);
    formData.append("birthPlace", newCandidat.birthPlace);
    formData.append("diplomeDate", newCandidat.diplomeDate);
    formData.append("diplome", newCandidat.diplome);
    formData.append("type", newCandidat.type);
    formData.append("langues", newCandidat.langues);
    formData.append("status", newCandidat.status || "single");
    formData.append("skills", newCandidat.skills);
    formData.append("experience", newCandidat.experience);
    formData.append("avatar", newCandidat.avatar);
    formData.append("cv", newCandidat.cv);
    formData.append("cvname", newCandidat.cvname);

    axios
      .post(`${baseURL}/candidates/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setTimeout(() => {
          history.push("/candidats");
        }, 2400);
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
      });
  }

  return (
    <div>
      <section className="section">
        <div className="row">
          <h1 className="text-center color-msit fw-bolder pb-5">
            Fiche de candidat
          </h1>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <div className="row pb-3">
                  <div className="col-md-5">
                    <div>
                      <label className="color-msit fw-bold">
                        Nom et Prénom :{" "}
                      </label>{" "}
                      <span>
                        {newCandidat.lastname} {newCandidat.firstname}
                      </span>
                    </div>
                    <div>
                      <label className="color-msit fw-bold ">
                        Type de candidat :{" "}
                      </label>{" "}
                      {newCandidat.typename}
                    </div>
                  </div>
                  <div className="col-md-5">
                    {" "}
                    <div>
                      <label className="color-msit fw-bold">Email : </label>{" "}
                      {newCandidat.email || noDataFound}
                    </div>
                    <div>
                      <label className="color-msit fw-bold">Téléphone : </label>{" "}
                      {newCandidat.phone || noDataFound}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations personnelles</h3>

                <div className="row pb-3">
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">Genre : </label>{" "}
                      {newCandidat.gender}
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div>
                      <label className="color-msit fw-bold">
                        Etat civil :{" "}
                      </label>{" "}
                      {newCandidat.status || noDataFound}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">Ville : </label>{" "}
                      {newCandidat.cityName ? (
                        newCandidat.cityName
                      ) : (
                        <span>{noDataFound}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">Adresse : </label>{" "}
                      {newCandidat.address || noDataFound}
                    </div>
                  </div>
                  <div className="col-md-2">
                    {" "}
                    <div>
                      <label className="color-msit fw-bold">
                        Code postal :{" "}
                      </label>{" "}
                      {newCandidat.cp || noDataFound}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="styleSection1 pb-3">
            <div className="card">
              <div className="card-body pt-4">
                <h3 className="pb-2">Informations professionnelle</h3>
                <div className="row pb-3">
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">Expérience :</label>{" "}
                      {newCandidat.experience || noDataFound}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">Compétence :</label>{" "}
                      {newCandidat.skills || noDataFound}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">Diplome:</label>{" "}
                      {newCandidat.diplome || noDataFound}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div>
                      <label className="color-msit fw-bold">
                        Année de diplome :
                      </label>{" "}
                      {newCandidat.diplomeDate || noDataFound}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <label className="color-msit fw-bold me-2">
                        langue :
                      </label>{" "}
                      {newCandidat.langues ? (
                        JSON.parse(newCandidat.langues).map((lang, key) => (
                          <span key={key} className="badge bg-secondary me-2">
                            {lang}
                          </span>
                        ))
                      ) : (
                        <span>{noDataFound}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="fs-4">
          <span className="text-danger"> {formerrors.email}</span>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-outline-secondary  me-2"
            onClick={() => {
              setChildComponent(
                <CandidatsFormStep3 setChildComponent={setChildComponent} />
              );
            }}
          >
            Retour
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              sendData();
            }}
          >
            Confirmer
          </button>
        </div>
      </section>
    </div>
  );
};

export default Confirm;
