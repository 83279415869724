const PERMISSIONS = {
  users: 'users',
  roles: 'roles',
  candidatTypes: 'candidatTypes',
  candidats: 'candidats',
  missions: 'missions',
  company: 'company',
  processEntretiens: 'processEntretiens',
  employees: 'employees',
  cv: 'cv',
  processCandidats: 'processCandidats',
  category: 'category',
  experience: 'experience',
  manager: 'manager',
  matching: 'matching',
  missionEmployees: 'missionEmployees',
  relaunchMission: 'relaunchMission',
  workdays: 'workdays',
  invoice: 'invoice',
};

const hasPermission = (user, permissionType, permission) => {
  if (user === null) {
    return false;
  }

  const permissions = JSON.parse(user.userpermissions);

  if (permissions[permissionType]) {
    return permissions[permissionType].includes(permission);
  }

  return false;
};

module.exports = {
  PERMISSIONS,
  hasPermission
};