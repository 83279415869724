import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import EmptyData from "../components/emptyData";
import { baseURL } from "../config/config";
import { UserContext } from "../context/UserContext";
import DisplayError from "../components/ErrorComponants/DisplayError";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { PERMISSIONS, hasPermission } from "../components/checkPermissions";
import Loader from "../components/loader";
const EmplyeeHome = () => {
  const { user } = useContext(UserContext);
  const hasMissionsEmployeeConsultPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "consult"
  );
  const hasWorkDaysGesionPermission = hasPermission(
    user,
    PERMISSIONS.workdays,
    "gerer"
  );
  const hasWorkDaysConsultPermission = hasPermission(
    user,
    PERMISSIONS.workdays,
    "consult"
  );
  const hasEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consult"
  );
  const [employeeData, setEmployeeData] = useState([]);
  const [missionData, setMissionData] = useState([]);
  const [mission, setMission] = useState([]);
  const [dates, setDates] = useState([]);
  const [month, setMonth] = useState("");
  const [allTimes, setAllTimes] = useState([]);
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  const days = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];

  function daysInThisMonth() {
    const now = new Date();
    setMonth(now.getMonth());
  }
  function handleMonthChange(selectedMonth) {
    const now = new Date();
    setMonth(selectedMonth);
    const MonthAbreviations = [
      "jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const newDate = new Date(
      `${MonthAbreviations[selectedMonth].toString()}/1/${now
        .getFullYear()
        .toString()}`
    );
    getCurrentMonthDates(newDate);
  }
  function getCurrentMonthDates(newDate) {
    const currentDate = newDate ? newDate : new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let dates = [];
    let week = [];
    for (var i = 1; i <= 31; i++) {
      const date = new Date(currentYear, currentMonth, i);

      if (date.getMonth() !== currentMonth) {
        break;
      }
      const formattedDateMonth =
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2);

      const formattedDate =
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear();
      week.push({
        showDate: formattedDateMonth,
        date: formattedDate,
        day: date.getDay(),
        month: currentDate.getMonth() + 1,
        daydate: date.getDate(),
      });
      if (date.getDay() === 6 || i === 31) {
        dates.push(week);
        week = [];
      }
    }
    setDates(dates);
  }
  function handleTime(time, mission) {
    axios
      .post(`${baseURL}/workdays/createWorkSheet`, {
        employee: employeeData?._id,
        mission: mission._id,
        day: JSON.parse(time).date.day,
        date: JSON.parse(time).date.date,
        month: JSON.parse(time).date.month,
        time: JSON.parse(time).time,
      })
      .then((response) => {
        GetAllWorkSheets();
        Toastify({
          text: "enregistré avec succees",
          duration: 2000,
          close: true,
          gravity: "bottom", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #0f84ff , #0f84ff )",
          },
        }).showToast();
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function GetEmpsUser() {
    const id = user.id;
    axios
      .get(`${baseURL}/employeeUser/getuser/${id}`)
      .then((response) => {
        if (response.data.error) return;
        setEmployeeData(response.data.data.employee);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function GetAllWorkSheets() {
    const id = employeeData?._id;
    axios
      .get(`${baseURL}/workdays/allWorkSheet/${id}`, {
        month: new Date().getMonth(),
      })
      .then((response) => {
        if (response.data.error) return;
        setAllTimes(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (
      employeeData?._id &&
      hasWorkDaysConsultPermission &&
      hasWorkDaysGesionPermission
    ) {
      GetAllWorkSheets();
    }
  }, [employeeData, hasWorkDaysConsultPermission, hasWorkDaysGesionPermission]);
  useEffect(() => {
    if (employeeData?._id && hasMissionsEmployeeConsultPermission) {
      getMissionByEmplyeeId();
    }
  }, [employeeData, hasMissionsEmployeeConsultPermission]);
  useEffect(() => {
    daysInThisMonth();
    getCurrentMonthDates();
  }, []);

  useEffect(() => {
    if (hasEmployeesConsultPermission) {
      GetEmpsUser();
    }
  }, [hasEmployeesConsultPermission]);
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable
          className="dataTableClass"
          columns={columnsMission}
          data={mission}
          pagination
        />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);

  async function getMissionByEmplyeeId() {
    const emplyeeId = employeeData?._id;
    if (!emplyeeId) return;

    try {
      const response = await axios.get(
        `${baseURL}/missionEmployees/missions/${emplyeeId}`
      );
      if (response.data.error) return;
      setMission(response.data.data);
    } catch (err) {
      DisplayError();
    }
  }
  const columnsMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "6%",
      sortable: true,
    },
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <div>{row.mission.title}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.mission.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.mission.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.mission.period}</div>
        </div>
      ),
      width: "12%",
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div>
            {row.mission.manager != null && row.mission.manager.fname}{" "}
            {row.mission.manager != null && row.mission.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="row col-12">
            <Link
              className="col-3 btn text-primary me-2"
              to={`/missions/details/${row.mission._id}`}>
              <i className="ri-arrow-right-s-fill"></i>
            </Link>
          </div>
        );
      },
      width: "9%",
    },
  ];

  return (
    <>
      <h1>Bonjour {user.name}</h1>
      <div>
        <div className="pagetitle row">
          <div className="col-md-8 pt-3">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">Acceuil</li>
              </ol>
            </nav>
          </div>
        </div>
        <section className="section profile  ">
          <div className="row">
            <div className="col-xl-4 pt-5">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center text-uppercase ">
                  <img
                    src={`${baseURL + user.avatar.path.substring(1)}`}
                    alt=""
                    className="rounded-circle"
                  />
                  <h2 className="text-center">{user != null && user.name}</h2>
                  <div className=" text-lowercase  mt-4 flex">
                    <div className="row">
                      <div className="col-1 label">
                        <i className="bi bi-envelope-fill"></i>{" "}
                      </div>
                      <div className="col-10">
                        <a
                          href={`mailto:${user.email}`}
                          className="color-msit "
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Envoyer mail">
                          {user != null && user.email}
                        </a>
                      </div>

                      <div
                        className="col-10"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Envoyer mail"></div>
                    </div>

                    <div className="row">
                      <div className="col-1 label">
                        <i className="bi bi-telephone-fill"></i>
                      </div>
                      <div className="col-10">
                        <a
                          href={`tel:+${user.phone}`}
                          className="color-msit "
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Appeler">
                          {user != null && user.phone}
                        </a>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-1 label">
                        <i className="bi bi-geo-alt  me-1"></i>
                      </div>
                      <div className="col-10">
                        {user != null && user.address}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-1 label">
                        <i className=" bi bi-briefcase me-1"></i>
                      </div>
                      <div className="col-10">
                        {employeeData != null && employeeData.job}{" "}
                        {employeeData.experience}
                        ans
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-1 label">
                        <i className=" bi bi-gift me-1"></i>
                      </div>
                      <div className="col-10">
                        {employeeData != null && employeeData.birthday
                          ? employeeData.birthday
                          : "Indisponible"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hasMissionsEmployeeConsultPermission &&
              hasEmployeesConsultPermission && (
                <div className="col-xl-8 card-mission-employee">
                  <div className="pagetitle row">
                    <h1>Les Missions</h1>
                  </div>
                  <section className="section">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-header d-flex ">
                            Missions affectees
                          </div>
                          <div className="card-body  pt-3">{missionData}</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
          </div>
        </section>
        {hasWorkDaysGesionPermission &&
          hasWorkDaysConsultPermission &&
          hasMissionsEmployeeConsultPermission &&
          hasEmployeesConsultPermission && (
            <div className="card">
              <div className="card-title pagetitle ps-4">
                <h1 className="row">
                  <p className="col-md-4">Feuille de temps pour :</p>
                  <div className=" col-md-2 pe-5">
                    <select
                      id="inputState"
                      className="form-select"
                      value={month}
                      onChangeCapture={(ev) =>
                        handleMonthChange(ev.target.value)
                      }>
                      <option disabled>...</option>
                      {monthNames.map((month, index) => {
                        return (
                          <option selected={month === index} value={index}>
                            {month}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </h1>
              </div>
              <div className="card-body">
                <div className="accordion" id="accordionExample">
                  {dates.map((week, i) => {
                    return (
                      <>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${i + 1}`}
                              aria-expanded="true"
                              aria-controls={`collapse${i + 1}`}>
                              semaine de {week[0].showDate} jusqu'a{" "}
                              {week[week.length - 1].showDate}
                            </button>
                          </h2>
                          <div
                            id={`collapse${i + 1}`}
                            className={`accordion-collapse collapse ${
                              i === 0 ? "show" : ""
                            }`}
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body table-responsive">
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Mission</th>
                                    {days.map((day) => {
                                      return <th scope="col">{day}</th>;
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {mission.map((miss) => {
                                    let weekIndex = -1;
                                    return (
                                      <tr>
                                        <td>1</td>
                                        <td> {miss.mission.title}</td>
                                        {days.map((day, daysIndex) => {
                                          if (
                                            week[weekIndex + 1] &&
                                            week[weekIndex + 1]["day"] ===
                                              daysIndex
                                          ) {
                                            weekIndex++;
                                            if (
                                              weekIndex === 7 ||
                                              weekIndex >= week.length
                                            ) {
                                              weekIndex = 0;
                                            }
                                            return (
                                              <td>
                                                <select
                                                  key={daysIndex}
                                                  id="inputState"
                                                  className="form-select"
                                                  value={JSON.stringify({
                                                    time: allTimes[
                                                      miss?.mission?._id
                                                    ]
                                                      ? allTimes[
                                                          miss?.mission?._id
                                                        ][week[weekIndex]?.date]
                                                      : "0",
                                                    date: {
                                                      showDate:
                                                        week[weekIndex]
                                                          ?.showDate,
                                                      date: week[weekIndex]
                                                        ?.date,
                                                      day: week[weekIndex]?.day,
                                                      month:
                                                        week[weekIndex]?.month,
                                                      daydate:
                                                        week[weekIndex]
                                                          ?.daydate,
                                                    },
                                                  })}
                                                  onChange={(ev) => {
                                                    handleTime(
                                                      ev.target.value,
                                                      miss.mission,
                                                      weekIndex
                                                    );
                                                  }}>
                                                  <option disabled selected>
                                                    ...
                                                  </option>
                                                  x
                                                  <option
                                                    value={JSON.stringify({
                                                      time: "0",
                                                      date: week[weekIndex],
                                                    })}>
                                                    0
                                                  </option>
                                                  <option
                                                    value={JSON.stringify({
                                                      time: "1",
                                                      date: week[weekIndex],
                                                    })}>
                                                    1
                                                  </option>
                                                  <option
                                                    value={JSON.stringify({
                                                      time: "0.5",
                                                      date: week[weekIndex],
                                                    })}>
                                                    0.5
                                                  </option>
                                                </select>
                                              </td>
                                            );
                                          } else {
                                            return <td></td>;
                                          }
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default EmplyeeHome;
