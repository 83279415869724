import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import Loader from "../loader";

function EditTypes({ setAddTypesModalFromChild, getAllTypes, typesId }) {
  const [types, setTypes] = useState({ types: "", id: "" });
  const [formerrors, setFormErrors] = React.useState();
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    axios.get(`${baseURL}/candidatTypes/${typesId}`).then((resp) => {
      if (resp.data.error) return;
      setTypes(resp.data.data);
    });
  }, []);

  function sendData() {
    setIsLoading(true);
    axios
      .put(`${baseURL}/candidatTypes/${typesId}`, { name: types })
      .then((resp) => {
        setFormErrors('');
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getAllTypes();
        setAddTypesModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data.message);
        setIsLoading(false);
      });
  }

  return (
    <div>
      <div
        className="modal fade show"
        id="role_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h3 className="text-align-center fw-bold">Modifier un type</h3>
            </div>

            <div className="modal-body">
              <label>Nom de type :</label>
              <input
                type="text"
                className="form-control"
                id="role_name"
                value={types.name}
                placeholder="veuillez saisir le type du candidat"
                onChange={(e) => setTypes(e.target.value)}
                required
              />
              <div className="input-error">
                {formerrors && (
                  <span className="text-danger">{formerrors}</span>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddTypesModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                onClick={sendData}
                disabled={isLoading}
                className="btn btn-primary"
              >
                {isLoading ? <Loader /> : "Enregistrer"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default EditTypes;
