import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="copyright">
        &copy; Copyright{" "}
        <strong>
          <span>MSIT Dashboard</span>
        </strong>
        . Tous droits réservés
      </div>
      <div className="credits">
        Développé par{" "}
        <a target={"_blank"} rel="noreferrer" href="https://msit-conseil.fr/">
          MSIT Conseil
        </a>
      </div>
    </footer>
  );
};

export default Footer;
