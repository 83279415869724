import React, { useEffect, useState } from "react";
import Loader from "../../components/loader";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { baseURL } from "../../config/config";
import axios from "axios";
import swal from "sweetalert";

function UpdateInterviewOffacnas({
  selectedRdv,
  refreshFunction,
  emptySelectedRdv,
}) {
  const initRdvFormErrors = {
    time: [""],
    date: [""],
    description: [""],
  };
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState();
  const [mision, setMission] = useState();
  const [time, setTime] = useState("");
  const [description, setDescription] = useState();
  const [rdvformerrors, setRdvFormErrors] = useState(initRdvFormErrors);
  const [type, setType] = useState("");
  const today = new Date().toISOString().substr(0, 10);
  async function updateRdv() {
    setIsLoading(true);
    await axios
      .put(`${baseURL}/rdv/update/${selectedRdv}`, {
        date: date ?? "",
        time: time ?? "",
        description: description ?? "",
        type: type,
        mision: mision,
      })
      .then((resp) => {
        setRdvFormErrors(initRdvFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setDate("");
        setTime("");
        setDescription("");
        setType("");
        setMission("");
        emptySelectedRdv(null);
        refreshFunction();
        const offcanvasButton = document.getElementById("annulerUpdateRdv");
        offcanvasButton.click();
      })
      .catch((error) => {
        setRdvFormErrors(error.response.data);
        setIsLoading(false);
      });
  }
  async function getRdvDetails() {
    await axios
      .get(`${baseURL}/rdv/getbyId/${selectedRdv}`)
      .then((resp) => {
        if (resp.data.error) return;
        setDate(resp.data.data.date);
        setTime(resp.data.data.time);
        setDescription(resp.data.data.description);
        setType(resp.data.data.type);
        setMission(resp.data.data.mission);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (
      selectedRdv !== null &&
      selectedRdv !== undefined &&
      selectedRdv !== ""
    ) {
      getRdvDetails();
    }
  }, [selectedRdv]);
  return (
    <div>
      {/* ofCanvas pour modifier un rdv */}
      <div
        className="offcanvas offcanvas-end offcanvas-body-border"
        tabIndex="-3"
        id="offcanvasUpdateRdv"
        aria-labelledby="offcanvasUpdateRdvLabel">
        <div className="offcanvas-header ">
          <h4 id="offcanvasUpdateRdv fw-bold">Modifier un Rendez-vous</h4>
          <button
            id="CloseUpdateRdv"
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="CloseUpdateRdv"></button>
        </div>
        <div className="offcanvas-body offcanvas-body-scrollable">
          <div className="row px-2 my-2">
            <label className="px-0 mb-2 text-500">Type : *</label>
            <select
              id="companyId"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              className="form-select">
              <option value="">Sélectionner un type</option>
              <option value="client">Presentation Client</option>
              <option value="esn">Presentation ESN</option>
            </select>
          </div>
          <div className="row px-2 my-2">
            <label className="px-0 mb-2 text-500">Date: *</label>
            <input
              defaultValue={date}
              type="date"
              className="form-control offcanvas-input"
              onChange={(e) => setDate(e.target.value)}
              required
              id="date"
              placeholder="Date"
              min={today}
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {rdvformerrors.date}
              </span>
            </div>
          </div>
          <div className="row px-2 my-2">
            <label className="px-0 mb-2 text-500">Temps: *</label>
            <input
              type="time"
              defaultValue={time}
              className="form-control offcanvas-input"
              onChange={(e) => {
                setTime(e.target.value);
              }}
              required
              id="time"
              placeholder="time"
              min={today}
            />
            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {rdvformerrors.time}
              </span>
            </div>
          </div>
          <div className="row px-2 my-2">
            <label className="px-0 mb-2 text-500">Description: *</label>
            <input
              defaultValue={description}
              className="form-control offcanvas-input"
              type="text"
              id="description"
              placeholder="Description"
              rows="4"
              onChange={(e) => setDescription(e.target.value)}
              required
            />

            <div className="input-error">
              <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                {rdvformerrors.description}
              </span>
            </div>
          </div>
          <div className="row px-2 pt-2 pb-1">
            <button
              type="button"
              className="btn btn-primary"
              onClick={updateRdv}
              disabled={isLoading}>
              {isLoading ? <Loader /> : "Modifier"}
            </button>
          </div>
          <div className="row px-2 pt-2 pb-1 ">
            <button
              type="button"
              id="annulerUpdateRdv"
              className="btn btn-outline-secondary col-11 w-100 mt-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close">
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateInterviewOffacnas;
