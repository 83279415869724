import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";
import { baseURL, csvCompanyPath } from "../../config/config";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";
import { Dropdown } from "react-bootstrap";
import CreateCompany from "./CreateCompany";
import UpdateCompany from "./UpdateCompany";
import ImportCompanyFromCSV from "../../components/company/ImportCompanyFromCSV";
import ContactDisplay from "../../components/contact/contactDisplay";
import { Tooltip } from "@mui/material";

const Company = () => {
  const { user } = useContext(UserContext);
  const noDataFound = "Aucune donnée trouvée";
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasCompanyCreatePermission = hasPermission(
    user,
    PERMISSIONS.company,
    "create"
  );
  const hasCompanyUpdatePermission = hasPermission(
    user,
    PERMISSIONS.company,
    "edit"
  );
  const hasCompanyStatusPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "change_status"
  );
  const [company, setCompany] = useState(null);
  const [data, setData] = useState();
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [contactFilter, setContactFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [importCompanyFromCSVModal, setImportCompanyFromCSVModal] =
    useState(false);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  useEffect(() => {
    if (company == null) return;
    const filteredData = applyFilters(company, [
      (data) => filterCandidatsByName(data, nameFilter),
      (data) => filterCandidatsByContact(data, contactFilter),
      (data) => filterCandidatsByPhone(data, phoneFilter),
      (data) => filterCandidatsByEmail(data, emailFilter),
    ]);
    if (contactFilter || nameFilter || phoneFilter || emailFilter) {
      if (filteredData.length > 0) {
        setData(
          <DataTable
            columns={columns}
            className="dataTableClass"
            data={filteredData}
            pagination
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            expandableRowsComponent={ExpandedComponent}
            expandableRows={isMobile <= 1280}
          />
        );
      } else {
        setData(<EmptyData />);
      }
    } else {
      setData(
        <DataTable
          columns={columns}
          data={company}
          className="dataTableClass"
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      );
    }
  }, [nameFilter, contactFilter, phoneFilter, emailFilter, company]);

  function applyFilters(data, filters) {
    let filteredData = data;

    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (nameFilter !== "") {
      sessionStorage.setItem("nameFilterCompany", nameFilter);
    }
    if (emailFilter !== "") {
      sessionStorage.setItem("emailFilterCompany", emailFilter);
    }
    if (contactFilter !== "") {
      sessionStorage.setItem("contactFilterCompany", contactFilter);
    }
    if (phoneFilter !== "") {
      sessionStorage.setItem("phoneFilterCompany", phoneFilter);
    }
    return filteredData;
  }
  function filterCandidatsByName(data, searchText) {
    return data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function filterCandidatsByContact(data, searchText) {
    return data.filter(
      (item) =>
        item.owner &&
        item.owner.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function filterCandidatsByEmail(data, searchText) {
    return data.filter(
      (item) =>
        item.email &&
        item.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function filterCandidatsByPhone(data, searchText) {
    return data.filter(
      (item) =>
        item.phone &&
        item.phone.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function handleRestoreFilter() {
    const nameFilterCompany = sessionStorage.getItem("nameFilterCompany");
    const emailFilterCompany = sessionStorage.getItem("emailFilterCompany");
    const contactFilterCompany = sessionStorage.getItem("contactFilterCompany");
    const phoneFilterCompany = sessionStorage.getItem("phoneFilterCompany");
    if (nameFilterCompany !== null) {
      setNameFilter(sessionStorage.getItem("nameFilterCompany"));
    }
    if (emailFilterCompany !== null) {
      setEmailFilter(sessionStorage.getItem("emailFilterCompany"));
    }
    if (contactFilterCompany !== null) {
      setContactFilter(sessionStorage.getItem("contactFilterCompany"));
    }
    if (phoneFilterCompany !== null) {
      setPhoneFilter(sessionStorage.getItem("phoneFilterCompany"));
    }
    setTimeout(() => {
      sessionStorage.removeItem("nameFilterCompany");
      sessionStorage.removeItem("emailFilterCompany");
      sessionStorage.removeItem("contactFilterCompany");
      sessionStorage.removeItem("phoneFilterCompany");
    }, 100);
  }
  async function downloadCsvTemplate() {
    fetch(csvCompanyPath, {
      method: "GET",
      headers: {
        "Content-Type": "file/csv",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `sociétés.csv`);
        link.click();
      });
  }
  async function getAllCompany() {
    axios
      .get(`${baseURL}/companies`)
      .then((response) => {
        if (response.data.error) return;
        setCompany(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (hasCompanyConsultPermission) {
      setData(<Loader />);
      getAllCompany();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasCompanyConsultPermission]);

  useEffect(() => {
    if (company == null) return;
    if (company.length > 0) {
      setData(
        <DataTable
          columns={columns}
          className="dataTableClass"
          data={company}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [company]);

  async function enableDisable(companyId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette sociètè?"
        : "Voulez vous Activer cette sociètè?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/companies/toggleStatus/${companyId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            swal(resp.data.message, {
              icon: "success",
              timer: 2500,
            });
            if (!usersInfo.error) {
              getAllCompany();
            }
          });
      }
    });
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const columns = [
    {
      name: "Société",
      selector: (row) => (
        <div className="row">
          <Link className="color-msit " to={`/company/details/${row._id}`}>
            <img
              src={row.logo}
              className="rounded-circle me-2 ms-2"
              width="35"
              height="35"
            />
            <span className="text-black">{row.name ? row.name : ""}</span>
          </Link>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <span className="text-color-datatable">
          {row.owner || "Aucune donnée trouvée"}
        </span>
      ),
      center: true,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Téléphone",
      selector: (row) =>
        row.phone ? (
          <a
            href={`tel:${row.phone}`}
            className="text-color-datatable "
            data-toggle="tooltip"
            data-placement="top"
            title="Appeler">
            <i className="bi bi-telephone-fill text-color-datatable"></i>{" "}
            {row.phone}
          </a>
        ) : (
          <span className="text-color-datatable">Aucune donnée trouvée</span>
        ),
      center: true,
      sortable: true,
      hide: "md",
    },
    {
      name: "Email",
      selector: (row) =>
        row.email ? (
          <a
            href={`mailto:${row.email}`}
            className="text-color-datatable "
            data-toggle="tooltip"
            data-placement="top"
            title="Envoyer mail">
            <i className="bi bi-envelope-fill text-color-datatable"></i>{" "}
            {row.email}
          </a>
        ) : (
          <span className="text-color-datatable">Aucune donnée trouvée</span>
        ),
      center: true,
      sortable: true,
      hide: "md",
    },
    {
      name: "Ville",
      selector: (row) => (
        <span className="text-color-datatable">
          {row.city || "Aucune donnée trouvée"}
        </span>
      ),
      center: true,
      sortable: true,
      hide: "lg",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasCompanyStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisable(row._id, row.isEnabled);
                      }}
                      checked={row.isEnabled === "yes"}
                    />
                    Activer la société
                  </label>
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                href={`#/company/details/${row._id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2"> Consulter la société </span>
              </Dropdown.Item>
              {hasCompanyUpdatePermission && (
                <Dropdown.Item
                  className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#modifierUtilisateur"
                  aria-controls="modifierUtilisateur"
                  onClick={() => handleUpdateButtonClick(row._id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <g clipPath="url(#clip0_192_15848)">
                      <path
                        d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_192_15848">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="ms-2">Modifier la société</span>
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M7.99935 8.66732C9.10392 8.66732 9.99935 7.77189 9.99935 6.66732C9.99935 5.56275 9.10392 4.66732 7.99935 4.66732C6.89478 4.66732 5.99935 5.56275 5.99935 6.66732C5.99935 7.77189 6.89478 8.66732 7.99935 8.66732Z"
                  stroke="black"
                  stroke-width="1.07"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.99935 14.6673C10.666 12.0007 13.3327 9.61284 13.3327 6.66732C13.3327 3.7218 10.9449 1.33398 7.99935 1.33398C5.05383 1.33398 2.66602 3.7218 2.66602 6.66732C2.66602 9.61284 5.33268 12.0007 7.99935 14.6673Z"
                  stroke="black"
                  stroke-width="1.07"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Ville :
            </div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data.city || "Aucune donnée trouvée"}
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                  stroke="#667085"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Contact :
            </div>
            <div className="text-color-datatable">
              {data.owner || "Aucune donnée trouvée"}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">
              <i className="bi bi-envelope-fill text-color-datatable"></i> Email
              :
            </div>

            {data.email ? (
              <Tooltip title={data?.email} arrow>
                <a
                  href={`mailto:${data?.email}`}
                  className="text-color-datatable "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Envoyer mail">
                  {data?.email?.substring(0, 25)}
                  {data?.email?.length > 25 ? "..." : ""}
                </a>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">
              <i className="bi bi-telephone-fill text-color-datatable"></i>{" "}
              Téléphone :
            </div>
            <div className="text-color-datatable">
              <Tooltip title={data.phone} arrow>
                <a
                  href={`tel:${data.phone}`}
                  className="text-color-datatable "
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Appeler">
                  {data.phone}
                </a>
              </Tooltip>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );
  const columnsResponsive = [
    {
      name: "Société",
      selector: (row) => (
        <>
          <a
            data-bs-toggle="collapse"
            href={`#SocieteData${row._id}`}
            role="button"
            aria-controls={`SocieteData${row._id}`}>
            <div className="mt-2 d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <img
                src={row.logo}
                className="rounded-circle me-2 ms-2"
                width="35"
                height="35"
              />
              <div className="d-flex flex-column">
                <div>
                  <span className="text-black">
                    {row.name?.length > 25
                      ? `${row.name?.substring(0, 25)}...`
                      : row.name}
                  </span>
                </div>
              </div>
            </div>
          </a>

          <div className="collapse" id={`SocieteData${row._id}`}>
            <hr></hr>
            <div class="row">
              <div class="row">
                <div class="col-6">
                  <div className="text-color-datatable">Responsable</div>
                  <span className="text-color-datatable">
                    {row.owner.length > 17
                      ? row.owner.slice(0, 17) + "..."
                      : row.owner}
                  </span>
                </div>
                <div class="col-6">
                  <div className="text-color-datatable">Email</div>
                  <div className="text-color-datatable ">
                    {row.email.length > 17
                      ? row.email.slice(0, 17) + "..."
                      : row.email}
                  </div>
                </div>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-6">
                  <div className="text-color-datatable">Téléphone</div>
                  <div className="phone text-nowrap">
                    <a
                      href={`tel:+${row.phone}`}
                      className="text-color-datatable  "
                      data-placement="top"
                      title="Appeler">
                      <span className="text-color-datatable">
                        {row.phone || noDataFound}
                      </span>
                    </a>
                  </div>
                </div>
                <div class="col-6">
                  <div className="text-color-datatable">Ville</div>
                  <span className="text-color-datatable">
                    {row.city || noDataFound}
                  </span>
                </div>
              </div>
              <div className="mt-2">
                {hasCompanyStatusPermission && (
                  <label className="form-check form-check-single form-switch ">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisable(row._id, row.isEnabled);
                      }}
                      checked={row.isEnabled === "yes"}
                    />
                    Activer la société
                  </label>
                )}
              </div>
              <div>
                <a
                  className="btn  but_responsive mb-2"
                  href={`#/company/details/${row._id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                      stroke="#344054"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ms-2"> Consulter la société </span>
                </a>
              </div>
              <div>
                {hasCompanyUpdatePermission && (
                  <button
                    className="btn  but_responsive mb-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#modifierUtilisateur"
                    aria-controls="modifierUtilisateur"
                    onClick={() => handleUpdateButtonClick(row._id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier la société</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ),
      width: "100%",
      sortable: true,
    },
  ];
  const resetFilters = () => {
    setEmailFilter("");
    setNameFilter("");
    setContactFilter("");
    setPhoneFilter("");
  };
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    const button = document.querySelector('[data-toggle="filterButton"]');
    const svgPath = button.querySelector("svg path");

    if (button && svgPath) {
      button.classList.toggle("btn-primary");

      if (svgPath.getAttribute("stroke") === "white") {
        svgPath.setAttribute("stroke", "black");
      } else {
        svgPath.setAttribute("stroke", "white");
      }
    }
  };
  const [selectedForUpdate, setSelectedForUpdate] = useState(null);
  function handleUpdateButtonClick(companyId) {
    setSelectedForUpdate(companyId);
  }
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line icon_acceuil"></i>
                    <span className="acceuil_title">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="div_tabRoles">
        <div className="row">
          <div className="col-md-6 ">
            <h4 className="fw-bold m-4">Liste des sociétés</h4>
          </div>
          <div className="col-md-3 text-sm-end text-start d-flex justify-content-center align-items-center">
            <Dropdown className="w-100 mx-2">
              <Dropdown.Toggle
                bsPrefix="but-dropdown border-0 px-0 px-lg-1 py-lg-0  text-dark w-100"
                style={{ background: "none" }}>
                <button className="btn btn_download_cv w-100">
                  Importer
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 1.5L6 6.5L11 1.5"
                      stroke="#667085"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Dropdown.Toggle>

              <Dropdown.Menu className="candidat-dropdown-menu ">
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <button
                    className="btn btn_download_cv border-0"
                    onClick={() => {
                      setImportCompanyFromCSVModal(true);
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none">
                      <path
                        d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                        stroke="#344054"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Importer
                  </button>
                </Dropdown.Item>

                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <button
                    type="button"
                    className="btn btn_download_cv border-0"
                    onClick={downloadCsvTemplate}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none">
                      <path
                        d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
                        stroke="#344054"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Csv template
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-md-3 d-flex justify-content-center align-items-center">
            {hasCompanyCreatePermission && (
              <button
                className="btn btn-primary ms-xxl-2 ms-xxl-4 mx-2 w-100"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#ajouterSociete"
                aria-controls="ajouterSociete">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <g clip-path="url(#clip0_6646_41547)">
                    <path
                      d="M13.3327 6.66602V4.33268C13.3327 3.39926 13.3327 2.93255 13.151 2.57603C12.9912 2.26243 12.7363 2.00746 12.4227 1.84767C12.0661 1.66602 11.5994 1.66602 10.666 1.66602H4.33268C3.39926 1.66602 2.93255 1.66602 2.57603 1.84767C2.26243 2.00746 2.00746 2.26243 1.84767 2.57603C1.66602 2.93255 1.66602 3.39926 1.66602 4.33268V10.666C1.66602 11.5994 1.66602 12.0661 1.84767 12.4227C2.00746 12.7363 2.26243 12.9912 2.57603 13.151C2.93255 13.3327 3.39926 13.3327 4.33268 13.3327H6.66602M9.99935 12.4993L11.666 14.166L15.416 10.416M9.33268 18.3327H15.666C16.5994 18.3327 17.0661 18.3327 17.4227 18.151C17.7363 17.9912 17.9912 17.7363 18.151 17.4227C18.3327 17.0661 18.3327 16.5994 18.3327 15.666V9.33268C18.3327 8.39926 18.3327 7.93255 18.151 7.57603C17.9912 7.26243 17.7363 7.00746 17.4227 6.84767C17.0661 6.66602 16.5994 6.66602 15.666 6.66602H9.33268C8.39926 6.66602 7.93255 6.66602 7.57603 6.84767C7.26243 7.00746 7.00746 7.26243 6.84767 7.57603C6.66602 7.93255 6.66602 8.39926 6.66602 9.33268V15.666C6.66602 16.5994 6.66602 17.0661 6.84767 17.4227C7.00746 17.7363 7.26243 17.9912 7.57603 18.151C7.93255 18.3327 8.39926 18.3327 9.33268 18.3327Z"
                      stroke="white"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6646_41547">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="ms-2"> Ajouter une sociéte</span>
              </button>
            )}
          </div>
          <hr className="w-100"></hr>
          <div className="row pb-2 flex-lg-row-reverse">
            <div className="col-lg-2 text-sm-end  text-start row  ms-1 ">
              <button
                className="btn btn-primary but-filtre mt-3"
                type="button"
                onClick={toggleFilter}
                data-toggle="filterButton">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2">Filtres</span>
              </button>
            </div>
            <div className="col-lg-2 text-sm-end  text-start row  ms-1 ">
              <button
                className="btn btn-primary but-filtre mt-3 px-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvascontactDisplay"
                aria-controls="offcanvascontactDisplay">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 19C17 17.6044 17 16.9067 16.8278 16.3389C16.44 15.0605 15.4395 14.06 14.1611 13.6722C13.5933 13.5 12.8956 13.5 11.5 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span className="ms-2">Contacts</span>
              </button>
            </div>
          </div>
          {isFilterVisible && (
            <div className="  filterCardBackground">
              <div className="row p-2 mb-2">
                <div className="col-lg-7 col-md-12 d-flex ps-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Société</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par Société"
                          className="form-control inputFiltre"
                          type="search"
                          value={nameFilter}
                          onChange={(e) => setNameFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Contact</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par Responsable"
                          className="form-control inputFiltre"
                          type="search"
                          value={contactFilter}
                          onChange={(e) => setContactFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Email</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par Email"
                          className="form-control inputFiltre"
                          type="search"
                          value={emailFilter}
                          onChange={(e) => setEmailFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 row  mt-3 pt-xl-2">
                  <div className="col-lg-5 col-md-4 col-sm-12 pb-1 ps-4 pe-0">
                    <button type="button" className="btn w-100 px-1 btn-reset">
                      <img src={`../assets/img/repeat-04.svg`} /> Réinitialiser
                    </button>
                  </div>
                  <div className="col-lg-7 col-md-5 col-sm-12 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-restore"
                      onClick={handleRestoreFilter}>
                      <i className="ri-arrow-left-line px-1"></i>Dernière
                      recherche
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <CreateCompany getAllCompany={getAllCompany} />
          <UpdateCompany
            getAllCompany={getAllCompany}
            selectedForUpdate={selectedForUpdate}
          />
        </div>
        <section className="section">
          <div className="row">
            <div className="text-center">{data}</div>
          </div>
        </section>
      </div>
      {importCompanyFromCSVModal && (
        <ImportCompanyFromCSV hideModal={setImportCompanyFromCSVModal} />
      )}
      <ContactDisplay />
    </div>
  );
};

export default Company;
