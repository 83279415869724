import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import swal from "sweetalert";
import AddRelaunchMission from "../../components/relaunchMission/createRelaunchMission";
import EditRelaunchMission from "../../components/relaunchMission/editRelaunchMission";
import "./detailsMission.css";
import MissionEmployees from "../../components/mission/employees";
import { useHistory } from "react-router";
import MatchingMissionCandidat from "../../components/mission/matchingMissionCandidat";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import AffectEmployeeModal from "../../components/mission/affectEmployeeModal";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { useLayoutEffect } from "react";
import MissionRdvList from "../../components/mission/missionRdv";
import EditMission from "../../components/mission/updateMission";
import { Switch } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";

const MissionDetails = () => {
  const { user } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const noDataFound = "Aucune donnée trouvée";
  const hasRelaunchMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.relaunchMission,
    "consult"
  );
  const hasRelaunchMissionsCreatePermission = hasPermission(
    user,
    PERMISSIONS.relaunchMission,
    "create"
  );
  const hasRelaunchMissionsEditPermission = hasPermission(
    user,
    PERMISSIONS.relaunchMission,
    "edit"
  );
  const hasRelaunchMissionsStatusPermission = hasPermission(
    user,
    PERMISSIONS.relaunchMission,
    "change_status"
  );
  const hasMissionsProcessCreatePermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "create_mission_interview_process"
  );
  const hasMissionsEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "consult"
  );
  const hasMissionsEmployeesCreatePermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "affect_employee_to_mission_process"
  );
  const hasMatchingMissionsPermission = hasPermission(
    user,
    PERMISSIONS.matching,
    "matching_mission_candidates"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consult"
  );
  const hasMissionEmployeesAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "create"
  );
  const initFormErrors = {
    name: [""],
    etape: [""],
  };
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [mission, setMission] = useState();
  const [missionEmployees, setMissionEmployees] = useState([]);
  const [affectEmployeeModal, setAffectEmployeeModal] = useState();
  const [relaunchMission, setRelaunchMission] = useState([]);
  const [relaunchMissionData, setRelaunchMissionData] = useState();
  const [newStepName, setNewStepName] = useState();
  const [selectedRelaunchId, setSelectedRelaunchId] = useState();
  const { missionId } = useParams();
  const [formerrors, setFormErrors] = useState(initFormErrors);
  async function getMissionById() {
    await axios
      .get(`${baseURL}/missions/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {
        swal({
          title: "NOT AUTHORIZED",
          icon: "error",
          timer: 6000,
          dangerMode: true,
        });
      });
  }
  function checkPermission() {
    return user && mission;
  }
  useEffect(() => {
    getAllEmployees();
    getEmployeeByMissionId();
    if (hasRelaunchMissionsConsultPermission) {
      getRelaunchMissionByMissionId();
    }
  }, []);
  useEffect(() => {
    if (missionId === undefined) return;
    getMissionById();
  }, [missionId]);

  async function getRelaunchMissionByMissionId() {
    axios
      .get(`${baseURL}/relaunchMission/relaunchMissions/${missionId}`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setRelaunchMission(respData);
      })
      .catch((err) => {
        DisplayError();
      });
  }
  async function getAllEmployees() {
    axios
      .get(`${baseURL}/consultants/all`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setEmployees(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  function getEmployeeByMissionId() {
    if (missionId === null) return;
    axios
      .get(`${baseURL}/missionsConsultants/getConsultantByMission/${missionId}`)
      .then((res) => {
        if (res.data.error) return;
        setMissionEmployees(res.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  async function enableDisableRelaunchMission(relaunchMissionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Annuler ce rappel?"
        : "Voulez vous Valider ce rappel?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(
            `${baseURL}/relaunchMission/toggleStatus/${relaunchMissionId}`,
            {}
          )
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getRelaunchMissionByMissionId();
            }
          })
          .catch((error) => {
            DisplayError();
          });
      }
    });
  }
  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`)
          .then((resp) => {
            let usersInfo = resp.data;

            if (!usersInfo.error) {
              getMissionById();
            }
          });
      }
    });
  }
  async function toggleToDelete(missionId) {
    await axios
      .get(`${baseURL}/missions/toggleDelete/${missionId}`)
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getMissionById();
      })
      .catch(() => {
        DisplayError();
      });
  }
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const columnsRelaunchMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      center: true,
      sortable: true,
      hide: "md",
    },
    {
      name: "Titre",
      selector: (row) => <div className="py-2 col-12">{row.title}</div>,
      center: true,
      sortable: true,
      id: "name",
    },
    {
      name: "Description",
      center: true,
      cell: (row) => (
        <div>
          <div className="py-2 text">{row.description}</div>
        </div>
      ),
      id: "description",
      hide: "md",
    },
    {
      name: "Date",
      selector: (row) => <div className="py-2">{row.date}</div>,
      width: "25%",
      sortable: true,
      hide: "sm",
      id: "date",
    },
    {
      name: "Actions",
      center: true,

      cell: (row) => (
        <Dropdown className="w-auto">
          <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none">
              <path
                d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                stroke="#98A2B3"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                stroke="#98A2B3"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                stroke="#98A2B3"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Dropdown.Toggle>

          <Dropdown.Menu className="candidat-dropdown-menu">
            {hasRelaunchMissionsEditPermission && (
              <Dropdown.Item
                data-bs-toggle="offcanvas"
                data-bs-target="#relaunchMisionUpdate_offCanvas"
                aria-controls="relaunchMisionUpdate_offCanvas"
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                onClick={() => setSelectedRelaunchId(row._id)}>
                <i className="bi bi-pen"></i> Modifier
              </Dropdown.Item>
            )}

            {hasRelaunchMissionsStatusPermission && (
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                onClick={() => {
                  enableDisableRelaunchMission(row._id, row.isEnabled);
                }}>
                {row.isEnabled === "yes" ? (
                  <>
                    <i className="text-success bi bi-check2-square"></i>
                    Desactiver
                  </>
                ) : (
                  <>
                    <i className="text-danger bi bi-x-square"></i>
                    Activer
                  </>
                )}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: "actions",
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">description :</div>
            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.description}
            </div>
          </div>
          {isMobile <= 599 ? (
            <div className="col-md-6 my-1">
              <div className="ms-1 text-details">Date :</div>
              <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
                {data?.date}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );
  useEffect(() => {
    if (relaunchMission.length > 0) {
      setRelaunchMissionData(
        <DataTable
          responsive
          className="dataTableClass"
          columns={columnsRelaunchMission}
          data={relaunchMission}
          pagination
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 959}
        />
      );
    } else {
      setRelaunchMissionData(<EmptyData />);
    }
  }, [relaunchMission]);
  const handleInputChange = () => {
    if (
      newStepName === "" ||
      newStepName === null ||
      newStepName === undefined
    ) {
      return;
    }
    const value = newStepName;
    let list = mission?.steps ?? [];
    if (list.length > 0) {
      const newIndex = list[list.length - 1].index + 1 ?? 0 + 1;
      list[list.length] = { index: newIndex, name: value };
      updateProcessMission(list);
    } else {
      const newIndex = 0;
      list[list.length] = { index: newIndex, name: value };
      updateProcessMission(list);
    }
  };

  async function updateProcessMission(processSteps, deletedStepIndex) {
    let updatedSteps = processSteps.map((step) => ({
      index: step.index,
      name: step.name,
    }));
    deletedStepIndex = deletedStepIndex ?? [];
    if (deletedStepIndex.length > 0) {
      updatedSteps.map((step) => {});

      updatedSteps = updatedSteps.filter(
        (step) => deletedStepIndex[0] !== step.index
      );
    }

    await axios
      .post(`${baseURL}/missions/process/${missionId}`, {
        steps: updatedSteps,
        deletedStepIndices: deletedStepIndex,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        Toastify({
          text: resp.data.message,
          duration: 2000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #0f8499 , #0f8499 )",
          },
        }).showToast();
        setNewStepName("");
        getMissionById();
      })
      .catch((error) => {
        const errors = error.response.data;
        setFormErrors(errors);
        Object.keys(errors).map((key) => {
          Toastify({
            text: errors[key],
            duration: 2000,
            close: true,
            gravity: "bottom",
            position: "right",
            stopOnFocus: true,
            style: {
              background: "linear-gradient(to right, #F44D , #F44D )",
            },
          }).showToast();
        });
        setFormErrors(error.response.data);
      });
  }
  const handleRemoveClick = async (indexToDelete) => {
    let list = mission?.steps;
    const deletedStepIndex = [list[indexToDelete].index] ?? [];
    const updatedSteps = [...list];
    list.splice(indexToDelete, 1);
    list = updatedSteps;
    updateProcessMission(list, deletedStepIndex);
  };
  return (
    <div>
      <div className="row d-flex justify-content-between mb-1">
        <div className="col-lg-4 col-md-4 col-sm-6 d-flex align-items-center">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line color-blue me-1"></i>
                    <span className="color-blue">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="card card-type-condidat">
        <div className="card-header">
          <div className="pagetitle row">
            <div className="row">
              <div className="col-md-8">
                <h1>Détails de la mission</h1>
              </div>
              <div className="col-md-4">
                <button
                  onClick={history.goBack}
                  type="button"
                  className="btn btn-outline-secondary mb-2 float-end  me-2">
                  Retour
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <Link to={"/"}>
                        <i className="bi bi-house-door"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item ">
                      <Link to={"/missions"}>mission</Link>
                    </li>
                    <li className="breadcrumb-item ">details</li>
                  </ol>
                </nav>
              </div>
              <div className="col-lg-4 text-end ">
                <Dropdown className="w-auto">
                  <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none">
                      <path
                        d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                        stroke="#98A2B3"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                        stroke="#98A2B3"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                        stroke="#98A2B3"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="candidat-dropdown-menu">
                    <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                      <label
                        className="form-check form-check-single form-switch"
                        onClick={() => {
                          enableDisableMission(mission?.id, mission?.isEnabled);
                        }}>
                        <input
                          className="form-check-input"
                          data-placement="top"
                          type="checkbox"
                          checked={mission?.isEnabled === "yes"}
                        />
                        Activer la Mission
                      </label>
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#modifierMission"
                      aria-controls="modifierMission">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none">
                        <g clipPath="url(#clip0_192_15848)">
                          <path
                            d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                            stroke="#344054"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_192_15848">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="ms-2">Modifier la Mission</span>
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => toggleToDelete(mission?.id)}
                      className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                      <svg
                        className="me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none">
                        <path
                          d="M6 2H10M2 4H14M12.6667 4L12.1991 11.0129C12.129 12.065 12.0939 12.5911 11.8667 12.99C11.6666 13.3412 11.3648 13.6235 11.0011 13.7998C10.588 14 10.0607 14 9.00623 14H6.99377C5.93927 14 5.41202 14 4.99889 13.7998C4.63517 13.6235 4.33339 13.3412 4.13332 12.99C3.90607 12.5911 3.871 12.065 3.80086 11.0129L3.33333 4M6.66667 7V10.3333M9.33333 7V10.3333"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Supprimer
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <section>
            <div className="row mt-3 justify-content-center  align-items-center">
              <div className=" col-12 col-xl-6 ">
                <div className="card card-type-condidat p-3">
                  <div className="row ">
                    <div className=" col-lg-6 my-2 ">
                      <div className=" align-items-center d-flex">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_423_49331)">
                            <path
                              d="M6.00065 7.99992L7.33399 9.33325L10.334 6.33325M6.13525 13.7338C6.35375 13.7049 6.57447 13.7642 6.74853 13.8983L7.55069 14.5138C7.81585 14.7175 8.18471 14.7175 8.44913 14.5138L9.28166 13.8746C9.4372 13.7553 9.63348 13.7027 9.82754 13.7286L10.8689 13.8657C11.2 13.9094 11.5192 13.7249 11.6474 13.4161L12.0481 12.4472C12.1229 12.2657 12.2666 12.122 12.4481 12.0472L13.4169 11.6465C13.7257 11.5191 13.9102 11.1991 13.8665 10.868L13.7346 9.86431C13.7057 9.6458 13.765 9.42507 13.8991 9.251L14.5146 8.4488C14.7182 8.18362 14.7182 7.81474 14.5146 7.5503L13.8753 6.71773C13.7561 6.56218 13.7035 6.36589 13.7294 6.17182L13.8665 5.13037C13.9102 4.79927 13.7257 4.48002 13.4169 4.35187L12.4481 3.95114C12.2666 3.87633 12.1229 3.73263 12.0481 3.55115L11.6474 2.58229C11.52 2.27341 11.2 2.08897 10.8689 2.13267L9.82754 2.26971C9.63348 2.29637 9.4372 2.24378 9.2824 2.12527L8.44987 1.48603C8.18471 1.28233 7.81585 1.28233 7.55143 1.48603L6.71891 2.12527C6.56336 2.24378 6.36708 2.29637 6.17303 2.27119L5.13163 2.13416C4.80055 2.09045 4.48132 2.27489 4.35318 2.58377L3.95321 3.55263C3.87766 3.73337 3.73397 3.87707 3.55324 3.95262L2.58444 4.35261C2.27557 4.48076 2.09114 4.80001 2.13484 5.13111L2.27187 6.17256C2.29705 6.36663 2.24446 6.56292 2.12596 6.71773L1.48675 7.5503C1.28306 7.81548 1.28306 8.18436 1.48675 8.4488L2.12596 9.28137C2.2452 9.43692 2.29779 9.63321 2.27187 9.82728L2.13484 10.8687C2.09114 11.1998 2.27557 11.5191 2.58444 11.6472L3.55324 12.048C3.73471 12.1228 3.8784 12.2665 3.95321 12.4479L4.35392 13.4168C4.48132 13.7257 4.80129 13.9101 5.13237 13.8664L6.13525 13.7338Z"
                              stroke="black"
                              strokeWidth="1.06667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_423_49331">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Titre :
                      </div>
                      <div>{mission != null && mission?.title}</div>
                    </div>
                    <div className=" col-lg-6 my-2 ">
                      <div className="text-xl-nowrap align-items-center d-flex">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6 9.99992H10M14 5.33325H2M10.6667 1.33325V3.33325M5.33333 1.33325V3.33325M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                            stroke="black"
                            stroke-width="1.07"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Date début de mission :
                      </div>
                      <div>{mission != null && mission?.startDate}</div>
                    </div>
                    <div className=" col-lg-6 my-2 ">
                      <div className="text-nowrap align-items-center d-flex">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_423_49342)">
                            <path
                              d="M8.00065 3.99992V7.99992L10.6673 9.33325M14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992Z"
                              stroke="black"
                              strokeWidth="1.06667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_423_49342">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Période :
                      </div>
                      <div>
                        {mission != null && mission?.period
                          ? mission?.period
                          : "aucune data"}
                      </div>
                    </div>
                    <div className="col-lg-6 my-2">
                      <div className="text-nowrap align-items-center d-flex">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2 13.3333C3.55719 11.6817 5.67134 10.6667 8 10.6667C10.3287 10.6667 12.4428 11.6817 14 13.3333M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
                            stroke="black"
                            stroke-width="1.07"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Contact :
                      </div>
                      <div>
                        {mission != null && mission?.manager?.fname}{" "}
                        {mission != null && mission?.manager?.lname}
                      </div>
                    </div>
                    <div className="col-lg-6 my-2 order-lg-1 order-2">
                      <div className="text-nowrap align-items-center d-flex">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2 13.3333C3.55719 11.6817 5.67134 10.6667 8 10.6667C10.3287 10.6667 12.4428 11.6817 14 13.3333M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
                            stroke="black"
                            stroke-width="1.07"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Client :
                      </div>
                      <div>
                        {mission != null && mission?.client
                          ? mission?.client?.name
                          : "aucune data "}
                      </div>
                    </div>
                    <div className="col-lg-6 my-2 order-lg-2 order-1">
                      <div className="text-nowrap align-items-center d-flex">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2 13.3333C3.55719 11.6817 5.67134 10.6667 8 10.6667C10.3287 10.6667 12.4428 11.6817 14 13.3333M11 5C11 6.65685 9.65685 8 8 8C6.34315 8 5 6.65685 5 5C5 3.34315 6.34315 2 8 2C9.65685 2 11 3.34315 11 5Z"
                            stroke="black"
                            stroke-width="1.07"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        La société :
                      </div>
                      <div>
                        {mission != null && mission?.company ? (
                          <Link
                            className="color-msit "
                            to={`/company/details/${mission?.company?._id}`}>
                            <span className="text-black">
                              {mission?.company?.name
                                ? mission?.company?.name
                                : ""}
                            </span>
                          </Link>
                        ) : (
                          "aucune data "
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 my-2 order-3">
                      <div className="text-nowrap align-items-center d-flex">
                        <i class="bi bi-cash-coin me-1"></i>
                        TJM :
                      </div>
                      <div>
                        {mission != null && mission?.salary
                          ? mission?.salary
                          : "aucune data "}
                      </div>
                    </div>
                    <div className="col-lg-12 my-2 order-4 ">
                      <div className="text-nowrap align-items-center d-flex">
                        <div>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M7.33333 2.66667H5.2C4.0799 2.66667 3.51984 2.66667 3.09202 2.88465C2.71569 3.0764 2.40973 3.38236 2.21799 3.75869C2 4.18651 2 4.74656 2 5.86667V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H10.1333C11.2534 14 11.8135 14 12.2413 13.782C12.6176 13.5903 12.9236 13.2843 13.1153 12.908C13.3333 12.4802 13.3333 11.9201 13.3333 10.8V8.66667M8.66667 11.3333H4.66667M10 8.66667H4.66667M13.4142 2.58579C14.1953 3.36683 14.1953 4.63317 13.4142 5.41421C12.6332 6.19526 11.3668 6.19526 10.5858 5.41421C9.80474 4.63317 9.80474 3.36683 10.5858 2.58579C11.3668 1.80474 12.6332 1.80474 13.4142 2.58579Z"
                              stroke="black"
                              stroke-width="1.06667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Description :
                        </div>
                      </div>
                      <div>{mission != null && mission?.description}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6">
                <div className="card  card-type-condidat card-type-mission-details p-3 py-2">
                  <div className="d-flex row mt-2 justify-content-between">
                    <div className=" col-md-9 mb-3">
                      <input
                        type="text"
                        className="form-control w-100"
                        name="Etape"
                        value={newStepName}
                        onChange={(e) => setNewStepName(e.target.value)}
                        placeholder={`Etape `}
                      />

                      <div className="input-error">
                        <span className="text-danger">{formerrors.steps}</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <button
                        className="w-100 text-center btn btn-primary justify-content-center d-flex align-items-center"
                        onClick={handleInputChange}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.99935 4.16675V15.8334M4.16602 10.0001H15.8327"
                            stroke="white"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <div className="row justify-content-between overflow-y-scroll">
                    {mission != null &&
                      mission?.steps.map((item, key) => {
                        return (
                          <div className="col-md-6" key={key}>
                            <div className="card card-type-condidat">
                              <div className="card-body d-flex justify-content-between align-items-center pb-0 m-1">
                                <h6 className="mb-0">{item.name}</h6>
                                <button
                                  type="button"
                                  className="btn p-1"
                                  onClick={() => handleRemoveClick(key)}>
                                  <i className="text-black bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="card card-type-condidat">
            <div className="card-header">
              <div className="pagetitle">
                <div className="row m-2">
                  <div className="col-md-8">
                    <h1>Listes des affectés</h1>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      name="Etape"
                      // onChange={(e) => handleInputChange(e, key)}
                      placeholder={`Nom `}
                    />
                  </div>
                </div>
                {checkPermission() && (
                  <div className="m-3 gap-2 row  align-items-center justify-content-end">
                    {hasMissionEmployeesAffectPermission &&
                      hasEmployeesConsultPermission && (
                        <button
                          type="button"
                          className="col-md-2 text-nowrap btn btn-primary btn-sm btn-rounded text-sm"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasAffectEmployee"
                          aria-controls="offcanvasAffectEmployee">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.99935 10.8333V5.83333M7.49935 8.33333H12.4993M15.8327 17.5V6.5C15.8327 5.09987 15.8327 4.3998 15.5602 3.86502C15.3205 3.39462 14.9381 3.01217 14.4677 2.77248C13.9329 2.5 13.2328 2.5 11.8327 2.5H8.16602C6.76588 2.5 6.06582 2.5 5.53104 2.77248C5.06063 3.01217 4.67818 3.39462 4.4385 3.86502C4.16602 4.3998 4.16602 5.09987 4.16602 6.5V17.5L9.99935 14.1667L15.8327 17.5Z"
                              stroke="white"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Affecter
                        </button>
                      )}
                    {hasMissionsEmployeesConsultPermission &&
                      hasMissionsEmployeesCreatePermission && (
                        <Link
                          className="p-0 col-md-2 "
                          to={`/missions/process/${missionId}`}>
                          <button
                            type="button"
                            className="w-100 text-nowrap btn btn-primary btn-sm btn-rounded text-sm">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_730_22444)">
                                <path
                                  d="M1.66602 9.99997L9.70121 14.0176C9.81053 14.0722 9.86518 14.0996 9.92252 14.1103C9.9733 14.1198 10.0254 14.1198 10.0762 14.1103C10.1335 14.0996 10.1882 14.0722 10.2975 14.0176L18.3327 9.99997M1.66602 14.1666L9.70121 18.1842C9.81053 18.2389 9.86518 18.2662 9.92252 18.277C9.9733 18.2865 10.0254 18.2865 10.0762 18.277C10.1335 18.2662 10.1882 18.2389 10.2975 18.1842L18.3327 14.1666M1.66602 5.83331L9.70121 1.81571C9.81053 1.76105 9.86518 1.73372 9.92252 1.72297C9.9733 1.71344 10.0254 1.71344 10.0762 1.72297C10.1335 1.73372 10.1882 1.76105 10.2975 1.81571L18.3327 5.83331L10.2975 9.8509C10.1882 9.90556 10.1335 9.93289 10.0762 9.94365C10.0254 9.95317 9.9733 9.95317 9.92252 9.94365C9.86518 9.93289 9.81053 9.90556 9.70121 9.8509L1.66602 5.83331Z"
                                  stroke="white"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_730_22444">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            Process
                          </button>
                        </Link>
                      )}
                    {hasMatchingMissionsPermission &&
                      hasCompanyConsultPermission && (
                        <button
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasMatching"
                          aria-controls="offcanvasMatching"
                          type="button"
                          className="col-md-2 text-nowrap btn btn-primary btn-sm btn-rounded text-sm">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4.16602 7.5H15.8327M4.16602 12.5H15.8327"
                              stroke="white"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Matching
                        </button>
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              {checkPermission() && (
                <div className="col-lg-12 row">
                  <div className="col-lg-12">
                    {mission && employees && (
                      <MissionEmployees
                        getEmployeeByMissionId={getEmployeeByMissionId}
                        mission={mission}
                        employees={missionEmployees}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <MissionRdvList missionId={missionId} />
          <div className="card card-type-condidat p-2">
            {checkPermission() && (
              <div className="row  mt-3">
                <h5 className="fw-bold mb-3 col-md-8 col-12">
                  Liste des rappels missions
                </h5>
                {hasRelaunchMissionsCreatePermission && (
                  <div className="col-md-4 col-12">
                    <button
                      data-bs-toggle="offcanvas"
                      data-bs-target="#relaunchMision_offCanvas"
                      aria-controls="relaunchMision_offCanvas"
                      type="button"
                      className="btn btn-primary btn-sm btn-rounded float-end text-sm mb-2">
                      <i className="bi bi-calendar-date me-1"></i>
                      Créer un rappel
                    </button>
                  </div>
                )}
              </div>
            )}
            {checkPermission() && (
              <div className="col-md-12">{relaunchMissionData}</div>
            )}
          </div>
          {affectEmployeeModal}
          {mission && employees && (
            <AffectEmployeeModal
              employees={employees}
              SetAffectModalFromChild={setAffectEmployeeModal}
              mission={mission}
              getAllEmployees={getAllEmployees}
              getEmployeeByMissionId={getEmployeeByMissionId}
            />
          )}
          <AddRelaunchMission
            getRelaunchMissionByMissionId={getRelaunchMissionByMissionId}
            mission={missionId}
          />
          <EditRelaunchMission
            getRelaunchMissionByMissionId={getRelaunchMissionByMissionId}
            relaunchMissionId={selectedRelaunchId}
          />
          <EditMission
            refreshAllMission={getMissionById}
            selectedMissionForUpdate={missionId}
          />
          {/* <MatchingMissionCandidat missionId={missionId} /> */}
        </div>
      </div>
    </div>
  );
};

export default MissionDetails;
