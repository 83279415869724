import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { baseURL } from "../../config/config";
import AddTypes from "../../components/condidatesTypes/AddTypes";
import EditTypes from "../../components/condidatesTypes/EditTypes";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";
import swal from "sweetalert";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";

const CondidatsTypes = () => {
  const [addTypesModal, setaddTypesModal] = useState();
  const [types, setTypes] = useState(null);
  const [typesToFilterFrom, setTypesToFilterFrom] = useState(null);
  const [data, setData] = useState();
  const [nameFilter, setNameFilter] = useState("");
  const { user } = useContext(UserContext);
  const hasCandidatTypesCreatePermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "create"
  );
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consult"
  );
  const hasCandidatTypeseditPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "edit"
  );
  const hasCandidatTypesStatusPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "change_status"
  );
  async function getAllTypes() {
    axios
      .get(`${baseURL}/candidatTypes`)
      .then((response) => {
        if (response.data.error) return;
        setTypes(response.data.data);
        setTypesToFilterFrom(
          response.data.data.map((item) => item.name.toLowerCase())
        );
      })
      .catch((error) => {
        DisplayError();
      });
  }
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  useEffect(() => {
    if (hasCandidatTypesConsultPermission) {
      setData(<Loader />);
      getAllTypes();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasCandidatTypesConsultPermission]);

  useEffect(() => {
    if (types == null) return;
    const filteredData = applyFilters(types, [
      (data) => filterCandidatTypeByName(data, nameFilter),
    ]);
    if (nameFilter) {
      if (filteredData.length > 0) {
        setData(
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
            paginationComponentOptions={{ noRowsPerPage: true }}
          />
        );
      } else {
        setData(<EmptyData />);
      }
    } else {
      setData(
        <DataTable
          columns={columns}
          className="dataTableClass"
          data={types}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{ noRowsPerPage: true }}
        />
      );
    }
  }, [nameFilter, types]);
  function toggle(type_id, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver ce type de consultant?"
        : "Voulez vous Activer ce type de consultant?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/candidatTypes/toggleStatus/${type_id}`)
          .then((response) => {
            if (response.data.error) return;
            getAllTypes();
          })
          .catch((error) => {
            DisplayError();
          });
      }
    });
  }
  function filterCandidatTypeByName(data, searchText) {
    return data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function applyFilters(data, filters) {
    let filteredData = data;

    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (nameFilter !== "") {
      sessionStorage.setItem("TypeFilterCandidatType", nameFilter);
    }
    return filteredData;
  }

  function handleRestoreFilter() {
    const nameFilterCandidatType = sessionStorage.getItem(
      "TypeFilterCandidatType"
    );
    if (nameFilterCandidatType !== null) {
      setNameFilter(nameFilterCandidatType);
    }
    setTimeout(() => {
      sessionStorage.removeItem("TypeFilterCandidatType");
    }, 100);
  }
  const columns = [
    {
      name: "",
      cell: (row) => (
        <div className="row">
          {hasCandidatTypesStatusPermission && (
            <label className="form-check form-check-single form-switch">
              <input
                className="form-check-input"
                data-placement="top"
                type="checkbox"
                checked={row.isEnabled === "yes"}
                onChange={() => {
                  toggle(row.id, row.isEnabled);
                }}
              />
            </label>
          )}
        </div>
      ),
      width: "12%",
      id: "Action",
    },
    {
      name: "Types des condidats",
      selector: (row) => row.name,
      width: "60%",
      sortable: true,
      id: "type",
      style: {
        color: "#475467",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px",
      },
    },

    {
      width: "28%",
      name: "Actions",
      cell: (row) => (
        <div className="row">
          {/* <div className="col-6">
            <button className="btn btn-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.3333 4.99935V4.33268C13.3333 3.39926 13.3333 2.93255 13.1517 2.57603C12.9919 2.26243 12.7369 2.00746 12.4233 1.84767C12.0668 1.66602 11.6001 1.66602 10.6667 1.66602H9.33333C8.39991 1.66602 7.9332 1.66602 7.57668 1.84767C7.26308 2.00746 7.00811 2.26243 6.84832 2.57603C6.66667 2.93255 6.66667 3.39926 6.66667 4.33268V4.99935M8.33333 9.58268V13.7493M11.6667 9.58268V13.7493M2.5 4.99935H17.5M15.8333 4.99935V14.3327C15.8333 15.7328 15.8333 16.4329 15.5608 16.9677C15.3212 17.4381 14.9387 17.8205 14.4683 18.0602C13.9335 18.3327 13.2335 18.3327 11.8333 18.3327H8.16667C6.76654 18.3327 6.06647 18.3327 5.53169 18.0602C5.06129 17.8205 4.67883 17.4381 4.43915 16.9677C4.16667 16.4329 4.16667 15.7328 4.16667 14.3327V4.99935"
                  stroke="#475467"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div> */}
          {hasCandidatTypeseditPermission && (
            <div className="col-6">
              <button
                type="button"
                className="btn btn-sm text-primary "
                onClick={() =>
                  setaddTypesModal(
                    <EditTypes
                      setAddTypesModalFromChild={setaddTypesModal}
                      typesId={row.id}
                      getAllTypes={getAllTypes}
                    />
                  )
                }>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none">
                  <path
                    d="M2.39662 15.0973C2.43491 14.7527 2.45405 14.5804 2.50618 14.4194C2.55243 14.2765 2.61778 14.1405 2.70045 14.0152C2.79363 13.8739 2.91621 13.7513 3.16136 13.5061L14.1666 2.5009C15.0871 1.58043 16.5795 1.58043 17.4999 2.5009C18.4204 3.42138 18.4204 4.91376 17.4999 5.83424L6.49469 16.8395C6.24954 17.0846 6.12696 17.2072 5.98566 17.3004C5.86029 17.383 5.72433 17.4484 5.58146 17.4946C5.42042 17.5468 5.24813 17.5659 4.90356 17.6042L2.08325 17.9176L2.39662 15.0973Z"
                    stroke="#475467"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      ),
      id: "Actions",
    },
  ];

  return (
    <div>
      <div className="row d-flex justify-content-between mb-1">
        <div className="col-lg-4 col-md-4 col-sm-6 d-flex align-items-center">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line color-blue me-1"></i>
                    <span className="color-blue">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        {hasCandidatTypesCreatePermission && (
          <div className="col-lg-4 col-md-5 col-sm-12 text-lg-end text-sm-start ">
            <button
              type="button"
              className={
                addTypesModal
                  ? "btn btn-add-after mb-2 ms-2"
                  : "btn btn-add mb-2 ms-2"
              }
              onClick={() =>
                setaddTypesModal(
                  addTypesModal ? (
                    ""
                  ) : (
                    <AddTypes
                      setAddTypesModalFromChild={setaddTypesModal}
                      getAllTypes={getAllTypes}
                      typesToFilterFrom={typesToFilterFrom}
                    />
                  )
                )
              }>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M9.99996 13.4167H6.24996C5.08699 13.4167 4.5055 13.4167 4.03234 13.5602C2.96701 13.8834 2.13333 14.717 1.81016 15.7824C1.66663 16.2555 1.66663 16.837 1.66663 18M15.8333 18V13M13.3333 15.5H18.3333M12.0833 6.75C12.0833 8.82107 10.4044 10.5 8.33329 10.5C6.26222 10.5 4.58329 8.82107 4.58329 6.75C4.58329 4.67893 6.26222 3 8.33329 3C10.4044 3 12.0833 4.67893 12.0833 6.75Z"
                  stroke={addTypesModal ? "black" : "white"}
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              &nbsp;Ajouter un Type de consultants
            </button>
          </div>
        )}
      </div>
      <div className="card card-type-condidat ">
        <div className="card-title py-1">
          <div className="row px-3 mt-3">
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <h2 className="page-title">Listes des Types des consultants</h2>
            </div>
            <div className="col-lg-3 col-sm-6 mb-2">
              <div className="advance-search">
                <form action="#">
                  <input
                    type="text"
                    className="form-control input-search"
                    id="myInput"
                    placeholder="Taper un mot..."
                    onChange={(e) => {
                      setNameFilter(e.target.value);
                    }}
                    value={nameFilter}
                  />
                </form>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12 d-flex">
              <div className="col-lg-5 col-md-3 col-sm-6 ms-2">
                <button
                  type="button"
                  className="btn btn-reset"
                  onClick={() => {
                    setNameFilter("");
                    setData(
                      <DataTable
                        className="dataTableClass"
                        columns={columns}
                        data={types}
                        pagination
                      />
                    );
                  }}>
                  <img src={`../../assets/img/repeat-04.svg`} />
                  &nbsp;Réinitialiser
                </button>
              </div>
              <div className="col-lg-6 col-md-4 col-sm-6 ms-2">
                <button
                  type="button"
                  className="btn but-restore  "
                  onClick={handleRestoreFilter}>
                  <i className="ri-arrow-left-line"></i> Dernière recherche
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-1">
          {addTypesModal}
          {data}
        </div>
      </div>
    </div>
  );
};
export default CondidatsTypes;
