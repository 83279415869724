/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { baseURL } from "../../config/config";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import DisplayError from "../../components/ErrorComponants/DisplayError";
const AllInvoices = () => {
  const [data, setData] = useState();
  function getdata() {
    axios
      .get(`${baseURL}/invoices/getInvoices`)

      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  function renderSwitch(row) {
    switch (row.status) {
      case "draft":
        return (
          <>
            <Link
              className="col-3 btn text-primary "
              to={`/UpdateInvoice/${row.invoiceId}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Modifier candidat"
            >
              <i className="bi bi-pen"></i>
            </Link>
            <button
              className="col-3 btn text-success"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Marquer comme vérifiée"
              onClick={(e) => {
                swal({
                  text: "Voulez vous vraiment marquer la facture comme vérifiée?",
                });
                updateStatus(row.invoiceId, "verified");
              }}
            >
              <i className="bi bi-send"></i>
            </button>
          </>
        );
      default:
        return "";
    }
  }
  async function updatePayment(Id, updatedState) {
    axios
      .put(`${baseURL}/invoices/updatePayment/${Id}`, {
        isPaid: updatedState,
      })
      .then((res) => {
        if (res.data.error) return;
        getdata();
      })
      .catch((error) => {
        DisplayError();
      });
  }

  function switchPaidStatus(row) {
    switch (row.isPaid) {
      case "paid":
        return (
          <>
            <div className="btn-group dropup">
              <button type="button" className="btn bg-success msit-white">
                Payée
              </button>
              <button
                type="button"
                className="btn msit-white bg-success dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-types">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "paid");
                    }}
                    s
                  >
                    Payée
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "not paid");
                    }}
                  >
                    Non payée
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "partially paid");
                    }}
                  >
                    Partiellement payée
                  </a>
                </li>
              </ul>
            </div>
          </>
        );
      case "not paid":
        return (
          <>
            <div className="btn-group dropup">
              <button type="button" className="btn btn-danger msit-white">
                Non payée
              </button>
              <button
                type="button"
                className="btn msit-white  btn-danger dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-types">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "paid");
                    }}
                    s
                  >
                    Payée
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "not paid");
                    }}
                  >
                    Non payée
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "partially paid");
                    }}
                  >
                    Partiellement payée
                  </a>
                </li>
              </ul>
            </div>
          </>
        );
      case "partially paid":
        return (
          <>
            <div className="btn-group dropup">
              <button type="button" className="btn bg-warning msit-white">
                Partiellement payée
              </button>
              <button
                type="button"
                className="btn msit-white bg-warning  dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-types">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "paid");
                    }}
                    s
                  >
                    Payée
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "not paid");
                    }}
                  >
                    Non payée
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      updatePayment(row.invoiceId, "partially paid");
                    }}
                  >
                    Partiellement payée
                  </a>
                </li>
              </ul>
            </div>
          </>
        );

      default:
        return "";
    }
  }
  useEffect(() => {
    getdata();
  }, []);
  async function updateStatus(Id, updatedStatus) {
    let message =
      "Voulez vous vraiment marquer cette facture comme vérifiée ? ";
    swal({
      title: message,
      text: "elle sera automatiquement envoyée à votre comptable.",
      icon: "warning",
      buttons: ["Annuler", "Oui"],
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .put(`${baseURL}/invoices/updateStatus/${Id}`, {
            status: updatedStatus,
          })
          .then((res) => {
            if (res.data.error) return;
            swal({
              title: "Facture envoyée avec succès",
              icon: "success",
              timer: 1500,
            });
            getdata();
          })
           .catch((error) => {
        DisplayError();
      });
      }
    });
  }
  const columns = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "4%",
      sortable: true,
      cellborder: "red",
    },
    {
      name: "Numéro de facture ",

      selector: (row) => <div>{row.invoice_num}</div>,
      width: "8%",
      sortable: true,
    },
    {
      name: "Date de la facture",
      selector: (row) => <div>{row.invoice_date}</div>,

      width: "10%",
      sortable: true,
    },

    {
      name: "Date limite",
      selector: (row) => <div>{row.due_date}</div>,

      width: "10%",
      sortable: true,
    },

    {
      name: "Vendeur",
      selector: (row) => <div>{<div>{row.seller.seller_name}</div>}</div>,
      width: "12%",
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => <div>{<div>{row.client.client_name}</div>}</div>,
      width: "12%",
      sortable: true,
    },

    {
      name: "Total de la facture",
      selector: (row) => <div>{<div> € {row.invoice_total}</div>}</div>,
      width: "10%",
      sortable: true,
    },

    {
      name: "Type",
      selector: (row) => <div>{<div> {row.type}</div>}</div>,
      width: "8%",
      sortable: true,
    },

    {
      name: "Etat",
      width: "12%",
      sortable: true,
      cell: (row) => (
        <div className="row">
          {/* <span className="col-3  btn">
            {row.isPaid === "not paid" ? (
              <span className="badge bg-danger">Non payée</span>
            ) : row.isPaid === "paid" ? (
              <span className="badge bg-success">Payée</span>
            ) : row.isPaid === "partially paid" ? (
              <span className="badge bg-warning text-light">
                Partiellement payée
              </span>
            ) : null}
          </span> */}
          {switchPaidStatus(row)}
          <div className="hstack gap-3"></div>
        </div>
      ),
    },

    {
      name: "Actions",
      width: "15%",
      cell: (row) => (
        <div className="row">
          {renderSwitch(row)}
          <Link
            className="col-3 btn text-default "
            to={`/allinvoices/${row.invoiceId}`}
            data-toggle="tooltip"
            data-placement="top"
            title="detail facture"
          >
            <i className="ri-eye-fill"></i>
          </Link>
          <div className="hstack gap-3"></div>
        </div>
      ),
    },
  ];
  const conditionalRowStyles = [
    {
      when: (row) => row.status === "not confirmed",
      style: {
        backgroundColor: "rgb(255, 0, 0 , 0.2)",

        color: "black",
      },
    },
    {
      when: (row) => row.status === "confirmed",
      style: {
        backgroundColor: "rgb(0, 153, 51, 0.2)",

        color: "black",
      },
    },
  ];
  return (
    <>
      <div>
        <div className="pagetitle">
          <div className="row">
            <div className="col-md-8">
              <h1>Tous les factures</h1>
            </div>
            <div className="col-md-4">
              <Link to="/">
                <button
                  type="button"
                  className="btn btn-outline-secondary mb-2 float-end  me-2">
                  Retour
                </button>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>
                      <i className="bi bi-house-door"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">tous les facture</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header d-flex justify-content-end">
                  <div className="col-md-3">
                    <input
                      type="text"
                      // onChange={(e) => filterCandidats(e.target.value)}
                      className="form-control"
                      placeholder="filtrer par les compétences"
                    />
                  </div>
                </div>
                <div className="card-body  pt-3">
                  <DataTable
                    className="dataTableClass"
                    columns={columns}
                    data={data}
                    conditionalRowStyles={conditionalRowStyles}
                    pagination
                    paginationPerPage={8}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AllInvoices;
