import React, { useContext, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../loader";
import { baseURL } from "../../config/config";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { UserContext } from "../../context/UserContext";

function AddTypes({
  typesToFilterFrom,
  setAddTypesModalFromChild,
  getAllTypes,
}) {
  const [typeName, setTypeName] = useState();
  const [formerrors, setFormErrors] = useState("");
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const hasCandidatTypesConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidatTypes,
    "consult"
  );
  async function sendData() {
    if (typesToFilterFrom?.includes(typeName?.toLowerCase())) {
      setFormErrors("Type deja exist");
      return;
    }
    setIsLoading(true);
    await axios
      .post(`${baseURL}/candidatTypes/create`, {
        name: typeName,
      })
      .then((resp) => {
        setFormErrors("");
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setAddTypesModalFromChild("");
        if (hasCandidatTypesConsultPermission) {
          getAllTypes();
        }
      })
      .catch((error) => {
        setFormErrors(error.response.data.message);
        setIsLoading(false);
      });
  }
  return (
    <div className="row pb-3 pt-2 px-2">
      <label className="text-500 mb-2">Type de consultant</label>
      <div className="col-lg-9 col-md-9 col-sm-12 mb-1">
        <input
          type="text"
          className="form-control input-search"
          id="role_name"
          placeholder="Ajouter un type"
          onChange={(e) => setTypeName(e.target.value)}
          required
        />
        <div className="input-error">
          {formerrors && <span className="text-danger">{formerrors}</span>}
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-12">
        <button
          type="button"
          onClick={sendData}
          disabled={isLoading}
          className="btn btn-add">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none">
                <path
                  d="M9.99996 4.66602V16.3327M4.16663 10.4993H15.8333"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Ajouter
            </>
          )}
        </button>
      </div>
    </div>
  );
}

export default AddTypes;
