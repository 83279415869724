import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/loader";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyData from "../../components/emptyData";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Tooltip } from "@mui/material";
import AffectMissionModal from "../employee/affectMissionModal";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import CreateNote from "../../components/candidat/candidatNote";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import NotAuthorized from "../../components/NotAuthorized";
import ReplayIcon from "@mui/icons-material/Replay";

const Supplier = () => {
  const [supplier, setSupplier] = React.useState([]);
  const [data, setData] = React.useState();
  const [hasMore, setHasMore] = React.useState(true);
  const [loadedSuppliers, setLoadedSuppliers] = React.useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [affectMissionModal, setAffectMissionModal] = useState();
  const [missions, setMissions] = useState([]);
  const [addNote, setAddNote] = useState();
  const noDataFound = "Aucune donnée trouvée";
  const { user } = useContext(UserContext);
  const hasEmployeesConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consult"
  );
  const hasEmployeesStatusPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "change_status"
  );
  const hasEmployeesCreatePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create"
  );
  const hasCompanyConslutPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasEmployeesEditPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "edit"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionsEmployeeAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "create"
  );
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const hasSupplierNotePermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create_note"
  );
  const hasSupplierNoteConsultPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "consulter_note"
  );
  async function getAllSupplier() {
    axios
      .get(`${baseURL}/employees/suppliers/all`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setSupplier(response.data.data);
        setLoadedSuppliers(respData.slice(0, loadedSuppliers.length + 6));
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function getAllMissions() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMissions(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (hasMissionsConsultPermission) {
      getAllMissions();
    }
  }, [hasMissionsConsultPermission]);
  useEffect(() => {
    if (hasEmployeesConsultPermission) {
      setData(<Loader />);
      getAllSupplier();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasEmployeesConsultPermission]);

  function getLoadedSupplierList() {
    const filteredData = filterSuppliers(supplier);
    let loadedSupplierLength = 0;
    if (nameFilter !== "" || emailFilter !== "" || phoneFilter !== "") {
      loadedSupplierLength = Math.min(filteredData.length, 6);
    } else {
      loadedSupplierLength = filteredData.length;
    }
    setLoadedSuppliers(filteredData.slice(0, loadedSupplierLength));
  }
  const filterSuppliers = (suppliers) => {
    const filteredSuppliers = suppliers.filter((supplier) => {
      const nameMatch =
        supplier.fname.toLowerCase().includes(nameFilter.toLowerCase()) ||
        supplier.lname.toLowerCase().includes(nameFilter.toLowerCase());
      const emailMatch = supplier.email
        .toLowerCase()
        .includes(emailFilter.toLowerCase());
      const phoneMatch = supplier.phone.includes(phoneFilter);
      if (nameFilter !== "") {
        sessionStorage.setItem("nameFilterSupplier", nameFilter);
      }
      if (emailFilter !== "") {
        sessionStorage.setItem("emailFilterSupplier", emailFilter);
      }
      if (phoneFilter !== "") {
        sessionStorage.setItem("phoneFilterSupplier", phoneFilter);
      }

      return nameMatch && emailMatch && phoneMatch;
    });

    return filteredSuppliers;
  };
  function handleRestoreFilter() {
    const nameFilterSupplier = sessionStorage.getItem("nameFilterSupplier");
    const emailFilterSupplier = sessionStorage.getItem("emailFilterSupplier");
    const phoneFilterSupplier = sessionStorage.getItem("phoneFilterSupplier");
    if (nameFilterSupplier !== null) {
      setNameFilter(sessionStorage.getItem("nameFilterSupplier"));
    }
    if (emailFilterSupplier !== null) {
      setEmailFilter(sessionStorage.getItem("emailFilterSupplier"));
    }
    if (phoneFilterSupplier !== null) {
      setPhoneFilter(sessionStorage.getItem("phoneFilterSupplier"));
    }
    setTimeout(() => {
      sessionStorage.removeItem("nameFilterSupplier");
      sessionStorage.removeItem("emailFilterSupplier");
      sessionStorage.removeItem("phoneFilterSupplier");
    }, 100);
  }
  useEffect(() => {
    if (!hasEmployeesConsultPermission) {
      return;
    }
    const filteredData = filterSuppliers(supplier);
    setLoadedSuppliers(filteredData.slice(0, loadedSuppliers.length + 6));

    if (filteredData.length === 0) {
      setData(<EmptyData />);
    } else {
      setData("");
    }
  }, [
    hasEmployeesConsultPermission,
    supplier,
    nameFilter,
    emailFilter,
    phoneFilter,
  ]);

  async function enableDisable(supplierId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver ce fournisseur?"
        : "Voulez vous Activer ce fournisseur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${supplierId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllSupplier();
            }
          })
          .catch((error) => {
            DisplayError();
          });
      }
    });
  }
  function affectMissionToEmployee(supplier) {
    setAffectMissionModal(
      <AffectMissionModal
        SetAffectModalFromChild={setAffectMissionModal}
        employee={supplier}
        missions={missions}
        getMissions={getAllSupplier}
      />
    );
  }
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les fournisseurs</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">fournisseur</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-5 d-flex justify-content-end">
            {hasEmployeesConsultPermission && (
              <Tooltip title={"Filtrer"} arrow>
                <button
                  className="btn btn-secondary  mb-2 btn-sm"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <FilterAltIcon />
                </button>
              </Tooltip>
            )}
            {hasEmployeesCreatePermission && hasCompanyConslutPermission && (
              <Link to="/supplier/create">
                <Tooltip title={"Ajouter un fournisseur"} arrow>
                  <button type="button" className="btn btn-primary  mb-2 ms-2">
                    <i className="bi bi-plus-lg"></i>
                  </button>
                </Tooltip>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="row d-flex justify-content-end">
          <div className="collapse p-3 row" id="collapseExample">
            <div className="col-lg-8  row  ">
              <div className="col-lg-4  col-11 px-2 ">
                <h5> Nom </h5>
                <input
                  type="text"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                  className="form-control"
                  placeholder="Filtrer par Nom"
                />
              </div>
              <div className="col-lg-4  col-11 px-2 pt-2 pt-md-0">
                <h5> Email </h5>
                <input
                  type="text"
                  value={emailFilter}
                  onChange={(e) => setEmailFilter(e.target.value)}
                  className="form-control"
                  placeholder="Filtrer par Email"
                />
              </div>
              <div className="col-lg-4  col-11 px-2 pt-2 pt-lg-0 ">
                <h5> Télephone </h5>
                <input
                  type="text"
                  value={phoneFilter}
                  onChange={(e) => setPhoneFilter(e.target.value)}
                  className="form-control"
                  placeholder="Filtrer par Télephone"
                />
              </div>
            </div>
            <div className="col-lg-4 row  mt-4 ">
              <div className="col-3  pt-2 pt-sm-0">
                <Tooltip title={"Réinitialiser le filtre"} arrow>
                  <button
                    type="button"
                    className="btn btn-secondary "
                    onClick={() => {
                      setNameFilter("");
                      setEmailFilter("");
                      setPhoneFilter("");
                    }}
                  >
                    <FilterAltOffIcon />
                  </button>
                </Tooltip>
              </div>
              <div className="col-6  pt-2 pt-sm-0">
                <Tooltip title={"Restaurer le dernier filtre"} arrow>
                  <button
                    type="button"
                    className="btn btn-secondary "
                    onClick={handleRestoreFilter}
                  >
                    <i className="fa-solid fa-filter-circle-xmark"></i>
                    <ReplayIcon />
                  </button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body  pt-3 mt-2">{data}</div>
        <section className="section">
          <InfiniteScroll
            dataLength={loadedSuppliers.length}
            next={getLoadedSupplierList}
            hasMore={hasMore}
          >
            <div className="row">
              {loadedSuppliers.map((supplier, key) => {
                return (
                  <div className="col-md-6" key={key}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 mt-2 mb-3">
                            <div className="row">
                              <div className="col-lg-6">
                                <Link
                                  className="color-msit "
                                  to={`/suppliers/details/${supplier._id}`}
                                >
                                  <div className="fw-bold">
                                    {supplier.fname} {supplier.lname}
                                  </div>
                                </Link>
                              </div>
                              <div className="col-lg-6 d-flex justify-content-end">
                                {hasEmployeesEditPermission && (
                                  <Link to={`/supplier/edit/${supplier._id}`}>
                                    <button
                                      type="button"
                                      className="btn text-primary"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Modifier fournisseur"
                                    >
                                      <i className="bi bi-pen"></i>
                                    </button>
                                  </Link>
                                )}
                                {hasEmployeesStatusPermission && (
                                  <button
                                    className=" btn text-primary me-2 btn"
                                    onClick={() => {
                                      enableDisable(
                                        supplier._id,
                                        supplier.isEnabled
                                      );
                                    }}
                                  >
                                    {supplier.isEnabled === "yes" ? (
                                      <i
                                        className="text-success bi bi-toggle2-on"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Désactiver fournisseur"
                                      ></i>
                                    ) : (
                                      <i
                                        className="text-danger bi bi-toggle2-off"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Activer fournisseur"
                                      ></i>
                                    )}
                                  </button>
                                )}
                                <Link
                                  className="btn text-default "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Profile fournisseur"
                                  to={`/suppliers/details/${supplier._id}`}
                                >
                                  <i className="ri-eye-fill"></i>
                                </Link>
                                {hasSupplierNotePermission &&
                                  hasSupplierNoteConsultPermission && (
                                    <button
                                      className=" btn text-warning"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Créer note"
                                      onClick={() => {
                                        setAddNote(
                                          <CreateNote
                                            setAddNoteModalFromChild={
                                              setAddNote
                                            }
                                            id={supplier._id}
                                            destination={"SupplierNotes"}
                                          />
                                        );
                                      }}
                                    >
                                      <i className="ri-sticky-note-line"></i>
                                    </button>
                                  )}
                                {hasCvConsultPermission &&
                                  hasMissionsConsultPermission &&
                                  hasMissionsEmployeeAffectPermission && (
                                    <button
                                      className="btn text-success"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Affecter fournisseur à une mission"
                                      onClick={() => {
                                        affectMissionToEmployee(supplier);
                                      }}
                                    >
                                      <i class="ri-file-text-fill"></i>
                                    </button>
                                  )}
                              </div>
                              <hr></hr>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 d-flex justify-md-start justify-content-center">
                              <a className="avatar avatar-xxl" data-abc="true">
                                {supplier.isEnabled === "yes" ? (
                                  <span className="avatar avatar-xxl status-success text_active ">
                                    <img
                                      alt=""
                                      src={
                                        baseURL +
                                        supplier.avatar?.path.substring(1)
                                      }
                                    />
                                    Actif
                                  </span>
                                ) : (
                                  <span className="avatar1 avatar-xxl1 status-failed text_inactif ">
                                    <img
                                      alt=""
                                      src={
                                        baseURL +
                                        supplier.avatar?.path.substring(1)
                                      }
                                    />
                                    Inactif
                                  </span>
                                )}
                              </a>
                            </div>

                            <div className="col-md-6  mt-4 d-flex justify-md-start justify-content-center">
                              <div className="text-light fs-12 mb-3 ">
                                <div className="mb-1">
                                  <a
                                    href={`mailto:${supplier?.email}`}
                                    className="color-msit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Envoyer mail"
                                  >
                                    <i className="bi bi-envelope-fill"></i>{" "}
                                    {supplier?.email ? supplier.email : null}
                                  </a>
                                  {!supplier?.email && (
                                    <span className="color-black">
                                      {noDataFound}
                                    </span>
                                  )}
                                </div>
                                <div className="mb-1">
                                  <a
                                    href={`tel:+${supplier?.phone}`}
                                    className="color-msit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Appeler"
                                  >
                                    <i className="bi bi-telephone-fill"></i>{" "}
                                    {supplier?.phone ? supplier.phone : null}
                                  </a>
                                  {!supplier?.phone && (
                                    <span className="color-black">
                                      {noDataFound}
                                    </span>
                                  )}
                                </div>
                                <div className="mb-1">
                                  <i className="bi bi-geo-alt  me-1"></i>
                                  <span>
                                    {supplier.address
                                      ? supplier.address
                                      : noDataFound}
                                  </span>
                                </div>
                                <div className="mb-1">
                                  <i className="bi bi-briefcase me-1"></i>
                                  <span>
                                    {supplier.job} ({supplier.experience}ans)
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
          {addNote}
          {affectMissionModal}
        </section>
      </div>
    </div>
  );
};

export default Supplier;
