import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CandidatsFormStep3 from "./CandidatsFormStep3";
import CandidatsFormStep1 from "./CandidatsFormStep1";
import { CandidatContext } from "../../context/candidatContext";
import { baseURL } from "../../config/config";

const initFormErrors = {
  status: [""],
  city: [""],
  address: [""],
  cp: [""],
};

const CandidatsFormStep2 = ({ setChildComponent }) => {
  const msg = "Ce champ est obligatoire";
  const [countries, setCountries] = React.useState([]);

  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [city, setCity] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState(
    newCandidat.countryid
  );

  function getCountries() {
    axios.get(`${baseURL}/apiData/countries`).then((resp) => {
      if (resp.data.error) return;
      setCountries(resp.data.data);
    });
  }
  const handlecountry = (event) => {
    const selectedCountryId = event.target.value;
    setSelectedCountryId(selectedCountryId);
    setNewCandidat((prevState) => ({
      ...prevState,
      countryid: selectedCountryId,
    }));
  };

  function getCities(countryid) {
    if (!countryid) {
      return;
    }
    axios.get(`${baseURL}/apiData/cities/${countryid}`).then((resp) => {
      if (resp.data.error) {
        setCity(resp.data.data);
        return;
      }
      setCity(resp.data.data);
    });
  }
  useEffect(() => {
    getCities(selectedCountryId);
  }, [selectedCountryId]);

  useEffect(() => {
    getCountries();
  }, []);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat.countryid !== "") {
      if (newCandidat.city === "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          city: msg,
        }));
      }
    }
    if (error === false) {
      setChildComponent(
        <CandidatsFormStep3 setChildComponent={setChildComponent} />
      );
    }
  }
  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "66%" }}
          aria-valuenow="66"
          aria-valuemin="0"
          aria-valuemax="100"></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="col-md-6">
                <label>Etat Civil </label>
                <div className="input-group mb-2">
                  <select
                    id="inputEtatCivil"
                    value={newCandidat.status}
                    className="form-select"
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        status: e.target.value,
                      }));
                    }}>
                    <option disabled selected value="">
                      Sélectionner votre êtat civil
                    </option>
                    <option value="single">Célibataire</option>
                    <option value="in_relationship">En couple</option>
                    <option value="married">Marié</option>
                    <option value="divorced">Divorcé</option>
                    <option value="pacs">Pacsé</option>
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.status}</span>
                </div>
              </div>
              <div className="col-md-6 ">
                <label>Adresse </label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    value={newCandidat?.address}
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        address: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.address}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>pays </label>
                <div className="input-group mb-2">
                  <select
                    id="inputState"
                    value={newCandidat?.countryid}
                    className="form-select"
                    onChange={(e) => handlecountry(e)}>
                    <option disabled selected value="">
                      Sélectionner une pays
                    </option>
                    {countries != null &&
                      countries.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.countryid}</span>
                </div>
              </div>
              <div className="col-md-6">
                <label>Ville </label>
                <div className="input-group mb-2">
                  <select
                    id="inputState"
                    value={newCandidat.city}
                    onChange={(e) => {
                      const selectedCityId = e.target.value;
                      const selectedCity = city.find(
                        (cityIteration) => cityIteration.id === selectedCityId
                      );
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        city: selectedCityId,
                        cityName: selectedCity ? selectedCity.name : "",
                      }));
                    }}
                    className="form-select">
                    <option disabled value="">
                      Sélectionner une ville
                    </option>
                    {city != null &&
                      city.map((cityIteration, index) => {
                        return (
                          <option key={index} value={cityIteration.id}>
                            {cityIteration.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="input-error">
                  <span className="text-danger">{errorMessage.city}</span>
                </div>
              </div>

              <div className="col-md-6">
                <label>Code postal </label>
                <div className="input-group mb-2">
                  <input
                    type="number"
                    min="0"
                    value={newCandidat?.cp}
                    onChange={(e) => {
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        cp: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </div>

                <div className="input-error">
                  <span className="text-danger">{errorMessage.cp}</span>
                </div>
              </div>
            </div>

            <div className="text-end">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => {
                  setChildComponent(
                    <CandidatsFormStep1 setChildComponent={setChildComponent} />
                  );
                }}>
                Retour
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}>
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CandidatsFormStep2;
