import { Tooltip } from "@mui/material";
import React from "react";
const CandidatDetailsComponent = ({ candidat }) => {
  const noDataFound = "Aucune donnée trouvée";

  const errors = {
    birthNull: "Date de naissance indisponible",
    lieuBirthNull: "Lieu de naissance indisponible",
  };

  return (
    <div className="tab-content pt-2">
      <div
        className="card-body-candidat tab-pane fade show active profile-overview"
        id="profile-overview">
        <div className="row ps-3">
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat  color-msit ">
              Nombre d'année d'experience
            </h5>
            <p className="small mb-0 ">
              {candidat != null && candidat.experience}
            </p>
          </div>
          <div className="col-md-4 details_consultant my-3 ">
            <h5 className="card-title-candidat color-msit ">Compétence</h5>
            <p className="small mb-0 ">
              <Tooltip title={candidat?.skills?.toString()}>
                <div>
                  {candidat != null && candidat?.skills?.length > 20
                    ? `${candidat.skills.substring(0, 20)}...`
                    : candidat.skills}
                </div>
              </Tooltip>
            </p>
          </div>
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">Diplome</h5>
            <p className="small mb-0 ">
              {candidat != null ? candidat.diplome || noDataFound : ""}
            </p>
          </div>
        </div>

        <div className="row ps-3">
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">
              Année de diplome
            </h5>
            <p className="small mb-0 ">
              {candidat != null ? candidat.diplomeDate || noDataFound : ""}
            </p>{" "}
          </div>
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit">Langue</h5>
            <p className="small">
              {candidat?.langues != null && candidat?.langues?.trim() !== "" ? (
                JSON.parse(candidat?.langues).map((lang, key) => (
                  <span key={key} className="badge bg-secondary me-2">
                    {lang}
                  </span>
                ))
              ) : (
                <span>{noDataFound}</span>
              )}
            </p>
          </div>
          <div className="col-md-4 details_consultant  my-2">
            <h5 className="card-title-candidat color-msit ">Genre</h5>
            <p className="small mb-0 ">{candidat != null && candidat.gender}</p>
          </div>
        </div>
        <div className="row ps-3">
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">Adresse</h5>
            <p className="small mb-0 ">
              {candidat != null ? candidat.address || noDataFound : ""}
            </p>
          </div>
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">Ville</h5>
            {candidat != null ? candidat.city?.name || noDataFound : ""}
          </div>
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">Code postale</h5>
            {candidat != null ? candidat.cp || noDataFound : ""}
          </div>
        </div>
        <div className="row ps-3">
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">
              Date de naissance
            </h5>
            <p className="small mb-0 ">
              {candidat != null ? candidat.birthday || errors.birthNull : ""}
            </p>
          </div>
          <div className="col-md-4 details_consultant my-3">
            <h5 className="card-title-candidat color-msit ">
              Lieu de naissance
            </h5>
            <p className="small mb-0 ">
              {candidat != null
                ? candidat.birthPlace || errors.lieuBirthNull
                : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidatDetailsComponent;
