import React from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";

function DeleteManager({
  setAddManagerModalFromChild,
  getManagerByCompanyId,
  managerId,
}) {
  function deleteManager() {
    axios.delete(`${baseURL}/managers/${managerId}`).then((resp) => {
      if (resp.data.error) return;

      swal("Cette contact  est supprimé avec succès!", {
        icon: "success",
        timer: 2000,
      });
      getManagerByCompanyId();
      setTimeout(() => {
        setAddManagerModalFromChild("");
      }, 2400);
    });
  }

  React.useEffect(() => {
    swal({
      title: "êtes vous sûr de supprimer cette contact?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer ce contact!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        deleteManager();
      } else {
        setAddManagerModalFromChild("");
      }
    });
  }, []);
  return <div></div>;
}

export default DeleteManager;
