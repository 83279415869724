import React, { useContext, useState } from "react";
import CandidatsFormStep2 from "./CandidatsFormStep2";
import { CandidatContext } from "../../context/candidatContext";
import { useHistory } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const initFormErrors = {
  lastname: [""],
  firstname: [""],
  email: [""],
  phone: [""],
  birthDay: [""],
  lieu_naissance: [""],
  avatar: [""],
};
const CandidatsFormStep1 = ({ setChildComponent }) => {
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newCandidat, setNewCandidat } = useContext(CandidatContext);
  const history = useHistory();
  const uploadedImage = React.useRef(null);
  const msg = "Ce champ est obligatoire";
  const msg1 = "Saisissez une valeur valide ";
  const regex =
    /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newCandidat.gender === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        gender: msg,
      }));
    }
    if (newCandidat.lastname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        lastname: msg,
      }));
    } else {
      if (newCandidat.lastname.length < 3) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          lastname: msg1,
        }));
      }
    }
    if (newCandidat.firstname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        firstname: msg,
      }));
    } else {
      if (newCandidat.firstname.length < 3) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          firstname: msg1,
        }));
      }
    }
    if (newCandidat.email !== "") {
      if (regex.test(newCandidat.email) === false) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          email: msg1,
        }));
      }
    }

    if (error === false) {
      setChildComponent(
        <CandidatsFormStep2 setChildComponent={setChildComponent} />
      );
    }
  }
  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "33%" }}
          aria-valuenow="33"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3" id="form">
            <div className="col-md-6">
              <label>Vous étes :</label>
              <div className=" mb-2">
                <div className="row pt-1">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="man"
                        value="male"
                        onChange={(e) => {
                          setNewCandidat((prevState) => ({
                            ...prevState,
                            gender: e.target.value,
                          }));
                        }}
                        checked={newCandidat?.gender === "male"}
                      />
                      <label htmlFor="man" className="form-check-label">
                        Homme
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="woman"
                        value="femele"
                        onChange={(e) => {
                          setNewCandidat((prevState) => ({
                            ...prevState,
                            gender: e.target.value,
                          }));
                        }}
                        checked={newCandidat?.gender === "femele"}
                      />
                      <label htmlFor="woman" className="form-check-label">
                        Femme
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="other"
                        value="other"
                        onChange={(e) => {
                          setNewCandidat((prevState) => ({
                            ...prevState,
                            gender: e.target.value,
                          }));
                        }}
                        checked={newCandidat?.gender === "other"}
                      />
                      <label htmlFor="other" className="form-check-label">
                        Autre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.gender}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Nom *</label>
              <div className="input-group mb-2">
                <input
                  name="name"
                  type="text"
                  value={newCandidat?.lastname}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      lastname: e.target.value,
                    }));
                  }}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.lastname}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Prénom *</label>
              <div className="input-group mb-2">
                <input
                  type="text"
                  value={newCandidat?.firstname}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      firstname: e.target.value,
                    }));
                  }}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.firstname}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Email </label>
              <div className="input-group mb-2">
                <input
                  type="email"
                  value={newCandidat?.email}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.email}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Téléphone </label>
              <div className="input-group mb-2">
                <PhoneInput
                  inputClass="phoneInput"
                  className="phoneInput"
                  placeholder="Téléphone"
                  value={newCandidat?.phone}
                  onChange={(value, country, event) =>
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      phone: event.target.value ? event.target.value : value,
                    }))
                  }
                />
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.phone}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box">
                <div className="date-picker">
                  <label>Date de naissance </label>
                  <div className="input-group mb-2">
                    <input
                      value={newCandidat?.birthDay}
                      className="form-control"
                      onChange={(e) => {
                        setNewCandidat((prevState) => ({
                          ...prevState,
                          birthDay: e.target.value,
                        }));
                      }}
                      type="date"
                      id="datePicker"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.birthDay}</span>
              </div>
            </div>

            <div className="col-md-6">
              <label>Lieu de naissance </label>
              <div className="input-group mb-2">
                <input
                  type="text"
                  value={newCandidat?.birthPlace}
                  onChange={(e) => {
                    setNewCandidat((prevState) => ({
                      ...prevState,
                      birthPlace: e.target.value,
                    }));
                  }}
                  className="form-control"
                  required
                />
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage?.birthPlace}</span>
              </div>
            </div>
            <div className="col-md-6">
              <span>Upload Avatar </span>
              <div className="input-group mb-2">
                <div className="custom-file">
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    className="form-control"
                    id="inputGroupFile01"
                    required
                    onChange={(e) => {
                      handleImageUpload(e);
                      setNewCandidat((prevState) => ({
                        ...prevState,
                        avatar: e.target.files[0],
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <img
                alt=""
                ref={uploadedImage}
                style={{
                  weight: "30%",
                  width: "40%",
                }}
              />
            </div>
            <div className="text-end ">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => history.goBack()}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CandidatsFormStep1;
