import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuid } from "uuid";
import { baseURL } from "../config/config";
import Tooltip from "@mui/material/Tooltip";
import CadidatToEmplyee from "../../src/components/candidat/CadidatToEmplyee";
import DisplayError from "./ErrorComponants/DisplayError";
import CandidateInterviewNote from "./candidateInterview/candidateInterviewNote";
import { UserContext } from "../context/UserContext";
import { PERMISSIONS, hasPermission } from "./checkPermissions";
import { FaRegAddressCard } from "react-icons/fa";
const initFormErrors = {
  candidat: [""],
  step: [""],
};
const Entretien = () => {
  const { user } = useContext(UserContext);

  const hasCandidatsConsultPermission = hasPermission(
    user,
    PERMISSIONS.candidats,
    "consult"
  );
  const hasCandidatsProcessConsultPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "consult"
  );
  const hasCandidatsProcessAffectPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "affect_candidat_to_process"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasEmployeeCreateFromCandidatPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "create_from_candidat"
  );
  const [process, setProcess] = useState([]);
  const [candidats, setCandidats] = useState([]);
  const [selectedCandidat, setSelectedCandidat] = useState();
  const [selectedStep, setSelectedStep] = useState();
  const [liststeps, setListSteps] = useState([]);
  const { processId } = useParams();
  const [columns, setColumns] = useState();
  const [addEmployeeModal, setaddEmployeeModal] = useState();
  const [itemsListAccepted, setItemsListAccepted] = useState();
  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [addNote, setAddNote] = useState();

  async function getAllProcess() {
    axios
      .get(`${baseURL}/process/details/${processId}`)
      .then((response) => {
        if (response.data.error) return;
        setProcess(response.data.data);
        setListSteps([]);

        const parsedSteps = response.data.data.steps;
        const updatedListSteps = parsedSteps.map((item) => ({
          value: item.index,
          label: item.name,
        }));
        setListSteps(updatedListSteps);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  async function getAllCandidats() {
    axios
      .get(`${baseURL}/candidates`)
      .then((response) => {
        if (response.data.error) return;
        setCandidats([]);
        response.data.data.map((item) => {
          return setCandidats((prevState) => [
            ...prevState,
            {
              value: item._id,
              label: `${item.firstname}  ${item.lastname}`,
            },
          ]);
        });
      })
      .catch(() => {
        DisplayError();
      });
  }

  function affectCandidat() {
    axios
      .post(`${baseURL}/candidatProcess/affect`, {
        candidat: selectedCandidat?.id,
        process: processId,
        step: selectedStep,
      })
      .then((resp) => {
        setFormErrors("");
        if (resp.data.error) return;
        getAffectedCandidatByProcess();
      })
      .catch((error) => {
        DisplayError(
          error.response.data.candidat
            ? error.response.data.candidat
            : error.response.data.step
        );
        setFormErrors(error.response.data);
      });
  }

  function changeCandidatStep(candidatID, step) {
    axios
      .post(`${baseURL}/candidatProcess/update`, {
        candidat: candidatID,
        process: processId,
        step: step === undefined ? "Affected" : step,
      })
      .then((resp) => {
        if (resp.data.error) return;
        getAffectedCandidatByProcess();
      })
      .catch(() => {
        DisplayError();
      });
  }
  async function getAffectedCandidatByProcess() {
    axios
      .get(`${baseURL}/candidatProcess/get/${processId}`)
      .then((response) => {
        if (response.data.error) return;
        let data = response.data.data;
        let cols = [];
        let itemslistAccepted = [];
        response.data.data?.Affected?.map((item) => {
          itemslistAccepted.push(item);
        });
        setItemsListAccepted(itemslistAccepted);
        const finalColumn = {
          name: "Affecté",
          items: itemslistAccepted,
        };
        liststeps?.map((step, key) => {
          return (cols[uuid()] = {
            name: step.label,
            index: step.value,
            items: data[step.value] != null ? data[step.value] : [],
          });
        });
        cols[finalColumn] = finalColumn;
        setColumns(cols);
      })
      .catch(() => {
        DisplayError();
      });
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      changeCandidatStep(removed.id, destColumn.index);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];

      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  useEffect(() => {
    getAllProcess();
    if (hasCandidatsConsultPermission) {
      getAllCandidats();
    }
  }, [hasCandidatsConsultPermission]);

  useEffect(() => {
    if (liststeps === undefined) return;
    getAffectedCandidatByProcess();
  }, [liststeps]);
  return (
    <div>
      <div className="pagetitle">
        <h1>Entretien</h1>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  Les processes d'entretiens
                </li>
                <li className="breadcrumb-item active">Entretien</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <Link to="/process">
              <button
                type="button"
                className="btn btn-outline-secondary float-end  me-2"
              >
                Retour
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="row mb-5">
          <h1 className="col-md-12 d-flex justify-content-center fw-bold text-uppercase">
            {process.name?.length > 15
              ? `${process.name.slice(0, 15)}...`
              : process.name}
          </h1>
        </div>
        {hasCandidatsConsultPermission &&
          hasCandidatsProcessConsultPermission &&
          hasCandidatsProcessAffectPermission && (
            <div className="row">
              <div className="col-md-4">
                <label>Sélectionnez un candidat </label>
                <Select
                  className="shadow_btn"
                  options={candidats}
                  onChange={(e) => {
                    setSelectedCandidat({ label: e.label, id: e.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "13") document.getElementById("add").click();
                  }}
                  isSearchable
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.candidat} </span>
                </div>
              </div>

              <div className="col-md-4 ">
                <label>Sélectionnez une étape </label>
                <Select
                  className="shadow_btn"
                  options={liststeps}
                  onChange={(e) => {
                    setSelectedStep({ label: e.label, value: e.value });
                  }}
                  isSearchable
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.step} </span>
                </div>
              </div>
              <div className="col-md-4 pt-4 ">
                <button
                  type="button"
                  className="btn btn-primary shadow_btn"
                  onClick={() => {
                    affectCandidat();
                  }}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          )}
      </div>
      <div>
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          <div className="row x-scroll mt-5">
            {columns != null &&
              Object.entries(columns).map(([columnId, column], key) => {
                return (
                  <div
                    className="col-md-3  mb-5"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "",
                      borderColor: "",
                      borderRadius: 16,
                      width: "fit-content",
                    }}
                    key={columnId}
                  >
                    <div>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div>
                              <div
                                style={{
                                  backgroundColor: `rgb(236 236 236)`,
                                  fontSize: 18,
                                }}
                                className=" d-flex justify-content-center border_column_steps_title fw-bold text-uppercase pt-2 color-msit"
                              >
                                <Tooltip title={column.name}>
                                  <p>
                                    {column.name?.length > 17
                                      ? `${column.name.substring(0, 17)}...`
                                      : column.name}
                                  </p>
                                </Tooltip>
                              </div>
                              <div
                                className="border_column_steps "
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? `#ccc`
                                    : "rgb(236 236 236)",
                                  padding: 4,
                                  width: 250,
                                  minHeight: 300,
                                  height: "auto",
                                }}
                              >
                                {column.items.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <Tooltip
                                            title={
                                              itemsListAccepted.includes(
                                                item
                                              ) ? (
                                                hasCompanyConsultPermission &&
                                                hasEmployeeCreateFromCandidatPermission &&
                                                hasCandidatsConsultPermission ? (
                                                  <div
                                                    style={{
                                                      background:
                                                        snapshot.isDraggingOver
                                                          ? "lightblue"
                                                          : "#f6f7fb",
                                                      padding: 0,
                                                      display: "flex",
                                                      justifycontent: "center",
                                                      alignitems: "center",
                                                    }}
                                                  >
                                                    <h4>
                                                      Ajouter comme employé
                                                    </h4>
                                                    <button
                                                      className=" btn text-primary "
                                                      onClick={() =>
                                                        setaddEmployeeModal(
                                                          <CadidatToEmplyee
                                                            setaddEmployeeModalFromChild={
                                                              setaddEmployeeModal
                                                            }
                                                            candidatId={item.id}
                                                            getAllCandidats={
                                                              getAllCandidats
                                                            }
                                                          />
                                                        )
                                                      }
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-person-add"
                                                        viewBox="0 0 16 16"
                                                      >
                                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                                        <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                                      </svg>
                                                    </button>
                                                  </div>
                                                ) : null
                                              ) : (
                                                ""
                                              )
                                            }
                                            arrow
                                          >
                                            <div
                                              className=" mt-3 ms-3 me-3 "
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                userSelect: "none",
                                                padding: 16,
                                                margin: "0 0 8px 0",
                                                minHeight: "20px",
                                                boxShadow: `rgba(0, 0, 0, 0.12) 0px 1px 3px , rgba(0, 0, 0, 0.24) 0px 1px 2px`,
                                                backgroundColor:
                                                  snapshot.isDragging
                                                    ? `#fff`
                                                    : `#fff`,
                                                color: snapshot.isDragging
                                                  ? "black"
                                                  : `black`,
                                                borderRadius: 8,
                                                fontWeight: "bold",
                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <div className="row">
                                                <div
                                                  data-bs-toggle="tooltip"
                                                  data-bs-html="true"
                                                  title={item.content}
                                                  className="btn col-md-9 text_candidats"
                                                >
                                                  {hasCandidatsConsultPermission ? (
                                                    <Link
                                                      className="color-msit"
                                                      to={`/candidats/details/${item.id}`}
                                                    >
                                                      {item.content.length > 12
                                                        ? item.content.substr(
                                                            0,
                                                            12
                                                          ) + "..."
                                                        : item.content}
                                                    </Link>
                                                  ) : item.content.length >
                                                    12 ? (
                                                    item.content.substr(0, 12) +
                                                    "..."
                                                  ) : (
                                                    item.content
                                                  )}
                                                </div>

                                                {hasCandidatsConsultPermission && (
                                                  <button
                                                    className="col-3 btn text-primary"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title="Créer note"
                                                    onClick={() => {
                                                      setAddNote(
                                                        <CandidateInterviewNote
                                                          candidatDetails={item}
                                                          step={column}
                                                          getAffectedCandidatByProcess={
                                                            getAffectedCandidatByProcess
                                                          }
                                                          process={processId}
                                                          UserContext={user}
                                                          setAddNoteModalFromChild={
                                                            setAddNote
                                                          }
                                                          id={item.id}
                                                        />
                                                      );
                                                    }}
                                                  >
                                                    <FaRegAddressCard />
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </Tooltip>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}

                                {provided.placeholder}
                              </div>
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                );
              })}
          </div>
        </DragDropContext>
      </div>
      {addNote}
      {addEmployeeModal}
    </div>
  );
};
export default Entretien;
