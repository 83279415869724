import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import Select from "react-select";
import { PERMISSIONS, hasPermission } from "../checkPermissions";
import { Link, useParams } from "react-router-dom";

import "./statusProcess.css";
const initFormErrors = {
  interviewer: [""],
  errMsg: "Veuillez sélectionner un intervieweur.",
};
function CandidateInterviewNote({
  setAddNoteModalFromChild,
  id,
  getAffectedCandidatByProcess,
  UserContext,
  process,
  candidatDetails,
  step,
}) {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState("");
  const [formerrors, setFormErrors] = useState("");
  const { processId } = useParams();
  const [statusProcess, setStatusProcess] = useState("");

  const hasCandidatsProcessDisaffectPermission = hasPermission(
    UserContext,
    PERMISSIONS.processCandidats,
    "disaffect_candidat_step"
  );
  const hasCandidatsProcessNotePermission = hasPermission(
    UserContext,
    PERMISSIONS.processCandidats,
    "create_note"
  );
  const hasCandidatsProcessNoteConsultPermission = hasPermission(
    UserContext,
    PERMISSIONS.processCandidats,
    "consulter_note"
  );
  const hasCandidatsProcessAffectPermission = hasPermission(
    UserContext,
    PERMISSIONS.processCandidats,
    "affecter_interviewer"
  );
  const hasConsultReviewerPermission = hasPermission(
    UserContext,
    PERMISSIONS.processCandidats,
    "consulter_interviewer"
  );
  const noDataFound = "Aucune donnée trouvée";

  async function fetchData() {
    await axios
      .get(`${baseURL}/candidatProcess/getNotes/${id}/${process}`)
      .then((resp) => {
        if (resp.data.error) return;
        setNotes(resp.data.notes);
      })
      .catch(() => {
        DisplayError();
      });
  }

  function disaffectCandidat(candidatID) {
    swal({
      title: "êtes vous sûr de supprimer ce candidat?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer ce candidat!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`${baseURL}/candidatProcess/disaffect`, {
            candidat: candidatID,
            process: processId,
          })
          .then((resp) => {
            if (resp.data.error) return;
            swal("Ce candidat est supprimé avec succés!", {
              icon: "success",
              timer: 2000,
            });
            setAddNoteModalFromChild("");
            getAffectedCandidatByProcess();
          })
          .catch((error) => {
            DisplayError();
          });
      }
    });
  }
  useEffect(() => {
    if (hasCandidatsProcessNoteConsultPermission) {
      fetchData();
    }
  }, [hasCandidatsProcessNoteConsultPermission]);
  const tel = `tel:+${candidatDetails.phone}`;
  const mailto = `mailto:${candidatDetails.email}`;
  function sendData() {
    axios
      .post(`${baseURL}/candidatProcess/note/`, {
        candidat: id,
        note: note,
        step: step?.index ?? "Affected",
        stepName: step?.name ?? "Affected",
      })

      .then((resp) => {
        if (resp.data.error) return;
        fetchData();
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        setFormErrors(error.response.data.message);
        DisplayError();
      });
  }
  const [interviewers, setInterviewers] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [initialSelectedInterviewerEmail, setInitialSelectedInterviewerEmail] =
    useState("");
  const [selectedInterviewerValue, setSelectedInterviewerValue] = useState("");

  const [selectedInterviewer, setSelectedInterviewer] = useState("");

  const handleInterviewerChange = (selectedOption) => {
    setSelectedInterviewer(selectedOption);
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      interviewer: "",
    }));
  };

  const AffecteInterviewer = () => {
    if (selectedInterviewer?.value === initialSelectedInterviewerEmail) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        interviewer: initFormErrors.errMsg,
      }));
    } else {
      axios
        .post(`${baseURL}/candidatProcess/affectInterviewer`, {
          candidat: id,
          interviewer: selectedInterviewer?.value,
          process: processId,
          step: step?.index ?? "Affected",
        })
        .then((resp) => {
          setErrorMessage(initFormErrors);
          if (resp.data.error) return;
          swal(resp.data.message, {
            icon: "success",
            timer: 2500,
          });
          fetchReviewer();
          const newSelectedInterviewerLabel = interviewers.find(
            (option) => option.value === selectedInterviewer.value
          )?.label;
          setSelectedInterviewerValue(newSelectedInterviewerLabel);
          setSelectedInterviewer("");
        })
        .catch((error) => {
          setErrorMessage(error.response.data);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/users/allInterviewrs`)
      .then((response) => {
        const options = response.data.data.map((interviewer) => ({
          value: interviewer.email,
          label: interviewer.name,
        }));
        setInterviewers(options);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);
  const fetchReviewer = () => {
    const stepReviewer = step?.index ?? "Affected";
    axios
      .get(
        `${baseURL}/candidatProcess/getReviewer/${id}/${process}/${stepReviewer}`
      )
      .then((resp) => {
        if (resp.data.error) return;
        const interviewerEmail = resp.data.interviewer?.email;
        const candidatProcess = resp.data.status;
        setStatusProcess(candidatProcess);
        setSelectedInterviewerValue(resp.data.interviewer?.name);
        setInitialSelectedInterviewerEmail(interviewerEmail);
        setSelectedInterviewer(interviewerEmail);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (hasConsultReviewerPermission) {
      fetchReviewer();
    }
  }, [id, process, hasConsultReviewerPermission]);
  const handleValidation = (status) => {
    const requestBody = {
      candidat: id,
      process: process,
      status: status,
      step: step?.index ?? "Affected",
    };
    axios
      .post(`${baseURL}/candidatProcess/validateProcessCandidat`, requestBody)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        fetchReviewer();
        if (resp.data.error) {
          return;
        }
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  };
  return (
    <div>
      <div
        className="modal fade show"
        id="role_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h3 className="text-align-center fw-bold">Détails de candidat</h3>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="card-candidat">
                    <div className="candidat-card pt-4 d-flex flex-column align-items-center text-uppercase">
                      <div className="row">
                        <div className=" d-flex justify-content-center">
                          <img
                            alt=""
                            src={
                              candidatDetails.avatar != null
                                ? baseURL +
                                  candidatDetails.avatar.path.substring(1)
                                : undefined
                            }
                            className="rounded-circle NoteCard_img"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <h2>
                          <Link
                            className="btn text-primary "
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Details candidat"
                            to={`/candidats/details/${candidatDetails.id}`}
                          >
                            {candidatDetails?.content &&
                              `${candidatDetails.content.substring(0, 15)}${
                                candidatDetails.content.length > 15 ? "..." : ""
                              }`}
                          </Link>
                        </h2>
                        <Link
                          className=" text-primary color-msit "
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Details candidat"
                          to={`/candidats/details/${candidatDetails.id}`}
                        >
                          Voir détails
                        </Link>
                      </div>
                      <h6 className=" text-primary color-msit">
                        {candidatDetails?.type?.name &&
                          `${candidatDetails.type?.name.substring(0, 15)}${
                            candidatDetails.type?.name.length > 15 ? "..." : ""
                          }`}
                      </h6>
                    </div>

                    <div className="row mb-4  d-flex flex-column align-items-center">
                      <div className="col-md-12 d-flex justify-content-center ">
                        <i className="bi-envelope-fill me-1"></i>
                        {candidatDetails?.email ? (
                          <a
                            href={mailto}
                            className="color-msit"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Envoyer mail"
                          >
                            {candidatDetails.email.substring(0, 17)}
                            {candidatDetails.email.length > 17 ? "..." : ""}
                          </a>
                        ) : (
                          <span className="color-msit">{noDataFound}</span>
                        )}
                      </div>
                      <div
                        className="col-md-10 mb-4 d-flex justify-content-center"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Appeler"
                      >
                        <i className="bi-telephone-fill me-1"></i>
                        {candidatDetails != null && candidatDetails.phone ? (
                          <span className="color-msit">
                            <a className="color-msit" href={tel}>
                              {candidatDetails.phone}
                            </a>
                          </span>
                        ) : (
                          <span className="color-msit">{noDataFound}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    {UserContext?.email === initialSelectedInterviewerEmail ? (
                      <div className="row flex-row justify-content-center">
                        <button
                          id="validateButton"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          className="btn btn-outline-success mb-2 col-5 m-2"
                          onClick={() => handleValidation("validated")}
                        >
                          <FaCheck className="me-2" />
                          Valider
                        </button>
                        <button
                          id="rejectButton"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          className="btn btn-outline-danger mb-2 col-5 m-2"
                          onClick={() => handleValidation("rejected")}
                        >
                          <ImCross className="me-2" />
                          Rejeter
                        </button>
                      </div>
                    ) : null}
                    {hasCandidatsProcessDisaffectPermission && (
                      <button
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Supprimer"
                        className="btn btn-danger mb-2"
                        onClick={() => {
                          disaffectCandidat(id);
                        }}
                      >
                        <i className="ms-1 bi bi-trash"></i>
                        Désaffecter du process
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="modal-header justify-content-center row">
                    <div className="col-lg-7 d-flex align-items-center">
                      <h3 className="text-align-center ">
                        Etape Actuellle :
                        {step?.name.length > 17
                          ? `${step?.name.substring(0, 17)}...`
                          : step?.name}
                      </h3>
                      {statusProcess === "rejected" && (
                        <div className=" ms-2">
                          <div class="wrapper">
                            <svg class="checkmark" viewBox="0 0 52 52">
                              <circle
                                class="checkmark_circle"
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                              />
                              <path
                                class="checkmark_check"
                                fill="none"
                                d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                      {statusProcess === "validated" && (
                        <div class="ms-2">
                          <svg
                            width="60px"
                            height="60px"
                            viewBox="0 0 133 133"
                            version="1.1"
                          >
                            <g
                              id="check-group"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <circle
                                id="filled-circle"
                                fill="#78B348"
                                cx="66.5"
                                cy="66.5"
                                r="54.5"
                              ></circle>
                              <circle
                                id="white-circle"
                                fill="#FFFFFF"
                                cx="66.5"
                                cy="66.5"
                                r="55.5"
                              ></circle>
                              <circle
                                id="outline"
                                stroke="#78B348"
                                strokeWidth="4"
                                cx="66.5"
                                cy="66.5"
                                r="54.5"
                              ></circle>
                              <polyline
                                id="check"
                                stroke="#FFFFFF"
                                strokeWidth="4"
                                points="41 70 56 85 92 49"
                              ></polyline>
                            </g>
                          </svg>
                        </div>
                      )}
                      {statusProcess === "waiting" && (
                        <div class="clock ms-2">
                          <div class="cup top">
                            <div class="sand"></div>
                          </div>
                          <div class="cup">
                            <div class="sand delayed"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {hasCandidatsProcessAffectPermission &&
                    hasConsultReviewerPermission && (
                      <>
                        <div className="d-flex mt-2">
                          <p>Interviewer: {selectedInterviewerValue}</p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <Select
                            id="interviewerSelect"
                            options={interviewers}
                            onChange={handleInterviewerChange}
                            placeholder="Sélectionner un Interviewer"
                            value={selectedInterviewer}
                          />
                          <div className="input-error m-1">
                            <span className="text-danger">
                              {errorMessage.interviewer}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            onClick={AffecteInterviewer}
                            className="btn btn-primary"
                          >
                            Affecter
                          </button>
                        </div>
                      </>
                    )}
                  {hasCandidatsProcessNotePermission &&
                    hasCandidatsProcessNoteConsultPermission && notes && (
                      <>
                        <div className="d-flex">
                          <p> Note :</p>  
                        </div>
                       
                        <textarea
                          type="text"
                          className="form-control mb-3 "
                          id="role_name"
                          defaultValue={notes[step?.index]?.note || notes["Affected"]?.note}
                          placeholder="veuillez saisir une note"
                          onChange={(e) => setNote(e.target.value)}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">{formerrors}</span>
                        </div>
                        <div className="mb-3 d-flex justify-content-end">
                          <button
                            type="button"
                            onClick={sendData}
                            className="btn btn-primary"
                          >
                            Enregistrer
                          </button>
                        </div>
                      </>
                    )}
                  {hasCandidatsProcessNoteConsultPermission && (
                    <div className="mt-2">
                      <h3 className="justify-content-center d-flex">
                        Notes des autres etapes
                      </h3>
                      <div className="Notes_display">
                        {Object.keys(notes).map((displayStep) => {
                          return (
                            <div
                              key={displayStep}
                              className="card user-card1-full NoteCard  "
                            >
                              <div className="card-body">
                                <label className="color-msit">
                                  Etape {notes[displayStep].stepName} :
                                </label>
                                <p>{notes[displayStep].note}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setAddNoteModalFromChild("")}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
}

export default CandidateInterviewNote;
