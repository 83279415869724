const permissions = {
  admin: {
    name: "Accès admin",
    items: [{ name: "admin", value: "isAdmin" }],
  },
  users: {
    name: "Utilisateurs",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Changer Mot De Passe", value: "change_password" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
      { name: "Lier Employé comme Utilisateur", value: "link_employee_user" },
    ],
  },
  roles: {
    name: "Roles",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Supprimer", value: "delete" },
    ],
  },
  candidatTypes: {
    name: "Type des candidats",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Consulter Activé", value: "consulter_enabled" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
    ],
  },
  candidats: {
    name: "Candidats",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
      { name: "Gérer Note", value: "create_note" },
      { name: "Consulter Note", value: "consulter_note" },
      { name: "Consulter Cv", value: "consulter_cv" },
      { name: "verrouiller", value: "lock" },
    ],
  },
  missions: {
    name: "Missions",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
      {
        name: "Créer un Processus d'Entretien de Mission",
        value: "create_mission_interview_process",
      },
    ],
  },
  company: {
    name: "Sociétés",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
    ],
  },
  processEntretiens: {
    name: "Process des entretiens",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
      { name: "Supprimer", value: "delete" },
    ],
  },
  employees: {
    name: "Employées",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
      { name: "Créer à partir du Candidat", value: "create_from_candidat" },
      { name: "Créer un employée utilisateur", value: "create_employee_user" },
      { name: "Gérer Note", value: "create_note" },
      { name: "Consulter Note", value: "consulter_note" },
    ],
  },
  cv: {
    name: "CV",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
    ],
  },
  processCandidats: {
    name: "Process des entretiens des candidats",
    items: [
      { name: "Consulter", value: "consult" },
      {
        name: "Affecter le Candidat au Processus",
        value: "affect_candidat_to_process",
      },
      {
        name: "Mettre à jour l'étape du Candidat",
        value: "update_candidat_step",
      },
      {
        name: "Désaffecter l'étape du Candidat",
        value: "disaffect_candidat_step",
      },
      { name: "Affecter interviewer", value: "affecter_interviewer" },
      { name: "Consulter interviewer", value: "consulter_interviewer" },
      { name: "Gérer Note", value: "create_note" },
      { name: "Consulter Note", value: "consulter_note" },
    ],
  },
  category: {
    name: "Catégories",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Changer Status", value: "change_status" },
    ],
  },
  experience: {
    name: "Expériences",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "modifier", value: "edit" },
      { name: "Supprimer", value: "delete" },
    ],
  },
  manager: {
    name: "Contactes des Sociétés ",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Supprimer", value: "delete" },
    ],
  },
  matching: {
    name: "Correspondances des candidats ",
    items: [
      {
        name: "Correspondance des Candidats pour la Mission",
        value: "matching_mission_candidates",
      },
    ],
  },
  missionEmployees: {
    name: "Gestionnaires des affectations",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Changer Status", value: "change_status" },
      {
        name: "Affecter l'Employé au Processus de Mission",
        value: "affect_employee_to_mission_process",
      },
      {
        name: "Consulter le Processus de l'Employé par Identifiant de Mission",
        value: "consulter_process_employee_by_mission",
      },
    ],
  },
  relaunchMission: {
    name: "Rappeles des missions",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Changer Status", value: "change_status" },
      { name: "Modifier", value: "edit" },
    ],
  },
  workdays: {
    name: "Feuilles du temps",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Gérer", value: "gerer" },
    ],
  },
  invoice: {
    name: "Factures",
    items: [
      { name: "Consulter", value: "consult" },
      { name: "Créer", value: "create" },
      { name: "Modifier", value: "edit" },
      { name: "Télécharger facture", value: "uploadFacture" },
    ],
  },
};
export default permissions;
