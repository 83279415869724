import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import AffectMissionModal from "../employee/affectMissionModal";
import EditMission from "../../components/mission/updateMission";
import Loader from "../../components/loader";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { UserContext } from "../../context/UserContext";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
const SupplierDetails = (get) => {
  const [supplier, setSupplier] = useState([]);
  const noDataFound = "Aucune donnée trouvée";

  const { user } = useContext(UserContext);

  const hasEmployeesEditPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "edit"
  );
  const hasEmployeesStatusPermission = hasPermission(
    user,
    PERMISSIONS.employees,
    "change_status"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionsEmployeeAffectPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "create"
  );
  const hasCvConsultPermission = hasPermission(user, PERMISSIONS.cv, "consult");
  const hasMissionsEmployeeConsultPermission = hasPermission(
    user,
    PERMISSIONS.missionEmployees,
    "consult"
  );
  const { supplierId } = useParams();
  const [missionData, setMissionData] = useState([]);
  const [affectMissionModal, setAffectMissionModal] = useState();
  const [mission, setMission] = useState([]);
  const [missions, setMissions] = useState([]);
  const [addMissionModal, setAddMissionModal] = useState();
  const tel = `tel:+${supplier.phone}`;
  const mailto = `mailto:${supplier.email}`;
  async function getDetailsSupplier() {
    if (supplierId === undefined) return;
    axios
      .get(`${baseURL}/consultants/${supplierId}`)
      .then((response) => {
        if (response.data.error) return;
        setSupplier(response.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  function affectMissionToEmployee() {
    setAffectMissionModal(
      <AffectMissionModal
        SetAffectModalFromChild={setAffectMissionModal}
        employee={supplier}
        missions={missions}
        getMissions={getMission}
      />
    );
  }
  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`${baseURL}/missionEmployees/toggleStatus/${supplierId}`, {
            missionId: missionId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getMission();
            }
          })
          .catch((err) => {
            DisplayError();
          });
      }
    });
  }
  async function getMission() {
    if (supplierId === null) return;
    axios
      .get(`${baseURL}/missionEmployees/missions/${supplierId}`)
      .then((response) => {
        if (response.data.error) return;
        setMission(response.data.data);
      })
      .catch((err) => {
        DisplayError();
      });
  }
  async function getAllMissions() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMissions(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (mission == null) return;
    if (mission.length > 0) {
      setMissionData(
        <DataTable
          className="dataTableClass"
          columns={columnsMission}
          data={mission}
          pagination
        />
      );
    } else {
      setMissionData(<EmptyData />);
    }
  }, [mission]);
  async function enableDisable(supplierId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver ce fournisseur?"
        : "Voulez vous Activer ce fournisseur?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/employees/toggleStatus/${supplierId}`, {})
          .then((resp) => {
            getDetailsSupplier();
          });
      }
    });
  }
  const columnsMission = [
    {
      name: "#",
      selector: (row, index) => ++index,
      width: "6%",
      sortable: true,
    },
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <div>{row.mission.title}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>{row.mission.description}</div>
        </div>
      ),
      width: "10rem",
      sortable: true,
    },
    {
      name: "Date debut de mission",
      selector: (row) => (
        <div>
          <div>{row.mission.startDate}</div>
        </div>
      ),
      width: "18%",
      sortable: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row.mission.period}</div>
        </div>
      ),
      width: "15%",
      sortable: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div>
            {row.mission.manager != null && row.mission.manager.fname}{" "}
            {row.mission.manager != null && row.mission.manager.lname}
          </div>
        </div>
      ),
      width: "13%",
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row) => {
        return (
          <div className="row col-12">
            <button
              className="col-3 btn text-primary me-2"
              onClick={() =>
                setAddMissionModal(
                  <EditMission
                    setAddMissionModalFromChild={setAddMissionModal}
                    missionId={row.mission._id}
                    getMissions={getMission}
                  />
                )
              }>
              <i className="bi bi-pen"></i>
            </button>
            <button
              className="col-3 btn text-primary me-2 btn"
              onClick={() => {
                enableDisableMission(row.mission._id, row.mission.isEnabled);
              }}>
              {row.isEnabled === "yes" ? (
                <i className="text-success bi bi-toggle2-on"></i>
              ) : (
                <i className="text-danger bi bi-toggle2-off"></i>
              )}
            </button>

            <Link
              className="col-3 btn text-primary me-2"
              to={`/missions/details/${row.mission._id}`}>
              <i className="ri-arrow-right-s-fill"></i>
            </Link>
          </div>
        );
      },
      width: "15%",
    },
  ];
  useEffect(() => {
    getDetailsSupplier();
    getAllMissions();
  }, []);
  useEffect(() => {
    if (hasMissionsEmployeeConsultPermission) {
      setMissionData(<Loader />);
      getMission();
    }
  }, [hasMissionsEmployeeConsultPermission]);
  return (
    <div>
      <div className="pagetitle row">
        <div className="col-md-8">
          <h1>Détails de fournisseur</h1>
        </div>
        <div className="col-md-4">
          <Link to="/supplier">
            <button
              type="button"
              className="btn btn-outline-secondary mb-2 float-end  me-2">
              Retour
            </button>
          </Link>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/supplier">Les fournisseurs</Link>
            </li>
          </ol>
        </nav>
      </div>
      <section className="section profile">
        <div className="row">
          <div className="col-md-5">
            <fieldset className="border_supplier_details p-3 mt-2">
              <div className="profile-card">
                <div className="row ms-2">
                  <div className="col-8">
                    {" "}
                    <h4 className="fw-bold color-msit">
                      {supplier != null && supplier.fname}
                      {"  "}
                      {supplier != null && supplier.lname}
                    </h4>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <div className="d-flex justify-content-end">
                      <div>
                        {hasEmployeesEditPermission && (
                          <Link to={`/supplier/edit/${supplierId}`}>
                            <button type="button" className="btn text-primary">
                              <i
                                className="bi bi-pen"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Modifier fournisseur"></i>
                            </button>
                          </Link>
                        )}
                        {hasEmployeesStatusPermission && (
                          <button
                            className="col-3 btn text-primary me-2 btn"
                            onClick={() => {
                              enableDisable(supplier._id, supplier.isEnabled);
                            }}>
                            {supplier.isEnabled === "yes" ? (
                              <i
                                className="text-success bi bi-toggle2-on"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Désactiver fournisseur"></i>
                            ) : (
                              <i
                                className="text-danger bi bi-toggle2-off"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Activer fournisseur"></i>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ms-2">
                  <div className="col-md-4">
                    {" "}
                    <img
                      src={supplier != null && supplier.avatar}
                      className="avatar-border"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="row text-lowercase mt-4 flex">
                      <div className="row">
                        <div className="col-1 label ">
                          <i className="bi-envelope me-1"></i>
                        </div>
                        <div className="col-10">
                          {supplier &&
                          supplier.email &&
                          supplier.email.length > 16 ? (
                            <a
                              className="color-msit"
                              href={mailto}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Envoyer mail">
                              {`${supplier.email.substring(0, 16)}...`}
                            </a>
                          ) : (
                            <span className="color-black">
                              {supplier && supplier.email
                                ? supplier.email
                                : noDataFound}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-1 label ">
                          <i className="bi-telephone me-1"></i>
                        </div>
                        <div className="col-10">
                          {supplier && supplier.phone ? (
                            <a
                              className="color-msit"
                              href={tel}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Appeler">
                              {supplier.phone}
                            </a>
                          ) : (
                            <span className="color-black">{noDataFound}</span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-1 label">
                          <i className="bi bi-geo-alt  me-1"></i>
                        </div>
                        <div className="col-10">
                          {supplier && supplier.address
                            ? supplier.address
                            : noDataFound}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-1 label">
                          <i className=" bi bi-briefcase me-1"></i>
                        </div>
                        <div className="col-10">
                          {supplier != null && supplier.job} (
                          {supplier.experience}
                          ans)
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-1">
                          <i className=" bi bi-gift"></i>
                        </div>
                        <div className="col-9">
                          {supplier != null && supplier.birthday ? (
                            <div>
                              {supplier.birthday} ({supplier.birthPlace})
                            </div>
                          ) : (
                            <span className="">{noDataFound}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-sm-12">
                <fieldset className="border_supplier_details p-3">
                  <legend className="float-none w-auto p-2">
                    <h6 className="f-w-600 color-msit">Informations</h6>
                  </legend>
                  <div className="">
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">Expérience</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null && supplier.experience}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">Salaire</p>
                        <h6 className="text-muted f-w-400">
                          {supplier && supplier.salary
                            ? supplier.salary
                            : noDataFound}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">
                          type de contrat
                        </p>
                        <h6 className="text-muted f-w-400">
                          {supplier && supplier.typeOfContract
                            ? supplier.typeOfContract
                            : noDataFound}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">type</p>
                        <h6 className="text-muted f-w-400">
                          {supplier != null &&
                          supplier.type &&
                          supplier.type == "supplier"
                            ? "fournisseur"
                            : ""}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">
                          Date de début
                        </p>
                        <h6 className="text-muted f-w-400">
                          {supplier && supplier.startDate
                            ? supplier.startDate
                            : noDataFound}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">NIRPP</p>
                        <h6 className="text-muted f-w-400">
                          {supplier && supplier.nirpp
                            ? supplier.nirpp
                            : noDataFound}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">ville</p>
                        <h6 className="text-muted f-w-400">
                          {supplier && supplier.city?.name
                            ? supplier.city?.name
                            : noDataFound}
                        </h6>
                      </div>
                      <div className="col-sm-4">
                        <p className="m-b-10 fw-bold color-msit">Code postal</p>
                        <h6 className="text-muted f-w-400">
                          {supplier && supplier.cp ? supplier.cp : noDataFound}
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <p className="m-b-10 fw-bold color-msit">Langues</p>
                        {supplier?.langues &&
                        supplier.langues.split(",").length > 0 ? (
                          <p className="small">
                            {supplier.langues.split(",").map((lang, key) => (
                              <span
                                key={key}
                                className="badge bg-secondary me-2">
                                {lang.trim()}
                              </span>
                            ))}
                          </p>
                        ) : (
                          <h6 className="text-muted f-w-400">{noDataFound}</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pagetitle pt-5">
        <h1>Les Mission</h1>
        <div className="row">
          <div className="col-md-8"></div>
          {hasCvConsultPermission &&
            hasMissionsConsultPermission &&
            hasMissionsEmployeeAffectPermission && (
              <div className="col-md-4">
                <button
                  type="button"
                  className="btn btn-primary btn-rounded float-end text-sm"
                  onClick={() => affectMissionToEmployee()}>
                  <i className="bi bi-plus-lg"></i> Ajouter une mission
                </button>
              </div>
            )}
        </div>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-end">
                <div className="col-md-3"></div>
              </div>
              <div className="card-body  pt-3">{missionData}</div>
            </div>
          </div>
          {addMissionModal}
          {affectMissionModal}
        </div>
      </section>
    </div>
  );
};

export default SupplierDetails;
