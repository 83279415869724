import React, { useContext, useState } from "react";
import { SupplierContext } from "../../pages/supplier/CreateSupplier";
import SupplierFormStep2 from "./SupplierFormStep2";
import { useHistory } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const initFormErrors = {
  lname: [""],
  fname: [""],
  email: [""],
  phone: [""],
  birthDay: [""],
  lieu_naissance: [""],
  avatar: [""],
};

const SupplierFormStep1 = ({ setChildComponent }) => {
  const history = useHistory();
  const msg = "Ce champ est obligatoire";
  const msg1 = "Saisissez une valeur valide ";
  const regex =
    /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newSupplier, setNewSupplier } = useContext(SupplierContext);

  const uploadedImage = React.useRef(null);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);

    if (newSupplier.gender === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        gender: msg,
      }));
    }

    if (newSupplier.lname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        lname: msg,
      }));
    } else if (newSupplier.lname.length < 3) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        lname: msg1,
      }));
    }

    if (newSupplier.fname === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        fname: msg,
      }));
    } else if (newSupplier.fname.length < 3) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        fname: msg1,
      }));
    }

    if (newSupplier.email !== "") {
      if (regex.test(newSupplier.email) === false) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          email: msg1,
        }));
      }
    }

    if (error === false) {
      setChildComponent(
        <SupplierFormStep2 setChildComponent={setChildComponent} />
      );
    }
  }
  return (
    <div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3" id="form">
            <div className="col-md-6">
              <label>Vous étes :</label>
              <div className="row pt-1">
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="male"
                      onChange={(e) => {
                        setNewSupplier((prevState) => ({
                          ...prevState,
                          gender: e.target.value,
                        }));
                      }}
                      checked={newSupplier?.gender === "male"}
                    />
                    <label className="form-check-label">Homme</label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="femele"
                      onChange={(e) => {
                        setNewSupplier((prevState) => ({
                          ...prevState,
                          gender: e.target.value,
                        }));
                      }}
                      checked={newSupplier?.gender === "femele"}
                    />
                    <label className="form-check-label">Femme</label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="myCheck"
                      value="other"
                      onChange={(e) => {
                        setNewSupplier((prevState) => ({
                          ...prevState,
                          gender: e.target.value,
                        }));
                      }}
                      checked={newSupplier?.gender === "other"}
                    />

                    <label className="form-check-label">Autre</label>
                  </div>
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage.gender}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Nom *</label>
              <input
                name="name"
                type="text"
                value={newSupplier.lname}
                onChange={(e) => {
                  setNewSupplier((prevState) => ({
                    ...prevState,
                    lname: e.target.value,
                  }));
                }}
                className="form-control"
                required
              />
              <div className="input-error">
                <span className="text-danger"> {errorMessage.lname}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Prénom *</label>
              <input
                type="text"
                value={newSupplier.fname}
                onChange={(e) => {
                  setNewSupplier((prevState) => ({
                    ...prevState,
                    fname: e.target.value,
                  }));
                }}
                className="form-control"
                required
              />
              <div className="input-error">
                <span className="text-danger"> {errorMessage.fname}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Email </label>
              <input
                type="email"
                value={newSupplier.email}
                onChange={(e) => {
                  setNewSupplier((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
                className="form-control"
                required
              />
              <div className="input-error">
                <span className="text-danger"> {errorMessage.email}</span>
              </div>
            </div>
            <div className="col-md-6">
              <label>Téléphone </label>
              <PhoneInput
                inputClass="phoneInput"
                className="phoneInput"
                placeholder="Téléphone"
                value={newSupplier.phone}
                onChange={(value, country, event) =>
                  setNewSupplier((prevState) => ({
                    ...prevState,
                    phone: event.target.value ? event.target.value : value,
                  }))
                }
              />
              <div className="input-error">
                <span className="text-danger"> {errorMessage.phone}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box">
                <div className="date-picker">
                  <label>Date de naissance </label>
                  <input
                    value={newSupplier.birthDay}
                    className="form-control"
                    onChange={(e) => {
                      setNewSupplier((prevState) => ({
                        ...prevState,
                        birthDay: e.target.value,
                      }));
                    }}
                    type="date"
                    id="datePicker"
                    required
                  />
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger"> {errorMessage.birthDay}</span>
              </div>
            </div>

            <div className="col-md-6">
              <label>Lieu de naissance </label>
              <input
                type="text"
                value={newSupplier.birthPlace}
                onChange={(e) => {
                  setNewSupplier((prevState) => ({
                    ...prevState,
                    birthPlace: e.target.value,
                  }));
                }}
                className="form-control"
                required
              />
              <div className="input-error">
                <span className="text-danger"> {errorMessage.birthPlace}</span>
              </div>
            </div>
            <div className="col-md-6">
              <span>Upload Avatar </span>
              <div className="custom-file">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  multiple="false"
                  className="form-control"
                  id="inputGroupFile01"
                  required
                  onChange={(e) => {
                    handleImageUpload(e);
                    setNewSupplier((prevState) => ({
                      ...prevState,
                      avatar: e.target.files[0],
                    }));
                  }}
                />
                <div className="input-error">
                  <span className="text-danger"> {errorMessage.avatar}</span>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <img
                alt=""
                ref={uploadedImage}
                style={{
                  weight: "30%",
                  width: "40%",
                }}
              />
            </div>
            <div className="text-end ">
              <button
                type="button"
                className="btn btn-outline-secondary  me-2"
                onClick={() => history.goBack()}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={validate}
              >
                Continuer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SupplierFormStep1;
