import { React, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";
export const InvoiceDetails = () => {
  const { invoiceId } = useParams();
  const [data, setdata] = useState([]);
  const [invoice_num, setInvoice_num] = useState("");
  const [invoice_date, setInvoice_date] = useState("");
  const [due_date, setDue_date] = useState("");
  const [invoice_total, setInvoice_total] = useState("");
  const [tax_total, setTax_total] = useState("");
  const [note, setNote] = useState("");
  const [total_HT, setTotal_HT] = useState("");
  const [client_name, setClient_name] = useState("");
  const [client_email, setClient_email] = useState("");
  const [client_adress, setClient_adress] = useState("");
  const [seller_name, setSeller_name] = useState("");
  const [seller_email, setSeller_email] = useState("");
  function getprod() {
    axios
      .get(`${baseURL}/invoices/getproductbyinvoice/${invoiceId}`)

      .then((res) => {
        setdata(res.data.data);
      });
  }

  function get() {
    axios
      .get(`${baseURL}/invoices/getinvoicebyid/${invoiceId}`)
      .then((res) => {
        setNote(res.data.data.invoice.note);
        setInvoice_num(res.data.data.invoice.invoice_num);
        setClient_name(res.data.data.invoice.client.client_name);
        setClient_email(res.data.data.invoice.client.client_email);
        setClient_adress(res.data.data.invoice.client.client_adress);
        setInvoice_date(res.data.data.invoice.invoice_date);
        setDue_date(res.data.data.invoice.due_date);
        setSeller_name(res.data.data.invoice.seller.seller_name);
        setSeller_email(res.data.data.invoice.seller.seller_email);
        setInvoice_total(res.data.data.invoice.invoice_total);
        setTax_total(res.data.data.invoice.tax_total);
        setTotal_HT(res.data.data.invoice.total_HT);
      })
       .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    get();
    getprod();
  }, []);

  return (
    <div className="row">
      <div className="col-9 row">
        <div className="page-content container col-md-8 col-lg-9">
          <div className="container px-0">
            <div className="row mt-4">
              <div className="invoice card p-4 p-xl-5 my-3 my-xl-4 ">
                <div className=" card-header d-flex justify-content-center textcolorlikenav">
                  <h1 className=" fw-bold pt-0 textcolorlikenav">FACTURE</h1>
                </div>
                <div className="col-12 col-lg-10 offset-lg-1">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-center text-150">
                        <i className="fa fa-book fa-2x text-success-m2 mr-1"></i>
                      </div>
                    </div>
                  </div>

                  <hr className="row brc-default-l1 mx-n1 mb-4" />

                  <div className="row">
                    <div className="col-sm-6 mb-5 mt-5">
                      <div>
                        <span
                          className="text-600 text-90 text-black"
                          type="text"
                        >
                          Facture à:
                        </span>
                        <span className="text-600 text-90" type="text">
                          {client_name ? client_name : null}
                        </span>
                      </div>
                      <div>
                        <span
                          className="text-600 text-90 text-black"
                          type="text"
                        >
                          Email client:
                        </span>
                        <span className="text-600 text-110 text-black align-middle">
                          {client_email ? client_email : null}
                        </span>
                      </div>
                      <div>
                        <span
                          className="text-600 text-90 text-black"
                          type="text"
                        >
                          Adresse du facturation:
                        </span>
                        <span className="text-600 text-90" type="text">
                          {client_adress ? client_adress : null}
                        </span>
                      </div>
                      <div>
                        <span
                          className="text-600 text-90 text-black"
                          type="text"
                        >
                          Facture de:
                        </span>
                        <span className="text-600 text-90" type="text">
                          {seller_name ? seller_name : null}
                        </span>
                      </div>
                      <div>
                        <span
                          className="text-600 text-90 text-black"
                          type="text"
                        >
                          Email vendeur:
                        </span>
                        <span className="text-600 text-90" type="text">
                          {seller_email ? seller_email : null}
                        </span>
                      </div>
                    </div>

                    <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end mb-5 mt-5">
                      <hr className="d-sm-none" />
                      <div className="text-grey-m2">
                        <div className="my-2 text-black">
                          <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                          <span className="text-600 text-90" type="text">
                            Numéro du facture:
                          </span>{" "}
                          {invoice_num ? invoice_num : null}
                        </div>

                        <div className="my-2">
                          <span
                            className="text-600 text-90 text-black"
                            type="text"
                          >
                            Date De la facture:
                          </span>
                          {invoice_date ? invoice_date : null}
                        </div>

                        <div className="my-2">
                          <span
                            className="text-600 text-90 text-black"
                            type="text"
                          >
                            Date Limite:
                          </span>
                          {due_date ? due_date : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5">
                    <div className="row text-600 text-white bgc-default-tp1 py-25">
                      <div className="d-none d-sm-block col-1">#</div>
                      <div className="col-9 col-sm-5">Description</div>
                      <div className="d-none d-sm-block col-4 col-sm-2">
                        Quantité
                      </div>
                      <div className="d-none d-sm-block col-sm-2">
                        Prix Unitaire
                      </div>
                      <div className="col-2">Total</div>
                    </div>

                    {data?.map((item) => (
                      <div className="text-95 text-secondary-d3 ">
                        <div className="row mb-2 mb-sm-0 py-25">
                          <div className="d-none d-sm-block col-1">1</div>
                          <div className="col-9 col-sm-5">
                            {" "}
                            {item.product_name}{" "}
                          </div>
                          <div className="d-none d-sm-block col-2">
                            {item.product_quantity}{" "}
                          </div>
                          <div className="d-none d-sm-block col-2 text-95">
                            € {item.product_unit_price}{" "}
                          </div>
                          <div className="col-2 text-secondary-d2">
                            € {item.product_total}{" "}
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}

                    <div className="row border-b-2 brc-default-l2"></div>

                    <div className="row mt-3">
                      <div className="col-lg-9 text-grey-d2 text-95 mt-5  ">
                        <a className=" fw-bold a2"> Note : </a>
                        <span className="text-100 text-secondary-d1 ms-3">
                          {note ? note : null}
                        </span>
                      </div>
                      <div className="col-lg-3 text-right  mt-5">
                        <div>
                          <a className=" fw-bold a2">Total HT : </a>
                          <span className="text-120 text-secondary-d1 ms-3 ">
                            € {total_HT ? total_HT : null}
                          </span>
                        </div>
                        <div>
                          {" "}
                          <a className=" fw-bolder a2">Tva : </a>
                          <span className="text-110 text-secondary-d1 ms-3">
                            {tax_total ? tax_total : null}
                          </span>
                        </div>
                        <div className="mt-5">
                          {" "}
                          <a className=" fw-bolder a2">Total TTC: </a>
                          <span className="text-100 text-secondary-d1 ms-3">
                            € {invoice_total ? invoice_total : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-3 pt-4 mt-4  px-1 px-6">
        <div className="fact card " style={{ width: "120%", height: "60px" }}>
          <div className="my-4 ">
            <h3 className="text-center fw-bold" style={{ color: "#002b58" }}>
              Actions
            </h3>
          </div>
        </div>
        <div className="fact card  " style={{ width: "120%" }}>
          <div className="d-flex justify-centent-center flex-column align-items-center p-6">
            {/* <div>
              <PrintInvoice />
            </div> */}
            <div>
              <button
                href="#"
                className="button  btn btn-primary profile-button"
                // onClick={() =>
                //   setHistoryModal(
                //     <GetHistoryModal
                //       setHistoryModal={setHistoryModal}
                //       Id={invoice_id}
                //     />
                //   )
                // }
              >
                Historique
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
