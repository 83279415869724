//server esn
// export const baseURL = "https://esn-back.msit-cloud.fr";
// export const localUrl = "https://esn.msit-cloud.fr";
//server
export const baseURL = "https://dash-back.msit-cloud.fr";
export const localUrl = "https://dashboard.msit-cloud.fr";
//local
// export const baseURL = "http://localhost:5000";
// export const localUrl = "http://localhost:3000";
export const csvPath = baseURL + "/public/consultants-csv-template.csv";
export const csvCompanyPath = baseURL + "/public/company-csv-template.csv";
export const extractorLink = "http://127.0.0.1:8000/extracting/getData/";
