import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import Select from "react-select";
import { Link } from "react-router-dom";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import Loader from "../../components/loader";
const initFormErrors = {
  interviewer: [""],
  errMsg: "Veuillez sélectionner un intervieweur.",
};
const initRdvFormErrors = {
  time: [""],
  date: [""],
  description: [""],
};
function ConsultantMissionDetails({
  consultantId,
  mission,
  consultantDetails,
  stepName,
  stepIndex,
  getEmployeeByMissionId,
  setSelectedConsultant,
  setSelectedColumn,
}) {
  const { user } = useContext(UserContext);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState("");
  const [formerrors, setFormErrors] = useState("");
  const [rdvformerrors, setRdvFormErrors] = useState("");
  const [statusProcess, setStatusProcess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState("");
  const [description, setDescription] = useState();
  const [type, setType] = useState("");
  const [selectedRdv, setSelectedRdv] = useState("");
  const [listRdv, setlistRdv] = useState([]);
  const today = new Date().toISOString().substr(0, 10);
  const hasCandidatsProcessDisaffectPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "disaffect_candidat_step"
  );
  const hasCandidatsProcessNotePermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "create_note"
  );
  const hasCandidatsProcessNoteConsultPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "consulter_note"
  );
  const hasCandidatsProcessAffectPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "affecter_interviewer"
  );
  const hasConsultReviewerPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "consulter_interviewer"
  );
  const noDataFound = "Aucune donnée trouvée";
  const offcanvasCreateRdvRef = useRef();
  const offcanvasConsulterRdvRef = useRef();
  const offcanvasUpdateRdvRef = useRef();
  const ouvriroffcanvasCreateRdv = () => {
    const offcanvasCreateRdv = new window.bootstrap.Offcanvas(
      offcanvasCreateRdvRef.current
    );
    offcanvasCreateRdv.show();
  };
  const ouvriroffcanvasConsulterRdv = () => {
    getRdv();
    const offcanvasConsulterRdv = new window.bootstrap.Offcanvas(
      offcanvasConsulterRdvRef.current
    );
    offcanvasConsulterRdv.show();
  };
  const ouvriroffcanvasUpdateRdv = () => {
    getRdv();
    const offcanvasUpdateRdv = new window.bootstrap.Offcanvas(
      offcanvasUpdateRdvRef.current
    );
    offcanvasUpdateRdv.show();
  };
  async function fetchData() {
    setNote("");
    setNotes("");
    await axios
      .get(`${baseURL}/missionsConsultants/getNotes/${consultantId}/${mission}`)
      .then((resp) => {
        if (resp.data.error) return;
        setNotes(resp.data.notes);
      })
      .catch(() => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (
      selectedRdv !== null &&
      selectedRdv !== undefined &&
      selectedRdv !== ""
    ) {
      getRdvDetails();
    }
  }, [selectedRdv]);
  async function getRdvDetails() {
    await axios
      .get(`${baseURL}/rdv/getbyId/${selectedRdv}`)
      .then((resp) => {
        if (resp.data.error) return;
        setDate(resp.data.data.date);
        setTime(resp.data.data.time);
        setDescription(resp.data.data.description);
        setType(resp.data.data.type);
      })
      .catch(() => {
        DisplayError();
      });
  }
  function disaffectMissionProcess(consultantId) {
    swal({
      title: "êtes vous sûr de supprimer ce candidat?",
      text: "Une fois supprimé, vous ne pourrez pas récupérer ce candidat!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .post(`${baseURL}/missionsConsultants/disaffect`, {
            consultant: consultantId,
            mission: mission,
          })
          .then((resp) => {
            if (resp.data.error) return;
            swal("Process mission supprimé avec succès !", {
              icon: "success",
              timer: 2000,
            });
            const boutonOffcanvas = document.getElementById(
              "annulerButtonDetails"
            );
            boutonOffcanvas.click();
            setSelectedConsultant("");
            setSelectedColumn("");
            getEmployeeByMissionId();
          })
          .catch(() => {
            DisplayError();
          });
      }
    });
  }
  useEffect(() => {
    if (
      consultantId === "" ||
      consultantId === undefined ||
      consultantId === null
    ) {
      return;
    }
    // if (hasCandidatsProcessNoteConsultPermission) {
    fetchData();
    // }
    // }, [hasCandidatsProcessNoteConsultPermission]);
  }, [consultantId]);
  const tel = `tel:+${consultantDetails?.phone}`;
  const mailto = `mailto:${consultantDetails?.email}`;
  function sendData() {
    let newIndex = stepIndex ?? stepName;

    if (stepName === "Non Affectés") {
      newIndex = "notAffected";
    }
    if (stepName === "Affectés") {
      newIndex = "affected";
    }
    if (stepName === "Rejetés") {
      newIndex = "rejected";
    }
    axios
      .post(`${baseURL}/missionsConsultants/note/`, {
        consultant: consultantId,
        note: note,
        step: newIndex,
        stepName: stepName,
      })
      .then((resp) => {
        if (resp.data.error) return;
        fetchData();
        Toastify({
          text: resp.data.message,
          duration: 2000,
          close: true,
          gravity: "bottom",
          position: "right",
          stopOnFocus: true,
          style: {
            background: "linear-gradient(to right, #0f8499 , #0f8499 )",
          },
        }).showToast();
      })
      .catch((error) => {
        setFormErrors(error.response.data.message);
        DisplayError();
      });
  }
  async function createRdv() {
    setIsLoading(true);
    await axios
      .post(`${baseURL}/rdv/create`, {
        mission: mission ?? "",
        consultant: consultantId ?? "",
        date: date ?? "",
        time: time ?? "",
        description: description ?? "",
        stepName: stepName,
        stepIndex: stepIndex,
        type: type,
      })
      .then((resp) => {
        setRdvFormErrors(initRdvFormErrors);
        setIsLoading(false);
        getRdv();
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        const offcanvasButton = document.getElementById("cancelCreateRdv");
        offcanvasButton.click();
      })
      .catch((error) => {
        setRdvFormErrors(error.response.data);
        setIsLoading(false);
      });
  }
  async function toggleToDelete(selectedRdvId) {
    await axios
      .get(`${baseURL}/rdv/toggleDelete/${selectedRdvId}`)
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getRdv();
      })
      .catch((error) => {
        DisplayError();
      });
  }
  async function updateRdv() {
    setIsLoading(true);
    await axios
      .put(`${baseURL}/rdv/update/${selectedRdv}`, {
        date: date ?? "",
        time: time ?? "",
        description: description ?? "",
        type: type,
      })
      .then((resp) => {
        setRdvFormErrors(initRdvFormErrors);
        setIsLoading(false);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getRdv();
        const offcanvasButton = document.getElementById("annulerUpdateRdv");
        offcanvasButton.click();
      })
      .catch((error) => {
        setRdvFormErrors(error.response.data);
        setIsLoading(false);
      });
  }
  async function getRdv() {
    await axios
      .post(`${baseURL}/rdv/getbystep`, {
        mission: mission,
        consultant: consultantId,
        stepName: stepName,
        stepIndex: stepIndex,
      })
      .then((resp) => {
        setlistRdv(resp.data.data);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  const [interviewers, setInterviewers] = useState([]);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [initialSelectedInterviewerEmail, setInitialSelectedInterviewerEmail] =
    useState("");
  const [selectedInterviewerValue, setSelectedInterviewerValue] = useState("");

  const [selectedInterviewer, setSelectedInterviewer] = useState("");

  const handleInterviewerChange = (selectedOption) => {
    setSelectedInterviewer(selectedOption);
    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      interviewer: "",
    }));
  };

  const AffecteInterviewer = () => {
    let newIndex = stepIndex;

    if (stepName === "Non Affectés") {
      newIndex = "notAffected";
    }
    if (stepName === "Affectés") {
      newIndex = "affected";
    }
    if (stepName === "Rejetés") {
      newIndex = "rejected";
    }
    if (selectedInterviewer?.value === initialSelectedInterviewerEmail) {
      setErrorMessage((prevErrors) => ({
        ...prevErrors,
        interviewer: initFormErrors.errMsg,
      }));
    } else {
      axios
        .post(`${baseURL}/missionsConsultants/affectInterviewer`, {
          consultant: consultantId,
          interviewer: selectedInterviewer?.value,
          mission: mission,
          step: newIndex,
        })
        .then((resp) => {
          setErrorMessage(initFormErrors);
          if (resp.data.error) return;
          swal(resp.data.message, {
            icon: "success",
            timer: 2500,
          });
          fetchReviewer();
          const newSelectedInterviewerLabel = interviewers.find(
            (option) => option.value === selectedInterviewer.value
          )?.label;
          setSelectedInterviewerValue(newSelectedInterviewerLabel);
          setSelectedInterviewer("");
        })
        .catch((error) => {
          setErrorMessage(error.response.data);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/users/allInterviewrs`)
      .then((response) => {
        const options = response.data.data.map((interviewer) => ({
          value: interviewer.email,
          label: interviewer.name,
        }));
        setInterviewers(options);
      })
      .catch((error) => {
        console.error("une erruere est survenue:", error);
      });
  }, []);
  const fetchReviewer = () => {
    let stepReviewer = stepIndex ?? null;
    if (stepName === "Non Affectés") {
      stepReviewer = "notAffected";
    }
    if (stepName === "Affectés") {
      stepReviewer = "affected";
    }
    if (stepName === "Rejetés") {
      stepReviewer = "rejected";
    }
    axios
      .get(
        `${baseURL}/missionsConsultants/getReviewer/${consultantId}/${mission}/${stepReviewer}`
      )
      .then((resp) => {
        if (resp.data.error) return;
        const interviewerEmail = resp.data.interviewer?.email;
        const candidatProcess = resp.data.status;
        setStatusProcess(candidatProcess);
        setSelectedInterviewerValue(resp.data.interviewer?.name);
        setInitialSelectedInterviewerEmail(interviewerEmail);
        setSelectedInterviewer(interviewerEmail);
      })
      .catch((error) => {
        DisplayError();
      });
  };
  useEffect(() => {
    if (
      consultantId === null ||
      consultantId === undefined ||
      consultantId === "" ||
      stepIndex === null ||
      stepIndex === undefined ||
      stepIndex === "" ||
      stepName === null ||
      stepName === undefined ||
      stepName === ""
    ) {
      return;
    }
    if (hasConsultReviewerPermission) {
      fetchReviewer();
    }
  }, [stepName, stepIndex, consultantId, hasConsultReviewerPermission]);
  const handleValidation = (status) => {
    let newIndex = stepIndex;

    if (stepName === "Non Affectés") {
      newIndex = "notAffected";
    }
    if (stepName === "Affectés") {
      newIndex = "affected";
    }
    if (stepName === "Rejetés") {
      newIndex = "rejected";
    }
    const requestBody = {
      consultant: consultantId,
      mission: mission,
      status: status,
      step: newIndex,
    };
    axios
      .post(
        `${baseURL}/missionsConsultants/validateMissionsConsultants`,
        requestBody
      )
      .then((resp) => {
        setErrorMessage(initFormErrors);
        fetchReviewer();
        if (resp.data.error) {
          return;
        }
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
      })
      .catch((error) => {
        setErrorMessage(error.response.data);
      });
  };
  const notesByStepName = {
    "Non Affectés": notes["notAffected"]?.note,
    Affectés: notes["affected"]?.note,
    Rejetés: notes["rejected"]?.note,
  };
  const defaultValue =
    stepIndex && notes[stepIndex]?.note
      ? notes[stepIndex]?.note
      : notesByStepName[stepName] || null;

  return (
    <div
      className="offcanvas offcanvas-lg offcanvas-end offcanvas-body-border"
      tabIndex="-1"
      id="offcanvasDetailsSteps"
      aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header ">
        <h4 id="offcanvasRightLabel fw-bold">Détails de consultant</h4>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setErrorMessage(initFormErrors);
          }}></button>
      </div>
      <div className="offcanvas-body offcanvas-body-scrollable">
        <div className="row">
          <div className="col-md-12">
            <div className="card-candidat">
              <div className="rounded-start-details-consultant candidat-card d-xl-flex flex-colums flex-xl-rows align-items-center text-uppercase">
                <img
                  alt=""
                  src={
                    consultantDetails?.avatar != null
                      ? baseURL + consultantDetails?.avatar.path.substring(1)
                      : baseURL + "/public/company-logo/default_logo.jpg"
                  }
                  className="rounded-start-details-consultant NoteCard_img w-100"
                />

                <div className="text-center mx-1">
                  <h2>
                    {consultantDetails?.firstname &&
                      `${consultantDetails?.firstname.substring(0, 15)}${
                        consultantDetails?.firstname.length > 10 ? "..." : ""
                      }`}{" "}
                    {consultantDetails?.lastname &&
                      `${consultantDetails?.lastname.substring(0, 15)}${
                        consultantDetails?.lastname.length > 10 ? "..." : ""
                      }`}
                  </h2>
                  <div className="row mb-4  d-flex align-items-center mx-1">
                    <div className="col-6 d-flex justify-content-center ">
                      <i className="bi-envelope-fill me-1"></i>
                      {consultantDetails?.email ? (
                        <a
                          href={mailto}
                          className="color-msit"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Envoyer mail">
                          {consultantDetails?.email.substring(0, 10)}
                          {consultantDetails?.email.length > 10 ? "..." : ""}
                        </a>
                      ) : (
                        <span className="color-msit">
                          {noDataFound?.length > 15
                            ? `${noDataFound.slice(0, 15)}...`
                            : noDataFound}
                        </span>
                      )}
                    </div>
                    <div
                      className="col-6 d-flex justify-content-center align-items-center"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Appeler">
                      <i className="bi-telephone-fill me-1"></i>
                      {consultantDetails != null && consultantDetails?.phone ? (
                        <span className="color-msit">
                          <a className="color-msit" href={tel}>
                            {consultantDetails?.phone.substring(0, 10)}
                            {consultantDetails?.phone.length > 10 ? "..." : ""}
                          </a>
                        </span>
                      ) : (
                        <span className="color-msit">
                          {noDataFound?.length > 15
                            ? `${noDataFound.slice(0, 15)}...`
                            : noDataFound}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row mb-4  d-flex align-items-center mx-1">
                    <div className="col-6 d-flex justify-content-center align-items-center">
                      <svg
                        className="text-star"
                        width="50"
                        height="50"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.1676 6.00065V4.00065C11.1676 2.52789 9.97366 1.33398 8.5009 1.33398C7.02814 1.33398 5.83423 2.52789 5.83423 4.00065V6.00065M2.89557 6.90196L2.49557 11.1686C2.38183 12.3818 2.32497 12.9883 2.52626 13.4568C2.7031 13.8684 3.01298 14.2087 3.40624 14.4232C3.85391 14.6673 4.46314 14.6673 5.6816 14.6673H11.3202C12.5387 14.6673 13.1479 14.6673 13.5956 14.4232C13.9888 14.2087 14.2987 13.8684 14.4755 13.4568C14.6768 12.9883 14.62 12.3818 14.5062 11.1686L14.1062 6.90196C14.0102 5.87755 13.9622 5.36534 13.7318 4.97809C13.5289 4.63704 13.2291 4.36406 12.8706 4.19388C12.4636 4.00065 11.9491 4.00065 10.9202 4.00065L6.0816 4.00065C5.05269 4.00065 4.53824 4.00065 4.13118 4.19388C3.77267 4.36406 3.4729 4.63704 3.27 4.97809C3.03962 5.36534 2.9916 5.87755 2.89557 6.90196Z"
                          stroke="black"
                          stroke-width="1.07"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {consultantDetails != null &&
                      consultantDetails?.posteType ? (
                        <span className="color-msit">
                          {consultantDetails?.posteType?.name}
                        </span>
                      ) : (
                        <span className="color-msit">
                          {noDataFound?.length > 15
                            ? `${noDataFound.slice(0, 15)}...`
                            : noDataFound}
                        </span>
                      )}
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <Link
                        className="text-nowrap text-white btn btn-primary color-msit "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Details candidat"
                        to={`/candidats/details/${consultantDetails?._id}`}>
                        Voir détails
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center">
              {user?.email === initialSelectedInterviewerEmail ? (
                <div className="row flex-row justify-content-center">
                  <button
                    id="validateButton"
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Supprimer"
                    className="btn btn-outline-success mb-2 col-5 m-2"
                    onClick={() => handleValidation("validated")}>
                    <FaCheck className="me-2" />
                    Valider
                  </button>
                  <button
                    id="rejectButton"
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Supprimer"
                    className="btn btn-outline-danger mb-2 col-5 m-2"
                    onClick={() => handleValidation("rejected")}>
                    <ImCross className="me-2" />
                    Rejeter
                  </button>
                </div>
              ) : null}
              {/* {hasCandidatsProcessDisaffectPermission && ( */}
              <div className="row px-1 pt-2 pb-1">
                <div className="col-lg-6 px-1">
                  <button
                    type="button"
                    onClick={ouvriroffcanvasCreateRdv}
                    ata-bs-toggle="offcanvas"
                    data-bs-target="#ajouterSociete"
                    aria-controls="ajouterSociete"
                    data-placement="top"
                    title="Planifier un Rdv"
                    className="btn btn-primary mb-2 w-100">
                    <svg
                      width="24"
                      className="mx-2"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Planifier un Rdv
                  </button>
                </div>
                <div className="col-lg-6 px-1">
                  <button
                    type="button"
                    onClick={ouvriroffcanvasConsulterRdv}
                    ata-bs-toggle="offcanvas"
                    data-bs-target="#ConsulterSociete"
                    aria-controls="ConsulterSociete"
                    data-placement="top"
                    title="Planifier un Rdv"
                    className="btn btn-primary mb-2 w-100">
                    <svg
                      width="24"
                      className="mx-2"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 6V12L16 14M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Consulter les Rdvs
                  </button>
                </div>
              </div>
              <div className="row px-2 pt-2 pb-1">
                <button
                  type="button"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Supprimer"
                  className="btn btn-danger mb-2"
                  onClick={() => {
                    disaffectMissionProcess(consultantDetails?._id);
                  }}>
                  <i className="mx-1 bi bi-trash"></i> Désaffecter du process
                </button>
              </div>

              {/* )} */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="modal-header justify-content-center row">
              <div className="col-lg-7 d-flex align-items-center">
                <h3 className="text-align-center ">
                  Etape Actuellle :
                  {stepName?.length > 17
                    ? `${stepName?.substring(0, 17)}...`
                    : stepName ?? ""}
                </h3>
                {statusProcess === "rejected" && (
                  <div className=" ms-2">
                    <div class="wrapper">
                      <svg class="checkmark" viewBox="0 0 52 52">
                        <circle
                          class="checkmark_circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />
                        <path
                          class="checkmark_check"
                          fill="none"
                          d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {statusProcess === "validated" && (
                  <div class="ms-2">
                    <svg
                      width="60px"
                      height="60px"
                      viewBox="0 0 133 133"
                      version="1.1">
                      <g
                        id="check-group"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd">
                        <circle
                          id="filled-circle"
                          fill="#78B348"
                          cx="66.5"
                          cy="66.5"
                          r="54.5"></circle>
                        <circle
                          id="white-circle"
                          fill="#FFFFFF"
                          cx="66.5"
                          cy="66.5"
                          r="55.5"></circle>
                        <circle
                          id="outline"
                          stroke="#78B348"
                          stroke-width="4"
                          cx="66.5"
                          cy="66.5"
                          r="54.5"></circle>
                        <polyline
                          id="check"
                          stroke="#FFFFFF"
                          stroke-width="4"
                          points="41 70 56 85 92 49"></polyline>
                      </g>
                    </svg>
                  </div>
                )}
                {statusProcess === "waiting" && (
                  <div class="clock ms-2">
                    <div class="cup top">
                      <div class="sand"></div>
                    </div>
                    <div class="cup">
                      <div class="sand delayed"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* {hasCandidatsProcessAffectPermission &&
                    hasConsultReviewerPermission && ( */}
            <>
              <div className="d-flex mt-2">
                <p>Interviewer: {selectedInterviewerValue}</p>
              </div>
              <div className="col-md-12 mb-3">
                <Select
                  id="interviewerSelect"
                  options={interviewers}
                  onChange={handleInterviewerChange}
                  placeholder="Sélectionner un Interviewer"
                  value={selectedInterviewer}
                />
                <div className="input-error m-1">
                  <span className="text-danger">
                    {errorMessage.interviewer}
                  </span>
                </div>
              </div>
              <div className="row px-2 pt-2 pb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={AffecteInterviewer}>
                  Affecter
                </button>
              </div>
            </>
            {/* )} */}
            {/* {hasCandidatsProcessNotePermission &&
                    hasCandidatsProcessNoteConsultPermission && notes && ( */}
            <>
              <div className="d-flex">
                <p> Note :</p>
              </div>

              <textarea
                type="text"
                className="form-control mb-3 "
                id="role_name"
                defaultValue={defaultValue}
                placeholder="veuillez saisir une note"
                onChange={(e) => setNote(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger">{formerrors}</span>
              </div>
              <div className="row px-2 pt-2 pb-1">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendData}>
                  Enregistrer
                </button>
              </div>
            </>
            {/* )} */}
            {/* {hasCandidatsProcessNoteConsultPermission && ( */}
            <div className="mt-2">
              <h3 className="justify-content-center d-flex">
                Notes des autres etapes
              </h3>
              <div className="Notes_display">
                {notes.length !== ""
                  ? Object.keys(notes).map((displayStep) => {
                      return (
                        <div
                          key={displayStep}
                          className="card user-card1-full NoteCard  ">
                          <div className="card-body">
                            <label className="color-msit">
                              Etape {notes[displayStep].stepName} :
                            </label>
                            <p>{notes[displayStep].note}</p>
                          </div>
                        </div>
                      );
                    })
                  : "Aucune note"}
              </div>
            </div>
            {/* )} */}
            {/* ofCanvas pour ajouter un rdv */}
            <div
              className="offcanvas offcanvas-end offcanvas-body-border"
              tabIndex="-1"
              id="offcanvasCreateRdv"
              aria-labelledby="offcanvasCreateRdvLabel"
              ref={offcanvasCreateRdvRef}>
              <div className="offcanvas-header ">
                <h4 id="offcanvasCreateRdv fw-bold">Ajouter un Rendez-vous</h4>
                <button
                  id="CloseCreateCompany"
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="CloseCreateCompany"></button>
              </div>
              <div className="offcanvas-body offcanvas-body-scrollable">
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Type : *</label>
                  <select
                    id="companyId"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    className="form-select">
                    <option value="">Sélectionner un type</option>
                    <option value="client">Presentation Client</option>
                    <option value="esn">Presentation ESN</option>
                  </select>
                </div>
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Date: *</label>
                  <input
                    type="date"
                    className="form-control offcanvas-input"
                    onChange={(e) => setDate(e.target.value)}
                    required
                    id="date"
                    placeholder="Date"
                  />

                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {rdvformerrors.date}
                    </span>
                  </div>
                </div>
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Temps: *</label>
                  <input
                    type="time"
                    defaultValue={time}
                    className="form-control offcanvas-input"
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    required
                    id="time"
                    placeholder="time"
                    min={today}
                  />

                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {rdvformerrors.time}
                    </span>
                  </div>
                </div>
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Description: *</label>
                  <input
                    className="form-control offcanvas-input"
                    type="text"
                    id="description"
                    placeholder="Description"
                    rows="4"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />

                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {rdvformerrors.description}
                    </span>
                  </div>
                </div>
                <div className="row px-2 pt-2 pb-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={createRdv}
                    disabled={isLoading}>
                    {isLoading ? <Loader /> : "Ajouter"}
                  </button>
                </div>
                <div className="row px-2 pt-1 pb-2">
                  <button
                    id="cancelCreateRdv"
                    type="button"
                    className="btn but-restore"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close">
                    Annuler
                  </button>
                </div>
              </div>
            </div>
            {/* ofCanvas pour Liste des rdv */}
            <div
              className="offcanvas offcanvas-end offcanvas-body-border"
              tabIndex="-2"
              id="offcanvasConsulterRdv"
              aria-labelledby="offcanvasConsulterRdvLabel"
              ref={offcanvasConsulterRdvRef}>
              <div className="offcanvas-header ">
                <h4 id="offcanvasConsulterRdv fw-bold">
                  Liste des Rendez-vous
                </h4>
                <button
                  id="CloseConsultRdv"
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="CloseConsultRdv"></button>
              </div>
              <div className="offcanvas-body offcanvas-body-scrollable">
                {listRdv.length > 0
                  ? listRdv?.map((item) => {
                      return (
                        <div className="row px-2 my-2 consultant-card h-auto flex-row ">
                          <div className="consultant-first-titles-name mt-0 mt-3 mb-0 consultant-first-titles">
                            <div className="row">
                              <div className="col-6">{item.title}</div>
                              <div className="col-6 text-end">
                                <button
                                  onClick={() => {
                                    ouvriroffcanvasUpdateRdv();
                                    setSelectedRdv(item._id);
                                  }}
                                  ata-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasUpdateRdv"
                                  aria-controls="offcanvasUpdateRdv"
                                  className="mx-1 btn btn-outline-primary border-0">
                                  <i class="mx-1 bi bi-pencil-square"></i>
                                </button>
                                <button
                                  onClick={() => {
                                    toggleToDelete(item._id);
                                  }}
                                  className="mx-1 btn btn-outline-danger border-0">
                                  <i className="mx-1 bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                              <div>Type de presentation :</div>
                              <div>{item?.type.toUpperCase()}</div>
                            </div>
                          </div>
                          <div>
                            <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                              <div>Consultants :</div>
                              <div className="d-flex">
                                <img
                                  src={
                                    item?.consultant?.avatar !== null
                                      ? baseURL +
                                        item?.consultant?.avatar?.path?.substring(
                                          1
                                        )
                                      : baseURL +
                                        "/public/company-logo/default_logo.jpg"
                                  }
                                  className="rounded-circle mx-1"
                                  width="35"
                                  height="35"
                                  alt=""
                                />
                                <div className="d-block">
                                  <p>
                                    {item.consultant.firstname}{" "}
                                    {item.consultant.lastname}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                              <div>Societe :</div>
                              <div className="d-flex">
                                <img
                                  src={
                                    item?.company?.logo !== null
                                      ? baseURL +
                                        item?.company?.logo?.path?.substring(1)
                                      : baseURL +
                                        "/public/company-logo/default_logo.jpg"
                                  }
                                  className="rounded-circle mx-1"
                                  width="35"
                                  height="35"
                                  alt=""
                                />
                                <div className="d-block">
                                  <p>{item.company.name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item?.client ? (
                            <div>
                              <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                                <div>Client Finale :</div>
                                <div className="d-flex">
                                  <img
                                    src={
                                      item?.client?.logo !== null
                                        ? baseURL +
                                          item?.client?.logo?.path?.substring(1)
                                        : baseURL +
                                          "/public/company-logo/default_logo.jpg"
                                    }
                                    className="rounded-circle mx-1"
                                    width="35"
                                    height="35"
                                    alt=""
                                  />
                                  <div className="d-block">
                                    <p>{item?.client.name}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div className="col-6">
                              <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                                <span className="ms-2">Date :</span>
                              </div>
                              <div className="">{item.date}</div>
                            </div>
                            <div className="col-6">
                              <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                                <span className="ms-2">Temps :</span>
                              </div>
                              <div className="">{item.time}</div>
                            </div>
                          </div>
                          <div>
                            <div className="mt-0 mt-3 mb-0 consultant-first-titles">
                              <span className="ms-2">Description :</span>
                            </div>
                            <div className="">{item.description}</div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <div className="row mt-2 d-flex justify-content-center ">
                  <button
                    type="button"
                    id="annulerConsultRdv"
                    className="btn btn-outline-secondary col-11  mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close">
                    Annuler
                  </button>
                </div>
              </div>
            </div>
            {/* ofCanvas pour modifier un rdv */}
            <div
              className="offcanvas offcanvas-end offcanvas-body-border"
              tabIndex="-3"
              id="offcanvasUpdateRdv"
              aria-labelledby="offcanvasUpdateRdvLabel"
              ref={offcanvasUpdateRdvRef}>
              <div className="offcanvas-header ">
                <h4 id="offcanvasUpdateRdv fw-bold">Modifier un Rendez-vous</h4>
                <button
                  id="CloseUpdateRdv"
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="CloseUpdateRdv"></button>
              </div>
              <div className="offcanvas-body offcanvas-body-scrollable">
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Type : *</label>
                  <select
                    id="companyId"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    className="form-select">
                    <option value="">Sélectionner un type</option>
                    <option value="client">Presentation Client</option>
                    <option value="esn">Presentation ESN</option>
                  </select>
                </div>
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Date: *</label>
                  <input
                    value={date}
                    type="date"
                    className="form-control offcanvas-input"
                    onChange={(e) => setDate(e.target.value)}
                    required
                    id="date"
                    placeholder="Date"
                    min={today}
                  />

                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {rdvformerrors.date}
                    </span>
                  </div>
                </div>
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Temps: *</label>
                  <input
                    type="time"
                    defaultValue={time}
                    className="form-control offcanvas-input"
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    required
                    id="time"
                    placeholder="time"
                    min={today}
                  />
                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {rdvformerrors.time}
                    </span>
                  </div>
                </div>
                <div className="row px-2 my-2">
                  <label className="px-0 mb-2 text-500">Description: *</label>
                  <input
                    defaultValue={description}
                    className="form-control offcanvas-input"
                    type="text"
                    id="description"
                    placeholder="Description"
                    rows="4"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />

                  <div className="input-error">
                    <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                      {rdvformerrors.description}
                    </span>
                  </div>
                </div>
                <div className="row px-2 pt-2 pb-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={updateRdv}
                    disabled={isLoading}>
                    {isLoading ? <Loader /> : "Modifier"}
                  </button>
                </div>
                <div className="row px-2 pt-2 pb-1 ">
                  <button
                    type="button"
                    id="annulerUpdateRdv"
                    className="btn btn-outline-secondary col-11 w-100 mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close">
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 d-flex justify-content-center ">
          <button
            type="button"
            id="annulerButtonDetails"
            className="btn btn-outline-secondary col-11  mt-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close">
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConsultantMissionDetails;
