import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";

function ImportFromCv({ hideModal, getAllEmployees }) {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [candidatId, setCandidatId] = useState();
  const [loading, setLoading] = useState(false);
  const [childComponent, setChildComponent] = useState();
  const [newCandidat, setNewCandidat] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    city: "",
    cityName: "",
    address: "",
    cp: "",
    phone: "",
    birthDay: "",
    birthPlace: "",
    diplomeDate: "",
    diplome: "",
    type: "",
    typename: "",
    langues: "",
    status: "",
    skills: "",
    experience: "",
    avatar: "",
    cv: "",
    cvname: "",
  });
  function handleUpload(e) {
    const file = e.target.files[0];
    setFileName(file.name);
    setFile(file);
  }
  async function handleCvUpload() {
    setLoading(!loading);
    const data = new FormData();
    data.append("cv", file);
    await axios.post(`${baseURL}/cv/add`, data).then(async (resp) => {
      const cvID = resp.data.cvId;
      axios
        .get(`${baseURL}/cv/getcvextract/${cvID}`)
        .then(async (resp) => {
          setFile(null);
          setLoading(false);
          swal(resp.data.message, {
            icon: "success",
            timer: 2500,
          });
          hideModal(false);
          getAllEmployees();
        })
        .catch((err) => {
          setFile(null);
          setLoading(false);
          hideModal(false);
          DisplayError();
        });
    });
  }

  return (
    <div>
      <div
        className="modal fade show import-modal"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 class="modal-title">Importer des consultant</h5>
              <button
                type="button"
                class="btn close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => hideModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="import-bloc">
                <label className="px-0 mb-2 text-500" for="import-csv">
                  <div>
                    <div className="text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none">
                        <path
                          d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                          stroke="#475467"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p>
                      {file != null
                        ? fileName
                        : "Cliquer pour choisir un cv ou glisser directement (PDF, Word ...)"}
                    </p>
                  </div>
                </label>
              </div>
              <input
                type="file"
                className="d-none"
                id="import-csv"
                accept=".pdf, .docx"
                onChange={(e) => {
                  handleUpload(e);
                }}
                required
              />
              <div className="input-error">
                <span className="text-danger fs-14 fw-400 ff-Inter lh-20">
                  {/* {errorMessage?.lastname} */}
                </span>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                onClick={() => hideModal(false)}>
                Annuler
              </button>
              <button
                type="button"
                onClick={handleCvUpload}
                className="btn btn-add">
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        onClick={() => {
          hideModal(false);
        }}></div>
    </div>
  );
}

export default ImportFromCv;
