import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CreateProcess from "../../components/process_interview_component/CreateProcess";
import axios from "axios";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
import UpdateProcess from "../../components/process_interview_component/UpdateProcess";
import Loader from "../../components/loader";
import EmptyData from "../../components/emptyData";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { Tooltip } from "@mui/material";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";
import ReplayIcon from '@mui/icons-material/Replay';
const Process = () => {
  const { user } = useContext(UserContext);
  const hasProcessEntretienConsultPermission = hasPermission(
    user,
    PERMISSIONS.processEntretiens,
    "consult"
  );
  const hasProcessEntretienCreatePermission = hasPermission(
    user,
    PERMISSIONS.processEntretiens,
    "create"
  );
  const hasProcessEntretienUpdatePermission = hasPermission(
    user,
    PERMISSIONS.processEntretiens,
    "edit"
  );
  const hasProcessEntretienStatusPermission = hasPermission(
    user,
    PERMISSIONS.processEntretiens,
    "change_status"
  );
  const hasCandidatsProcessEditPermission = hasPermission(
    user,
    PERMISSIONS.processCandidats,
    "update_candidat_step"
  );
  const [addProcessModal, setaddProcessModal] = useState();
  const [process, setProcess] = useState([]);
  const [data, setData] = useState();
  const [loadedProcess, setLoadedProcess] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  const containerRef = useRef(null);
  const itemsPerPage = 21;
  useEffect(() => {
    if (process == null) return;
    const filteredData = applyFilters(process, [
      (data) => filterByName(data, nameFilter),
    ]);
    if (filteredData.length > 0) {
      setData("");
      setLoadedProcess(filteredData.slice(0, loadedProcess.length + 6));
    } else {
      setLoadedProcess([]);
      setData(<EmptyData />);
    }
  }, [nameFilter]);

  function applyFilters(data, filters) {
    let filteredData = data;
    
    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (nameFilter !== "") {
      sessionStorage.setItem("nameFilterProcess", nameFilter);
    }
    return filteredData;
  }
  function filterByName(data, searchText) {
    return data.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function handleRestoreFilter() {
    const nameFilterProcess= sessionStorage.getItem("nameFilterProcess");
    if (nameFilterProcess !== null) {
      setNameFilter(nameFilterProcess);
    }
    setTimeout(() => {
      sessionStorage.removeItem("nameFilterProcess");
    }, 100);
  }
  async function getAllProcess() {
    axios
      .get(`${baseURL}/process/`)
      .then((response) => {
        if (response.data.error) return;
        const respData = response.data.data;
        setProcess(respData);
        const initialData = respData.slice(0, itemsPerPage);
        setLoadedProcess(initialData);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    if (process.length === 0) {
      setData(<EmptyData />);
    } else {
      if (process.length > 0) {
        setData("");
      }
    }
  }, [process]);

  const loadMoreData = () => {
    const startIndex = loadedProcess.length;
    const endIndex = startIndex + itemsPerPage;
    const nextData = process.slice(startIndex, endIndex);
    const newData = [...loadedProcess, ...nextData];
    setLoadedProcess(newData);
  };

  useEffect(() => {
    if (hasProcessEntretienConsultPermission) {
      setData(<Loader />);
      getAllProcess();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasProcessEntretienConsultPermission]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollHeight - container.scrollTop ===
      container.clientHeight
    ) {
      loadMoreData();
    }
  };
  async function enableDisable(processId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver ce process?"
        : "Voulez vous Activer ce process?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/process/toggleStatus/${processId}`, {})
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllProcess();
            }
          });
      }
    });
  }
  return (
    <div>
      <div className="pagetitle">
        <div className="row">
          <div className="col-md-8">
            <h1>Les processes d'entretien</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/"}>
                    <i className="bi bi-house-door"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item active">processes</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4 d-flex justify-content-end ">
            {hasProcessEntretienConsultPermission && (
              <Tooltip title={"Filtrer"} arrow>
                <button
                  className="btn btn-secondary btn-sm  mx-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <FilterAltIcon />
                </button>
              </Tooltip>
            )}
            {hasProcessEntretienCreatePermission && (
              <Tooltip title={"Ajouter"} arrow>
                <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={() =>
                    setaddProcessModal(
                      <CreateProcess
                        setAddProcessModalFromChild={setaddProcessModal}
                        getAllProcess={getAllProcess}
                      />
                    )
                  }
                >
                  <i className="bi bi-plus-lg"></i>
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <section className="section">
        <div className="card">
          <div className="row d-flex ms-2 pt-2">
            <div className="collapse p-3 row" id="collapseExample">
              <div className="col-lg-8  col-12 px-2 ">
                <h5> Nom </h5>
                <div className="row ">
                  <div className="col-sm-6 ">
                    <input
                      value={nameFilter}
                      type="text"
                      onChange={(e) => {
                        setNameFilter(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Nom"
                    />
                  </div>
                  <div className="col-3  col-md-1 pt-2 pt-sm-0">
                    <Tooltip title={"Réinitialiser le filtre"} arrow>
                      <button
                        type="button"
                        className="btn btn-secondary aligne-items-center"
                        onClick={() => {
                          setNameFilter("");
                          setData("");
                        }}
                      >
                        <FilterAltOffIcon />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="col-3 col-md-1   pt-2 pt-sm-0">
                    <Tooltip title={"Restaurer le dernier filtre"} arrow>
                      <button
                        type="button"
                        className="btn btn-secondary "
                        onClick={handleRestoreFilter}
                      >
                        <i className="fa-solid fa-filter-circle-xmark"></i>
                        <ReplayIcon />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="overflow-none"
              onScroll={handleScroll}
              ref={containerRef}
            >
              <div className="row mt-5">
                {data !== ""
                  ? data
                  : loadedProcess.map((item, key) => (
                      <div className="col-md-4 mb-3" key={key}>
                        <div className="col-md-12 mt-2 mb-3 accordion-item">
                          <div className="row ">
                            <div className="col-md-6 pt-2">
                              {hasCandidatsProcessEditPermission ? (
                                <Link
                                  className="ms-3 fw-bold style_process_title color-msit"
                                  to={`/entretien/${item.id}`}
                                >
                                  {item.name.length > 10
                                    ? `${item.name.slice(0, 10)}...`
                                    : item.name}
                                </Link>
                              ) : (
                                <div className="m-3 fw-bold style_process_title color-msit">
                                  {item.name.length > 10
                                    ? `${item.name.slice(0, 10)}...`
                                    : item.name}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                              {hasCandidatsProcessEditPermission && (
                                <Link to={`/entretien/${item.id}`}>
                                  <button
                                    type="button"
                                    className="btn text-success"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Détaills process"
                                  >
                                    <i className="bi bi-bar-chart"></i>
                                  </button>
                                </Link>
                              )}
                              {hasProcessEntretienUpdatePermission && (
                                <button
                                  type="button"
                                  className="btn text-primary"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Modifier process"
                                  onClick={() =>
                                    setaddProcessModal(
                                      <UpdateProcess
                                        setAddProcessModalFromChild={
                                          setaddProcessModal
                                        }
                                        getAllProcess={getAllProcess}
                                        processId={item.id}
                                      />
                                    )
                                  }
                                >
                                  <i className="bi bi-pen"></i>
                                </button>
                              )}
                              {hasProcessEntretienStatusPermission && (
                                <button
                                  className="btn text-primary me-2 btn"
                                  onClick={() => {
                                    enableDisable(item.id, item.isEnabled);
                                  }}
                                >
                                  {item.isEnabled === "yes" ? (
                                    <i
                                      className="text-success bi bi-toggle2-on"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Désactiver process"
                                    ></i>
                                  ) : (
                                    <i
                                      className="text-danger bi bi-toggle2-off"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Activer process"
                                    ></i>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
            {addProcessModal}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Process;
