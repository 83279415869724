import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import EmptyData from "../../components/emptyData";
import Loader from "../../components/loader";
import { baseURL } from "../../config/config";
import EditMission from "../../components/mission/updateMission";
import CreateMission from "../../components/mission/createMission";
import DisplayError from "../../components/ErrorComponants/DisplayError";
import { PERMISSIONS, hasPermission } from "../../components/checkPermissions";
import { UserContext } from "../../context/UserContext";
import NotAuthorized from "../../components/NotAuthorized";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import MissionConsultantsDisplay from "../../components/mission/missionConsultantsDisplay";

const Missions = () => {
  const { user } = useContext(UserContext);

  const hasMissionsEditPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "edit"
  );
  const hasMissionsStatusPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "change_status"
  );
  const hasMissionsConsultPermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "consult"
  );
  const hasMissionsCreatePermission = hasPermission(
    user,
    PERMISSIONS.missions,
    "create"
  );
  const hasCompanyConsultPermission = hasPermission(
    user,
    PERMISSIONS.company,
    "consult"
  );
  const hasManagersConsultPermission = hasPermission(
    user,
    PERMISSIONS.manager,
    "consult"
  );
  const [mission, setMission] = useState(null);
  const [data, setData] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  const [descriptionFilter, setDescriptionFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [societeFilter, setSocieteFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const noDataFound = "Aucune donnée trouvée";
  async function getAllMission() {
    axios
      .get(`${baseURL}/missions/allMissions`)
      .then((response) => {
        if (response.data.error) return;
        let respData = response.data.data;
        setMission(respData);
      })
      .catch((error) => {
        DisplayError();
      });
  }
  useEffect(() => {
    if (hasMissionsConsultPermission) {
      setData(<Loader />);
      getAllMission();
    } else {
      setData(<NotAuthorized />);
    }
  }, [hasMissionsConsultPermission]);

  useEffect(() => {
    if (mission == null) return;
    const filteredData = applyFilters(mission, [
      (data) => filterMissionByDescription(data, descriptionFilter),
      (data) => filterMissionByTitle(data, titleFilter),
      (data) => filterMissionBySociete(data, societeFilter),
      (data) => filterMissionByDate(data, dateFilter),
    ]);

    if (descriptionFilter || titleFilter || societeFilter || dateFilter) {
      if (filteredData.length > 0) {
        setData(
          <DataTable
            columns={columnsMission}
            className="dataTableClass"
            data={filteredData}
            pagination
            customStyles={customStyles}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            expandableRowsComponent={ExpandedComponent}
            expandableRows={isMobile <= 1280}
          />
        );
      } else {
        setData(<EmptyData />);
      }
    } else {
      setData(
        <DataTable
          className="dataTableClass"
          columns={columnsMission}
          data={mission}
          pagination
          customStyles={customStyles}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
          expandableRowsComponent={ExpandedComponent}
          expandableRows={isMobile <= 1280}
        />
      );
    }
  }, [mission, descriptionFilter, titleFilter, societeFilter, dateFilter]);

  async function enableDisableMission(missionId, isEnabled) {
    let message =
      isEnabled === "yes"
        ? "voulez vous Désactiver cette mission?"
        : "Voulez vous Activer cette mission?";

    swal({
      title: message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        axios
          .get(`${baseURL}/missions/toggleStatus/${missionId}`)
          .then((resp) => {
            let usersInfo = resp.data;

            if (!usersInfo.error) {
              getAllMission();
            }
          });
      }
    });
  }
  async function toggleToDelete(missionId) {
    await axios
      .get(`${baseURL}/missions/toggleDelete/${missionId}`)
      .then((resp) => {
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        getAllMission();
      })
      .catch(() => {
        DisplayError();
      });
  }
  function applyFilters(data, filters) {
    let filteredData = data;

    for (const filter of filters) {
      filteredData = filter(filteredData);
    }
    if (descriptionFilter !== "") {
      sessionStorage.setItem("descriptionFilterMission", descriptionFilter);
    }
    if (titleFilter !== "") {
      sessionStorage.setItem("titleFilterMission", titleFilter);
    }
    if (societeFilter !== "") {
      sessionStorage.setItem("societeFilterMission", societeFilter);
    }
    if (dateFilter !== "") {
      sessionStorage.setItem("DateFilterMission", dateFilter);
    }
    return filteredData;
  }

  function filterMissionByDescription(data, searchText) {
    return data.filter(
      (item) =>
        item.description &&
        item.description.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function filterMissionByTitle(data, searchText) {
    return data.filter(
      (item) =>
        item.title &&
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function filterMissionBySociete(data, searchText) {
    return data.filter(
      (item) =>
        item.company.name &&
        item.company.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }
  function filterMissionByDate(data, selectedDate) {
    if (!selectedDate) return data;
    return data.filter((item) => {
      const startDate = new Date(item.startDate);
      const selectedDateObj = new Date(selectedDate);

      return startDate.toDateString() === selectedDateObj.toDateString();
    });
  }
  function handleRestoreFilter() {
    const titleFilterMission = sessionStorage.getItem("titleFilterMission");
    const descriptionFilterMission = sessionStorage.getItem(
      "descriptionFilterMission"
    );
    const societeFilterMission = sessionStorage.getItem("societeFilterMission");
    const DateFilterMission = sessionStorage.getItem("DateFilterMission");
    if (titleFilterMission !== null) {
      setTitleFilter(sessionStorage.getItem("titleFilterMission"));
    }
    if (descriptionFilterMission !== null) {
      setDescriptionFilter(sessionStorage.getItem("descriptionFilterMission"));
    }
    if (societeFilterMission !== null) {
      setSocieteFilter(sessionStorage.getItem("societeFilterMission"));
    }
    if (DateFilterMission !== null) {
      setDateFilter(sessionStorage.getItem("DateFilterMission"));
    }
    setTimeout(() => {
      sessionStorage.removeItem("titleFilterMission");
      sessionStorage.removeItem("descriptionFilterMission");
      sessionStorage.removeItem("societeFilterMission");
      sessionStorage.removeItem("DateFilterMission");
    }, 100);
  }
  const handleResetFilter = () => {
    setTitleFilter("");
    setDescriptionFilter("");
    setSocieteFilter("");
    setDateFilter("");
  };
  const [selectedMissionForUpdate, setSelectedMissionForUpdate] =
    useState(null);
  function handleUpdateMissionButtonClick(missionId) {
    setSelectedMissionForUpdate(missionId);
  }
  const columnsMission = [
    {
      name: "Titre",
      selector: (row) => (
        <div>
          <Link className="color-msit " to={`/missions/details/${row?.id}`}>
            {row?.title}
          </Link>
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => (
        <div className="widthDescriptionMission">
          <div>
            {row.description ? (
              <>
                {row?.description?.substring(0, 15)}
                {row?.description?.length > 15 ? "..." : ""}
              </>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ),
      hide: "md",
      sortable: true,
      center: true,
    },
    {
      name: "Date de début ",
      selector: (row) => (
        <div>
          <div>{row?.startDate}</div>
        </div>
      ),
      hide: "lg",
      sortable: true,
      center: true,
    },
    {
      name: "Période",
      selector: (row) => (
        <div>
          <div>{row?.period}</div>
        </div>
      ),
      hide: "lg",
      sortable: true,
      center: true,
    },
    {
      name: "TJM",
      selector: (row) => (
        <div>
          <div>{row?.salary ?? noDataFound}</div>
        </div>
      ),
      hide: "md",
      sortable: true,
      center: true,
    },
    {
      name: "Contact",
      selector: (row) => (
        <div>
          <div>
            {row?.manager != null && row?.manager?.fname}
            {row?.manager != null && row?.manager?.lname}
            {" / "}
            {row?.manager != null && row?.company?.name}
          </div>
        </div>
      ),
      sortable: true,
      hide: "sm",
      center: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="row w-100 justify-content-center">
          <Dropdown className="w-auto">
            <Dropdown.Toggle bsPrefix="but-dropdown border-0 px-1 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none">
                <path
                  d="M10.4993 10.834C10.9596 10.834 11.3327 10.4609 11.3327 10.0007C11.3327 9.54041 10.9596 9.16732 10.4993 9.16732C10.0391 9.16732 9.66602 9.54041 9.66602 10.0007C9.66602 10.4609 10.0391 10.834 10.4993 10.834Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 5.00065C10.9596 5.00065 11.3327 4.62755 11.3327 4.16732C11.3327 3.70708 10.9596 3.33398 10.4993 3.33398C10.0391 3.33398 9.66602 3.70708 9.66602 4.16732C9.66602 4.62755 10.0391 5.00065 10.4993 5.00065Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4993 16.6673C10.9596 16.6673 11.3327 16.2942 11.3327 15.834C11.3327 15.3737 10.9596 15.0007 10.4993 15.0007C10.0391 15.0007 9.66602 15.3737 9.66602 15.834C9.66602 16.2942 10.0391 16.6673 10.4993 16.6673Z"
                  stroke="#98A2B3"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="candidat-dropdown-menu">
              {hasMissionsStatusPermission && (
                <Dropdown.Item className="fs-14 ff-Inter lh-20 fw-500 color-grey-54">
                  <label className="form-check form-check-single form-switch">
                    <input
                      className="form-check-input"
                      data-placement="top"
                      type="checkbox"
                      onChange={() => {
                        enableDisableMission(row?.id, row?.isEnabled);
                      }}
                      checked={row?.isEnabled === "yes"}
                    />
                    Activer la mission
                  </label>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                onClick={() => toggleToDelete(row?.id)}>
                <i className="text-danger mx-1 bi bi-trash"></i>

                <span className="ms-2">Supprimer la mission</span>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                data-bs-toggle="offcanvas"
                data-bs-target="#missionConsultantsList"
                aria-controls="missionConsultantsList"
                onClick={() => handleUpdateMissionButtonClick(row?.id)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                    stroke="#667085"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2">Liste des Consultants </span>
              </Dropdown.Item>
              <Dropdown.Item
                className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                href={`#/missions/details/${row?.id}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M1.61342 8.4761C1.52262 8.33234 1.47723 8.26046 1.45182 8.1496C1.43273 8.06632 1.43273 7.93498 1.45182 7.85171C1.47723 7.74084 1.52262 7.66896 1.61341 7.5252C2.36369 6.33721 4.59693 3.33398 8.00027 3.33398C11.4036 3.33398 13.6369 6.33721 14.3871 7.5252C14.4779 7.66896 14.5233 7.74084 14.5487 7.85171C14.5678 7.93498 14.5678 8.06632 14.5487 8.1496C14.5233 8.26046 14.4779 8.33234 14.3871 8.4761C13.6369 9.66409 11.4036 12.6673 8.00027 12.6673C4.59693 12.6673 2.36369 9.66409 1.61342 8.4761Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.00027 10.0007C9.10484 10.0007 10.0003 9.10522 10.0003 8.00065C10.0003 6.89608 9.10484 6.00065 8.00027 6.00065C6.8957 6.00065 6.00027 6.89608 6.00027 8.00065C6.00027 9.10522 6.8957 10.0007 8.00027 10.0007Z"
                    stroke="#344054"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="ms-2"> Consulter la mission </span>
              </Dropdown.Item>
              {hasMissionsEditPermission &&
                hasCompanyConsultPermission &&
                hasManagersConsultPermission && (
                  <Dropdown.Item
                    className="fs-14 ff-Inter lh-20 fw-500 color-grey-54"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#modifierMission"
                    aria-controls="modifierMission"
                    onClick={() => handleUpdateMissionButtonClick(row?.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <g clipPath="url(#clip0_192_15848)">
                        <path
                          d="M7.33398 2.66617H4.53398C3.41388 2.66617 2.85383 2.66617 2.426 2.88415C2.04968 3.0759 1.74372 3.38186 1.55197 3.75819C1.33398 4.18601 1.33398 4.74606 1.33398 5.86617V11.4662C1.33398 12.5863 1.33398 13.1463 1.55197 13.5741C1.74372 13.9505 2.04968 14.2564 2.426 14.4482C2.85383 14.6662 3.41388 14.6662 4.53398 14.6662H10.134C11.2541 14.6662 11.8141 14.6662 12.242 14.4482C12.6183 14.2564 12.9243 13.9505 13.116 13.5741C13.334 13.1463 13.334 12.5863 13.334 11.4662V8.66617M5.33397 10.6662H6.45033C6.77645 10.6662 6.93951 10.6662 7.09296 10.6293C7.22901 10.5967 7.35907 10.5428 7.47836 10.4697C7.61292 10.3872 7.72822 10.2719 7.95882 10.0413L14.334 3.66617C14.8863 3.11388 14.8863 2.21845 14.334 1.66617C13.7817 1.11388 12.8863 1.11388 12.334 1.66617L5.95881 8.04133C5.7282 8.27193 5.6129 8.38723 5.53045 8.52179C5.45734 8.64108 5.40347 8.77114 5.37081 8.90719C5.33397 9.06064 5.33397 9.2237 5.33397 9.54982V10.6662Z"
                          stroke="#344054"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_192_15848">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2">Modifier la mission</span>
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      center: true,
    },
  ];
  const ExpandedComponent = ({ data }) => (
    <pre className="w-100 px-5">
      {isMobile <= 1280 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Date de début :</div>

            <div className="color-black-98 fs-14 fw-400 lh-20 py-1 ff-Inter">
              {data?.startDate || noDataFound}
            </div>
          </div>
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">Période :</div>
            {data?.period || noDataFound}
          </div>
        </div>
      ) : (
        ""
      )}
      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-6 my-1">
            <div className="ms-1 text-details">TJM :</div>

            {data.salary || noDataFound}
          </div>

          {isMobile <= 599 ? (
            <div className="col-md-6 my-1">
              <div className="ms-1 text-details">Contact :</div>
              {data.manager !== null
                ? data?.manager?.fname +
                  " " +
                  data?.manager?.lname +
                  " / " +
                  data?.company?.name
                : noDataFound}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {isMobile <= 959 ? (
        <div className="row my-1">
          <div className="col-md-12 my-1">
            <div className="ms-1 text-details">Description :</div>

            {data.description ? (
              <Tooltip title={data.description} arrow>
                <div>
                  {data?.description?.substring(0, 60)}
                  {data?.description?.length > 60 ? "..." : ""}
                </div>
              </Tooltip>
            ) : (
              noDataFound
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <hr className="W-100"></hr>
    </pre>
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: " var(--gray-50, #F9FAFB);",
        color: "#5d7079",
      },
    },
  };
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
    const button = document.querySelector('[data-toggle="filterButton"]');
    const svgPath = button.querySelector("svg path");

    if (button && svgPath) {
      button.classList.toggle("btn-primary");

      if (svgPath.getAttribute("stroke") === "white") {
        svgPath.setAttribute("stroke", "black");
      } else {
        svgPath.setAttribute("stroke", "white");
      }
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/"}>
                  <div className="d-flex align-items-center">
                    <i className="ri-arrow-left-line icon_acceuil"></i>
                    <span className="acceuil_title">Accueil</span>
                  </div>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="div_tabRoles">
        <div className="row">
          <div className="col-md-8 ">
            <h4 className="fw-bold m-4">Liste des missions</h4>
          </div>
          <div className="col-md-4 text-sm-end text-start">
            {hasMissionsCreatePermission &&
              hasCompanyConsultPermission &&
              hasManagersConsultPermission && (
                <button
                  className="btn btn-primary m-2 m-md-4 ms-4"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#ajouterMission"
                  aria-controls="ajouterMission">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M9.99935 10.8333V5.83333M7.49935 8.33333H12.4993M15.8327 17.5V6.5C15.8327 5.09987 15.8327 4.3998 15.5602 3.86502C15.3205 3.39462 14.9381 3.01217 14.4677 2.77248C13.9329 2.5 13.2328 2.5 11.8327 2.5H8.16602C6.76588 2.5 6.06582 2.5 5.53104 2.77248C5.06063 3.01217 4.67818 3.39462 4.4385 3.86502C4.16602 4.3998 4.16602 5.09987 4.16602 6.5V17.5L9.99935 14.1667L15.8327 17.5Z"
                      stroke="white"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="ms-2"> Ajouter une mission</span>
                </button>
              )}
          </div>
          <hr className="w-100"></hr>
          <div className="row pb-2">
            <div className="col text-sm-end  text-start row  ms-1 ">
              <div>
                <button
                  className="btn btn-primary but-filtre mt-3"
                  type="button"
                  onClick={toggleFilter}
                  data-toggle="filterButton">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none">
                    <path
                      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="ms-2">Filtres</span>
                </button>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <div className=" filterCardBackground">
              <div className="row p-2 mb-2">
                <div className="col-lg-7 col-md-12 d-flex ps-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Description</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par description"
                          className="form-control inputFiltre"
                          type="search"
                          value={descriptionFilter}
                          onChange={(e) => setDescriptionFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Societe</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par societe"
                          className="form-control inputFiltre"
                          type="search"
                          value={societeFilter}
                          onChange={(e) => setSocieteFilter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 px-1">
                      <label className="text-500 mb-1">Date</label>
                      <div className="groupFiltre ">
                        <svg
                          className="iconFiltre"
                          aria-hidden="true"
                          viewBox="0 0 24 24">
                          <g>
                            <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                          </g>
                        </svg>
                        <input
                          placeholder="Filtrer par date"
                          className="form-control inputFiltre"
                          type="date"
                          value={dateFilter}
                          onChange={(e) => setDateFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 row  mt-3 pt-2">
                  <div className="col-lg-5 col-md-4 col-sm-12 pb-1 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-reset"
                      onClick={handleResetFilter}>
                      <img src={`../assets/img/repeat-04.svg`} /> Réinitialiser
                    </button>
                  </div>
                  <div className="col-lg-7 col-md-5 col-sm-12 ps-4 pe-0">
                    <button
                      type="button"
                      className="btn w-100 px-1 btn-restore"
                      onClick={handleRestoreFilter}>
                      <i className="ri-arrow-left-line px-1"></i>Dernière
                      recherche
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <section className="section">
          <div className="row card-body px-0">
            <div className="text-center">{data}</div>
          </div>
        </section>
        <CreateMission
          specification="mission"
          refreshAllMission={getAllMission}
        />

        <EditMission
          refreshAllMission={getAllMission}
          selectedMissionForUpdate={selectedMissionForUpdate}
        />
        <MissionConsultantsDisplay
          selectedMissionForUpdate={selectedMissionForUpdate}
        />
      </div>
    </div>
  );
};

export default Missions;
