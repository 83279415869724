import React, { useContext, useRef, useState } from "react";
import axios from "axios";
import CandidatsExtractingFormStep3 from "./CandidatsExtractingFormStep3";
import { CandidatContext } from "../../context/candidatContext";
import { baseURL } from "../../config/config";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const initerrorMessage = {
  companyname: [""],
  titlePoste: [""],
  secteur: [""],
  description: [""],
  typeContrat: [""],
  adress: [""],
  date_deb: [""],
  date_fin: [""],
  programminglanguage: [""],
  duration: [""],
};

const CandidatsExtractingFormStep4 = () => {
  const [company, setCompany] = React.useState();
  const [post_title, setPost_title] = React.useState();
  const [sector, setSector] = React.useState();
  const [contract_type, setContract_type] = React.useState();
  const [address, setAdresse] = React.useState();
  const [start_date, setStart_date] = React.useState();
  const [end_date, setEnd_date] = React.useState();
  const [programminglanguage, setprogramminglanguage] = useState();
  const [actual, setActual] = useState(false);
  function experienceActual() {
    setActual(!actual);
  }
  const { newCandidat } = useContext(CandidatContext);
  const initFormErrors = {
    companyname: [""],
    titlePoste: [""],
    secteur: [""],
    description: [""],
    typeContrat: [""],
    adress: [""],
    date_deb: [""],
    date_fin: [""],

    programminglanguage: [""],
    duration: [""],
  };
  const editorRef = useRef(null);

  const [formerrors, setFormErrors] = useState(initFormErrors);
  const [duration, setduration] = React.useState();
  function sendData() {
    axios
      .post(`${baseURL}/experience/create`, {
        contract_type: contract_type,
        post_title: post_title,
        company: company,
        sector: sector,
        address: address,
        description: editorRef.current.getContent(),
        start_date: start_date,
        end_date: end_date,
        programminglanguage: programminglanguage,
        duration: duration,
        actual: actual,
        candidat: newCandidat.candidateId,
      })
      .then((resp) => {
        setFormErrors(initerrorMessage);
        if (resp.data.error) return;
        swal(resp.data.message, {
          icon: "success",
          timer: 2500,
        });
        setFormErrors("");
        setCompany("");
        setPost_title("");
        setSector("");
        setContract_type("");
        setAdresse("");
        setStart_date("");
        setEnd_date("");
        setprogramminglanguage("");
        setduration("");
      if (editorRef.current) {
        editorRef.current.setContent(""); 
      }
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  return (
    <div>
      <div className="progress" style={{ height: "10px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "90%" }}
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="card">
        <div className="card-body pt-5">
          <form className="row g-3">
            <div className="row">
              <div className="row">
                <div className="col-md-6 mt-2">
                  {" "}
                  <label> titre de poste *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="titre de post"
                    onChange={(e) => setPost_title(e.target.value)}
                    value={post_title}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.post_title}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Secteur *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="secteur"
                    onChange={(e) => setSector(e.target.value)}
                    value={sector}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.sector} </span>
                  </div>
                </div>
              </div>

              <div className="row">
                {" "}
                <div className="col-md-12 mt-2">
                  <label> Nom de société *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.company}</span>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <label> Description *</label>
                <Editor
                  className="form-control"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  init={{
                    height: 200,
                    menubar: true,
                    plugins: [
                      "advcode",
                      "advlist",
                      "advtable",
                      "anchor",
                      "autocorrect",
                      "autolink",
                      "autosave",
                      "casechange",
                      "charmap",
                      "checklist",
                      "codesample",
                      "directionality",
                      "editimage",
                      "emoticons",
                      "export",
                      "footnotes",
                      "formatpainter",
                      "help",
                      "image",
                      "insertdatetime",
                      "link",
                      "linkchecker",
                      "lists",
                      "media",
                      "mediaembed",
                      "mergetags",
                      "nonbreaking",
                      "pagebreak",
                      "permanentpen",
                      "powerpaste",
                      "searchreplace",
                      "table",
                      "tableofcontents",
                      "tinymcespellchecker",
                      "typography",
                      "visualblocks",
                      "visualchars",
                      "wordcount",
                    ],
                    menu: {
                      file: {
                        title: "File",
                        items:
                          "newdocument restoredraft | preview | export print | deleteallconversations",
                      },
                      edit: {
                        title: "Edit",
                        items:
                          "undo redo | cut copy paste pastetext | selectall | searchreplace",
                      },
                      view: {
                        title: "View",
                        items:
                          "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments",
                      },
                      insert: {
                        title: "Insert",
                        items:
                          "image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
                      },
                      format: {
                        title: "Format",
                        items:
                          "bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat",
                      },
                      tools: {
                        title: "Tools",
                        items:
                          "spellchecker spellcheckerlanguage | a11ycheck code wordcount",
                      },
                      table: {
                        title: "Table",
                        items:
                          "inserttable | cell row column | advtablesort | tableprops deletetable",
                      },
                      help: { title: "Help", items: "help" },
                    },
                    toolbar:
                      "undo redo spellcheckdialog  |" +
                      " blocks fontselect fontsizeselect  |" +
                      " bold italic underline forecolor backcolor | " +
                      "link image |" +
                      " align | " +
                      " lineheight checklist bullist numlist |" +
                      " indent outdent |" +
                      " removeformat typography | help",

                    content_style:
                      "body { font-family:Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n; font-size:14px }",
                    fontsize_formats:
                      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    font_formats: [
                      "Andale Mono=andale mono,times",
                      " Arial=arial,helvetica,sans-serif",
                      " Arial Black=arial black,avant garde",
                      " Book Antiqua=book antiqua,palatino",
                      " Comic Sans MS=comic sans ms,sans-serif",
                      " Courier New=courier new,courier",
                      " Georgia=georgia,palatino",
                      " Helvetica=helvetica",
                      " Impact=impact,chicago",
                      " Symbol=symbol",
                      " Tahoma=tahoma,arial,helvetica,sans-serif",
                      " Terminal=terminal,monaco",
                      " Times New Roman=times new roman,times",
                      " Trebuchet MS=trebuchet ms,geneva",
                      " Verdana=verdana,geneva",
                      " Webdings=webdings",
                      " Wingdings=wingdings,zapf dingbats",
                    ],
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.description}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-2">
                  <label> Adresse *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Address"
                    onChange={(e) => setAdresse(e.target.value)}
                    value={address}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.address}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label>langages*</label>{" "}
                  <span className="textSkills">separation par(,)</span>
                  <input
                    type="text"
                    className="form-control"
                    id="programminglanguage"
                    onChange={(e) => setprogramminglanguage(e.target.value)}
                    value={programminglanguage}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.programminglanguage}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mt-2">
                  <label>Type de contrat *</label>
                  <select
                    className="form-select"
                    name="Type de contrat"
                    onChange={(e) => setContract_type(e.target.value)}
                    value={contract_type}
                  >
                    cdi
                    <option selected disabled></option>
                    <option value="fulltime">À plein temps</option>
                    <option value="parialtime">temps partiel</option>
                    <option value="independent">indépendant</option>
                    <option value="freelancer">freelancer</option>
                    <option value="cdd">cdd</option>
                    <option value="cdi">cdi</option>
                    <option value="internship">stage</option>
                    <option value="alternation">alternance</option>
                    <option value="seasonal">saisonnier</option>
                  </select>
                  <div className="input-error">
                    <span className="text-danger">
                      {formerrors.contract_type}
                    </span>
                  </div>
                </div>
                <div className="col-md-6 mt-2 row">
                  <div className="col-md-8">
                    <label> Durée </label>
                    <input
                      type="text"
                      className="form-control "
                      id="duration"
                      onChange={(e) => setduration(e.target.value)}
                      value={duration}
                      disabled={actual}
                      required
                    />
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <div class="form-check form-switch">
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        Actuelle
                      </label>
                      <input
                        class="form-check-input "
                        type="checkbox"
                        onChange={() => experienceActual()}
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>

                  <div className="input-error">
                    <span className="text-danger">{formerrors.duration}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-md-6 mt-2">
                  <label> Date de début *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_d"
                    onChange={(e) => setStart_date(e.target.value)}
                    value={start_date}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.start_date}</span>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <label> Date de fin *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="date_f"
                    onChange={(e) => setEnd_date(e.target.value)}
                    disabled={actual}
                    value={end_date}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.end_date}</span>
                  </div>
                </div>
              </div>
              <div className="row mb-3 mt-3">
                <div className="col-lg-4">
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-2"
                    onClick={() => {
                      sendData();
                    }}
                  >
                    Ajouter une éxperience
                  </button>
                </div>
                <div className="col-sm-3">
                  <Link
                    to={`/candidats/details/${newCandidat.candidateId}`}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Profile candidat"
                  >
                    <button
                      type="button"
                      className="btn btn-primary mt-2 mt-lg-0"
                    >
                      Terminer
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CandidatsExtractingFormStep4;
