import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../config/config";
import DisplayError from "../ErrorComponants/DisplayError";

const initFormErrors = {
  Name: [""],
};
export const Updatecategory = ({ setDisplayModal, getdata, Id }) => {
  const [Name, setName] = useState("");
  const [errorMessages, setErrorMessages] = useState(initFormErrors);
  function update() {
    if (Name === "") {
      setErrorMessages((prevState) => ({
        ...prevState,
        Name: "Ce champs est obligatoire",
      }));
    } else {
      axios
        .put(`${baseURL}/category/updatecategory/${Id}`, {
          Name: Name,
        })
        .then((res) => {
          if (res.data.error) return;
          swal(res.data.message, {
            icon: "success",
            timer: 2500,
          });
          setTimeout(() => {
            setDisplayModal("");
            getdata();
          }, 2400);
        })
        .catch((error) => {
          setErrorMessages(error.response.data);
        });
    }
  }

  function getCategory() {
    axios
      .get(`${baseURL}/category/getcategory/${Id}`)
      .then((res) => {
        setName(res.data.data.Name);
      })
      .catch((error) => {
        DisplayError();
      });
  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div
        className="modal fade show"
        id="manager_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="d-flex fw-bold justify-content-center mb-5 ">
                Modifier une catégorie
              </h3>
              <div className="row">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Nom :</label>
                    <input
                      defaultValue={Name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control form-control-lg"
                      type="text"
                      placeholder="nom catégorie"
                    />
                    <div style={{ color: "#ff0000" }}>{errorMessages.Name}</div>
                  </div>
                </form>
              </div>
              <div className="modal-footer mt-4 ">
                <button
                  type="button"
                  className="btn btn-light"
                  data-bs-dismiss="modal"
                  onClick={() => setDisplayModal("")}
                >
                  Annuler
                </button>
                <button
                  className="btn btn-primary "
                  onClick={() => {
                    update();
                  }}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
